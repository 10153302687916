import React from 'react';
import { Input } from 'antd';

import { ReactComponent as Search } from '../../../assets/icons/search.svg';

const SearchInput = ({
    name = '',
    defaultValue = '',
    handleSearch = () => { }
}) => {
    return (
        <Input
            defaultValue={defaultValue}
            prefix={<Search style={{ width: '1em' }} />}
            onChange={(e) => handleSearch(name, e.target.value)}
            size="large"
            placeholder="Search..."
            allowClear
            addonAfter={false}
            className="gx-mb-0 searchbox"
            style={{ width: '100%' }}
        />
    )
}

export default SearchInput;
