import React, { useState, useEffect } from 'react';
import { InputNumber, Radio, Form } from 'antd';
import Title from '../components/Title';
import Description from '../components/Description';
import NormalText from '../components/NormalText';
import useRules from '../hooks/useRules';

export const Donation = ({
	label,
	description,
	primaryColor,
	secondaryColor,
	options,
	textColor,
	required,
	name,
	value,
}) => {
	const rules = useRules(required);

	const [donationValue, setDonationValue] = useState();

	return (
		<div>
			<Title label={label} color={primaryColor} required={required} name={name} />
			<Description label={description} color={secondaryColor} />
			<div className="gx-d-flex" style={{ width: '100%' }}>
				<Form.Item rules={rules} name={name}>
					<Radio.Group
						value={donationValue}
						onChange={event => {
							setDonationValue(event.target.value);
						}}
						className="gx-d-flex"
					>
						{options.map(option => (
							<Radio.Button
								style={{ marginRight: '0px' }}
								key={option.id}
								value={option.value}
							>
								<NormalText color={textColor} label={option.label} />
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
				<div className="gx-d-flex">
					<Radio.Button disabled>$</Radio.Button>
					<Form.Item rules={rules} name={name} style={{ width: '100%' }}>
						<InputNumber
							type="number"
							style={{ width: '100%' }}
							placeholder="Other Amount"
							value={donationValue}
							onChange={value => {
								setDonationValue(value);
							}}
						/>
					</Form.Item>
				</div>
			</div>
		</div>
	);
};
