import React, { useState } from 'react';
import { Form, Select } from 'antd';
import Title from '../components/Title';
import Description from '../components/Description';
import useRules from '../hooks/useRules';
import NormalText from '../components/NormalText';

const { Option } = Select;

interface Props {
	name: string;
	label: string;
	description: string;
	options: Array<{
		id: any;
		label: string;
		value: any;
		disabled: boolean;
		icon?: any;
		rowvalue?: any;
	}>;
	value: any;
	onChange: any;
	defintion: string;
	required: string;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	error: string;
	display: boolean;
	unitPrice: any;
}

export const Multidrop = ({
	label,
	name,
	options,
	value,
	onChange,
	description,
	defintion,
	required,
	primaryColor,
	secondaryColor,
	textColor,
	error,
	display,
	unitPrice,
}: Props) => {
	const rules = useRules(required);

	const [units, setUnits] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);

	const setUnit = item => {
		if (item) {
			setUnits(item.label.props.label); // incase we need the unit variable later on
			let total = item.label.props.label * unitPrice;
			setTotalPrice(total);
		}
	};

	if (!required) {
		options = [
			...options.map(option => ({
				...option,
				id: option?.id,
				label: option?.label,
				value: option?.value,
				disabled: option?.disabled,
				icon: option?.icon,
				rowvalue: option?.rowvalue,
			})),
		];
	}
	return (
		<div>
			<Title
				label={label}
				color={primaryColor}
				error={error}
				required={required}
				name={name}
			/>
			<Description label={description} color={secondaryColor} />
			<div className="gx-d-flex">
				<Form.Item rules={rules} name={name}>
					<Select
						onClick={onChange}
						style={{ width: '100%' }}
						placeholder={'Select one item'}
						value={value?.value}
						onChange={setUnit}
						onClear={() => {
							setTotalPrice(0);
						}}
						defaultValue={undefined}
						allowClear={true}
						labelInValue //need this please antd select onchange only grabs value unless you specific this https://ant.design/components/select/
					>
						{options.map((option, index) => (
							<Option key={option.value} value={option.value}>
								{/* react throwing error because the valueid, i.e. the option.id is the same, 
							therefor the selection gets messed up) 
							if we change it to option.value, the navigation is fine because the valueId is the same
							*/}
								<NormalText color={textColor} label={option.label} />
							</Option>
						))}
					</Select>
				</Form.Item>
				<h3 className="gx-ml-3 gx-mt-2 fg-primary-gradient">Unit Price: ${unitPrice}</h3>

				{totalPrice > 0 && (
					<h3 className="gx-ml-3 gx-mt-2 fg-primary-gradient">Total: ${totalPrice}</h3>
				)}
			</div>
		</div>
	);
};
