import { Tag } from 'antd';
import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';

const CurrentRefinements = ({ categoriesMap = [], tagsMap=[], items, refine }) => {
    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
    return (
        <div className="custom-current-refinement-container">
            {items.length > 0 &&  [items[0]].map(item => (
                <div key={item.label}>
                    {item.items ? (
                        <React.Fragment>
                            {item.items.map(nested => (
                                <Tag
                                    key={nested.label}
                                    style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}
                                    className="refinement-tag"
                                    onClose={() => refine(nested.value)}
                                    closable
                                >
                                    {categoriesMap[nested.label] ? categoriesMap[nested.label] : tagsMap[nested.label] ? tagsMap[nested.label] : nested.label}
                                </Tag>
                            ))}
                        </React.Fragment>
                    ) : (
                        <Tag
                            key={item.label}
                            style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}
                            className="refinement-tag"
                            onClose={() => refine(item.value)}
                            closable
                        >
                                {categoriesMap[item.label] ?? item.label}
                        </Tag>
                    )}
                </div>
            ))}
        </div>
    )
}

export default connectCurrentRefinements(CurrentRefinements);