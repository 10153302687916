import React from "react";
import { Button } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const CommonQuantityCount = ({
    handleQuantityDecrement,
    quantityCount,
    handleQuantityIncrement,
    maxCount
}) => {
    return (
        <div style={{ border: '1px solid black', borderRadius: 5, padding: '5px' }} className='gx-d-flex gx-align-items-center gx-mr-2'>
            <Button
                size='small'
                type="text"
                style={{ margin: "0px 0px 0px 0px" }}
                onClick={handleQuantityDecrement}
                // disabled={quantityCount === 1 ? true : false}
            >
                <MinusOutlined style={{ color: '#a9a9a9' }} />
            </Button>
            <div style={{ marginRight: 2, marginLeft: 2, color: '#000000' }} >
                {quantityCount}
            </div>
            <Button
                size='small'
                style={{ margin: "0px 0px 0px 0px" }}
                type="text"
                onClick={handleQuantityIncrement}
                // disabled={maxCount === quantityCount ? true : false}
            >
                <PlusOutlined style={{ color: '#a9a9a9' }} />
            </Button>
        </div>
    )
}

export default CommonQuantityCount;