import { uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { requestFilterProductByPrice } from '../../../../appRedux/actions/EcommerceStore';

export const useProductFilter = () => {
	const dispatch = useDispatch();
	const productPrices = useSelector(state => state.ecommerce.storeProductPrices);
	const productPrintPrices = useSelector(state => state.ecommerce.storeProductPrintPrices);
	const filterByPrice = () => {
		// Removed  && !p.price_collection condition
		let temp = productPrices.filter(p => p.productid && p.isFetched).map(p => p.productid);
		let temp1 = productPrintPrices
			.filter(p => p.productid && p.isFetched)
			.map(p => p.productid);
		if (temp.length || temp1.length) {
			dispatch(requestFilterProductByPrice(uniq([...temp, ...temp1])));
		}
	};

	return [filterByPrice];
};
