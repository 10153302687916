import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connectStateResults } from 'react-instantsearch-dom';
import { Badge } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import ClearRefinements from '../../Common/Algolia/ClearRefinements';
import { ReactComponent as Up } from '../../../assets/icons/up.svg';

const FilterContent = ({ searchState, popOverVisible, sortBy }) => {
    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
    let currentRefinementListCount = useMemo(() => {
        let tempCount = 0;
        if (searchState?.refinementList) {
            for (const key in searchState?.refinementList) {
                if (key !== 'publicationStart' && key !== 'publicationEnd') {
                    tempCount += searchState?.refinementList[key]?.length
                }
            }
        }
        return tempCount;
    }, [searchState?.refinementList]);
    return (
        <div
            className={
                popOverVisible
                    ? "gx-d-flex gx-align-items-center gx-justify-content-between filter-header filter-header-active"
                    : "gx-d-flex gx-align-items-center gx-justify-content-between filter-header"
            }
        >
            <div>{sortBy ? 'SortBy' : 'Filter'}</div>
            <div className="gx-d-flex gx-align-items-center">
               {!sortBy ?  <>
                    {!popOverVisible ? <Badge className="gx-mr-1" style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }} count={currentRefinementListCount} /> : null}
                    {currentRefinementListCount ? <ClearRefinements /> : null}
                </> : null}
                {popOverVisible ? <Up style={{ width: '1em' }} /> : <CaretDownFilled />}
            </div>
        </div>
    )
};

export default connectStateResults(FilterContent);
