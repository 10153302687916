import {
	RECEIVE_CONSUMPTION_PRODUCTS,
	RECEIVE_CONSUMPTION_TAGS,
	RECEIVE_CONSUMPTION_CATEGORIES,
	RECEIVE_CONSUMPTION_PRODUCT_INFO,
	RECEIVE_CONSUMPTION_BUNDLE_INFO,
	RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS,
	RECEIVE_CONSUMPTION_FILES_ANALYSIS,
	STORE_CARDS_VIEW_TYPE,
	STORE_SORT_TYPE,
	STORE_EMBED_MODE,
	REQUEST_STORE_SEARCH_STATE,
	RECEIVE_OPEN_ACCESS_CONFIG,
	RECEIVE_VIDEO_START
} from 'constants/ActionTypes';
import { isEmpty } from 'lodash';
import {
	LOADING_CONSUMPTION_PRODUCTS,
	LOADING_CONSUMPTION_TAGS,
	LOADING_CONSUMPTION_CATEGORIES,
	LOADING_CONSUMPTION_CONFIG,
	RECEIVE_CONSUMPTION_CONFIG,
} from '../actions';
import { RECEIVE_VIDEO_SEEK_TIME } from '../../constants/ActionTypes';

const initialState = {
	products: [],
	productsStatus: 'idle',
	tags: [],
	tagsStatus: 'idle',
	tagsMap: {},
	categories: [],
	categoriesStatus: 'idle',
	categoriesMap: {},
	productInfoMap: {},
	bundleInfoMap: {},
	config: null,
	configStatus: 'idle',
	productAnalaysisStatus: 'idle',
	fileAnalaysisStatus: 'idle',
	cardDisplayType: 0,
	sortType: '0',
	embedmode: '0',
	searchState: {},
	moduleUrlId: '',
	categoriesMapping: {},
	advanceCategoriesMapping: {},
	seekTime: 0
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LOADING_CONSUMPTION_CONFIG:
			return { ...state, configStatus: 'loading' };

		case RECEIVE_CONSUMPTION_CONFIG: {
			if (payload.success === 0) return { ...state };
			const config = { downloadPdf: payload.data.enable_product_download ?? false, noProductsFoundMessage: payload.data.no_products_found_message ?? 'You currently do not have any products purchased.', hide_all_tags: payload.data.hide_product_tag ?? false};
			return { ...state, config, configStatus: 'success' };
		}

		case LOADING_CONSUMPTION_PRODUCTS:
			return { ...state, productsStatus: 'loading' };

		case RECEIVE_CONSUMPTION_PRODUCTS: {
			const tempProducts = payload.data.map(product => {
				let config_json = (typeof product.config_json === 'string'
					? JSON.parse(product.config_json)
					: product.config_json) ?? {};
				let authorInfo = config_json.author_info_array ?? [];
				let evaluationConfig = config_json?.evaluation_config ?? {};
				let creditConfig = config_json?.credits_array ?? [];
				let quizConfig = config_json?.quiz_config ?? {};
				let showTimes = config_json?.show_times?? false;
				let sessionStartTime = config_json?.start_time_unix ?? 0;
				let sessionEndTime = config_json?.end_time_unix ?? 0;
				return {
					id: product.product_id,
					uuid: product.product_uuid,
					title: product.product_label,
					description: product.description,
					image: product.image_url,
					categoryId: product.product_categoryid,
					tagIds: product.tags ?? [],
					isSuperBundle: product.super_bundle ?? false,
					isBundle: product.bundle ?? false,
					childrenIds: product.children?.map(childId => parseInt(childId)) ?? [],
					parentId: null,
					grandparentId: null,
					files:
						(typeof product.config_json === 'string'
							? JSON.parse(product.config_json)?.files_array
							: product.config_json?.files_array) ?? [],
					authorInfo: authorInfo.map(author => ({
						...author,
						fullname: `${author.firstname?.trim()} ${author.lastname?.trim()}`,
					})),
					author_block: product.config_json?.author_block ?? '',
					publicationDate: product.publication_date,
					date_added: product.date_added,
					quick_view_only: product.config_json?.quick_view_only ?? false,
					hide_product: product.config_json?.hide_card ?? false,
					hide_card_only: product.config_json?.hide_card_only ?? false,
					presentationOrderOf:
						(typeof product.config_json === 'string'
							? JSON.parse(product.config_json)?.presentationOrderOf
							: product?.config_json?.presentationOrderOf),
					sessionOrderOf: (typeof product.config_json === 'string'
						? JSON.parse(product.config_json)?.sessionOrderOf
						: product?.config_json?.sessionOrderOf),
					evaluation_config: evaluationConfig,
					credits_array: creditConfig,
					quiz_config: quizConfig,
					showTimes: showTimes,
					sessionStartTime: sessionStartTime,
					sessionEndTime: sessionEndTime,
					ab_order:
						(typeof product.config_json === 'string'
							? JSON.parse(product.config_json)?.ab_order
							: product?.config_json?.ab_order),
				};
			});

			const parentMap = new Map();
			let products = [];
			let replacementProductIDMap = {};
			let processProducts = [];
			// key - product to replace (session)
			// value - product to be replaced with(single child/abstract)
			//  parent/session : child/abstract
			let alreadyProcessedProductIDs = [];
			tempProducts.forEach(pro => {
				if (pro.childrenIds.length == 1) {
					let childProduct = tempProducts.find(prod => {
						return parseInt(prod.id) == pro.childrenIds[0];
					});
					if (!isEmpty(childProduct)) {
						let tempChildProduct = { ...childProduct };
						//build files array
						let tempFiles = [
							...tempChildProduct.files,
							...pro.files.filter(f => !f.fieldid),
						];
						//Inherit fields from parent
						tempChildProduct.parentId = pro.parentId;
						tempChildProduct.grandparentId = pro.grandparentId;
						tempChildProduct.files = tempFiles;
						tempChildProduct.isDerivedParent = false;
						tempChildProduct.showTimes = pro.showTimes;
						if (pro.quick_view_only) {
							tempChildProduct.quick_view_only = pro.quick_view_only;
						}
						if (pro.isSuperBundle) {
							tempChildProduct.isSuperBundle = pro.isSuperBundle;
							tempChildProduct.isDerivedParent = true;
						}
						products.push(tempChildProduct);
						alreadyProcessedProductIDs.push(tempChildProduct.id);
						replacementProductIDMap[parseInt(pro.id)] = tempChildProduct;
					} else {
						processProducts.push(pro);
					}
				} else {
					processProducts.push(pro);
				}
			});

			processProducts.forEach(prod => {
				if (!alreadyProcessedProductIDs.includes(prod.id)) {
					let tempProduct = { ...prod };
					if (prod.childrenIds.length) {
						tempProduct.childrenIds = prod.childrenIds.map(id =>
							Object.keys(replacementProductIDMap).includes(id.toString())
								? replacementProductIDMap[id].id
								: id,
						);
					}
					products.push(tempProduct);
				}
			});

			// compute parents
			for (const product of products)
				for (const childId of product.childrenIds)
					if (!parentMap.has(childId)) parentMap.set(childId, product.id);

			// assign parent to each product
			for (const product of products) product.parentId = parentMap.get(product.id);

			// compute grandparents
			for (const [productId, parentId] of parentMap.entries()) {
				const grandparentId = parentMap.get(parentId);
				if (grandparentId) parentMap.set(productId, grandparentId);
			}

			// assign grandparent to each product
			for (const product of products) product.grandparentId = parentMap.get(product.id);

			const tagIdSet = new Set(products.flatMap(p => p.tagIds));
			const categoryIdSet = new Set(products.flatMap(p => p.categoryId ?? []));

			const tagsMapping = {};

			for (const tagId of tagIdSet) {
				const mappingData = products.filter(p => p.tagIds.map(id => parseInt(id, 10)).includes(parseInt(tagId, 10))).map(p => p.id);
				tagsMapping[tagId] = mappingData;
			}

			const categoriesMapping = {};
			for (const categoryId of categoryIdSet) {
				const categoryMappingData = products.filter(p => parseInt(p.categoryId) === parseInt(categoryId) && !p.parentId).map(p => p.id);
				categoriesMapping[categoryId] = categoryMappingData;
			}

			const advanceCategoriesMapping = {};

			for (const categoryId of categoryIdSet) {
				const advanceCategoryMappingData = products.filter(p => p.categoryId === categoryId).map(p => p.id);
				advanceCategoriesMapping[categoryId] = advanceCategoryMappingData;
			}

			const authorBasedMapping = products.reduce((acc, p) => {
				let temp = { ...acc };
				for (const author of p.authorInfo) {
					if (author.country) {
						if (!temp.country[author.country]) {
							temp.country[author.country] = new Set();
						}
						temp.country[author.country].add(p.id)
					}
					if (author.company) {
						if (!temp.company[author.company]) {
							temp.company[author.company] = new Set();
						}
						temp.company[author.company].add(p.id)
					}
				}
				return temp;
			}, { country: {}, company: {} })

			const countryMapping = {};

			for (const key in authorBasedMapping.country) {
				const countryData = products.filter(item => authorBasedMapping.country[key]?.has(item.id)).map(p => p.id)
				countryMapping[key] = countryData;
			}

			const companyMapping = {};

			for (const key in authorBasedMapping.company) {
				const companyData = products.filter(item => authorBasedMapping.company[key]?.has(item.id)).map(p => p.id);
				companyMapping[key] = companyData;
			}

			return {
				...state,
				products,
				tagsMapping,
				categoriesMapping,
				advanceCategoriesMapping,
				countryMapping,
				companyMapping,
				productsStatus: 'success',
			};
		}

		case LOADING_CONSUMPTION_TAGS:
			return { ...state, tagsStatus: 'loading' };
		case RECEIVE_CONSUMPTION_TAGS: {
			const tags = payload.data;

			// map id -> label
			const tagsMap = {};
			for (const tag of tags) tagsMap[tag.id] = tag.label;

			return {
				...state,
				tags,
				tagsMap,
				tagsStatus: 'success',
			};
		}

		case LOADING_CONSUMPTION_CATEGORIES:
			return { ...state, categoriesStatus: 'loading' };
		case RECEIVE_CONSUMPTION_CATEGORIES: {
			const categories = payload.data;

			// map id -> label
			const categoriesMap = {};
			for (const category of categories)
				categoriesMap[category.category_id] = category.category_label;

			return {
				...state,
				categories,
				categoriesMap,
				categoriesStatus: 'success',
			};
		}
		case RECEIVE_CONSUMPTION_PRODUCT_INFO: {
			const { results, uuid } = payload;

			const productInfo = {
				id: results.data.product_id,
				uuid: results.data.product_uuid,
				title: results.data.product_label,
				description: results.data.product_config?.description,
				tagIds: results.data.product_config?.tags ?? [],
				files:
					results.data.product_config?.files_array.map(file => ({
						title: file.title,
						type: file.type,
						url: file.url,
						duration: file.duration,
						download: file.download ?? state.config?.downloadPdf ?? false, // config overwrite if exists
					})) ?? [],
			};

			return {
				...state,
				productInfoMap: {
					...state.productInfoMap,
					[uuid]: productInfo,
				},
			};
		}
		case RECEIVE_CONSUMPTION_BUNDLE_INFO: {
			const { results, uuid } = payload;

			let bundleInfo = results.data.map(child => ({
				id: child.product_id,
				uuid: child.product_uuid,
				title: child.product_label,
				description: child.product_config?.description,
				tagIds: child.product_config?.tagid ?? [],
				childrenIds: child.product_children ?? [],
				children: [],
			}));

			const childrenIds = [];
			// aggregate children into parents
			for (const product of bundleInfo) {
				for (const childId of product.childrenIds) {
					const child = bundleInfo.find(b => b.id === childId);
					if (!child) {
						console.error(`bundle ${product.id} has invalid childId`);
						continue;
					}
					childrenIds.push(childId);
					product.children.push(child);
				}
			}

			// remove childrens
			bundleInfo = bundleInfo.filter(product => !childrenIds.includes(product.id));

			return {
				...state,
				bundleInfoMap: {
					...state.bundleInfoMap,
					[uuid]: bundleInfo,
				},
			};
		}
		case RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS: {
			return { ...state, productAnalaysisStatus: 'success' };
		}
		case RECEIVE_CONSUMPTION_FILES_ANALYSIS: {
			return { ...state, fileAnalaysisStatus: 'success' };
		}
		case STORE_EMBED_MODE: {
			return { ...state, embedmode: payload.embedmode };
		}
		case STORE_CARDS_VIEW_TYPE: {
			return { ...state, ...payload };
		}
		case STORE_SORT_TYPE: {
			return { ...state, ...payload };
		}
		case REQUEST_STORE_SEARCH_STATE: {
			return {
				...state,
				searchState: {
					...state.searchState,
					...payload.searchState,
				},
			};
		}
		case RECEIVE_OPEN_ACCESS_CONFIG: {
			return {
				...state,
				moduleUrlId: payload.moduleUrlId,
				hasOpenAccess: payload.hasOpenAccess,
				hasDirectAccess: payload.hasDirectAccess,
				hasProductAccess: payload.hasProductAccess,
			};
		}
		case RECEIVE_VIDEO_START: {
			return {
				...state
			}
		}
		case RECEIVE_VIDEO_SEEK_TIME: {
			return {
				...state,
				seekTime: payload.seek_time
			}
		}
		default:
			return state;
	}
};
