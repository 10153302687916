import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Tabs, Skeleton, Card, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import Iframe from 'react-iframe';

import { FILE } from 'components/Common/constants';
import BundleDetails from './BundleDetails';
import Presentations from './Presentations';
// import FileList from './FileLists';

import { hitsPerPage } from '../../constants';
import { useProducts } from '../../hooks/useProducts';
import { storeProductAnalysisRequest } from '../../../../appRedux/actions';
import auth from '../../../../loginAuth/loginAuth';
import { ReactComponent as Certificate } from '../../../../assets/icons/Certificate.svg';

import loader from '../../../../assets/images/loader.svg'

const ConsumptionArea = ({ product, expanded, searchState = {} }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { bundleId, superBundleId, productId } = useParams();
	const [page, setPage] = useState(0);
	const [bundlePageNum, setBundlePageNum] = useState(0);

	const [activeTab, setActiveTab] = useState("0");
	const [resetTabs, setResetChildTabs] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const appdir = useSelector(state => state.loginInfo.appdir);
	const contactUid = useSelector(state => state.auth.contact_uuid);
	const embedmode = useSelector(state => state.consumption.embedmode);
	const hasFreeAccess = new URLSearchParams(history.location.search).get("openAccess");
	const source = useSelector(state => state.loginInfo.source_hex);
	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

	const [prodsLoading, products] = useProducts();

	let id = bundleId;
	if (superBundleId && bundleId) {
		id = bundleId;
	} else if (superBundleId) {
		id = superBundleId;
	} else if (productId && !superBundleId && !bundleId) {
		id = productId;
	}
	const bundle = useMemo(() => products.find(prod => prod.id === parseInt(id)), [products]);
	const productInfo = useMemo(() => products.find(prod => prod.id === parseInt(productId)), [products, productId]);

	const children = useMemo(
		() => products.filter(p => bundle?.childrenIds.some(childId => childId === p.id)),
		[products, bundle],
	);

	const superBundle = useMemo(() => products.find(prod => prod.id === parseInt(superBundleId)), [
		product,
	]);

	useEffect(() => {
		if (!prodsLoading) {
			if (productId && bundleId) {
				setSelectedProduct(productInfo);
				setActiveTab('4');
			} else if (!productId && bundleId && superBundleId) {
				setSelectedProduct(productInfo);
			}
			if (product?.files?.length === 0 && product?.childrenIds?.length > 0 && bundleId) {
				setActiveTab('4');
			}
			//  else {
			// 	setSelectedProduct(children[0]);
			// }
		}
	}, [prodsLoading, productId]);

	useEffect(() => {
		if (product) {
			storeProductAnalysis(product);
		}
	}, [product]);

	useEffect(() => {
		if (!bundle?.isSuperBundle && bundle?.isBundle && children?.length === 1) {
			setSelectedProduct(children[0]);
		}
	}, [bundle, children])

	useEffect(() => {
		if (productId) {
			let selectedIndex = children.findIndex(prod => prod.id === parseInt(productId));
			if (selectedIndex >= 0) {
				let tempPage = ((selectedIndex + 1) % hitsPerPage) === 0
					? (parseInt((selectedIndex + 1) / hitsPerPage) - 1)
					: parseInt((selectedIndex + 1) / hitsPerPage);
				setPage(tempPage);
			}
			setSelectedProduct(productInfo);
		} else {
			setSelectedProduct(children[0]);
		}
	}, [productId]);

	useEffect(() => {
		if ((bundle?.files?.length === 1 && !superBundleId) || (superBundleId && bundleId) || (productId)) {
			setSelectedFile(bundle?.files?.[0]);
		} else {
			handleSelectFile(null);
		}
	}, [bundle?.files])

	useEffect(() => {
		if (bundle && !bundle?.description && !bundle?.files?.length && !bundle.isSuperBundle && bundleId) {
			handleTabChange('4', productInfo);
		}
	}, [bundle])

	useEffect(() => {
		if (superBundleId && bundleId && !productId && selectedProduct && !bundle?.isSuperBundle && bundle?.isBundle && children?.length > 1) {
			setSelectedProduct(null);
		}
	}, [superBundleId, bundleId, productId, selectedProduct, bundle, children]);

	useEffect(() => {
		if (hasFreeAccess && !product?.files?.length && product?.childrenIds.length && product?.isBundle && !product?.isSuperBundle) {
			setActiveTab('4')
		}
	}, [activeTab, product, hasFreeAccess])

	const storeProductAnalysis = (product) => {
		dispatch(storeProductAnalysisRequest({
			appdir,
			contactUid: contactUid === ''
				? JSON.parse(auth.getCookie('contact_uuid'))
				: contactUid,
			productId: product.id
		}));
	};

	const handleBundlePageChange = (value) => {
		setPage(value);
		setBundlePageNum(value);
	};

	const handleSelectProduct = (data) => {
		if (data) {
			if (bundleId && productId && !superBundleId) {
				// to display the product in the bundle page directly
				history.push(
					`/${history.location.pathname.split('/')[1]
					}/consumption/bundle/${bundleId}/${data.id}${history.location.search}`,
				);
			} else if (bundleId && productId && superBundleId) {
				// to display the product in the group consumption(grand parent) page directly
				history.push(
					`/${history.location.pathname.split('/')[1]
					}/consumption/group/${superBundleId}/${bundleId}/${data.id}${history.location.search}`,
				);
			}
			scrollToView();
		} else if (!data && bundleId && superBundleId) {
			history.push(
				`/${history.location.pathname.split('/')[1]
				}/consumption/group/${superBundleId}/${bundleId}${history.location.search}`,
			);
		}
		setSelectedProduct(data);
	};

	const gotoProduct = (product, searchData) => {
		if (!product.isSuperBundle && !product.isBundle && bundle?.isSuperBundle) {
			// Redirection for the product directly associated with the grand parent.
			history.push(`../product/${product.id}${history.location.search}`);
		} else if (!product.isSuperBundle && !product.isBundle && bundle?.isBundle && !superBundleId) {
			// to display the product in the bundle page
			if (productId) {
				history.push(
					`/${history.location.pathname.split('/')[1]
					}/consumption/bundle/${product.parentId}/${product.id}${history.location.search}`,
				);
			}
			handleTabChange('4', product);
		} else if (!product.isSuperBundle && !product.isBundle) {
			// to display the product in the group consumption(grand parent) page.
			// if (superBundleId && bundleId && productId) {
			if (superBundleId && bundleId) {
				history.push(
					`/${history.location.pathname.split('/')[1]
					}/consumption/group/${superBundleId}/${bundleId}/${product.id}${history.location.search}`,
				);
			}
			handleTabChange('4', product);
		} else if (searchData) {
			// to redirect to the group consumption(grand parent) page while click on the bundle associated with grand parent.
			history.push(
				`../group/${product.parentId}/${searchData.id}${history.location.search}`,
			)
		} else {
			const route = product.isSuperBundle ? 'selection' : product.isBundle ? 'bundle' : 'product';
			history.push(`../${route}/${product.id}${history.location.search}`);
		}
		scrollToView();
	};

	const handleSelectFile = (data) => {
		if (data?.type === FILE.LINK) {
			window.open(data?.url);
			return;
		}
		setSelectedFile(data);
	};

	const scrollToView = () => {
		try {
			if (window.parent && window.parent) {
				// window.parent.scrollTo(0, 0);
				window.parent.postMessage({ type: 'scrollToTop' }, '*');

			} else {
				window.scrollTo(0, 0);
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleTabChange = (key, productData) => {
		if (activeTab === '4' && key === activeTab) {
			setResetChildTabs(true);
			if (!productData) {
				handleSelectProduct(null);
			}
		}
		setActiveTab(key);
		scrollToView();
		setSelectedProduct(productData);
		if (bundle?.files?.length === 1) {
			setSelectedFile(bundle?.files?.[0]);
		} else {
			handleSelectFile(null);
		}
	};

	const handleResetChildTabs = () => {
		setResetChildTabs(false);
	};

	const renderTabBar = (props, DefaultTabBar) => (
		<DefaultTabBar {...props} className="gx-px-5 gx-pt-1 gx-pb-2 gx-mb-0 gx-bg-grey" />
	);

	if (prodsLoading)
		return (
			<div className='loader'>
				<img src={loader} alt='loader' />
			</div>
		);

	if (bundle?.isSuperBundle && bundle?.isBundle && !bundle.isDerivedParent && !bundleId
		&& ((!bundle?.files?.length && !bundle?.description) || (embedmode === '1'))) {
		return (
			<main className="gx-d-flex gx-flex-column gx-h-100">
				<section
					className="fshadow-top-1 gx-px-5"
					style={{ zIndex: 4, flex: '1 1 auto' }}
				>
					<BundleDetails
						bundle={product}
						page={bundlePageNum}
						setPage={handleBundlePageChange}
						selectedProduct={selectedProduct}
						handleTabChange={handleTabChange}
						scrollToView={scrollToView}
						gotoProduct={gotoProduct}
						selectedFile={selectedFile}
						handleSelectFile={handleSelectFile}
						searchState={searchState}
					/>
				</section>
			</main>
		)
	}

	return (
		<main className="gx-d-flex gx-flex-column gx-h-100">
			{((children?.length > 1 && !superBundle?.quick_view_only)
				|| bundle?.isSuperBundle
				|| (!bundle?.isSuperBundle && !bundle?.isBundle)
			) ? (<section
				className="fshadow-top-1"
				style={{ zIndex: 4, flex: '1 1 auto' }}
			>
				<Tabs
					defaultActiveKey={0}
					size="default"
					renderTabBar={renderTabBar}
					activeKey={activeTab}
					onChange={handleTabChange}
				>
					{((bundle?.description || bundle?.files?.length) ||
						(bundle?.isSuperBundle && bundle?.isBundle && !bundleId)) ? (
						<Tabs.TabPane
							key={0}
							className="bundle-tab-pane gx-px-5 gx-pt-2"
							tab={
								<div className="gx-d-flex gx-align-items-center">
									<span>Description</span>
								</div>
							}
						>
							<BundleDetails
								bundle={product}
								page={bundlePageNum}
								setPage={handleBundlePageChange}
								// selectedProduct={selectedProduct}
								handleTabChange={handleTabChange}
								scrollToView={scrollToView}
								gotoProduct={gotoProduct}
								selectedFile={selectedFile}
								handleSelectFile={handleSelectFile}
								searchState={searchState}
							/>
							{/* {((bundle.files.length) && ((superBundleId && bundleId) || (productId))) ? (
								<FileList
									bundle={bundle}
									selectedFile={selectedFile}
									handleSelectFile={handleSelectFile}
								/>
							) : null} */}
						</Tabs.TabPane>
					) : null}
					{/* {bundle?.files?.length ? (
						<Tabs.TabPane
							key={1}
							// className="gx-px-5 gx-pt-2"
							tab={
								<div className="gx-d-flex gx-align-items-center fg-grey">
									<span>Files</span>
								</div>
							}
						>
							<FileList
								bundle={bundle}
								selectedFile={selectedFile}
								handleSelectFile={handleSelectFile}
							/>
						</Tabs.TabPane>
					) : null} */}
					{/* <Tabs.TabPane
						key={2}
						className="gx-px-5 gx-pt-2"
						tab={
							<div className="gx-d-flex gx-align-items-center">
								<span>Evaluations</span>
							</div>
						}
					>
						<h4 className="fg-grey">These are the evaluations</h4>
					</Tabs.TabPane>
					<Tabs.TabPane
						key={3}
						className="gx-px-5 gx-pt-2"
						tab={
							<div className="gx-d-flex gx-align-items-center">
								<span>Discussions</span>
							</div>
						}
					>
						<h4 className="fg-grey">These are the discussions</h4>
					</Tabs.TabPane> */}
					{/* Hidden the Presentation tab on the product and grand parent page */}
					{(!product?.isSuperBundle && !product?.isBundle && !bundleId && !superBundleId) || (bundle?.isSuperBundle && bundle?.isBundle && !bundleId)
						? null
						: <Tabs.TabPane
							key={4}
							// className="gx-px-5 gx-pt-2"
							tab={
								<div className="gx-d-flex gx-align-items-center">
									<span>Presentations</span>
								</div>
							}
						>
							{activeTab === '4'
								? (
									<Presentations
										loading={prodsLoading}
										children={children}
										product={selectedProduct}
										expanded={expanded}
										page={page}
										resetTabs={resetTabs}
										setPage={(value) => {
											setPage(value);
											scrollToView();
										}}
										handleSelectProduct={handleSelectProduct}
										gotoProduct={gotoProduct}
										handleReset={handleResetChildTabs}
										superBundle={superBundle}
									/>
								) : null}
						</Tabs.TabPane>}
					{appdir === 'demo' ? (<>
						{(bundle?.isBundle && !bundle?.isSuperBundle && !_isEmpty(product?.evaluation_config) && !productId) ?
							<Tabs.TabPane
								key={5}
								tab={
									<div className="gx-d-flex gx-align-items-center">
										<span>Evaluation</span>
									</div>
								}
							>
								<div className='evaluationWrapper'>
									<Iframe url={`https://masterapp.econference.io/masterapp_summer2012/proceedings_v2/forms/index.cfm?ID=${product?.evaluation_config?.urlid}&remote_embed=1&apiv2=1&hide_title=1&uid=${contactUid}&&productid=${product?.uuid}&source=${source}`} height="700px" width='100%' frameBorder="3" />
								</div>
							</Tabs.TabPane> : null}
						{(bundle?.isBundle && !bundle?.isSuperBundle && !_isEmpty(product?.quiz_config) && !productId) ?
							(<Tabs.TabPane
								key={6}
								tab={
									<div className="gx-d-flex gx-align-items-center">
										<span>Quiz</span>
									</div>
								}
							>
								<></>
							</Tabs.TabPane>) : null}
						{(bundle?.isBundle && !bundle?.isSuperBundle && !_isEmpty(product?.credits_array) && !productId) ?
							(<Tabs.TabPane
								key={7}
								tab={
									<div className="gx-d-flex gx-align-items-center">
										<span>Credits</span>
									</div>
								}
							>
								<div>
									{product?.credits_array?.map((item) => {
										return (
											<Card
												className="card gx-mb-0 gx-pointer gx-h-100 product-card shadow-1"
												style={{ margin: '1rem', maxWidth: '70%' }}
											>
												<div className='gx-d-flex gx-align-items-center gx-justify-content-between'>
													<div className='gx-d-flex gx-align-items-center' style={{ gap: '3rem' }}>
														<div className='gx-d-flex gx-align-items-center' style={{ minWidth: '9rem' }}>
															<div style={{ marginBottom: 10, marginTop: '1rem' }}>
																<Certificate style={{ width: '3rem', marginRight: 4 }} />
															</div>
															<div className='credit-label'>{item?.label}</div>
														</div>
														<div className=' credit-label'>
															{`${item?.value}.0 Credits`}
														</div>
													</div>

													<div style={{ textAlign: 'end' }}>
														<Button
															style={{
																background: styling['primary-dark-color'] || "#993333",
																color: '#ffff'
															}}
														>
															Claim Credits
														</Button>
													</div>
												</div>
											</Card>
										)
									})}
								</div>
							</Tabs.TabPane>) : null}
					</>) : null}
				</Tabs>
			</section>) : (
				<Presentations
					loading={prodsLoading}
					children={children}
					product={selectedProduct}
					expanded={expanded}
					page={page}
					resetTabs={resetTabs}
					setPage={(value) => {
						setPage(value);
						scrollToView();
					}}
					handleSelectProduct={handleSelectProduct}
					gotoProduct={gotoProduct}
					handleReset={handleResetChildTabs}
					superBundle={superBundle}
				/>
			)}
		</main>
	);
};

export default ConsumptionArea;
