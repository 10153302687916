import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import auth from '../../../loginAuth/loginAuth';
import { Col, Row, Button, Form, Card, Alert } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import PayNow from '../../Common/PayNow';
import RegistrationItems from '../../Common/RegistrationItems';
import PaymentOptions from '../../Common/PaymentOptions';
import FreeItemPayment from '../../Common/FreeItemPayment';

import { getRegistrationRoute, getRegistrationPublicRoute } from '../helpers.js';
import CheckoutSignIn from '../../Ecommerce/CheckoutSignin/CheckoutSignin';

import {
	requestDeleteCartItem,
	requestDeleteCartItemNoLogin,
	requestPaymentSettings,
	requestCartInfo,
	requestAttenuuidInfo,
	requestRequestAddItemApiNoLogin,
	requestPublicToPrivateCart,
	requestSelectionArray,
	requestDeleteNonProcessedPayment,
	requestPrevScreen,
	requestResetScreenNum,
} from '../../../appRedux/actions/Ecommerce';

import { requestAttendeeSettings } from '../../../appRedux/actions/EcommerceStore';

import Widget from '../../Widget';
import CircularProgress from '../../CircularProgress';

import { ReactComponent as BagIcon } from '../../../assets/icons/bag.svg';

//PaymentItemsArray for existing payments
const RegistrationCheckout = props => {
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(true);
	const { moduleUrlId } = useParams();
	const [simpleinvoiceobj, setSimpleinvoiceobj] = useState({}); // this is the initial values for the form component

	const history = useHistory();
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');
	const [questionFormRequired, setQuestionFormRequired] = useState(false);
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	const loading = false;

	const appdir = useSelector(state => state.loginInfo.appdir);

	const menuObjects = useSelector(state => state.ecommerce.menuObjects);

	const attenuuid = useSelector(state => state.ecommerce.attenuuid);
	const cartUuid = useSelector(state => state.ecommerce.cartUuid);
	const cartItems = useSelector(state => state.ecommerce.cartItems);
	const oldCartItems = useSelector(state => state.ecommerce.oldCartItems);

	const selectionArrayFetched = useSelector(state => state.ecommerce.selectionArrayFetched);
	const attendeeSettingsFetched = useSelector(state => state.ecommerce.attendeeSettingsFetched);
	const languageObject = useSelector(
		state => state.ecommerce.attendeeSettings?.pagelanguagestruct,
	);
	const paymentSettings = useSelector(state => state.ecommerce.paymentSettings);

	const paymentSettingsFetched = useSelector(state => state.ecommerce.paymentSettingsFetched);
	const specialCheckoutFormSubmitted = useSelector(
		state => state.ecommerce.specialCheckoutFormSubmitted,
	);
	const attendeeStatus = useSelector(state => state.ecommerce.status);

	const paymentSuccess = useSelector(state => state.common?.paymentProgress.SUCCESS);
	const publicCheckout = useSelector(
		state =>
			state.ecommerce?.attendeeSettings?.attendeesiteadmin?.generalconfigobject
				?.public_checkout,
	);

	const width = useSelector(state => state.settings.width);
	const mobileThreshold = useSelector(state => state.common.mobileThreshold);

	const publicEmbed =
		useSelector(state => state.settings.publicEmbed) ||
		window.location.search.includes('public_embed=1'); // on the refresh the redux doesn't set public_embed, need windows.location.search for safety

	const jwt = publicEmbed ? props.auth.jwt : JSON.parse(auth.getCookie('jwt'));

	const returnPaymentApproved = success => {
		setPaymentSuccessful(success);
	};

	const deleteItem = product => {
		if (publicEmbed && !attenuuid) {
			let tempInvoiceItemArray = cartItems.InvoiceItemArray;
			tempInvoiceItemArray = tempInvoiceItemArray.filter(function (obj) {
				return obj.field_id !== product.fieldId;
			});

			let newCartItems = [];
			for (let i = 0; i < tempInvoiceItemArray.length; i++) {
				let currProduct = {};
				currProduct[tempInvoiceItemArray[i].field_name] = {
					valueid: tempInvoiceItemArray[i].value_id,
					qty: 1,
				};

				newCartItems.push(currProduct);
			}

			let formData = new FormData();
			formData.append('itemstoadd', JSON.stringify(newCartItems));
			formData.append('cartUuid', JSON.stringify(cartUuid));

			dispatch(
				requestRequestAddItemApiNoLogin({
					appdir,
					moduleUrlId,
					formData,
					cartUuid,
				}),
			);

			dispatch(requestDeleteCartItemNoLogin(product));
		} else {
			let myProduct = {};
			myProduct['field_id'] = product.fieldId;
			myProduct['invoice_id'] = product.invoiceId;
			myProduct = JSON.stringify(myProduct);

			let formData = new FormData();
			formData.append('itemstodelete', myProduct);

			dispatch(
				requestDeleteCartItem({
					appdir,
					moduleUrlId,
					attenuuid,
					formData,
				}),
			);
		}
	};

	const clickBackToSelection = publicEmbed => {
		dispatch(requestPrevScreen());
		history.push(
			publicEmbed
				? getRegistrationPublicRoute(moduleUrlId)
				: getRegistrationRoute(moduleUrlId),
		);
	};

	const deleteNonProcessedPayment = paymentId => {
		let formData = new FormData();
		formData.append('paymentid', JSON.stringify(paymentId));

		dispatch(requestDeleteNonProcessedPayment({ moduleUrlId, attenuuid, formData }));
	};

	useEffect(() => {
		if (publicEmbed && !cartItems && !jwt) {
			history.push(
				publicEmbed
					? getRegistrationPublicRoute(moduleUrlId)
					: getRegistrationRoute(moduleUrlId),
			);
		}

		if (!attendeeSettingsFetched) {
			dispatch(requestAttendeeSettings({ appdir, moduleUrlId }));
		}
	}, [cartItems]);

	useEffect(() => {
		if (!attenuuid) {
			dispatch(requestAttenuuidInfo({ appdir, moduleUrlId }));
		}
		if (cartItems?.simpleinvoiceobj && attenuuid) {
			setSimpleinvoiceobj(cartItems.simpleinvoiceobj);
		}
	}, []);

	useEffect(() => {
		if (!paymentSettings || !Object.keys(paymentSettings).length) {
			dispatch(
				requestPaymentSettings({
					appdir,
					moduleUrlId,
					publicEmbed,
				}),
			);
		}
		if (publicEmbed && jwt) {
			dispatch(
				requestAttenuuidInfo({
					appdir,
					moduleUrlId,
				}),
			);
		}
	}, [jwt, paymentSettings]);

	useEffect(() => {
		if (!attenuuid) return;

		if (publicEmbed && jwt) {
			dispatch(
				requestPublicToPrivateCart({
					moduleUrlId,
					cartUuid,
					attenuuid,
				}),
			);
		}

		// coming from public embed, need to first call publicToPrivate api first before we call this
		// if coming from normal, we call this right away
		if (!publicEmbed) {
			dispatch(
				requestCartInfo({
					appdir,
					moduleUrlId,
					attenuuid,
				}),
			);
		}
	}, [attenuuid]);

	useEffect(() => {
		if (cartItems && Object.keys(paymentSettings).length !== 0) {
			setLoader(false);
		}
	}, [cartItems, paymentSettings]);

	useEffect(() => {
		if (cartItems?.simpleinvoiceobj && attenuuid) {
			setSimpleinvoiceobj(cartItems.simpleinvoiceobj);
		}
	}, [cartItems.simpleinvoiceobj, jwt]);

	useEffect(() => {
		if (!selectionArrayFetched && appdir && jwt) {
			dispatch(
				requestSelectionArray({
					appdir,
					moduleUrlId,
					publicEmbed,
				}),
			);
		}
	}, [selectionArrayFetched, appdir, jwt]);

	useEffect(() => {
		const questions = menuObjects[0]?.fieldids;

		if (!questions) return;
		if (questions.some(question => question.required === 1)) setQuestionFormRequired(true);
	}, [menuObjects]);

	return (
		<div className="container">
			{loader ||
				(loading && (
					<Widget styleName="gx-card">
						<CircularProgress className="gx-profile-content" />
					</Widget>
				))}

			<Button
				className="button-primary gx-font-weight-semi-bold shadow-2 gx-mb-3"
				shape="round"
				onClick={() => clickBackToSelection(publicEmbed)}
			>
				Continue Shopping
			</Button>

			<Row>
				<Col xl={12} lg={12} md={24} sm={24} xs={24}>
					{cartItems && (
						<div>
							<Card className="card">
								<div className="">
									<div>
										<div
											style={{ float: 'left', paddingTop: '2%' }}
											className="gx-text-secondary gx-font-weight-semi-bold gx-page-title gx-ml-1 gx-mt-1"
										>
											{languageObject?.title ?? 'Checkout'}
										</div>
										<BagIcon
											className="fg-primary-dark-light"
											style={{ float: 'right', width: '3em' }}
										/>
									</div>

									<RegistrationItems
										languageObject={{
											invoice_items_header:
												languageObject?.Checkout_ItemsHeader ?? '',
											invoice_amount_header:
												languageObject?.Checkout_InvoiceStatementTotalAmount ??
												'',
											total_invoiced_header:
												languageObject?.Checkout_TotalInvoiceAmount ?? '',
											payments_header:
												languageObject?.Checkout_PaymentsReceived ?? '',
											non_processed_payment:
												languageObject?.Checkout_NotProcessed ?? '',
											net_owing_header:
												languageObject?.Checkout_NetOwing ?? '',
											pay_now_by: languageObject?.Checkout_PayNowBy ?? '',
											title: 'Check Out',
										}}
										cartItems={oldCartItems ? oldCartItems : cartItems}
										paymentSettings={paymentSettings}
										onDelete={deleteItem}
										onDeletePayment={deleteNonProcessedPayment}
										publicUrlCheck={publicEmbed}
										renderPaymentComponent={setCurrentPaymentMethod}
										width={width}
										mobileThreshold={mobileThreshold}
										module={'registration'}
									/>
								</div>

								{attendeeStatus === 'Incomplete' &&
									Object.entries(simpleinvoiceobj).length &&
									parseInt(cartItems?.NetOwing) === 0 && (
										<div className="">
											<FreeItemPayment
												moduleUrlId={moduleUrlId}
												attenuuid={attenuuid}
												moduleIdentifier={'ecommerce'}
												paymentSettings={paymentSettings}
											/>
										</div>
									)}

								<div>
									<div className="gx-d-flex">
										<div>
											{menuObjects[0]?.screen_text &&
												jwt &&
												specialCheckoutFormSubmitted === 0 &&
												attenuuid &&
												Object.entries(simpleinvoiceobj).length > 0 && (
													<div
														className="gx-ml-4"
														dangerouslySetInnerHTML={{
															__html: menuObjects[0].screen_text,
														}}
													></div>
												)}
										</div>
									</div>
								</div>
								<div className="">
									{Object.keys(paymentSettings)?.length &&
										!paymentSuccess &&
										jwt && (
											<PaymentOptions
												renderPaymentComponent={setCurrentPaymentMethod}
												currentPaymentMethod={currentPaymentMethod}
												cartItems={oldCartItems || cartItems}
												paymentSettings={paymentSettings}
											/>
										)}
								</div>
							</Card>
						</div>
					)}
				</Col>

				<Col xl={12} lg={12} md={24} sm={24} xs={24}>
					{!jwt && !publicCheckout && <CheckoutSignIn />}

					{paymentSettings !== undefined &&
						Object.keys(paymentSettings).length !== 0 &&
						// parseInt(cartItems.NetOwing) > 0 &&
						(currentPaymentMethod || paymentSuccess) &&
						jwt && (
							<PayNow
								cartItems={
									Object.keys(cartItems).length !== 0 ? cartItems : oldCartItems
								}
								paymentSettings={paymentSettings}
								netOwing={
									Object.keys(cartItems).length !== 0
										? cartItems.NetOwing
										: oldCartItems.NetOwing
								}
								totalPaid={
									Object.keys(cartItems).length !== 0
										? cartItems.TotalPayment
										: oldCartItems.TotalPayment
								}
								attenuuid={attenuuid}
								moduleIdentifier={'registration'}
								appdir={appdir}
								currentPaymentMethod={currentPaymentMethod}
								setCurrentPaymentMethod={setCurrentPaymentMethod}
								returnPaymentApproved={returnPaymentApproved}
								publicEmbed={publicEmbed}
								status={attendeeStatus}
							/>
						)}
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
	};
};

export default connect(mapStateToProps)(RegistrationCheckout);
