import moment from "moment";

const parseFinancialItem = ({ rowvalue, valuedate, valuedate2, rowvalue2, rowvalue3 }) => {
	let value = rowvalue, showDateDescription = false, date1Opacity = 1, date2Opacity = 1, date3Opacity = 1;
	const initialDate = moment.utc(valuedate).format("MMM DD, YYYY"), midDate = moment.utc(valuedate2).format("MMM DD, YYYY");
	if (valuedate?.length && valuedate2?.length) {
		const date2diff = moment(initialDate).diff(moment.now(), 'days') >= 0;
		const date1diff = moment(midDate).diff(moment.now(), 'days') >= 0;
		value = date2diff ? rowvalue2 : rowvalue3;
		showDateDescription = true;
		if (date1diff) { date2Opacity = 0.5; date3Opacity = 0.5; }
		else if (date2diff && !date1diff) { date1Opacity = 0.5; date3Opacity = 0.5; }
		else { date2Opacity = 0.5; date1Opacity = 0.5; }
	}
	return ({
		value, midDate, initialDate,
		initialAmount: rowvalue,
		midAmount: rowvalue2,
		lastAmount: rowvalue3,
		showDateDescription, date1Opacity, date2Opacity, date3Opacity
	})
}

export const parseFormItemValue = value => ({
	id: value.valueid,
	label: value.rowlabel,
	text: value.rowtext,
	checked: value.valueischeckedbydefault,
	valuemetajson: value.valuemetajson,
	...parseFinancialItem(value),
});

export const parseFormItem = field => ({
	id: field?.fieldid,
	type: field?.fieldtype,
	styles: {},
	value: null,
	name: field?.fieldname,
	label: field?.fieldlabel,
	description: field?.fielddescription,
	defintion: field?.field_definition,
	contentType: field?.field_content_type,
	required: field?.required == 1,
	pricing: field?.valuefield == 1,
	richText: field?.richtext == 1,
	options: field?.valueids ? field?.valueids.map(parseFormItemValue) : null,
	width: field?.colwidth,
	height: field?.rowheight,
	horizontalClass: field?.HorizontalClass,
	unitPrice: field?.unit_price,
	imgUrl: field?.imgURL,
	displayIf: field?.displayfield,
	message: field?.dues_standing_message,
	fieldmetajson: field?.fieldmetajson,
});

export const parseScreen = screen => ({
	id: screen.screen,
	type: screen.screentype,
	title: screen.button,
	hideIfComplete: screen.hideifcomplete,
	formItems: screen?.fieldids?.map(parseFormItem),
});

export const parseForm = formData => {
	let parsedData = {
		screens: [],
	};
	parsedData.screens = formData.attenmenu.map(parseScreen);

	return parsedData;
};

export const createHashKey = form => {
	return `${form.module}_${form.component}_${form.function}`;
};

export const createValidationHashKey = info => {
	if (!info) return '';
	let hashKey = '';
	Object.keys(info).map(key => {
		hashKey += `${key}=${info[key]}_`;
	});
	return hashKey;
};
