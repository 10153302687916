import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _reduce from 'lodash/reduce';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import { PlayCircleOutlined, PictureOutlined, FilePdfOutlined, UserOutlined, DoubleRightOutlined, FileOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from "react-router-dom";

import { FILE } from 'components/Common/constants';
import { useTags } from '../hooks/useTags';
import { useCategory } from '../hooks/useCategory';
import { useProducts } from '../hooks/useProducts';
import { ReactComponent as Certificate } from '../../../assets/icons/Certificate.svg';
import { ReactComponent as BulletIcon } from '../../../assets/icons/dot.svg';

import { Card, Image, Avatar, Typography } from 'antd';

const { Paragraph } = Typography;

const ProductCardList = ({ product, goto }) => {
    const history = useHistory();
    let querySearch = new URLSearchParams(history.location.search).get("query")?.toLowerCase()
    const appdir = useSelector(state => state.loginInfo.appdir);

    const [, tagsMap] = useTags();
    const [, categoriesMap] = useCategory();
    const [prodsLoading, products] = useProducts();
    const [authorInfo, setAuthorInfo] = useState({});

    const category = categoriesMap[product.categoryId];
    const parentId = product.grandparentId ?? product.parentId;

    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
    const config = useSelector(({ consumption }) => consumption.config);
    const bundleInfo = useMemo(() => products.find(prod => prod.id === parseInt(product.id)), [products, product.id]);
    const grandparentProduct = useMemo(() => products.find((prod) => prod.id === product?.grandparentId), [products, product]);
    const parentProduct = useMemo(() => products.find((prod) => prod.id === product?.parentId), [products, product]);
    const showTimes = bundleInfo?.showTimes || grandparentProduct?.showTimes|| parentProduct?.showTimes;
    useEffect(() => {
        if (product && product.authorInfo && product.authorInfo.length) {
            let presenter = product.authorInfo.find((prod) => prod.presenter);
            const searchedAuthor = product.authorInfo.find((items) => items.fullname.toLowerCase().includes(querySearch));
            setAuthorInfo(searchedAuthor ? searchedAuthor : presenter);
        } else {
            setAuthorInfo({});
        }
    }, [product]);

    const getChildProducts = (bundle, isCredit = false) => {
        let childData = [];
        let bundleData = isCredit ? bundle?.credits_array?.length : bundle?.files?.length;
        // this is the scenario to filter the bundle which ishaving the same files as it's only child.
        if (bundleData &&
            (bundle?.isSuperBundle // to allow the grandParent from the restriction
                || (!bundle?.isSuperBundle && !bundle?.isBundle)  // to allow the products from the restriction
                || (!bundle?.isSuperBundle &&
                    bundle?.isBundle &&  // to check the bundle with single child
                    bundle?.childrenIds?.length > 1)
            )
        ) {
            childData = [bundle];
        }
        if (bundle?.childrenIds?.length) {
            _forEach(_filter(products, (p) => bundle?.childrenIds.includes(p.id)), (p) => {
                childData = [...childData, ...getChildProducts(p, isCredit)]
            });
        }
        return childData;
    };

    const constructItemCount = () => {
        const children = getChildProducts(bundleInfo);
        let stateCount = _reduce(children, (total, child) => {
            let filteredChild = {
                ...child,
                files: _filter(child.files, file => (file.hasOwnProperty("hide") ? !file.hide : true)),
            };
            let contents = _groupBy(filteredChild?.files, 'type');
            let temp = { ...total };
            _forEach(contents, (content, key) => {
                if (key == '') {
                    temp['file'] = (total['file'] || 0) + content.length;
                } else {
                    temp[key] = (total[key] || 0) + content.length;
                }
            });
            return temp;
        }, {});
        return stateCount;
    };

    const constructCreditCount = useCallback(() => {
        let isCredit = true;
        const children = getChildProducts(bundleInfo, isCredit);
        let creditCount = _reduce(children, (total, child) => {
            let contents = _groupBy(child?.credits_array, 'label');
            _forEach(contents, (content, key) => {
                if (!total[key]) {
                    content.forEach(item => {
                        total[key] = item.value;
                    })
                } else {
                    content.forEach(item => {
                        total[key] += item.value;
                    })
                }
            })
            return total;
        }, {});
        return creditCount;
    }, [bundleInfo]);

    const productCount = useMemo(constructItemCount, [bundleInfo, products]);
    const creditCount = useMemo(constructCreditCount, [bundleInfo, products]);

    const parent = useMemo(
        () => !prodsLoading && parentId && products.find(product => product.id === parentId),
        [prodsLoading, parentId],
    );

    const handleOnExpand = (event) => {
        event.stopPropagation();
        goto(product, parent)
    };

    // const convertString = (str = '') => str.replace(/<[^>]*>/g, '')
    const convertString = (str = '') => {
        const elements = new DOMParser().parseFromString(str, 'text/html');
        return elements.body.innerText.replace(/\?/g, ' ')
    };

    return (
        <Card className="card gx-h-100 shadow-1 gx-pointer" bodyStyle={{ padding: '0px' }} onClick={() => goto(product, parent)}>
            {category && !config?.hide_all_tags && <span className="ribbon">{category}</span>}
            <div className='gx-p-4'>
                {!_isEmpty(creditCount) && appdir === 'demo' ? <div style={{ textAlign: "end" }}>
                    <Certificate style={{ width: '2.5rem', marginRight: 5, fill: styling['primary-dark-color'] }} />
                </div> : null}
                <div className="gx-d-flex" style={{ gap: "1.5em" }}>
                    <div style={{ flex: '0 0 auto' }}>
                        {product.image && (
                            <>
                                <Image
                                    className="card-image gx-my-2"
                                    style={{ width: '10em' }}
                                    alt="placeholder"
                                    src={product.image || require('assets/images/placeholder-image.png')}
                                    preview={false}
                                />
                                {/* <hr className="gx-mb-0" /> */}
                            </>
                        )}
                    </div>
                    <div className="gx-d-flex gx-flex-column gx-h-100">
                        <h4 className="fg-grey gx-mt-2 gx-font-weight-semi-bold">
                            {product.title}
                        </h4>

                        {parent && (
                            <p className="gx-text-primary gx-mt-1">Part of: {parent.title}</p>
                        )}
                        <div className="gx-d-flex gx-flex-1">
                            {/* product description */}
                            {/* <p
                                className="gx-mb-0"
                                dangerouslySetInnerHTML={{
                                    __html: product.description,
                                }}
                            /> */}
                            {product.description ? <Paragraph
                                ellipsis={{
                                    rows: 2,
                                    expandable: true,
                                    symbol: <span className='gx-text-bold gx-text-black'
                                    >More <DoubleRightOutlined style={{ fontSize: '11px' }} /></span>,
                                    onExpand: handleOnExpand
                                }}
                            >
                                {convertString(product?.description)}
                            </Paragraph> : null}
                        </div>
                        {showTimes ? 
                        bundleInfo.sessionStartTime > 0 ? 
                        <p className='gx-ml-2 gx-mt-2'>
                                    <ClockCircleOutlined /> &nbsp;
                                    {moment(bundleInfo.sessionStartTime).format('ddd, MMM D Y, hh:mm A')}
                                </p>
                        
                        :null:
                            product.publicationDate
                                ? <p>Publication Date:&nbsp;{moment(product.publicationDate).format('MM/DD/YYYY')}</p>
                                : null
                        }
                    </div>
                </div>
                <div style={{ marginLeft: "1.5em" }}>
                    {!_isEmpty(authorInfo)
                        ? (<div className="gx-py-3 description-text gx-d-flex gx-align-items-center">
                            <Avatar size="medium" src={authorInfo.picture} icon={<UserOutlined />} />
                            {authorInfo.fullname ? <div className='gx-ml-2'>{authorInfo.fullname}</div> : null}
                            {authorInfo.degrees ? <div>{`, ${authorInfo.degrees}`}</div> : null}
                        </div>)
                        : product.author_block ? (
                            <div className='gx-d-flex gx-align-items-center'>
                                <div><Avatar size="medium" icon={<UserOutlined />} /></div>
                                <div className='gx-ml-2'>{product.author_block}</div>
                            </div>
                        ) : null}
                </div>
            </div>
            {Object.keys(productCount).length ? (<div className="bottom-price-bar" style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}>
                <div className="gx-text-center gx-d-flex gx-align-items-center gx-justify-content-center">
                    {Object.keys(productCount).length ?
                        <span>
                            {_map(productCount, (count, key) => {
                                let fileName = count > 1 && key !== FILE.PDF ? key.concat("s") : key;
                                return (
                                    <span key={key}>
                                        {key === FILE.PDF &&
                                            <span className='gx-ml-3'><FilePdfOutlined /> {count} {fileName?.toUpperCase()?.concat(count > 1 ? 's' : '')}</span>
                                        }
                                        {key === FILE.VIDEO &&
                                            <span className='gx-ml-3'><PlayCircleOutlined /> {count} {_capitalize(fileName)}</span>
                                        }
                                        {key === FILE.IMAGE &&
                                            <span className='gx-ml-3'><PictureOutlined /> {count} {_capitalize(fileName)}</span>
                                        }
                                        {key === FILE.FILE &&
                                            <span className='gx-ml-3'><FileOutlined /> {count} {_capitalize(fileName)}</span>
                                        }
                                    </span>)
                            })}
                        </span> : null}
                    <span>
                        {!_isEmpty(creditCount) && appdir === 'demo' ?
                            <span className="gx-ml-3">
                                <Certificate style={{ width: '1rem', fill: "#ffffff" }} />{' '}
                                {_map(creditCount, (count, key) => {
                                    let fileName = count > 1 ? key + "s" : key;
                                    return (
                                        <>
                                            {count}{' '}
                                            {fileName}
                                            {Object.keys({ ...creditCount }).pop() !== key ? (
                                                <BulletIcon style={{ width: '1rem', fill: "#ffffff" }} />
                                            )
                                                : null}
                                        </>
                                    )
                                })}
                            </span> : null}
                    </span>
                </div>
            </div>) : null}
        </Card>
    );
};

export default ProductCardList;
