import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import ReactPlayer from 'react-player';
import CircularProgress from '../../../CircularProgress';
import Widget from '../../../Widget';

const VideoPlayer = ({ url, expanded, style = {}, transcript, product = {}, onHandleVideoStart = () => { }, onHandleVideoEnd = () => { }, onHandleVideoPlay }) => {
	const [tracksConfig, setTracksConfig] = useState([]);
	const [transcriptLoaded, setTranscriptLoaded] = useState(false);
	const [EndTriggered, setEndTriggered] = useState(false);
	const [runningTime, setRunningTIme] = useState(0);
	const [isReady, setIsReady] = useState(false);
	const playerRef = useRef();

	const timeToStart = useSelector(state => state.consumption.seekTime);

	useEffect(() => {
		if (transcript && isEmpty(tracksConfig) && !transcriptLoaded) {
			let tracks = [];
			fetch(transcript)
				.then(res => res.json())
				.then(response => {
					for (const lang of Object.keys(response)) {
						tracks.push({
							kind: 'subtitles',
							src: response[lang],
							srcLang: lang,
						});
					}
					setTracksConfig(tracks);
					setTranscriptLoaded(true);
				})
				.catch(() => {
					setTranscriptLoaded(true);
				});
		}
	});

	useEffect(() => {
		if (playerRef.current && playerRef.current.seekTo && timeToStart && isReady) {
			playerRef.current.seekTo(timeToStart, 'seconds');
		}
	}, [playerRef.current, timeToStart, isReady])

	const videoUrl = useMemo(() => product["files"]?.find((item) => item.type === "video"), [product])

	const onHandleProgress = (progress) => {
		let seekTime = Math.floor(progress.playedSeconds);
		setRunningTIme(seekTime);
		if (progress?.played >= 0.9 && !EndTriggered) {
			onHandleVideoEnd(videoUrl, seekTime);
			setEndTriggered(true);
		}
		if (seekTime % 10 == 0 &&
			playerRef.current.player.isPlaying
			&& seekTime >= 1 && seekTime != timeToStart && progress?.played < 0.9
		) {
			onHandleVideoPlay(videoUrl, runningTime)
			setEndTriggered(false);
		}
	}

	const handleStart = () => {
		onHandleVideoStart(videoUrl, runningTime);
	}

	const handleOnReady = () => {
		setIsReady(true);
	}

	return transcript && isEmpty(tracksConfig) && !transcriptLoaded ? (
		<Widget styleName="gx-card">
			<CircularProgress className="gx-profile-content" />
		</Widget>
	) : (
		<main className="player-wrapper" style={{ height: expanded ? '35vh' : '25vh', ...style }}>
			<ReactPlayer
				ref={playerRef}
				url={url}
				width="100%"
				height="100%"
				controls={true}
				config={{
					file: {
						tracks: tracksConfig,
						attributes: { controlsList: 'nodownload', crossorigin: "anonymous" },
					},
				}}
				onContextMenu={e => e.preventDefault()}
				onProgress={onHandleProgress}
				onStart={handleStart}
				onReady={handleOnReady}
			/>
		</main>
	);
};

export default VideoPlayer