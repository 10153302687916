import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';

import { useTags } from '../hooks/useTags';
import { useContentType } from '../hooks/useContentType';
import { useShoppingCart } from '../hooks/useShoppingCart';

import { Button, Tag, Card, Spin, Popover, Image, Typography, Avatar, Tooltip } from 'antd';
import { BookOutlined, DoubleRightOutlined, UserOutlined } from '@ant-design/icons';

import { ReactComponent as CartPlus } from '../../../../assets/icons/cartPlus.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { ReactComponent as PDF } from '../../../../assets/icons/pdf.svg';
import { ReactComponent as Book } from '../../../../assets/icons/book.svg';
import { ReactComponent as Link } from '../../../../assets/icons/link.svg';

import './selection.css';
import { useProducts } from '../hooks/useProducts';
import { paramsToSearchState, searchStateToParams } from '../helpers';
import isEmpty from 'lodash/isEmpty';
import DirectLinkModel from '../components/DirectLinkModel';
import CommonQuantityCount from './CommonQuantity';
import { updatedProductCount } from '../../../../appRedux/actions';

const { Paragraph } = Typography;

const ProductCardGrid = ({ product, price, showProduct, printPrice, productAccessList, handleAccess, quantityCount, setQuantityCount }) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const { moduleUrlId } = useParams();
	let querySearch = new URLSearchParams(history.location.search).get("query")?.toLowerCase()

	const [, products] = useProducts();
	const [authorInfo, setAuthorInfo] = useState({});
	const [selectedProduct, setSelectedProduct] = useState([])
	const [tagsLoading, tagsMap, getDisplayTagIds] = useTags();
	const [contentLoading, CONTENT, , getFascadeContentTypes] = useContentType();
	const [addToCart, , cart, removeFromCart] = useShoppingCart();
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);
	const productShareConfig = useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.product_share_config,
	);

	const cartItems = useSelector(state => state.ecommerce.cartItems.InvoiceItemArray) ?? []

	const cartDetails = useSelector(state => state.ecommerce.cartItems);

	const updatedProduct = useSelector(state => state.ecommerce.updatedProductCount);

	const storeConfig = useSelector(
		state => state.ecommerce.attendeeSettings?.attendeesiteadmin?.storeconfigobject,
	);
	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const searchParams = paramsToSearchState(history.location.search);

	const addedToCart = cart.some(
		invoice => invoice.field_id === (price?.fieldid || printPrice?.fieldid),
	);

	const parentBundle = useMemo(() => products.find((prod) => product.parentIds.includes(prod.id)), [products, product]);
	const grandParent = useMemo(() => products.find((prod) => parentBundle?.parentIds?.includes(prod.id)), [products, parentBundle]);

	const titleProduct = useMemo(() => {
		if (grandParent?.title) return grandParent;
		else if (parentBundle?.title) return parentBundle;
		else return null;
	}, [parentBundle, grandParent]);

	const publicationDate = useMemo(() => {
		if (product?.publicationDate) return product?.publicationDate
		else if (grandParent?.publicationDate) return grandParent?.publicationDate
		else if (parentBundle?.publicationDate) return parentBundle?.publicationDate
		else return null
	}, [product, parentBundle, grandParent]);

	useEffect(() => {
		if (product && product?.author_info_array && product?.author_info_array?.length) {
			let presenter = _filter(product.author_info_array, (prod) => prod.presenter);
			const searchedAuthor = product.author_info_array.find((items) => items.fullname.toLowerCase().includes(querySearch))
			setAuthorInfo((!_isEmpty(searchedAuthor) && searchedAuthor) ? searchedAuthor : presenter[0])
		} else {
			setAuthorInfo({})
		}
	}, [product, querySearch]);

	useEffect(() => {
		if (Object.keys(cartDetails).length > 0) {
			if (cartItems?.length > 0) {
				const product = []
				cartItems.map((cart) => {
					product.push({
						valueid: cart.value_id,
						quantity: cart.quantity,
						type: cart.field_type
					})
				})
				setSelectedProduct(product)
			} else {
				setSelectedProduct([])
			}
		}
	}, [cartItems, cartDetails])

	useEffect(() => {
		if(product.fieldType === 'multidrop' && !updatedProduct[product?.id] ){
			dispatch(updatedProductCount({[product?.id] : 1}))
		}
	},[product])

	const actionClicked = (event, tempPrice, quantityCount, fieldType) => {
		event.stopPropagation();
		if (addedToCart) {
			removeFromCart(tempPrice)
			dispatch(updatedProductCount({[product?.id] : 1}))
		} else {
			addToCart(tempPrice, quantityCount, fieldType)
		}
	};

	const handleQuantityIncrement = (product) => {
		if (updatedProduct?.[product.id] < product.multidropMax) {
			const count = updatedProduct?.[product?.id] + 1
			dispatch(updatedProductCount({[product?.id] : count}))
		}
	};

	const handleQuantityDecrement = (product) => {
		if (updatedProduct?.[product.id] > 1) {
			const count = updatedProduct?.[product?.id] - 1
			dispatch(updatedProductCount({[product?.id] : count}))
		}
	};

	const handleOnExpand = event => {
		event.stopPropagation();
		showProduct();
	};

	const handleProduct = (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (titleProduct?.id)
			history.push(
				`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/bundle/${titleProduct.id}?${publicEmbed ? 'public_embed=1' : ''}${searchStateToParams(searchParams)}`
			);
	};

	const convertString = (str = '') => str.replace(/<[^>]*>/g, '');

	const renderAccessNow = () => (
		<Button
			className="gx-mt-2 gx-ml-auto gx-mb-0 button-secondary button-round"
			onClick={handleAccess}
			style={{ backgroundColor: "#469f46" }}
		>
			<div className="gx-px-3 buy-btn-content">
				Access Now
			</div>
		</Button>
	);

	const renderPriceSection = (tempPrice, print_price = false) => {

		const filteredArray = selectedProduct.filter((cart) => cart.valueid === tempPrice.valueid && cart.type === 'multidrop')

		return (
			<>
				{
					tempPrice?.user_has_open_access || productAccessList.includes(product.id) ? (
						<div className='gx-w-100'>
							{/* <Tag className="gx-mb-0">
							{storeConfig?.free_for_members_message &&
								!_isEmpty(storeConfig?.free_for_members_message)
								? `${storeConfig?.free_for_members_message}`
								: 'You already have access'}
						</Tag> */}
							<div className='gx-d-flex'>
								{renderAccessNow()}
							</div>
						</div>
					) : (
						<>
							<div className="product-price-details">
								{tempPrice?.open_access_tease && tempPrice.open_access_tease.length > 0 ? (
									product.exclude_open_access_tease_message ? <></> : <>
										<Tooltip title={tempPrice.open_access_tease.join(', ')}>
											<Tag className="teaser-tag gx-mb-0">
												{storeConfig?.open_access_tease_message &&
													!_isEmpty(storeConfig?.open_access_tease_message)
													? `${storeConfig?.open_access_tease_message}`
													: 'Open access available for members'}
											</Tag>
										</Tooltip>
									</>
								) : (
									// teaser price
									tempPrice?.price_collection?.teaser && (
										<Tag className="teaser-tag gx-mb-0">
											{tempPrice?.price_collection?.teaser?.price === 0
												? 'FREE'
												: `$${tempPrice?.price_collection?.teaser?.price}`}{' '}
											for members
										</Tag>
									)
								)}
							</div>
							{/* Hiding the Buy button when there's no price for product */}
							{productAccessList.includes(product.id)
								? (<>{renderAccessNow()}</>)
								: !tempPrice?.price_collection?.primary && tempPrice?.isFetched ? (
									<></>
								) : (
									<div className='gx-d-flex gx-align-items-center gx-mt-auto gx-ml-auto'>
										{(product.fieldType === 'multidrop' && !filteredArray.length > 0) && <CommonQuantityCount
											quantityCount={updatedProduct?.[product.id]}
											handleQuantityDecrement={() => handleQuantityDecrement(product)}
											handleQuantityIncrement={() => handleQuantityIncrement(product)}
										/>}
										{(product.fieldType === 'multidrop' && filteredArray.length > 0) && <div className='gx-mr-3' style={{ fontSize: 16 }}>
											{filteredArray?.map((value) => {
												return <>
													Quantity: {value.quantity}
												</>
											})}
										</div>}
										<Button
											onClick={event => actionClicked(event, tempPrice, updatedProduct, product.fieldType)}
											className=" gx-mb-0 button-secondary button-round"
											shape={addedToCart ? 'circle' : 'round'}
										>
											{tempPrice?.price_collection?.primary ? (
												addedToCart ? (
													<Remove
														style={{
															width: '0.9rem',
															margin: '0.65em 0',
														}}
													/>
												) : (
													<div className="gx-px-3 buy-btn-content">
														<Tooltip
															title={`${print_price ? 'Only Print version available' : ''
																}`}
														>
															{print_price && <BookOutlined className="gx-mr-1" />}
															{tempPrice?.price_collection?.primary?.price === 0
																? 'FREE'
																: storeConfig?.buy_now_button_label &&
																	!_isEmpty(storeConfig?.buy_now_button_label)
																	? `${storeConfig?.buy_now_button_label} ($${tempPrice.price_collection.primary.price})`
																	: `BUY $${product.fieldType === 'multidrop' ? updatedProduct?.[product.id] * tempPrice?.price_collection?.primary?.price : tempPrice?.price_collection?.primary?.price}`}
														</Tooltip>
													</div>
												)
											) : (
												<Spin className="gx-px-5 gx-mr-0 gx-mb-0 gx-text-right" size="small" />
											)}
										</Button>
									</div>
								)}
						</>
					)
				}
			</>
		)
	}
	return (
		<Card
			size="large"
			className="gx-mb-0 gx-h-100 product-card card shadow-1"
			bodyStyle={{ padding: '0px' }}
		>
			<div className="gx-d-flex gx-flex-column gx-justify-content-between gx-h-100">
				<div className="gx-p-4 gx-h-100 gx-d-flex gx-flex-column gx-justify-content-between">
					<div>
						<div className="">
							{/* product image */}
							{product?.image && (
								<div className="gx-text-center">
									<Image
										className="card-image gx-pointer"
										style={{ marginBottom: '12px', height: '7em' }}
										alt="placeholder"
										src={
											product.image ??
											require('../../../../assets/images/product-placeholder.png')
										}
										preview={false}
										onClick={showProduct}
									/>
								</div>
							)}

							{/* product tags */}
							<div>
								{!tagsLoading &&
									showTags &&
									getDisplayTagIds(product).map(tagId => (
										<Tag
											key={tagId}
											className="tag gx-text-center gx-mr-1 gx-mb-1"
											style={{ whiteSpace: 'normal' }}
										>
											{tagsMap[tagId] ?? tagId}
										</Tag>
									))}
							</div>

							{/* product title */}
							<h4 className="fg-grey gx-pointer" style={{ marginBottom: '0.6em' }} onClick={showProduct}>
								{price?.price_collection?.primary.label ?? product.title}
							</h4>
							{/* product category */}
							{!product?.parentIds?.length && product?.category ? (
								<div style={{ marginBottom: '0.5rem' }}>
									<Tag
										className="gx-pointer"
										style={{
											background: '#353535',
											color: 'white',
											padding: '5px 12px',
										}}
									>
										{product?.category}
									</Tag>
								</div>
							) : titleProduct?.title ? (
								<Tooltip title={titleProduct?.title}>
									<Tag
										className={
											titleProduct?.title?.length > 80 ? 'truncateTitle' : ''
										}
										style={{
											background: '#353535',
											color: 'white',
											padding: '5px 12px',
											cursor: 'pointer',
											maxWidth: '80%',
										}}
										onClick={handleProduct}
									>
										{titleProduct?.title}
									</Tag>
								</Tooltip>
							) : null}
						</div>

						<div className="gx-mt-auto gx-mb-2">
							{product?.description ? (
								<Paragraph
									ellipsis={{
										rows: 2,
										expandable: true,
										symbol: (
											<span
												className="gx-text-bold gx-text-black gx-pointer"
												onClick={event => {
													event.stopPropagation();
													showProduct();
												}}>
												More{' '}
												<DoubleRightOutlined style={{ fontSize: '11px' }} />
											</span>
										),
										onExpand: handleOnExpand,
									}}
								// onClick={showProduct}
								>
									{convertString(product?.description)}
								</Paragraph>
							) : null}
							{/* product description */}
							{/* <Popover
						content={
							<p
								dangerouslySetInnerHTML={{
									__html: product.description,
								}}
								style={{
									maxWidth: '40em',
								}}
							></p>
						}
					>
						<h5 className="description-popover gx-mb-2">Description</h5>
					</Popover> */}
							{publicationDate ? (
								<p>
									Publication Date:&nbsp;
									{moment(publicationDate).format('MM/DD/YYYY')}
								</p>
							) : null}

							<div className="gx-d-flex gx-align-items-center gx-ml-1 ">
								{!contentLoading &&
									getFascadeContentTypes(product).has(CONTENT.DIGITAL) && (
										<PDF className="gx-mr-2" style={{ width: '1.3em' }} />
									)}
								{!contentLoading &&
									getFascadeContentTypes(product).has(CONTENT.PRINT) && (
										<Book className="gx-mr-2" style={{ width: '1.2em' }} />
									)}
								{
									productShareConfig && <DirectLinkModel
										base_url={`${productShareConfig.base_url}?pubid=${product.id}&bundle=1`}
										className='ant-footer-button-hide'
									>
										{productShareConfig &&
											// product.childrenIds &&
											// product.childrenIds.length > 0 &&
											!isEmpty(productShareConfig) &&
											productShareConfig.base_url && (
												<div
													className="gx-d-flex gx-justify-items-center"
													onClick={event => {
														// window.open(
														// 	`${productShareConfig.base_url}?pubid=${product.id}&bundle=1`,
														// );
														event.stopPropagation();
														event.preventDefault();
													}}
												>
													<Link className="gx-mr-2  gx-pointer" style={{ width: '1.2em' }} />
													<p className="gx-pointer" style={{ padding: 0, margin: 0 }}>{productShareConfig.display_text ?? 'Direct Link'}{' '}</p>
												</div>
											)}
									</DirectLinkModel>
								}
							</div>
						</div>
					</div>

					<div>
						<div className="gx-my-1" style={{ marginLeft: '1em' }}>
							{!_isEmpty(authorInfo) ? (
								<div className="description-text gx-d-flex gx-align-items-center">
									<div>
										<Avatar
											size="medium"
											src={authorInfo.picture}
											icon={<UserOutlined />}
										/>
									</div>
									{authorInfo.fullname ? (
										<div className="gx-ml-2">{authorInfo.fullname}</div>
									) : null}
									{authorInfo.degrees ? (
										<div>{`, ${authorInfo.degrees}`}</div>
									) : null}
								</div>
							) : product.author_block ? (
								<div className="gx-d-flex gx-align-items-center">
									<Avatar size="medium" icon={<UserOutlined />} />
									<div className="gx-ml-2">{product.author_block}</div>
								</div>
							) : null}
						</div>

						<div className="gx-d-flex gx-align-items-center -gx-border-top gx-border-light gx-pt-2">
							{price?.isFetched &&
								printPrice?.isFetched &&
								price?.price_collection?.primary &&
								printPrice?.price_collection?.primary ? (
								// Print and Digital Prices available
								<Button
									onClick={event => {
										event.stopPropagation();
										showProduct();
									}}
									className="gx-mt-auto gx-ml-auto gx-mb-0 button-secondary button-round"
									shape={'round'}
								>
									<div className="gx-px-3 buy-btn-content">See Options</div>
								</Button>
							) : (
								<>
									{printPrice?.isFetched &&
										printPrice?.price_collection?.primary &&
										price.isFetched &&
										!price?.price_collection?.primary ? (
										// Print Price available and digital price NOT available
										// Show Print icon
										<>{renderPriceSection(printPrice, true)}</>
									) : (
										<>{renderPriceSection(price)}</>
									)}
								</>
							)}
						</div>
					</div>
				</div>
				<div
					className="bottom-price-bar gx-pointer"
					style={{ backgroundColor: styling['primary-dark-color'] || '#993333' }}
					onClick={showProduct}
				>
					{product?.grandChildrenCount ? (
						<div className="gx-text-center gx-d-flex gx-align-items-center gx-justify-content-center">
							<CartPlus style={{ width: '1.2em', fill: '#ffffff' }} />
							<span className="gx-ml-1 gx-font-weight-normal">
								{product?.grandChildrenCount} Items
							</span>
						</div>
					) : null}
				</div>

			</div>
		</Card>
	);
};

export default ProductCardGrid;
