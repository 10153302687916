import { get, isArray, intersection } from 'lodash';
import resolveGraphDependency from './resolveGraphDependency';

let errorMessage = 'This is required';

/**
 * This function determines if the right input value is present to clear the dependency for user
 * @param {Array|Number*} prereq
 * @param {Array|Number*} userInput
 * For eg: if prereq is [501, 502, 505]. If userInput is
 * a) 501 - it should return true as 501 is present
 * b) [501, 504] - true
 * c) 504 or [503, 504] - false
 */
export const checkDisplayPrerequisites = (prereq, userInput) => {
	if (isArray(prereq) && isArray(userInput) && !intersection(prereq, userInput).length > 0) {
		return false;
	}

	if (isArray(prereq) && !isArray(userInput) && prereq.findIndex(i => i == userInput) < 0) {
		return false;
	}

	if (isArray(userInput) && !isArray(prereq) && userInput.findIndex(i => i == prereq) < 0) {
		return false;
	}

	if (!isArray(userInput) && !isArray(prereq) && userInput != prereq) {
		return false;
	}

	return true;
};

/**
 * This class handles various validations using the form data and the rules.
 */
class Validation {
	constructor() {
		this.data = {};
	}

	setData = (data, rules, formData) => {
		this.data = data;
		this.rules = rules;
		this.formData = formData;
		this.errors = {};
		this.display = {};
		this.filteredValues = {};
	};

	isPresentInData = key => get(this.data, key);

	isHidden = key => key && this.display[key] === false;

	isDisplayed = key => !this.isHidden(key);

	/**
	 * This determines the fields that are required irrespective of any condition
	 * @param {*} rule
	 */
	findAbsoluteRequired = rule =>
		rule &&
		rule.map(field => {
			// find exception when field is hidden by `displayIf`
			// if the data is not present or the data was empty for the required field, raise the error
			const formItem = this.formData?.formItems?.find((obj) => obj.name == field['FieldName']);
			let exception = false;
			if (formItem && formItem.displayIf == 0) {
				exception = true;
			}
			if (!exception) {
				const fieldValue = this.isPresentInData(field['FieldName']);
				if (
					!fieldValue
					|| (typeof fieldValue === 'object' || typeof fieldValue === 'string') && Object.values(fieldValue).length <= 0
				) {
					this.addError(field['FieldName'], errorMessage);
				}
			}
		});

	/**
	 * The rules contains set of dependencies to display a field.
	 * For eg: DROPDOWN1 [OPTION1, OPTION2] ==> DROPDOWN2
	 * This function will return the dependencies in an ascending order
	 * For eg: [1 => 2, 2 => 3]
	 */
	resolveOrder = rules => {
		let graph = {};
		let order = {};

		//creating a hashmap of dependencies using rules array
		rules.map((rule, index) => {
			if (!(rule['DisplayFieldName'] in graph)) {
				graph[rule['DisplayFieldName']] = [];
				order[rule['DisplayFieldName']] = index;
			}
			if (rule['TriggerFieldName']) {
				if (isArray(rule['TriggerFieldName'])) {
					graph[rule['DisplayFieldName']].concat(rule['TriggerFieldName']);
				} else {
					graph[rule['DisplayFieldName']].push(rule['TriggerFieldName']);
				}
			}
		});

		const orderdGraph = resolveGraphDependency(graph);
		return orderdGraph.map(name => rules[order[name]]);
	};

	/**
	 * Goes through rule and determines whether a certain field should be displayed or not
	 * TriggerField will decide whether DisplayField will be shown or not
	 * Here, by default display is assumed to true. So add only to disable
	 */
	findDisplayIf = rule =>
		rule &&
		rule.length &&
		this.resolveOrder(rule).map(field => {
			let tempHidden = [], tempdisplayPrerequisites = [], tempDataPresence = [];
			if (typeof field['TriggerFieldName'] === 'object') {
				Object.values(field['TriggerFieldName']).forEach((trigger) => {
					tempDataPresence.push(this.isPresentInData(trigger));
					tempHidden.push(this.isHidden(trigger));
					tempdisplayPrerequisites.push(
						trigger && checkDisplayPrerequisites(field['TriggerFieldValue'], this.data[trigger]))
				})
			} else {
				tempDataPresence.push(this.isPresentInData(field['TriggerFieldName']));
				tempHidden.push(this.isHidden(field['TriggerFieldName']));
				tempdisplayPrerequisites.push(field['TriggerFieldName'] && checkDisplayPrerequisites(field['TriggerFieldValue'], this.data[field['TriggerFieldName']]))
			}
			//if there is no input entry for TriggerField, hide the dependent display field
			if (
				!tempDataPresence.some(f => f)
				|| tempHidden.some(f => f)
				|| !tempdisplayPrerequisites.some(f => f)
			) {
				this.addDisplay(field['DisplayFieldName'], false); //if the data is there and it is visible, check if the right option is selected for TriggerField
			}
		});

	/**
	 * This determines the fields that are conditionally required.
	 * If you have selected TriggerField with right options, then the respective DisplaField should be required
	 * It adds the error for the required fields whose data is empty
	 */
	findRequiredIf = rule =>
		rule &&
		rule.length &&
		rule.map(field => {
			let sourceField = field['RequireFieldName'];

			//if the DisplayField is hidden, no need to show required
			if (this.isHidden(sourceField)) {
				return;
				//if the DisplayField is viiable and has data, no need to show required
			} else if (this.isPresentInData(sourceField)) {
				return;
			}

			let targetField;
			//extracts targetField. If array, select first one(hardcoded as decided)
			if (field['TriggerFieldName']) {
				if (isArray(field['TriggerFieldName']) && field['TriggerFieldName'].length > 0) {
					targetField = field['TriggerFieldName'][0];
				} else {
					targetField = field['TriggerFieldName'];
				}
			}

			if (
				field['TriggerFieldValue'] &&
				checkDisplayPrerequisites(field['TriggerFieldValue'], get(this.data, targetField))
			) {
				this.addError(sourceField, errorMessage);
			}
		});

	/**
	 * On selection of certain option in a field, target field's options will be filtered.
	 * For eg: A dropdown's option could affect other 5 dropdowns options.
	 * If option a is selected, first field will show only 3/5 options.
	 * second field will show 2/4 options.
	 * This function creates a list of filtered options need to be shown.
	 */
	filterOptions = rule =>
		rule &&
		rule.length &&
		rule.map(field => {
			if (!field.field || !isArray(field.targets) || !this.isDisplayed(field.field)) {
				return;
			}

			//go through all the targets that could be affected on selecting an option.
			//For eg: a target would be a form dropdown
			field['targets'].map(target => {
				//check if the right value is selected
				if (
					checkDisplayPrerequisites(
						target['selectedvalue'],
						get(this.data, field['field']),
					)
				) {
					this.addFilteredOption(target['target'], target['displayvalues']);
				}
			});
		});

	/**
	 * Adds error to the global error object
	 * @param {String} field fieldname
	 * @param {String} error errormessage
	 */
	addError = (field, error) => {
		if(this.formData?.formItems?.find((obj) => obj?.name == field)){
			if (!(field in this.errors)) {
				this.errors[field] = [];
			}
			this.errors[field].push(error);
		}
	};

	/**
	 * Adds display flag to the global display object
	 * @param {String} field fieldname
	 * @param {Boolean} flag
	 */
	addDisplay = (field, flag) => {
		this.display[field] = flag;
	};

	/**
	 * Add filtered option to the global filtered object
	 * @param {*} field fieldname
	 * @param {*} options the options that need to be shown
	 */
	addFilteredOption = (field, options) => {
		if (!(field in this.filteredValues)) {
			this.filteredValues[field] = options;
		}
		//if the options already exists before because of some other trigger, find an intersection
		//For eg: dropdown 1 and dropdown 2 both can affect options of dropdown 3
		this.filteredValues[field] = intersection(this.filteredValues[field], options);
	};

	//Starting point. This function is called to find the errors/validations
	findError = () => {
		//if there is no validation, return empty objects
		if (!this.rules) {
			return {
				errors: {},
				display: {},
				filteredValues: {},
			};
		}

		//We are using if else because these validations are to be done in a specific order.
		//Do not change the order unless you are very sure.

		if ('DISPLAYIF' in this.rules) {
			this.findDisplayIf(this.rules['DISPLAYIF']);
		}
		if ('ABSOLUTEREQUIRED' in this.rules) {
			this.findAbsoluteRequired(this.rules['ABSOLUTEREQUIRED']);
		}
		if ('REQUIREDIF' in this.rules) {
			this.findRequiredIf(this.rules['REQUIREDIF']);
		}
		if ('related_options_display' in this.rules) {
			this.filterOptions(this.rules['related_options_display']);
		}

		return {
			errors: this.errors,
			display: this.display,
			filteredValues: this.filteredValues,
		};
	};
}

export default new Validation();
