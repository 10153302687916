import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
	requestUserCompanyAffiliationData,
	requestCompanyData,
	requestCompanyMembersData,
	requestCorporateMembershipModuleUrlId,
} from '../../appRedux/actions';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { withRouter, useParams } from 'react-router';
import { returnRemainingMemberships } from './helpers/generalHelpers';
import { Col, Row, Card } from 'antd';
import { ReactComponent as DateIcon } from '../../assets/icons/date.svg';
import MembershipModal from '../../components/form/customLogicComponents/MembershipModal';

const CompanyHeader = props => {
	const { moduleUrlId } = useParams();

	const moduleUrlIdFetched = props.corporateMembership.moduleUrlIdFetched;

	const todayDate = new Date();
	const [companyExpired, setCompanyExpired] = useState(false);
	const languageObject = useSelector(state => state.corporateMembership.languageObject);
	const companyExpireDate = props.corporateMembership?.company?.expiry_date;
	const generalSettings = props.corporateMembership?.generalSettings;
	const showMembersModal =
		props.corporateMembership?.generalSettings?.corporate_membership?.company_members_modal
			?.show_individual_members_modal;
	const width = props.settings.width;
	const mobileThreshold = props.common.mobileThreshold;
	const modalItem = props.corporateMembership?.menu
		.filter(obj => obj.screentype === 'SubMenu')[0]
		?.submenu[0].fieldids.filter(obj => obj?.fieldtype === 'Multidrop');

	useEffect(() => {
		if (!moduleUrlIdFetched) {
			props.requestCorporateMembershipModuleUrlId({ moduleUrlId });
		}
	}, []);

	useEffect(() => {
		if (
			props.corporateMembership.companyFetched &&
			!props.corporateMembership.company.expiry_date
		) {
			setCompanyExpired(true);
		}
	}, [props.corporateMembership.companyFetched]);

	useEffect(() => {
		if (!props.corporateMembership.userCompanyAffiliation.comp_uuid && props.loginInfo.appdir) {
			props.requestUserCompanyAffiliationData({
				appDir: props.loginInfo.appdir,
				urlId: moduleUrlId,
			});
		} else {
			if (!props.corporateMembership.companyFetched) {
				props.requestCompanyData({
					compuuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
				});
			}
		}
	}, [props.corporateMembership.userCompanyAffiliation.comp_uuid, props.loginInfo.appdir]);

	useEffect(() => {
		props.requestCompanyData({
			compuuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
		});
	}, [props.corporateMembership.oldExhibitorCart]);

	useEffect(() => {
		if (
			props.corporateMembership.userCompanyAffiliationFetched &&
			props.corporateMembership.userCompanyAffiliation.comp_uuid &&
			!props.corporateMembership.companyMembersFetched
		) {
			props.requestCompanyMembersData({
				compuuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
				urlId: moduleUrlId,
			});
		}
	}, [
		props.corporateMembership.userCompanyAffiliationFetched,
		props.corporateMembership.userCompanyAffiliation.comp_uuid,
	]);

	return (
		props.corporateMembership.companyFetched && (
			<Card style={{ width: '100%' }} className="card">
				<Row>
					<Col span={width < mobileThreshold ? 24 : 9}>
						<div style={{ justifyContent: 'center', marginRight: '25px' }}>
							<div
								className="gx-mt-2"
								style={{ textAlign: 'left', fontSize: '16px' }}
							>
								Registration For: {props.corporateMembership.company.companyname}
							</div>
							{props.corporateMembership.company?.complogo && (
								<img
									style={{
										width: '20%',
										marginTop: '4%',
										marginBottom: '4%',
									}}
									src={props.corporateMembership.company?.complogo}
								></img>
							)}

							{/* {props.corporateMembership?.exhibitorCartDetails?.status && (
								<div style={{ fontSize: '16px' }}>
									Status:{' '}
									<span className="fg-primary-gradient gx-font-weight-semi-bold">
										{props.corporateMembership?.exhibitorCartDetails?.status}
									</span>
								</div>
							)} */}

							<div
								style={{ textAlign: 'left', fontSize: '15px' }}
								className="gx-font-weight-semi-bold gx-mt-2"
							>
								<div
									style={{ display: 'contents' }}
									className="gx-font-weight-medium"
								></div>

								{props.corporateMembership.company?.affiliate?.name && (
									<span className="gx-d-flex gx-mt-2">
										<span className="gx-text-secondary gx-font-weight-normal">
											Affiliate of:
										</span>
										<span className="gx-mx-1 gx-font-weight-semi-bold">
											{`${props.corporateMembership.company?.affiliate?.name}`}
										</span>
									</span>
								)}
							</div>
						</div>
					</Col>

					{props.corporateMembership.generalSettings?.default_exhibitor_level_type !==
						'Exhibitor' && (
						<Col span={width < mobileThreshold ? 24 : 7}>
							<div
								style={{
									float: 'left',
									display: 'inline-grid',
									width: '100%',
									marginTop: width > mobileThreshold ? '28%' : '',
								}}
							>
								{props.corporateMembership.generalSettings?.moduletype_internal ===
									'membership' && (
									<div className="gx-mb-2" style={{ fontSize: '16px' }}>
										Company Membership Type:{' '}
										<span className="fg-primary-gradient gx-font-weight-semi-bold">
											{!props.corporateMembership.company.membership_type
												? 'Non-Member'
												: props.corporateMembership.company.membership_type}
										</span>
									</div>
								)}

								{/* <div className="gx-mt-3" style={{ fontSize: '16px' }}>
									{props.corporateMembership.company.date_joined && (
										<div>
											Join{' '}
											<DateIcon
												className={'fg-primary-dark-light'}
												style={{ width: '1em', marginBottom: '-1%' }}
											/>
											{'  '}:{' '}
											<span className="fg-primary-gradient gx-font-weight-semi-bold">
												{`${moment(
													props.corporateMembership.company.date_joined,
												).format('MMM-DD-YYYY')}`}
											</span>
										</div>
									)}
								</div> */}
								<div className="gx-mt-3" style={{ fontSize: '16px' }}>
									{props.corporateMembership.company.expiry_date && (
										<div>
											Expiry{' '}
											<DateIcon
												className={'fg-primary-dark-light'}
												style={{ width: '1em', marginBottom: '-1%' }}
											/>{' '}
											{'  '}:{' '}
											<span className="fg-primary-gradient gx-font-weight-semi-bold">
												{`${moment(
													props.corporateMembership.company.expiry_date,
												).format('MMM-DD-YYYY')}`}
											</span>
										</div>
									)}
								</div>
							</div>
						</Col>
					)}

					{props.corporateMembership.generalSettings?.default_exhibitor_level_type !==
						'Exhibitor' && (
						<Col span={width < mobileThreshold ? 24 : 8}>
							<div style={{ marginLeft: '1%', paddingTop: '9%' }}>
								{props.corporateMembership.company.memberships_available && (
									<div className="gx-mt-3">
										<div style={{ marginTop: '4%' }}>
											{modalItem && showMembersModal && (
												<MembershipModal
													item={modalItem[0]}
													companyHeaderToggle={true}
												/>
											)}
											{/* 
											if the company expiry is within the renewal period:
											- included members are just taken from the membership count
											- current members are counted regardless of expiration date
											
											if company expiry is outside of the renewal period:
											- included members are just taken from the membership count
											- current members are counted only if their expiration date == company's expiration date


											*/}

											{props.corporateMembership.companyMembersFetched &&
												props.corporateMembership.companyMembersFetched &&
												props.corporateMembership.company.memberships_available.map(
													membership => {
														return (
															<div className="gx-mb-3">
																<div className="gx-mb-3">
																	{companyExpired ||
																	(moment(companyExpireDate).diff(
																		moment(todayDate),
																		'days',
																	) <
																		generalSettings.renewal_period_days &&
																		!props.corporateMembership.companyMembers.filter(
																			obj =>
																				obj.groupID ==
																					membership.groupid &&
																				Date.parse(
																					obj.expirationDate,
																				) > todayDate,
																		).length &&
																		!membership?.count) ? (
																		''
																	) : !companyExpired &&
																	  moment(
																			companyExpireDate,
																	  ).diff(
																			moment(todayDate),
																			'days',
																	  ) >
																			generalSettings.renewal_period_days &&
																	  !props.corporateMembership.companyMembers.filter(
																			obj =>
																				obj.groupID ==
																					membership.groupid &&
																				obj.expirationDate >=
																					companyExpireDate,
																	  ).length &&
																	  !membership?.count ? (
																		''
																	) : (
																		<span>
																			{membership.group_name}
																		</span>
																	)}
																</div>

																<div>
																	{!membership?.count ||
																	generalSettings
																		?.corporate_membership
																		?.hide_included_staff_count ? (
																		''
																	) : (
																		<span
																			style={{
																				marginLeft: '2%',
																				fontSize: '12px',
																			}}
																			className="fg-primary-gradient gx-font-weight-semi-bold"
																		>
																			{`${
																				languageObject.general_included_membership_label ??
																				'Included Memberships'
																			}: `}
																			{membership?.count}
																		</span>
																	)}

																	<div
																		style={{
																			marginTop: '2%',
																		}}
																	>
																		{companyExpired ||
																		(moment(
																			companyExpireDate,
																		).diff(
																			moment(todayDate),
																			'days',
																		) <
																			generalSettings.renewal_period_days &&
																			!props.corporateMembership.companyMembers.filter(
																				obj =>
																					obj.groupID ==
																					membership.groupid,
																			).length &&
																			!membership?.count) ? (
																			''
																		) : !companyExpired &&
																		  moment(
																				companyExpireDate,
																		  ).diff(
																				moment(todayDate),
																				'days',
																		  ) >
																				generalSettings.renewal_period_days &&
																		  !props.corporateMembership.companyMembers.filter(
																				obj =>
																					obj.groupID ==
																						membership.groupid &&
																					obj.expirationDate ===
																						companyExpireDate,
																		  ).length &&
																		  !membership?.count ? (
																			''
																		) : (
																			<span
																				style={{
																					marginLeft:
																						'2%',
																					fontSize:
																						'12px',
																				}}
																				className="fg-primary-gradient gx-font-weight-semi-bold"
																			>
																				{`${
																					languageObject.general_designated_members_label ??
																					'Designated Members'
																				}: `}

																				{companyExpired ||
																				moment(
																					companyExpireDate,
																				).diff(
																					moment(
																						todayDate,
																					),
																					'days',
																				) <
																					generalSettings.renewal_period_days
																					? props.corporateMembership.companyMembers.filter(
																							obj =>
																								obj.groupID ==
																								membership.groupid,
																					  ).length
																					: props.corporateMembership.companyMembers.filter(
																							obj =>
																								obj.groupID ==
																									membership.groupid &&
																								obj.expirationDate ===
																									companyExpireDate,
																					  ).length}
																			</span>
																		)}
																	</div>
																</div>
															</div>
														);
													},
												)}
										</div>
										{/* test 0 memberships */}
										{!Object.entries(
											props.corporateMembership.company.memberships_available,
										) && (
											<span
												style={{
													marginLeft: '2%',
													fontSize: '12px',
												}}
												className="fg-primary-gradient gx-font-weight-semi-bold"
											>
												None
											</span>
										)}
									</div>
								)}
							</div>
						</Col>
					)}
				</Row>
			</Card>
		)
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestCompanyData: requestCompanyData,
			requestUserCompanyAffiliationData: requestUserCompanyAffiliationData,
			requestCompanyMembersData: requestCompanyMembersData,
			requestCorporateMembershipModuleUrlId: requestCorporateMembershipModuleUrlId,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
		loginInfo: state.loginInfo,
		settings: state.settings,
		common: state.common,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(CompanyHeader));
