import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { ReactComponent as Left } from 'assets/icons/left.svg';
import { requestStoreAccessConfig } from '../../../appRedux/actions';

const ConsumptionHeader = ({ loading, product, superBundle, goBack }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const embedmode = useSelector(state => state.consumption.embedmode);
	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

	useEffect(() => {
		const searchState = new URLSearchParams(location?.search);
		if (searchState.get('moduleUrlId') && searchState.get('openAccess')) {
			dispatch(requestStoreAccessConfig({
				moduleUrlId: searchState.get('moduleUrlId'),
				hasOpenAccess: searchState.get('openAccess'),
				hasDirectAccess: searchState.get('directAccess'),
				hasProductAccess: searchState.get('productAccess')
			}));
		}
	}, [location?.search]);

	const isEmbedmode = embedmode === '1';
	return (
		<header
			className="shadow-bottom-2"
			style={{ zIndex: 10 }}
		>
			{isEmbedmode
				? <></>
				: (
					<div
						className="gx-d-flex gx-align-items-stretch"
						style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}
					>
						{(!product?.grandparentId && !product?.parentId) || (!superBundle) ? <div
							onClick={() => loading || goBack()}
							className="gx-px-4 gx-py-3 gx-pointer fg-white gx-mr-2 gx-ml-2 gx-d-flex"
						>
							<Left className="gx-my-auto" style={{ width: '.9rem' }} />
						</div> : <div className='gx-p-4  gx-mr-3'></div>}

						{loading ? (
							<Skeleton.Input
								style={{ width: 700 }}
								active
								className="gx-ml-2 gx-d-block gx-my-auto"
							/>
						) : (
							<div className="gx-p-1 gx-my-auto">
								<h2
									className="gx-text-white gx-font-weight-semi-bold gx-mb-0"
									style={{ fontSize: '1.3rem' }}
								>
									{superBundle?.title ? superBundle?.title : product?.title}
								</h2>
								{/* <p className="gx-text-light gx-mt-1 gx-mb-0">{superBundle?.title}</p> */}
							</div>
						)}
					</div>
				)
			}

			{superBundle?.title && product?.title ?
				<div className='gx-d-flex gx-p-3 gx-my-auto gx-pl-2' style={{ backgroundColor: styling['secondary-color'] || '#353535' }}>
					<div
						onClick={() => loading || goBack()}
						className="gx-pointer fg-white gx-mr-2 gx-ml-2 gx-d-flex"
					>
						<Left className="gx-my-auto" style={{ width: '.9rem' }} />
					</div>
					<h3 className='gx-text-white gx-font-weight-semi-bold gx-mb-0'
						style={{ marginLeft: '1.5rem' }}>
						{product?.title}
					</h3>
				</div> :
				null
			}
		</header>
	);
};

export default ConsumptionHeader;
