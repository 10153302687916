import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal, Button } from 'antd';
import moment from 'moment';
import auth from '../../../loginAuth/loginAuth';

const MembershipModal = props => {
	const todayDate = new Date();

	const { item, companyHeaderToggle } = props;
	const [openModal, setOpenModal] = useState(false);
	const generalSettings = useSelector(state => state.corporateMembership?.generalSettings);

	const authUser = useSelector(state => state.auth?.authUser);

	const companyExpireDate = useSelector(state => state.corporateMembership?.company?.expiry_date);
	const companyMembers = useSelector(state => state.corporateMembership?.companyMembers);
	const membershipsAvailable = useSelector(
		state => state.corporateMembership?.company?.memberships_available,
	);
	const companyMembersFetched = useSelector(
		state => state.corporateMembership?.companyMembersFetched,
	);
	const clickOpenModal = () => {
		setOpenModal(true);
	};

	const closeModal = () => {
		setOpenModal(false);
	};

	const renewal =
		moment(companyExpireDate).diff(moment(todayDate), 'days') <
		generalSettings.renewal_period_days;

	return (
		<div>
			{authUser && (
				<div>
					<Button
						type="primary"
						onClick={clickOpenModal}
						size={companyHeaderToggle ? 'small' : 'medium'}
					>
						{
							generalSettings?.corporate_membership?.company_members_modal
								?.open_modal_button_label
						}
					</Button>

					<Modal visible={openModal} onCancel={closeModal} footer={null}>
						<div>
							{membershipsAvailable && (
								<div className="gx-mt-3">
									<h2>
										{
											generalSettings?.corporate_membership
												?.company_members_modal?.modal_title
										}
									</h2>
									<div
										dangerouslySetInnerHTML={{
											__html:
												generalSettings?.corporate_membership
													?.company_members_modal?.modal_text,
										}}
									></div>

									{companyMembersFetched &&
										membershipsAvailable.map(membership => {
											return (
												<div>
													<div className="gx-mb-3 fg-primary-gradient">
														<span>
															{membership.group_name}
															{': '}
															{
																companyMembers.filter(
																	obj =>
																		obj.groupID ==
																		membership.groupid, // double equal on purpose, sometimes type of the same
																).length
															}
														</span>
													</div>
													<span>
														{companyMembers
															.filter(
																obj =>
																	obj.groupID ==
																	membership.groupid, // double equal on purpose, sometimes type of the same
															)
															.map(item => {
																if (
																	renewal &&
																	moment(companyExpireDate) <
																		moment(item.expirationDate)
																) {
																	return (
																		<div className="gx-mt-1">
																			<p>
																				<i>
																					{item.firstName}{' '}
																					{item.lastName}
																					{'  '}
																					{
																						item.email
																					}{' '}
																					<b>
																						(Prepaid -
																						Expiry :{' '}
																						{
																							item.expirationDate
																						}
																						){' '}
																					</b>
																				</i>
																			</p>
																		</div>
																	);
																} else {
																	return (
																		<div className="gx-mt-1">
																			<p>
																				{item.firstName}{' '}
																				{item.lastName}
																				{'  '}
																				{item.email}{' '}
																				<b>
																					(Expiry :{' '}
																					{
																						item.expirationDate
																					}
																					){' '}
																				</b>
																			</p>
																		</div>
																	);
																}
															})}
													</span>
												</div>
											);
										})}

									{companyMembersFetched && (
										<div>
											<div className="gx-mb-3 fg-primary-gradient">
												<span>Not A Member </span>
											</div>

											{companyMembers
												.filter(obj => !obj.groupID) // double equal on purpose, sometimes type of the same
												.map(item => (
													<div>
														<p>
															{item.firstName} {item.lastName}
														</p>
													</div>
												))}
										</div>
									)}

									{/* test 0 memberships */}
									{!Object.entries(membershipsAvailable) && (
										<span
											style={{
												marginLeft: '2%',
												fontSize: '12px',
											}}
											className="fg-primary-gradient gx-font-weight-semi-bold"
										>
											None
										</span>
									)}
								</div>
							)}
						</div>
					</Modal>
				</div>
			)}
		</div>
	);
};
export default MembershipModal;
