import React from 'react';

const OfficeFileViewer = ({ url, expanded, style = {} }) => (
    <main className="player-wrapper" style={{ height: expanded ? '35vh' : '25vh', ...style }}>
        <iframe
            id="ppt-viewer"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
            width='100%'
            height='400px'
            frameBorder="0"
        ></iframe>
    </main>
);

export default OfficeFileViewer;