import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Input } from 'antd';
import _debounce from 'lodash/debounce';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import './index.css';

const SearchBox = ({ currentRefinement, refine, handleCustomSearch = () => { }, customRefresh = false }) => {
	const onSearch = _debounce((value) => {
		refine(value);
		handleCustomSearch(value);
	}, 1000);
	const handleSearch = (event) => {
		event.persist();
		onSearch(event.target.value);
	};
	// customRefresh used to reflect the searched value in advanced search modal and primary searchbox also
	if (customRefresh)
		return (
			<Input
				key={"search-load"}
				prefix={<Search style={{ width: '1em' }} />}
				size="large"
				placeholder="Search..."
				allowClear
				addonAfter={false}
				className="gx-mb-0 searchbox"
				style={{ width: '100%' }}
				value={currentRefinement}
			/>
		);
	return (
		<Input
			key={"search-field"}
			defaultValue={currentRefinement}
			prefix={<Search style={{ width: '1em' }} />}
			onChange={handleSearch}
			size="large"
			placeholder="Search..."
			allowClear
			addonAfter={false}
			className="gx-mb-0 searchbox"
			style={{ width: '100%' }}
			autoFocus
			// className="searchbox"
		/>
	);
}

export default connectSearchBox(SearchBox);
