import React from 'react';

import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine, advanceSearch, currentRefinementListCount, showCount = false, clearSelectedValues = () => { } }) => (
	<div
		onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
			clearSelectedValues()
			refine(items)
		}}
		disabled={!items.length}
		className={!advanceSearch ? "button-secondary shadow-1 gx-pointer gx-px-2 gx-py-1" : null}
	>
		{!advanceSearch ? <div className="gx-d-flex gx-align-items-center">
			<span className="gx-mb-0">
				{/* Clear Filters {showCount ? `(${currentRefinementListCount})`: null} */}
				Clear Filters {(showCount && currentRefinementListCount) ? `(${currentRefinementListCount})` : null
				}
			</span>
		</div> : <div>Clear Advanced Search</div>}
	</div>
);
export default connectCurrentRefinements(ClearRefinements);
