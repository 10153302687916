import { all, call, fork, put, takeLatest, select, takeEvery } from 'redux-saga/effects';

import {
	REQUEST_CONSUMPTION_PRODUCTS,
	REQUEST_CONSUMPTION_TAGS,
	REQUEST_CONSUMPTION_CATEGORIES,
	REQUEST_CONSUMPTION_PRODUCT_INFO,
	REQUEST_CONSUMPTION_BUNDLE_INFO,
	REQUEST_CONSUMPTION_PRODUCT_ANALYSIS,
	REQUEST_CONSUMPTION_FILES_ANALYSIS,
	REQUEST_VIDEO_START,
	REQUEST_END_VIDEO,
	REQUEST_VIDEO_SEEK_TIME
} from 'constants/ActionTypes';
import { receiveEndVideo, requestLoginFormData, requestStoreEmbedMode, REQUEST_CONSUMPTION_CONFIG, storeVideoStartSuccess, receiveVideoSeekTime } from '../actions';

import {
	receiveProducts,
	receiveTags,
	receiveCategories,
	receiveProductInfo,
	receiveBundleInfo,
	loadingProducts,
	loadingTags,
	loadingCategories,
	receiveConfig,
	loadingConfig,
	storeProductAnalysisSuccess,
	storeFileAnalysisSuccess
} from 'appRedux/actions/Consumption';

import { fetchError } from 'appRedux/actions/Common';

import axios from 'axios';
import auth from 'loginAuth/loginAuth';
import { message } from 'antd';

const api = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';
const nodeApi = 'https://bm6uvz69il.execute-api.us-east-1.amazonaws.com/dev/api';

const getConfig = ({ appDir }) =>
	axios.get(
		`${nodeApi}?appdir=${appDir}&module=consumption&component=consumption&function=config`,
	);

const getProducts = async () =>
	await axios.get(`${api}?&module=products&component=user_products&function=get_user_products`, {
		headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
	});

const getCategories = async () =>
	await axios.get(`${api}?&module=products&component=products&function=get_categories`, {
		headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
	});

const getTags = async ({ source }) =>
	await axios.get(`${api}?source=${source}&module=client&component=tags&function=see_tags`, {
		headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
	});

const getProductInfo = ({ uuid }) =>
	axios.get(
		`${api}?module=products&component=products&function=get_product_info&product_uuid=${uuid}`,
		{
			headers: { Authorization: JSON.parse(auth.getCookie('jwt')) },
		},
	);

const getBundleInfo = async ({ uuid }) =>
	await axios.get(
		`${api}?module=products&component=products&function=get_bundle_details&product_uuid=${uuid}`,
		{ headers: { Authorization: JSON.parse(auth.getCookie('jwt')) } },
	);

const authenticateUser = async ({ appdir, uuid, ssoToken }) => {
	if (!uuid || !ssoToken) return;
	const { data } = await axios.post(
		`${api}?appdir=${appdir}&module=login&component=login&function=authenticate_user`,
		{
			uuid,
			sso_token: ssoToken,
		},
	);
	auth.setCookie('contact_uuid', JSON.stringify(uuid ?? ''), 7);
	auth.setCookie('ssotoken', JSON.stringify(ssoToken ?? ''), 7);
	auth.setCookie('jwt', JSON.stringify(data.jwt ?? ''), 7);
};

const storeProductAnalysis = async ({ appdir, contactUid, productId }) => {
	await axios({
		method: "post",
		url: `${nodeApi}?appdir=${appdir}&module=ecommerce&component=reports&function=consumption`,
		data: {
			contact_uuid: contactUid,
			product_id: productId,
			log_object: {
				event: "view_product"
			}
		},
		headers: { "Content-Type": "application/json" },
	});
}

const storeFileAnalysis = async ({ appdir, contactUid, productId, title, fileSize }) => {
	await axios({
		method: "post",
		url: `${nodeApi}?appdir=${appdir}&module=ecommerce&component=reports&function=consumption`,
		data: {
			contact_uuid: contactUid,
			product_id: productId,
			log_object: {
				event: "request_file",
				target: title,
				value: fileSize
			}
		},
		headers: { "Content-Type": "application/json" },
	});
}

const getStartVideoResponse = async ({ contactUid, product, videoUrl, appdir, runningTime }) => {
	let result = await axios({
		method: "post",
		url: `${nodeApi}?appdir=${appdir}&module=ecommerce&component=reports&function=consumption`,
		data: {
			contact_uuid: contactUid === ''
				? JSON.parse(auth.getCookie('contact_uuid'))
				: contactUid,
			product_id: product?.id,
			log_object: {
				"event": "video_start",
				"target": videoUrl,
				"value": runningTime
			}
		},
		headers: { "Content-Type": "application/json" },
	});
	return result;
}

const storeEndVideo = async ({ contactUid, product, videoUrl, appdir, runningTime }) => {
	let result = await axios({
		method: "post",
		url: `${nodeApi}?appdir=${appdir}&module=ecommerce&component=reports&function=consumption`,
		data: {
			contact_uuid: contactUid,
			product_id: product.id,
			log_object: {
				event: "video_complete",
				target: videoUrl,
				value: runningTime
			}
		},
		headers: { "Content-Type": "application/json" },
	})
	return result;
}

const fetchVideoSeekTime = async ({ payload: { contactUId, id, appdir} }) => {
	try {
		let result = await fetch(
			`${nodeApi}?module=ecommerce&component=reports&function=get_video_seek_time&appdir=${appdir}&contact_uuid=${contactUId}&product_id=${id}`,
			{
				method: 'GET',
			},
		);
		let resultJson = await result.json();
		if (resultJson.status === 0) {
			return {
				status: 0,
				seekTime: 0,
			};
		}
		return resultJson;
	} catch (error) {
		return {
			status: 0,
			seekTime: 0,
		};
	}
};


function* getConfigSaga() {
	try {
		const status = yield select(state => state.consumption.configStatus);
		if (status === 'loading' || status === 'success') return;
		yield put(loadingConfig());

		const appDir = window.location.hostname.substr(0, window.location.hostname.indexOf('.'));
		const { data } = yield call(getConfig, { appDir });

		yield put(receiveConfig(data));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getConfigWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_CONFIG, getConfigSaga);
}

function* getProductsSaga({ payload }) {
	try {
		const status = yield select(state => state.consumption.productsStatus);
		if (status === 'loading' || status === 'success') return;
		yield put(loadingProducts());

		const query = yield select(state => state.router.location.query);
		const appdir = window.location.hostname.substr(0, window.location.hostname.indexOf('.'));

		yield call(authenticateUser, {
			appdir,
			uuid: query.uuid,
			ssoToken: query.token,
		});

		yield put(requestLoginFormData({
			appdir,
		}));

		yield put(requestStoreEmbedMode({
			embedmode: query.embedmode
		}));

		const results = yield call(getProducts, payload);

		yield put(receiveProducts(results));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProductsWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_PRODUCTS, getProductsSaga);
}

function* getTagsSaga({ payload }) {
	try {
		const status = yield select(state => state.consumption.tagsStatus);
		if (status === 'loading' || status === 'success') return;

		yield put(loadingTags());

		const results = yield call(getTags, payload);
		yield put(receiveTags(results));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getTagsWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_TAGS, getTagsSaga);
}

function* getCategoriesSaga({ payload }) {
	try {
		const status = yield select(state => state.consumption.categoriesStatus);
		if (status === 'loading' || status === 'success') return;
		yield put(loadingCategories());

		const results = yield call(getCategories, payload);
		if (typeof results.data == 'string') {
			message.error(results.data);
		} else {
			yield put(receiveCategories(results));
		}
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getCategoriesWatcher() {
	yield takeEvery(REQUEST_CONSUMPTION_CATEGORIES, getCategoriesSaga);
}

function* getProductInfoSaga({ payload }) {
	try {
		const results = yield call(getProductInfo, payload);
		yield put(receiveProductInfo({ results, uuid: payload.uuid }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProductInfoWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_PRODUCT_INFO, getProductInfoSaga);
}

function* getBundleInfoSaga({ payload }) {
	try {
		const results = yield call(getBundleInfo, payload);
		yield put(receiveBundleInfo({ results, uuid: payload.uuid }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getBundleInfoWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_BUNDLE_INFO, getBundleInfoSaga);
}

function* storeProductAnalysisSaga({ payload }) {
	try {
		const results = yield call(storeProductAnalysis, payload);
		yield put(storeProductAnalysisSuccess({ results }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* storeProductAnalysisWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_PRODUCT_ANALYSIS, storeProductAnalysisSaga);
}

function* storeFileAnalysisSaga({ payload }) {
	try {
		const results = yield call(storeFileAnalysis, payload);
		yield put(storeFileAnalysisSuccess({ results }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getStartVideoSage({ payload }) {
	try {
		const results = yield call(getStartVideoResponse, payload);
		yield put(storeVideoStartSuccess({ results }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* storeEndVideoSaga({ payload }) {
	try {
		const results = yield call(storeEndVideo, payload)
		yield put(receiveEndVideo({ results }));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getRequestVideoSeekTime(action) {
	try {
		let result = yield call(fetchVideoSeekTime, action);
		yield put(receiveVideoSeekTime(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* storeFileAnalysisWatcher() {
	yield takeLatest(REQUEST_CONSUMPTION_FILES_ANALYSIS, storeFileAnalysisSaga);
}

function* getStartVideoWatcher() {
	yield takeLatest(REQUEST_VIDEO_START, getStartVideoSage);
}

function* storeEndVideoWatcher() {
	yield takeLatest(REQUEST_END_VIDEO, storeEndVideoSaga)
}

export function* requestVideoSeekTime() {
	yield takeEvery(REQUEST_VIDEO_SEEK_TIME, getRequestVideoSeekTime);
}


export default function* () {
	yield all([
		fork(getProductsWatcher),
		fork(getTagsWatcher),
		fork(getCategoriesWatcher),
		fork(getProductInfoWatcher),
		fork(getBundleInfoWatcher),
		fork(getConfigWatcher),
		fork(storeProductAnalysisWatcher),
		fork(storeFileAnalysisWatcher),
		fork(getStartVideoWatcher),
		fork(storeEndVideoWatcher),
		fork(requestVideoSeekTime)
	]);
}
