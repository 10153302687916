import React, { useState } from "react";
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import ProductCardGrid from "./ProductCardGrid";
import ProductCardList from "./ProductCardList";
import { ReactComponent as List } from '../../../../assets/icons/list.svg';
import { ReactComponent as Grid } from '../../../../assets/icons/grid.svg';
import { storeCardsViewType, storeSortType } from "../../../../appRedux/actions";
import { useAccessList } from "../hooks/useAccessList";

const GridView = ({
    Items,
    constructedProducts,
    showProduct,
    productPrices,
    productPrintPrices
}) => {
    const VIEW = { GRID: 0, LIST: 1 };
    const dispatch = useDispatch();
    const history = useHistory();
    const { moduleUrlId } = useParams();
    const [, productAccessList] = useAccessList();
    const cardView = useSelector((state) => state.ecommerce.cardDisplayType);
    const sortType = useSelector((state) => state.ecommerce.cardSortType);
    const publicEmbed = useSelector(state => state.settings.publicEmbed);

    const [quantityCount, setQuantityCount] = useState(1);

    const handleSort = (e) => {
        dispatch(storeSortType({ cardSortType: e.target.value }));
    };

    const handleSwitch = (value) => {
        dispatch(storeCardsViewType({ cardDisplayType: value }));
    };

    const handleAccess = (event, product) => {
        event.stopPropagation();
        const searchParams = new URLSearchParams({ openAccess: true, moduleUrlId, directAccess: true });
        if (!product.childrenIds.length) {
            history.push(`/${publicEmbed ? 'public' : 'main'}/consumption/product/${product.id}?${searchParams}`);
        } else {
            history.push(`/${publicEmbed ? 'public' : 'main'}/consumption/selection/${product.id}?${searchParams}`);
        }
    };

    return (
        <div>
            <div className='gx-d-flex gx-justify-content-end gx-mb-3 selectionFilter'>
                <div className="gx-d-flex gx-align-items-center">
                    <div className="gx-mb-2">Sort By: &nbsp;</div>
                    <Radio.Group
                        size="small"
                        onChange={(e) => handleSort(e)}
                        value={sortType}
                    >
                        {new URLSearchParams(history.location.search).get("query")
                            ?
                            (<Radio.Button value="2">Search Relevance</Radio.Button>) : null}
                        {Items.map((item, index) => (
                            <Radio.Button key={index} value={item.value}>{item.label}</Radio.Button>
                        ))}
                    </Radio.Group>
                </div>
                <Radio.Group
                    onChange={({ target }) => handleSwitch(target.value)}
                    value={cardView}
                    className="gx-ml-auto gx-d-flex gx-align-items-center"
                >
                    <Radio.Button
                        value={VIEW.GRID}
                        className="radio-button-left">
                        <div className="gx-d-flex gx-align-items-center">
                            <Grid
                                className="gx-mr-2"
                                style={{
                                    width: '1em',
                                }}
                            />
                            <p className="gx-mb-0">Grid</p>
                        </div>
                    </Radio.Button>
                    <Radio.Button
                        value={VIEW.LIST}
                        className="radio-button-right list-view-radio-btn">
                        <div className="gx-d-flex gx-align-items-center">
                            <List
                                className="gx-mr-2"
                                style={{
                                    width: '1em',
                                }}
                            />
                            <p className="gx-mb-0">List</p>
                        </div>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div>
                {cardView === VIEW.LIST ? (
                    <div>
                        {constructedProducts?.map((product, index) => (
                            !product.hide_card_only &&
                            <div key={index} className="gx-mb-3">
                                <ProductCardList
                                    product={product}
                                    quantityCount={quantityCount}
                                    setQuantityCount={setQuantityCount}
                                    productAccessList={productAccessList}
                                    showProduct={() => showProduct(product)}
                                    price={productPrices.find(price => price.productid === product.id)}
                                    printPrice={productPrintPrices.find(price => price.productid === product.id)}
                                    handleAccess={(e) => { handleAccess(e, product) }}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="ecommerce-grid">
                        {constructedProducts?.map((product, index) => {
                            return (
                            !product.hide_card_only &&
                            <div key={index}>
                                <ProductCardGrid
                                    product={product}
                                    quantityCount={quantityCount}
                                    setQuantityCount={setQuantityCount}
                                    productAccessList={productAccessList}
                                    showProduct={() => showProduct(product)}
                                    price={productPrices.find(price => price.productid === product.id)}
                                    printPrice={productPrintPrices.find(price => price.productid === product.id)}
                                    handleAccess={(e) => { handleAccess(e, product) }}
                                />
                            </div>
                        );
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default GridView;