import React from 'react';
import { Button } from 'antd';
import { ReactComponent as BackIcon } from '../../assets/icons/left.svg';

const GoBack = ({ handleBackClick }) => {
	return (
		<Button
			className="login-form-button gx-mt-1 button-primary gx-mb-3"
			shape={'round'}
			style={{
				boxShadow: '6px 5px 2px 1px rgba(45, 45, 45, 0.4)',
				border: 'transparent',
			}}
			onClick={() => handleBackClick?.()}
		>
			{
				<div style={{ display: 'flex' }}>
					<BackIcon style={{ width: '1em', marginLeft: '-8%' }} />
					<div
						style={{
							marginLeft: '16%',
							fontSize: '20px',
							fontWeight: '500px',
							letterSpacing: '3%',
						}}
					>
						Back
					</div>
				</div>
			}
		</Button>
	);
};

export default GoBack;
