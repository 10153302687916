import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { ReactComponent as LinkSvg } from '../../../../assets/icons/link.svg';

import moment from 'moment';

import { useProducts } from '../hooks/useProducts';
import { useProductPrices } from '../hooks/useProductPrices';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { useTags } from '../hooks/useTags';
import { useAccessList } from '../hooks/useAccessList';
import { getCheckoutRoute, getCheckoutPublicRoute } from '../helpers';

// import StoreHeader from '../components/StoreHeader';
import BundlePromo from './ProductBundlePromo';
// import { FILE } from 'components/Common/constants';

import { Card, Tag, Button, Pagination, Image, Skeleton, Spin, Tooltip, Radio } from 'antd';
// import { FieldTimeOutlined } from '@ant-design/icons';

import { ReactComponent as CartPlus } from '../../../../assets/icons/cartPlus.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { ReactComponent as PDF } from '../../../../assets/icons/pdf.svg';
import { ReactComponent as Video } from '../../../../assets/icons/video.svg';
import { ReactComponent as Left } from '../../../../assets/icons/left.svg';
import { ReactComponent as CartCheck } from '../../../../assets/icons/cartCheck.svg';
import loader from '../../../../assets/images/loader.svg';

import './product.css';
import { isEmpty } from 'lodash';
import { BookOutlined, GlobalOutlined } from '@ant-design/icons';
import DirectLinkModel from '../components/DirectLinkModel';
import { updatedProductCount } from '../../../../appRedux/actions';
import CommonQuantityCount from '../SelectionPage/CommonQuantity';

const ProductPage = () => {
	const { moduleUrlId, productId } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const [productsLoading, products] = useProducts();
	const [productPrices, getPrices, , productPrintPrices] = useProductPrices();
	const [addToCart, cartCount, cart, removeFromCart] = useShoppingCart();
	const [tagsLoading, tagsMap, getDisplayTagIds] = useTags();
	const [, productAccessList] = useAccessList();
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);
	const storeConfig = useSelector(
		state => state.ecommerce.attendeeSettings?.attendeesiteadmin?.storeconfigobject,
	);
	const [currentIndex, setCurrentIndex] = useState(0);

	const cartItems = useSelector(state => state.ecommerce.cartItems.InvoiceItemArray) ?? []

	const cartDetails = useSelector(state => state.ecommerce.cartItems)

	const updatedProduct = useSelector(state => state.ecommerce.updatedProductCount)

	const [selectedProduct, setSelectedProduct] = useState([])

	useEffect(() => {
		if (Object.keys(cartDetails).length > 0) {
			if (cartItems?.length > 0) {
				const product = []
				cartItems.map((cart) => {
					product.push({
						valueid: cart.value_id,
						quantity: cart.quantity,
						type: cart.field_type
					})
				})
				setSelectedProduct(product)
			} else {
				// setQuantityCount(1)
				setSelectedProduct([])
			}
		}
	}, [cartItems, cartDetails])

	const id = parseInt(productId);
	const product = useMemo(() => products.find(prod => prod.id === id), [products]);
	const parentBundle = useMemo(() => products.find((prod) => product?.parentIds?.includes(prod.id)), [products, product]);
	const grandParent = useMemo(() => products.find((prod) => parentBundle?.parentIds?.includes(prod.id)), [products, parentBundle]);
	const productShareConfig = useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.product_share_config,
	);

	const publicationDate = useMemo(() => {
		if (product?.publicationDate) return product?.publicationDate;
		else if (grandParent?.publicationDate) return grandParent?.publicationDate;
		else if (parentBundle?.publicationDate) return parentBundle?.publicationDate;
		else return null;
	}, [product, parentBundle, grandParent]);

	const { styling } = useSelector(({ loginInfo }) => loginInfo);
	const price = useMemo(() => productPrices.find(price => price.productid === id), [
		productPrices,
	]);
	const printPrice = useMemo(() => productPrintPrices.find(price => price.productid === id), [
		productPrintPrices,
	]);

	const [selectedPrice, setSelectedPrice] = useState(() => {
		// default selected price - Preference give to Digital Price
		if (price?.isFetched && printPrice?.isFetched) {
			if (cart.some(invoice => invoice.field_id === price?.fieldid && !invoice.print_price)) {
				return price;
			} else if (
				cart.some(
					invoice => invoice.field_id === printPrice?.fieldid && invoice.print_price,
				)
			) {
				return printPrice;
			} else if (printPrice?.price_collection?.primary && !price?.price_collection?.primary) {
				//Only print price available
				return printPrice;
			} else {
				// default Digital Price
				return price;
			}
		}
		// default Digital Price
		return price;
	});
	const [addedToCart, setAddedToCart] = useState(
		cart.some(invoice => invoice.field_id === selectedPrice?.fieldid),
	);

	const checkout = () => {
		history.push(
			publicEmbed ? getCheckoutPublicRoute(moduleUrlId) : getCheckoutRoute(moduleUrlId),
		);
	};

	useEffect(() => {
		getPrices([id]);
	}, []);
	useEffect(() => {
		setAddedToCart(cart.some(invoice => invoice.field_id === selectedPrice?.fieldid));
	}, [cart, selectedPrice]);

	useEffect(() => {
		if(product?.fieldType === 'multidrop' && !updatedProduct[product?.id]){
			dispatch(updatedProductCount({[product?.id] : 1}))
		}
	},[product])

	const handleAccess = event => {
		event.stopPropagation();
		const searchParams = new URLSearchParams({ openAccess: true, moduleUrlId, productAccess: true });
		history.push(
			`/${publicEmbed ? 'public' : 'main'}/consumption/product/${product.id}?${searchParams}`,
		);
	};

	const handleQuantityIncrement = (product) => {
		if (updatedProduct?.[product.id] < product.multidropMax) {
			const count = updatedProduct?.[product?.id] + 1
			dispatch(updatedProductCount({[product?.id] : count}))
		}
	};

	const handleQuantityDecrement = (product) => {
		if (updatedProduct?.[product.id] > 1) {
			const count = updatedProduct?.[product?.id] - 1
			dispatch(updatedProductCount({[product?.id] : count}))
		}
	};

	const actionClicked = (event, tempPrice, updatedProduct, product) => {
		event.stopPropagation();
		if (addedToCart) {
			removeFromCart(tempPrice)
			dispatch(updatedProductCount({[product?.id] : 1}))
		} else {
			addToCart(tempPrice, updatedProduct, product)
		}
	};

	const renderAccessNow = () => (
		<Button
			className="gx-mt-2 gx-ml-auto gx-mb-0 button-secondary button-round"
			onClick={handleAccess}
			style={{ backgroundColor: "#469f46" }}
		>
			<div className="gx-px-3 buy-btn-content">Access Now</div>
		</Button>
	);

	const currentItem = product?.files[currentIndex];

	const loading = tagsLoading || productsLoading;

	if (loading) {
		return (
			<div className="loader">
				<img src={loader} />
			</div>
		);
	}
	const renderPriceSection = tempPrice => {
		const filteredArray = selectedProduct.filter((cart) => cart.valueid === tempPrice.valueid && cart.type === 'multidrop')

		return (
		<>
			<div className="gx-mb-3 gx-d-flex gx-align-items-center">
				{tempPrice?.user_has_open_access || productAccessList.includes(product.id) ? (
					<div>
						{/* <Tag className="gx-mb-0">
							{storeConfig.free_for_members_message &&
								!isEmpty(storeConfig.free_for_members_message)
								? `${storeConfig.free_for_members_message}`
								: 'You already have access'}
						</Tag> */}
						<div className="gx-d-flex">{renderAccessNow()}</div>
					</div>
				) : (
					<>
						{tempPrice.open_access_tease && tempPrice.open_access_tease.length > 0 ? (
							product.exclude_open_access_tease_message ? <></> :
								<Tooltip title={tempPrice.open_access_tease.join(', ')}>
									<Tag className="gx-mb-0">
										{storeConfig.open_access_tease_message &&
											!isEmpty(storeConfig.open_access_tease_message)
											? `${storeConfig.open_access_tease_message}`
											: 'Open access available for members'}
									</Tag>
								</Tooltip>
						) : (
							<>
								{tempPrice.isFetched && tempPrice?.price_collection?.primary ? (
									<>
									    {(product.fieldType === 'multidrop' && !filteredArray.length > 0) && <CommonQuantityCount
											quantityCount={updatedProduct?.[product?.id]}
											handleQuantityDecrement={() => handleQuantityDecrement(product)}
											handleQuantityIncrement={() => handleQuantityIncrement(product)}
										/>}
										{(product.fieldType === 'multidrop' && filteredArray.length > 0) && <div className='gx-mr-3' style={{ fontSize: 16, "white-space":"nowrap" }}>
											{filteredArray?.map((value) => {
												return <>
    												Quantity: {value.quantity}
												</>
											})}
										</div>}
										<h4 className="gx-m-0 gx-my-auto fg-grey">Price:</h4>
										{tempPrice?.price_collection?.primary ? (
											<div className="gx-d-flex gx-align-items-center gx-ml-2">
												<h2 className="gx-mr-2 gx-mb-0">
													{tempPrice.price_collection.primary.price === 0
														? 'FREE'
														: `$${product?.fieldType === 'multidrop' ? updatedProduct?.[product.id] * tempPrice.price_collection.primary.price : tempPrice.price_collection.primary.price}`}
												</h2>

												{/* teaser */}
												<div className="product-price-details">
													{tempPrice?.price_collection?.teaser && (
														<Tag className="teaser-tag gx-mr-2">
															{tempPrice?.price_collection?.teaser
																?.price === 0
																? 'FREE'
																: `$${tempPrice?.price_collection?.teaser?.price}`}{' '}
															for members
														</Tag>
													)}
												</div>
											</div>
										) : (
											<Spin className="gx-ml-1" size="small" />
										)}
									</>
								) : (
									<></>
								)}
							</>
						)}
						<div className="gx-text-center">
							{productAccessList.includes(product.id) ? (
								<>{renderAccessNow()}</>
							) : tempPrice?.price_collection?.primary?.price === 0 ||
								tempPrice?.user_has_open_access ? null : (
								tempPrice.isFetched &&
								tempPrice.price_collection && (
									<Button
										onClick={event => actionClicked(event, tempPrice, updatedProduct, product?.fieldType)}
										className="button-primary"
										size="large"
										shape="round"
										style={{ height: 'auto' }}
									>
										<div className="gx-d-flex gx-justify-content-center gx-align-items-center gx-px-2 gx-py-1">
											{addedToCart ? (
												<Remove
													className="gx-mr-2"
													style={{ width: '1em' }}
												/>
											) : (
												<CartPlus
													className="gx-mr-2"
													style={{ width: '1.2em' }}
												/>
											)}

											{tempPrice?.price_collection && (
												<h4 className="gx-mb-0 gx-text-white">
													{addedToCart
														? 'Remove from Cart'
														: `Add to Cart`}
												</h4>
											)}
										</div>
									</Button>
								)
							)}
						</div>
						{!isEmpty(tempPrice?.rowtext) && (
							<div className="product-price-details">
								<Tag className="teaser-tag gx-mt-2 gx-mr-2">
									{tempPrice.rowtext}
								</Tag>
							</div>
						)}
					</>
				)}
			</div>
		</>
	)};

	return (
		<main className={publicEmbed ? 'gx-m-4' : 'container'}>
			{/* <StoreHeader productId={id} /> */}
			<div className="gx-mb-4 gx-d-flex">
				<Link
					to={
						publicEmbed
							? `/public/ecommerce/${moduleUrlId}/selection${history.location.search}`
							: `/main/ecommerce/${moduleUrlId}/selection${history.location.search}`
					}
				>
					<div className="gx-d-flex gx-align-items-center">
						<Left style={{ width: '1em' }} />
						<span className="gx-ml-1 gx-text-black">Back</span>
					</div>
				</Link>
				<Button
					disabled={parseInt(cartCount) > 0 ? false : true}
					onClick={checkout}
					className="gx-ml-auto button-primary"
					shape="round"
				>
					<div className="gx-d-flex gx-justify-content-center gx-align-items-center">
						<CartCheck className="gx-mr-2" style={{ width: '1.2em' }} />
						<p className="gx-mb-0">My Shopping Cart ({cartCount})</p>
					</div>
				</Button>
			</div>

			<main className="gx-d-lg-flex gx-align-items-start">
				<div style={{ flexBasis: '100%' }}>
					<Card className="card shadow-2">
						{loading ? (
							<>
								<Image
									className="gx-mb-3 card-image"
									alt="placeholder"
									src={require('../../../../assets/images/placeholder-image.png')}
								/>
								<Skeleton active className="gx-mb-3"></Skeleton>
								<Skeleton.Button
									className="gx-d-block"
									style={{ width: '100%' }}
									active
									size="large"
								/>
							</>
						) : (
							<>
								<div className="gx-d-flex gx-justify-content-between card-list-details">
									<div>
										{/* category */}
										<span className="category-ribbon">{product?.category}</span>

										{/* <Image
											className="gx-mb-3 card-image"
											alt="placeholder"
											src={
												product.image ||
												require('../../../../assets/images/product-placeholder.png')
											}
										/> */}

										{/* tags */}
										{showTags &&
											getDisplayTagIds(product).map(
												tagId =>
													tagsMap[tagId] && (
														<Tag key={tagId} className="tag">
															{tagsMap[tagId]}
														</Tag>
													),
											)}

										{/* title */}
										<h2 className="gx-mb-2 gx-font-weight-semi-bold gx-pt-5">
											{price?.price_collection?.primary.label ??
												product?.title}
										</h2>

										{/* <hr /> */}
										{/* description */}
										<p
											className="gx-mb-3"
											dangerouslySetInnerHTML={{
												__html: product?.description,
											}}
										/>
									</div>
									<div className="gx-mb-3">
										<div className="bundlePrice gx-d-flex gx-flex-column gx-align-items-center gx-ml-5">
											{price?.isFetched &&
												printPrice?.isFetched &&
												price?.price_collection?.primary &&
												printPrice?.price_collection?.primary ? (
												// Both prices available
												<>
													<Radio.Group
														className="gx-d-flex gx-align-items-center gx-mb-2"
														defaultValue={() => {
															return selectedPrice.print_price
																? 'print'
																: 'digital';
														}}
														disabled={addedToCart ? true : false}
													>
														<Radio.Button
															onClick={() => setSelectedPrice(price)}
															value="digital"
															className="radio-button-left gx-mb-0"
														>
															<div className="gx-d-flex gx-align-items-center">
																<GlobalOutlined
																	className="gx-mr-2"
																	style={{ width: '1.2em' }}
																/>
																<p className="gx-mb-0">Digital</p>
															</div>
														</Radio.Button>

														<Radio.Button
															onClick={() =>
																setSelectedPrice(printPrice)
															}
															value="print"
															className="radio-button-right gx-mb-0"
														>
															<div className="gx-d-flex gx-align-items-center">
																<BookOutlined
																	className="gx-mr-2"
																	style={{ width: '1.2em' }}
																/>
																<p className="gx-mb-0">Print</p>
															</div>
														</Radio.Button>
													</Radio.Group>

													<>{renderPriceSection(selectedPrice)}</>
												</>
											) : (
												<>
													{printPrice?.isFetched &&
														printPrice?.price_collection?.primary &&
														price.isFetched &&
														!price?.price_collection?.primary ? (
														// Print Price available and digital price NOT available
														<>
															<Tag
																style={{
																	borderLeft: `6px solid ${styling['primary-dark-color']}`,
																	borderRadius: '5px',
																}}
															>
																<BookOutlined /> Print Version
															</Tag>
															{renderPriceSection(printPrice)}
														</>
													) : (
														<>{renderPriceSection(price)}</>
													)}
												</>
											)}
										</div>
									</div>
								</div>
								{/* {product.parentIds.length > 0 && (
									<>
										<hr />
										{product.parentIds.map(parentId => (
											<BundlePromo key={parentId} bundleId={parentId} />
										))}
									</>
								)} */}
							</>
						)}
						<div>
							{publicationDate ? (
								<p className='gx-ml-4'>
									Publication Date:&nbsp;
									{moment(publicationDate).format('MM/DD/YYYY')}
								</p>
							) : null}
						</div>

						{productShareConfig && <DirectLinkModel
							base_url={`${productShareConfig.base_url}?pubid=${product?.id}&bundle=1`}
							className='ant-footer-button-hide'

						>
							{productShareConfig &&
								// product.childrenIds &&
								// product.childrenIds.length > 0 &&
								!isEmpty(productShareConfig) &&
								productShareConfig.base_url && (
									<div
										className="gx-d-flex gx-justify-items-center"
										onClick={event => {
											// window.open(
											// 	`${productShareConfig.base_url}?pubid=${product.id}&bundle=1`,
											// );
											event.stopPropagation();
											event.preventDefault();
										}}
										style={{ maxWidth: '6rem' }}
									>
										<LinkSvg className="gx-mr-2  gx-pointer" style={{ width: '1.3em', fill: 'lightgray' }} />
										<p className="gx-pointer" style={{ padding: 0, margin: 0 }}>{productShareConfig.display_text ?? 'Direct Link'}{' '}</p>
									</div>
								)}
						</DirectLinkModel>
						}
					</Card>
				</div>

				{/* <Card className="gx-mb-0 card" style={{ flexBasis: '60%' }}>
					{loading ? (
						<Skeleton active></Skeleton>
					) : (
						<>
							{product.files.length > 1 && (
								<Pagination
									className="gx-text-center gx-mb-2"
									current={currentIndex + 1}
									total={product.files.length ?? 0}
									defaultPageSize={1}
									onChange={page => setCurrentIndex(page - 1)}
								/>
							)}
							<h2 className="">{currentItem?.title}</h2>

							<p className="">{currentItem?.description}</p>

							{currentItem?.type === FILE.VIDEO && (
								<div className="gx-mb-3 gx-d-flex gx-align-items-center">
									<div className="gx-d-flex gx-align-items-center gx-mr-4">
										<Video
											className="fg-secondary"
											style={{ width: '1.2em' }}
										/>
										<h4 className="gx-ml-2 gx-mb-0 fg-grey">
											{currentItem.type}
										</h4>
									</div>
									<div className="gx-d-flex gx-align-items-center">
										<FieldTimeOutlined />
										<h4 className="gx-ml-2 gx-mb-0 fg-grey">
											{(currentItem?.duration / 60).toFixed(2)} minutes
										</h4>
									</div>
								</div>
							)}

							{currentItem?.type === FILE.PDF && (
								<div className="gx-mb-3 gx-d-flex gx-align-items-center">
									<PDF style={{ width: '1.4em' }} />
									<h4 className="gx-ml-2 gx-mb-0 fg-grey">{currentItem.type}</h4>
								</div>
							)}
						</>
					)}

					{currentItem?.image_url ? (
						<Image
							className="gx-mx-auto"
							style={{ maxHeight: '1000px', borderRadius: '3px' }}
							src={currentItem.image_url}
							alt={currentItem.title}
						></Image>
					) : (
						<div className="gx-bg-light gx-d-flex" style={{ minHeight: '700px' }}>
							<h4 className="gx-text-center gx-m-auto gx-text-light-grey">
								Preview Unavailable
							</h4>
						</div>
					)}
				</Card> */}
			</main>
		</main>
	);
};

export default ProductPage;
