import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './main/index';

const App = ({ match }) => (
	<Switch>
		<Route path={`${match.url}main`} component={Main} />
	</Switch>
);

export default App;
