import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import {
	requestAddItemNoLogin,
	requestRequestAddItemApiNoLogin,
	requestAddSelectionCart,
	requestAttenuuidInfo,
	requestDeleteCartItem,
	requestDeleteCartItemNoLogin,
	requestSelectionArray,
	requestFlushCartItems,
} from '../../../../appRedux/actions/Ecommerce';
import {
	requestProductAccessList
} from '../../../../appRedux/actions/EcommerceStore';


import { requestEraseCommonData } from '../../../../appRedux/actions/Common';

export const useShoppingCart = () => {
	const dispatch = useDispatch();
	const location = useSelector(state => state.router.location);

	const ecommerce = useSelector(state => state.ecommerce);
	const settings = useSelector(state => state.settings);
	const appdir = useSelector(state => state.loginInfo.appdir);
	const cart = useSelector(state => state.ecommerce.cartItems?.InvoiceItemArray ?? []);
	const selectionArrayFetched = useSelector(state => state.ecommerce.selectionArrayFetched);
	let publicUrlCheck = settings.publicEmbed;
	const [cartCount, setCartCount] = useState(() => {
		if (publicUrlCheck && ecommerce.cartItemsNoLogin?.length !== 0 && !ecommerce.cartItems) {
			return ecommerce.cartItemsNoLogin.InvoiceItemArray?.length;
		} else if (ecommerce.cartItems) {
			return ecommerce.cartItems.InvoiceItemArray?.length;
		} else {
			return 0;
		}
	});
	const { moduleUrlId } = useParams();

	useEffect(() => {
		if (publicUrlCheck && ecommerce.cartItemsNoLogin?.length !== 0 && !ecommerce.cartItems) {
			setCartCount(ecommerce.cartItemsNoLogin.InvoiceItemArray?.length);
		} else if (ecommerce.cartItems) {
			setCartCount(ecommerce.cartItems.InvoiceItemArray?.length);
		} else {
			setCartCount(0);
		}
	}, [publicUrlCheck, ecommerce.cartItemsNoLogin, ecommerce.cartItems]);
	useEffect(() => {
		if (!ecommerce.attenuuid) {
			dispatch(requestAttenuuidInfo({ appdir, moduleUrlId }));
		}
		//This should trigger after successful payment
		if (ecommerce.attenuuid == '' && ecommerce.cartItems?.SUCCESS) {
			dispatch(requestEraseCommonData({}));
			dispatch(requestFlushCartItems({}));
			dispatch(requestProductAccessList());
		}
	}, [ecommerce.attenuuid]);

	useEffect(() => {
		if (!ecommerce.cartUuid && appdir && !selectionArrayFetched) {
			dispatch(
				requestSelectionArray({
					appdir: appdir,
					moduleUrlId: moduleUrlId,
					publicEmbed: publicUrlCheck,
				}),
			);
		}
	}, [appdir]);

	const addToCart = (priceObject, value, fieldType, screen) => {
		console.log('addToCart', {priceObject, value, fieldType, screen})
		let myProduct = {};
		if(screen === "checkoutScreen") {
			myProduct[priceObject.field_name] = {
				valueid: priceObject.value_id,
				qty: fieldType === "multidrop" ? value?.[priceObject.value_id] : 1,
			}
		} else {
			myProduct[priceObject.fieldname] = {
				valueid: priceObject.valueid,
				qty: fieldType === "multidrop" ? value?.[priceObject.productid] : 1,
			};
		}
		// get the current cart, append to the current cart, then send it over as an object
		if (publicUrlCheck === true && ecommerce.attenuuid === '') {
			if (ecommerce.cartItemsNoLogin.length !== 0) {
				let currCartArray = ecommerce.cartItemsNoLogin.InvoiceItemArray;

				let tempMyProduct = {};
				tempMyProduct = currCartArray;
				tempMyProduct.push(myProduct);

				let formData = new FormData();
				formData.append('itemstoadd', JSON.stringify(tempMyProduct));

				dispatch(
					requestRequestAddItemApiNoLogin({
						appdir: appdir,
						moduleUrlId: moduleUrlId,
						formData: formData,
						cartUuid: ecommerce.cartUuid,
					}),
				);
			} else {
				let formData = new FormData();
				formData.append('itemstoadd', JSON.stringify(myProduct));

				dispatch(
					requestRequestAddItemApiNoLogin({
						appdir: appdir,
						moduleUrlId: moduleUrlId,
						formData: formData,
						cartUuid: ecommerce.cartUuid,
					}),
				);
			}

			dispatch(requestAddItemNoLogin(myProduct));
		} else {
			myProduct = JSON.stringify(myProduct);

			let formData = new FormData();
			formData.append('itemstoadd', myProduct);
			//make this in effects or sagas, make an action in add it
			//in the cartprogress is getting data from redux

			dispatch(
				requestAddSelectionCart({
					appdir: appdir,
					moduleUrlId: moduleUrlId,
					attenuuid: ecommerce.attenuuid,
					formData: formData,
					checkoutForm: 0,
				}),
			);
		}
	};

	const removeFromCart = priceObj => {
		if (publicUrlCheck && !ecommerce.attenuuid) {
			let tempInvoiceItemArray = ecommerce.cartItems.InvoiceItemArray;

			tempInvoiceItemArray = tempInvoiceItemArray.filter(
				obj => obj.field_id !== priceObj.fieldid,
			);

			let newCartItems = [];
			newCartItems = tempInvoiceItemArray.map(item => {
				return {
					[item.field_name]: {
						valueid: item.value_id,
						qty: 1,
					},
				};
			});

			let formData = new FormData();
			formData.append('itemstoadd', JSON.stringify(newCartItems));
			formData.append('cartUuid', JSON.stringify(ecommerce.cartUuid));

			dispatch(
				requestRequestAddItemApiNoLogin({
					appdir,
					moduleUrlId,
					formData,
					cartUuid: ecommerce.cartUuid
				}),
			);

			dispatch(requestDeleteCartItemNoLogin(priceObj));
		} else {
			let myProduct = {};
			myProduct.field_id = priceObj.fieldid;

			let invoice_id = ecommerce.cartItems.InvoiceItemArray.find(
				item => item.field_id === priceObj.fieldid,
			)?.invoice_id;

			if (!invoice_id) {
				console.error('invoice id does not exist in cart');
				return;
			}

			myProduct.invoice_id = invoice_id;
			myProduct = JSON.stringify(myProduct);

			let formData = new FormData();
			formData.append('itemstodelete', myProduct);

			dispatch(
				requestDeleteCartItem({
					appdir: appdir,
					moduleUrlId: moduleUrlId,
					attenuuid: ecommerce.attenuuid,
					formData: formData,
				}),
			);
		}
	};

	return [addToCart, cartCount, cart, removeFromCart];
};
