import React, { useMemo } from "react";
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Avatar, Tag, Tooltip, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { useBundleInfo } from '../hooks/useBundleInfo';
import { useShoppingCart } from "../hooks/useShoppingCart";
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { useAccessList } from "../hooks/useAccessList";
import { useProducts } from "../hooks/useProducts";
import { paramsToSearchState, searchStateToParams } from "../helpers";


const ModalContent = ({ product, storeConfig, handleAccess, handleClose }) => {
    const location = useLocation();
    const history = useHistory();
    const { moduleUrlId } = useParams();
    const [addToCart, , cart, removeFromCart] = useShoppingCart();
    const [
        ,
        ,
        productPrice,
        ,
        ,
        ,
        ,
        pricesLoading,
        bundlePrintPrice
    ] = useBundleInfo(product.id);
    const [, productAccessList] = useAccessList();
    const [, products] = useProducts();

    const addedToCart = useMemo(
        () => cart.some(invoice => invoice.field_id === productPrice?.fieldid),
        [cart],
    );
    const publicEmbed = useSelector(state => state.settings.publicEmbed);
    const searchParams = paramsToSearchState(location.search);

    const parentBundle = useMemo(() => products.find((prod) => product.parentIds.includes(prod.id)), [products, product]);
    const grandParent = useMemo(() => products.find((prod) => parentBundle?.parentIds?.includes(prod.id)), [products, parentBundle]);

    const publicationDate = useMemo(() => {
        if (product?.publicationDate) return product?.publicationDate
        else if (grandParent?.publicationDate) return grandParent?.publicationDate
        else if (parentBundle?.publicationDate) return parentBundle?.publicationDate
        else return null;
    }, [product, grandParent, parentBundle]);

    const titleProduct = useMemo(() => {
        if (grandParent?.title) return grandParent;
        else if (parentBundle?.title) return parentBundle;
        else return null;
    }, [grandParent, parentBundle]);

    const handleProduct = () => {
        if (titleProduct?.id) {
            history.push(
                `/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/bundle/${titleProduct.id}?${publicEmbed ? 'public_embed=1' : ''}?${searchStateToParams(searchParams)}`
            );
            handleClose();
        }
    }

    const renderAccessNow = () => (
        <Button
            className="gx-mt-2 gx-ml-auto gx-mb-0 button-secondary button-round"
            onClick={handleAccess}
            style={{ backgroundColor: "#469f46" }}
        >
            <div className="gx-px-3 buy-btn-content">
                Access Now
            </div>
        </Button>
    );

    const hasPrice = !pricesLoading && productPrice?.price_collection;
    return (
        <div className='gx-mt-4'>
            <div className='gx-d-flex gx-justify-content-between gx-align-items-center'>
                <h3>{product?.title}</h3>
            </div>
            <p
                dangerouslySetInnerHTML={{
                    __html: product?.description,
                }}
                className="gx-mt-2"
                style={{
                    textAlign: 'justify'
                }}
            ></p>
            <div>
                {publicationDate ? (
                    <p className='gx-ml-4'>
                        Publication Date:&nbsp;
                        {moment(publicationDate).format('MM/DD/YYYY')}
                    </p>
                ) : null}
            </div>
            <div style={{position:'absolute', maxWidth:'70%'}}>
                {!product?.parentIds?.length && product?.category
                    ? (
                        <Tag
                            style={{
                                background: '#353535',
                                color: 'white',
                                padding: '5px 12px',
                                cursor: 'pointer'
                            }}
                        >
                            {product.category}
                        </Tag>
                    ) : (
                        titleProduct?.title ? (
                            <Tooltip title={titleProduct?.title}>
                                    <Tag
                                        className={titleProduct?.title?.length > 80 ? "truncateTitle" : ""}
                                        style={{
                                            background: '#353535',
                                            color: 'white',
                                            padding: '5px 12px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleProduct}
                                    >
                                        {titleProduct?.title}
                                    </Tag>
                            </Tooltip>
                        ) : null
                    )
                }
            </div>
            <div>
                {product?.author_info_array?.length ? (<div className="gx-mt-5">
                    {product?.author_info_array.map((author) => {
                        return (
                            <div className="description-text gx-d-flex gx-align-items-center gx-mt-4">
                                <div><Avatar size="medium" src={author.picture} icon={<UserOutlined />} /></div>
                                <div className='gx-ml-2'>{`${author.firstname} ${author.lastname}`}</div>
                            </div>
                        )
                    })}
                </div>) : product.author_block ? (
                    <div className='gx-d-flex gx-align-items-center'>
                        <div className='gx-ml-2'>{product.author_block}</div>
                    </div>
                ) : null}
            </div>
            <div className="gx-d-flex gx-justify-content-end">
                <div className="primary-red-btn">
                    <div className="product-card-btn-wrapper gx-mb-1 gx-mt-3">
                        <div className="product-price-details gx-d-flex gx-justify-content-center">
                            {/* {productPrice?.price_collection?.teaser && (
									<Tag className="teaser-tag">
										{productPrice?.price_collection?.teaser?.price === 0
											? 'FREE'
											: `$${productPrice?.price_collection?.teaser?.price}`}{' '}
										for members
									</Tag>
								)} */}
                        </div>
                        {hasPrice &&
                            (productPrice.user_has_open_access || productAccessList.includes(product.id) ? (
                                <div>
                                    {/* <Tag className="gx-mb-0">
                                        {storeConfig.free_for_members_message &&
                                            !_isEmpty(storeConfig.free_for_members_message)
                                            ? `${storeConfig.free_for_members_message}`
                                            : 'You already have access'}
                                    </Tag> */}
                                <div className='gx-d-flex gx-mt-3'>
                                        {renderAccessNow()}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {productPrice.open_access_tease &&
                                        productPrice.open_access_tease.length > 0 && !product.exclude_open_access_tease_message &&(
                                            <>
                                                <Tooltip
                                                    title={productPrice.open_access_tease.join(
                                                        ', ',
                                                    )}
                                                >
                                                    <Tag className="gx-mb-0">
                                                        {storeConfig.open_access_tease_message &&
                                                            !_isEmpty(
                                                                storeConfig.open_access_tease_message,
                                                            )
                                                            ? `${storeConfig.open_access_tease_message}`
                                                            : 'Open access available for members'}
                                                    </Tag>
                                                </Tooltip>
                                                <br />
                                                <br />
                                            </>
                                        )}
                                    {productAccessList.includes(product.id)
                                        ? (<>{renderAccessNow()}</>)
                                        : (
                                            <Button
                                                className="button-primary gx-w-100"
                                                shape="round"
                                                onClick={e =>
                                                    addedToCart
                                                        ? removeFromCart(productPrice)
                                                        : addToCart(productPrice)
                                                }
                                            >
                                                <div className="gx-d-flex gx-justify-content-center gx-align-items-center">
                                                    {pricesLoading ? (
                                                        <Spin
                                                            className="gx-ml-1 gx-mt-2"
                                                            size="small"
                                                        />
                                                    ) : addedToCart ? (
                                                        <p>
                                                            <Remove
                                                                className="gx-mr-2"
                                                                style={{ width: '0.9em' }}
                                                            />
                                                            Remove
                                                        </p>
                                                    ) : (
                                                        productPrice?.price_collection && (
                                                            <>
                                                                <p className="gx-mb-0">
                                                                    {productPrice.price_collection
                                                                        .primary.price === 0
                                                                        ? 'FREE'
                                                                        : storeConfig.buy_now_button_label &&
                                                                            !_isEmpty(
                                                                                storeConfig.buy_now_button_label,
                                                                            )
                                                                            ? `${storeConfig.buy_now_button_label} ($${productPrice.price_collection.primary.price})`
                                                                            : `BUY $${productPrice.price_collection.primary.price}`}
                                                                </p>

                                                                {/* {productPrice.price_collection.primary.price !== totalPrice && (
														<>
															<h4 className="gx-mr-3 gx-text-strikethrough gx-text-grey gx-mb-0">
																${totalPrice}
															</h4>
															<Tag className="gx-mb-0 bundle-tag">
																Save {savingsPercentage}%
															</Tag>
														</>
													)} */}
                                                            </>
                                                        )
                                                    )}
                                                </div>
                                            </Button>
                                        )}
                                </>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalContent