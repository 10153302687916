import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { Drawer, Alert } from 'antd';

import StateResults from '../components/StateResults';
import SearchBox from 'components/Common/Algolia/SearchBox';
import Pagination from 'components/Common/Algolia/Pagination';
import FilterSidebar from '../components/FilterSidebar';
import Header from '../components/Header';
import AdvancedSearch from '../../Common/AdvanceSearch';
// import Switch from '../../Common/Switch';

import { useProducts } from '../hooks/useProducts';
import { useSearchClient } from '../hooks/useSearchClient';
import { useCategory } from '../hooks/useCategory';
import { useTags } from '../hooks/useTags';

import { hitsPerPage } from '../constants';
import { paramsToSearchState, searchStateToParams } from '../helpers';
// import { ReactComponent as List } from '../../../assets/icons/list.svg';
// import { ReactComponent as Grid } from '../../../assets/icons/grid.svg';

import '../styles/custom.less';
import { storeConsumptionSortType } from '../../../appRedux/actions';

// const VIEWS = [
// 	{ key: 'Product', label: 'Product View', value: 0, disableOnMobile: false, Icon: List },
// 	{ key: 'Author', label: 'Author View', value: 1, disableOnMobile: false, Icon: Grid },
// ];

const SelectionPage = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [prodsLoading, products] = useProducts();
	const [catsLoading, categoriesMap] = useCategory();
	const [tagsLoading, tagsMap] = useTags();

	const [showFilters, setShowFilters] = useState(false);
	const [pageSize, setPageSize] = useState(1);
	const [searchState, setSearchState] = useState(paramsToSearchState(location.search));
	// const [cardView, setCardView] = useState(0);
	const [customRefresh, setCustomRefresh] = useState(false);
	const sortType = useSelector((state) => state.consumption.sortType);
	const query = useSelector(state => state.router.location.query);
	const tagsMapping = useSelector(state => state.consumption.tagsMapping);
	const categoriesMapping = useSelector(state => state.consumption.categoriesMapping);
	const advanceCategoriesMapping = useSelector(state => state.consumption.advanceCategoriesMapping);
	const countryMapping = useSelector(state => state.consumption.countryMapping);
	const companyMapping = useSelector(state => state.consumption.companyMapping);

	const page = new URLSearchParams(history.location.search).get("page");
	const config = useSelector(state => state.consumption.config);
	useEffect(() => {
		// to refresh the search box for reflect searched value in both the places
		setCustomRefresh(true);
		setTimeout(() => {
			setCustomRefresh(false);
		}, 50);
	}, [searchState?.query]);

	const authorBasedMapping = useMemo(() => products.reduce((acc, p) => {
		let temp = { ...acc };
		for (const author of p.authorInfo) {
			if (author.country) {
				if (!temp.country[author.country]) {
					temp.country[author.country] = new Set();
				}
				temp.country[author.country].add(p.id)
			}
			if (author.company) {
				if (!temp.company[author.company]) {
					temp.company[author.company] = new Set();
				}
				temp.company[author.company].add(p.id)
			}
		}
		return temp;
	}, { country: {}, company: {} }), [products]);

	const searchClient = useSearchClient(prodsLoading, products, sortType, authorBasedMapping, tagsMapping, categoriesMapping, advanceCategoriesMapping, countryMapping, companyMapping);

	const publicEmbed = query.public_embed === '1';

	const advancedSearchRefinementCount = useMemo(() => {
		let filterCount = 0;
		if (searchState?.refinementList) {
			let selectedStartYearKey = Object.entries(searchState?.refinementList).filter(([key, item]) => key === 'publicationStart' && item)?.[0];
			Object.entries(searchState?.refinementList).forEach(([key, item]) => {
				if (selectedStartYearKey && key === 'publicationEnd' && item) {
					filterCount += 1;
				} else if (key !== 'category'
					&& key !== 'publicationStart'
					&& key !== 'publicationEnd'
					&& item?.length) {
					filterCount += item?.length
				}
			});
		}
		return filterCount;
	}, [searchState?.refinementList]);

	const gotoProduct = (product, parent) => {
		let route = '';
		if (product.isSuperBundle) route = `selection/${product.id}`; // path for super bundle
		else if (product.isBundle && !product.parentId) route = `bundle/${product.id}`; // path for independent bundle
		else if (product.isBundle && product.parentId) route = `group/${product.parentId}/${product.id}`; // path for bundle related to the superBundle
		else if (product.grandparentId && product.parentId && product.grandparentId !== product.parentId) route = `group/${product.grandparentId}/${product.parentId}/${product.id}`; // path for product which has both parent and grand parent.
		else if (product.parentId && !parent?.isSuperBundle) route = `bundle/${product.parentId}/${product.id}`; // path for products which has parent but no grand parent
		else route = `product/${product.id}`;

		const searchParams = paramsToSearchState(location.search);
		if (page) {
			searchParams.page = 1;
		}
		history.push(`${route}?${searchStateToParams(searchParams)}`);
	};

	if (!prodsLoading && (!products || products.length === 0)) {
		return (
			<div className="gx-mt-5">
				<Alert message={config?.noProductsFoundMessage ? (<div dangerouslySetInnerHTML={{__html: config?.noProductsFoundMessage,}}/>) :'You currently do not have any products purchased.'} type="warning" />
			</div>
		);
	}

	const onPageChange = () => {
		if (window.parent && window.parent.scrollTo) {
			window.parent.scrollTo(0, 0);
		} else {
			window.scrollTo(0, 0);
		}
	};

	const searchStateChange = state => {
		const newState = { ...state };
		if (newState?.refinementList
			&& newState?.refinementList?.publicationEnd
			&& newState?.refinementList?.publicationStart
			&& parseInt(newState?.refinementList?.publicationEnd, 10) < parseInt(newState?.refinementList?.publicationStart, 10)
		) {
			newState.refinementList.publicationEnd = "";
		}
		setSearchState(newState);
		history.push(`?${searchStateToParams(newState)}`);
	};

	// const handleSwitch = (value) => {
	// 	setCardView(value);
	// };

	const handlePageSize = (size) => {
		setPageSize(size)
	}

	const handleSearch = (value) => {
		if (value && sortType !== '2') {
			dispatch(storeConsumptionSortType({ sortType: '2' }));
		} else if (!value && sortType === '2') {
			dispatch(storeConsumptionSortType({ sortType: '0' }));
		} else {
			dispatch(storeConsumptionSortType({ sortType: '0' }));
		}
	};

	return (
		<InstantSearch
			indexName="consumption/selection"
			searchClient={searchClient}
			onSearchStateChange={searchStateChange}
			searchState={searchState}
		>
			<main className="selection-container gx-p-5">
				{!publicEmbed && <Header />}
				{/* <div className="gx-mb-3">
					<Switch
						views={VIEWS}
						handleSwitch={handleSwitch}
						value={cardView}
					/>
				</div> */}

				<section className="gx-d-flex gx-w-100" style={{ overflow: 'visible', gap: '1.5em' }}>
					<Drawer
						placement="left"
						visible={showFilters}
						onClose={() => setShowFilters(false)}
					>
						<div className="gx-algolia-sidebar gx-mx-0 gx-p-4">
							<FilterSidebar categories tags={false} />
						</div>
					</Drawer>

					<div className="gx-flex-1 gx-w-100" style={{ overflow: 'visible' }}>
						<div className="gx-mb-2 gx-d-flex selectionFilter">
							{advancedSearchRefinementCount ? null : <FilterSidebar categories tags={false} />}
							<SearchBox customRefresh={customRefresh} handleCustomSearch={handleSearch} />
							<div style={{ marginLeft: '0.5rem' }}>
								<FilterSidebar sortBy />
							</div>
						</div>
						{!prodsLoading ? (
							<AdvancedSearch
								searchStateValues={searchState}
								categoriesMap={categoriesMap}
								tagsMap={tagsMap}
								advancedRefinementCount={advancedSearchRefinementCount}
								handlePageSize={handlePageSize}
								customRefresh={customRefresh}
								handleCustomSearch={handleSearch}
							/>
						) : null
						}
						<div className="gx-mb-4">
							<StateResults loading={prodsLoading} gotoProduct={gotoProduct} />
						</div>

						<Configure hitsPerPage={pageSize === 1 ? hitsPerPage : pageSize} />

						<div className="gx-text-center gx-pt-3">
							<Pagination onPageChange={onPageChange} handlePageSize={handlePageSize} pageSize={pageSize} />
						</div>
					</div>
				</section>
			</main>
		</InstantSearch>
	);
};

export default SelectionPage;
