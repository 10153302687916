export const getCheckoutRoute = moduleUrlId => `/main/ecommerce/${moduleUrlId}/checkout`;

export const getCheckoutPublicRoute = moduleUrlId =>
	`/public/ecommerce/${moduleUrlId}/checkout?public_embed=1`;

export const getSelectionRoute = moduleUrlId => `/main/ecommerce/${moduleUrlId}/selection`;

export const getSelectionPublicRoute = moduleUrlId =>
	`/public/ecommerce/${moduleUrlId}/selection?public_embed=1`;

// strip text of <p> and </p> tags
export function stripPtags(text) {
	return text.replace(/<\/p>/gi, '\n\n')?.replace(/<p>/gi, '');
}

export function getBreadcrumbs(products, productId) {
	const product = products.find(prod => prod.id === productId);
	if (!product) return [];
	const isBundle = product.childrenIds.length > 0;
	const breadcrumb = {
		text: product.title,
		link: `../${isBundle ? 'bundle' : 'product'}/${product.id}`,
	};
	if (!product.parentIds.length) return [breadcrumb];
	const parentId = product.parentIds[0];
	return [...getBreadcrumbs(products, parentId), breadcrumb];
}

export const productsPerPage = 12;

export const paramsToSearchState = params => {
	const urlParams = new URLSearchParams(params);
	const searchState = {
		query: urlParams.get('query'),
		page: parseInt(urlParams.get('page')) || 1,
		publicationStart: urlParams.get('publicationStart'),
		publicationEnd: urlParams.get('publicationEnd'),
		refinementList: {
			category: urlParams.get('categories')?.split(',') ?? [],
			advanceCategory: urlParams.get('advanceCategories')?.split(',') ?? [],
			tag: urlParams.get('tags')?.split(',') ?? [],
			company: urlParams.get('companies')?.split(',') ?? [],
			country: urlParams.get('countries')?.split(',') ?? [],
			fileType: urlParams.get('fileType')?.split(',') ?? []
		},
	};
	return searchState;
};

export const searchStateToParams = state => {
	const query = state.query;
	const page = state.page;
	const publicationStart = state.publicationStart
	const publicationEnd = state.publicationEnd
	const categories = state.refinementList?.category;
	const tags = state.refinementList?.tag;
	const advanceCategories = state.refinementList?.advanceCategory;
	const companies = state.refinementList?.company;
	const countries = state.refinementList?.country
	const fileType = state.refinementList?.fileType;
	const searchParams = new URLSearchParams({ });
	if (query) searchParams.append('query', query);
	if (categories?.length) searchParams.append('categories', categories);
	if (tags?.length) searchParams.append('tags', tags);
	if (advanceCategories?.length) searchParams.append('advanceCategories', advanceCategories);
	if (companies?.length) searchParams.append('companies', companies);
	if (countries?.length) searchParams.append('countries', countries);
	if (fileType?.length) searchParams.append('fileType', fileType);
	if (publicationStart) searchParams.append('publicationStart', publicationStart)
	if (publicationEnd) searchParams.append('publicationEnd', publicationEnd)
	return searchParams;
};
