import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OpenModal from '../Common/OpenModal';
import { Form, Alert } from 'antd';
import FormComponent from '../form';
import { parseScreen } from '../../util/form';
import {
	requestToFetchUserContactInfoFieldValues,
	requestToUpdateUserContactInformation,
	requestToAddNewUserContact,
	requestCreateMiniProfileFields,
	requestUpdateMiniProfileFields,
} from '../../appRedux/actions';
import { useParams } from 'react-router';
import CircularProgress from '../../components/CircularProgress';
import { goToFirstError } from './helpers/generalHelpers';
import { ReactComponent as AddContactIcon } from '../../assets/icons/addContact.svg';

const UserContactInformation = ({ isButton, actionText, title, formData, compUuid, record }) => {
	const [validate, setValidate] = useState(false);
	const [formControl] = Form.useForm();
	const [formState, setFormState] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const [formAlert, setFormAlert] = useState(false);

	const dispatch = useDispatch();

	const corporateMembership = useSelector(state => state.corporateMembership);
	const loginInfo = useSelector(state => state.loginInfo);

	useEffect(() => {
		const user = corporateMembership.companyMembers.find(companyMember => {
			if (record) {
				return companyMember?.uuid === record?.uuid;
			}
		});

		if (user) {
			setInitialValues(user?.userContactInfoFieldValues);
		}

		setValidate(false);
	}, [corporateMembership.companyMembers, record]);

	const [validationsObject, setValidationsObject] = useState({
		error: false,
		validationErrors: {},
	});
	const { moduleUrlId } = useParams();

	const onUpdateOrAddClicked = () => {
		setValidate(true);
		let formData = formState;
		if (formData['firstname'] && formData['firstname'] && formData['email']) {
			formData['otheruuid'] = record.uuid;
			dispatch(
				requestUpdateMiniProfileFields({
					formData,
				}),
			);
		} else {
			// No record exists so must be a new addition.
			formData['add_from'] = 'Corporate Membership';
			dispatch(
				requestCreateMiniProfileFields({
					formData,
				}),
			);
		}
	};

	const onContactInformationClick = () => {
		if (record && actionText === 'UPDATE') {
			const user = corporateMembership.companyMembers.find(companyMember => {
				return companyMember?.uuid === record?.uuid;
			});

			if (user?.userContactInfoFieldValues?.state === 'initial') {
				dispatch(
					requestToFetchUserContactInfoFieldValues({
						urlId: moduleUrlId,
						userUuid: record?.uuid,
					}),
				);
			}
		}
	};
	const closeAlert = () => {
		setFormAlert(false);
	};

	let formLoader = true;
	if (!record) {
		formLoader = false;
	}
	if (
		record &&
		Object.keys(initialValues).length > 0 &&
		(initialValues?.state === 'fetched' || initialValues?.state === 'error')
	) {
		formLoader = false;
	}

	return (
		<div>
			<OpenModal
				isButton={isButton}
				icon={'edit'}
				title={title}
				action={actionText}
				handleOnClick={onContactInformationClick}
				modalContent={
					<div className="">
						<div>
							{!formLoader ? (
								<FormComponent
									form={formControl}
									initialValues={initialValues}
									formData={
										Array.isArray(formData[0].fieldids)
											? parseScreen(formData[0])
											: []
									}
									validationConfig={{
										module: 'contact',
										moduleurlid: moduleUrlId,
										menuid: formData[0]?.menuid,
									}}
									onChange={(validations, all) => {
										setValidationsObject(validations);
										setFormState(all);
									}}
									validate={validate}
									primaryColor={loginInfo.login_form.primary_color}
									secondaryColor={'#808080'}
									textColor={'#404040'}
								/>
							) : (
								<CircularProgress />
							)}
							{formAlert && (
								<Alert
									onClose={closeAlert}
									description={<div>Please add all required fields</div>}
									type="error"
									closable={true}
								/>
							)}
						</div>
					</div>
				}
				okClicked={onUpdateOrAddClicked}
			/>
		</div>
	);
};

export default UserContactInformation;
