import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from "react-router-dom";
import moment from 'moment';

import { useTags } from '../hooks/useTags';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { useContentType } from '../hooks/useContentType';

import { Button, Tag, Card, Spin, Image, Typography, Tooltip, Avatar } from 'antd';
import { BookOutlined, DoubleRightOutlined, MinusOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';

import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';

import { ReactComponent as CartPlus } from '../../../../assets/icons/cartPlus.svg';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { ReactComponent as PDF } from '../../../../assets/icons/pdf.svg';
import { ReactComponent as Book } from '../../../../assets/icons/book.svg';
import { ReactComponent as Link } from '../../../../assets/icons/link.svg';
import './selection.css';
import isEmpty from 'lodash/isEmpty';
import DirectLinkModel from '../components/DirectLinkModel';
import CommonQuantityCount from './CommonQuantity';
import { updatedProductCount } from '../../../../appRedux/actions';

const { Paragraph } = Typography;

const ProductCardList = ({ product, price, showProduct, printPrice, productAccessList, handleAccess, quantityCount, setQuantityCount }) => {
	const [tagsLoading, tagsMap, getDisplayTagIds] = useTags();
	const dispatch = useDispatch();
	const [contentLoading, CONTENT, , getFascadeContentTypes] = useContentType();
	const [selectedProduct, setSelectedProduct] = useState([])
	const [authorInfo, setAuthorInfo] = useState({});
	const [addToCart, , cart, removeFromCart] = useShoppingCart();
	const showTags = !useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);
	const storeConfig = useSelector(
		state => state.ecommerce.attendeeSettings?.attendeesiteadmin?.storeconfigobject,
	);

	const cartItems = useSelector(state => state.ecommerce.cartItems.InvoiceItemArray) ?? []

	const cartDetails = useSelector(state => state.ecommerce.cartItems)

	const updatedProduct = useSelector(state => state.ecommerce.updatedProductCount)

	const history = useHistory();
	let querySearch = new URLSearchParams(history.location.search).get("query")?.toLowerCase()
	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
	const productShareConfig = useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.product_share_config,
	);
	useEffect(() => {
		if (product && product?.author_info_array && product?.author_info_array?.length) {
			let presenter = _filter(product.author_info_array, (prod) => prod.presenter);
			const searchedAuthor = product.author_info_array.find((items) => items.fullname.toLowerCase().includes(querySearch))
			setAuthorInfo((!_isEmpty(searchedAuthor) && searchedAuthor) ? searchedAuthor : presenter[0])
		} else {
			setAuthorInfo({})
		}
	}, [product, querySearch]);


	useEffect(() => {
		if(product.fieldType === 'multidrop' && !updatedProduct[product?.id]){
			dispatch(updatedProductCount({[product?.id] : 1}))
		}
	},[product])

	useEffect(() => {
		if(Object.keys(cartDetails).length > 0) {
			if (cartItems?.length > 0) {
				const product = []
				cartItems.map((cart) => {
					product.push({
						valueid: cart.value_id,
						quantity: cart.quantity,
						type: cart.field_type
					})
				})
				setSelectedProduct(product)
			} else {
				setSelectedProduct([])
			}
		}
	}, [cartItems, cartDetails])

	const addedToCart = cart.some(invoice => invoice.field_id === (price?.fieldid || printPrice?.fieldid) );

	const actionClicked = (event, tempPrice, quantityCount, fieldType) => {
		event.stopPropagation();
		if (addedToCart) {
			removeFromCart(tempPrice)
			dispatch(updatedProductCount({[product?.id] : 1}))
		} else {
			addToCart(tempPrice, quantityCount, fieldType)
		}
	};

	const handleOnExpand = event => {
		event.stopPropagation();
		showProduct();
	};

	const handleQuantityIncrement = (product) => {
		if (updatedProduct?.[product.id] < product.multidropMax) {
			const count = updatedProduct?.[product?.id] + 1;
			dispatch(updatedProductCount({[product?.id] : count}))
		}
	};

	const handleQuantityDecrement = (product) => {
		if (updatedProduct?.[product.id] > 1) {
			const count = updatedProduct?.[product?.id] - 1;
			dispatch(updatedProductCount({[product?.id] : count}))
		}
	};

	const convertString = (str = '') => str.replace(/<[^>]*>/g, '');

	const renderAccessNow = () => (
		<Button
			className="gx-mt-2 gx-ml-auto gx-mb-0 button-secondary button-round"
			onClick={handleAccess}
			style={{ backgroundColor: "#469f46" }}
		>
			<div className="gx-px-3 buy-btn-content">
				Access Now
			</div>
		</Button>
	);

	const renderPriceSection = (tempPrice, print_price = false) => {

		const filteredArray = selectedProduct.filter((cart) => cart.valueid === tempPrice.valueid && cart.type === 'multidrop')

		return (
			<>
				{
					tempPrice?.user_has_open_access || productAccessList.includes(product.id) ? (
						<div>
							{/* <Tag className="gx-mb-0">
							{storeConfig.free_for_members_message &&
								!_isEmpty(storeConfig.free_for_members_message)
								? `${storeConfig.free_for_members_message}`
								: 'You already have access'}
						</Tag> */}
							<div className='gx-d-flex'>
								{renderAccessNow()}
							</div>
						</div>
					) : (
						<>
							{/* teaser price */}
							<div className="product-price-details">
								{tempPrice?.open_access_tease && tempPrice.open_access_tease.length > 0 ? (
									product.exclude_open_access_tease_message ?
										<></> : <>
											<Tooltip title={tempPrice.open_access_tease.join(', ')}>
												<Tag className="teaser-tag gx-mb-0">
													{storeConfig.open_access_tease_message &&
														!_isEmpty(storeConfig.open_access_tease_message)
														? `${storeConfig.open_access_tease_message}`
														: 'Open access available for members'}
												</Tag>
											</Tooltip>
										</>
								) : (
									tempPrice?.price_collection?.teaser && (
										<Tag className="teaser-tag gx-mr-2">
											{tempPrice?.price_collection?.teaser?.price === 0
												? 'FREE'
												: `$${tempPrice?.price_collection?.teaser?.price}`}{' '}
											for members
										</Tag>
									)
								)}
							</div>
							{/* product price */}
							{/* Hiding the Buy button when there's no price for product */}
							{productAccessList.includes(product.id)
								? (<>{renderAccessNow()}</>)
								: !tempPrice?.price_collection?.primary && tempPrice?.isFetched ? (
									<></>
								) : (	
									<div style={{width: 'max-content'}} className='gx-d-flex gx-align-items-center gx-mt-auto gx-ml-auto'>
										{(product.fieldType === 'multidrop' && !filteredArray.length > 0) && <CommonQuantityCount
											quantityCount={updatedProduct?.[product.id]}
											handleQuantityDecrement={() => handleQuantityDecrement(product)}
											handleQuantityIncrement={() => handleQuantityIncrement(product)}
										/>}
										{(product.fieldType === 'multidrop' && filteredArray.length > 0) && <div className='gx-mr-3' style={{ fontSize: 16 }}>
											{filteredArray?.map((value) => {
												return <>
													Quantity: {value.quantity}
												</>
											})}
										</div>}
										<Button
											onClick={event => actionClicked(event, tempPrice, updatedProduct, product.fieldType)}
											className="gx-mt-auto gx-ml-auto gx-mb-0 button-secondary button-round"
											shape={addedToCart ? 'circle' : 'round'}
										>
											{tempPrice?.price_collection?.primary ? (
												addedToCart ? (
													<Remove style={{ width: '0.9rem', margin: '0.65em 0' }} />
												) : (
													<div className="gx-px-3 buy-btn-content">
														<Tooltip
															title={`${print_price ? 'Only Print version available' : ''
																}`}
														>
															{print_price && <BookOutlined className="gx-mr-1" />}
															{tempPrice?.price_collection?.primary?.price === 0
																? 'FREE'
																: storeConfig?.buy_now_button_label &&
																	!_isEmpty(storeConfig?.buy_now_button_label)
																	? `${storeConfig?.buy_now_button_label} ($${tempPrice.price_collection.primary.price})`
																	: `BUY $${product.fieldType === 'multidrop' ?
																	    updatedProduct?.[product.id] * tempPrice?.price_collection?.primary?.price
																		: tempPrice?.price_collection?.primary?.price}`}
														</Tooltip>
													</div>
												)
											) : (
												<Spin className="gx-px-5 gx-mr-0 gx-mb-0 gx-text-right" size="small" />
											)}
										</Button>
									</div>
								)}
						</>
					)
				}
			</>
		)
	}

	return (
		<Card
			// hoverable
			size="large"
			className="gx-mb-0 card shadow-1"
			bodyStyle={{ padding: '0px' }}
		>
			{/* product type */}
			<span className="category-ribbon">{product.category}</span>
			<div className="gx-d-flex" style={{ gap: '1.5em', padding: '24px' }}>
				<div style={{ flex: '0 0 auto' }}>
					{product.image ? (
						<Image
							className="card-image gx-mb-3 gx-pt-5 gx-pointer"
							style={{ width: '10em' }}
							alt="placeholder"
							src={
								product.image ||
								require('../../../../assets/images/product-placeholder.png')
							}
							preview={false}
							onClick={showProduct}
						/>
					) : null}
					{/* product tags */}
					{/* <div>
						{!tagsLoading &&
							showTags &&
							getDisplayTagIds(product).map(tagId => (
								<Tag key={tagId} className="tag">
									{tagsMap[tagId] ?? tagId}
								</Tag>
							))}
					</div> */}

					{/* product content tags */}
					{/* <div className="gx-mt-2 gx-d-flex gx-align-items-center gx-ml-1">
						{!contentLoading &&
							getFascadeContentTypes(product).has(CONTENT.DIGITAL) && (
								<PDF className="gx-mr-2" style={{ width: '1.3em' }} />
							)}
						{!contentLoading && getFascadeContentTypes(product).has(CONTENT.PRINT) && (
							<Book className="gx-mr-2" style={{ width: '1.2em' }} />
						)}
					</div> */}
				</div>
				<section className="gx-d-flex gx-flex-column gx-flex-1 gx-pt-5">
					<div className="gx-d-flex gx-pointer">
						<div>
							{/* product title */}
							<h3 onClick={showProduct}>{price?.price_collection?.primary?.label || product?.title}</h3>
						</div>
					</div>
					<div className="gx-d-flex gx-flex-1">
						{/* product description */}
						{product?.description ? (
							<Paragraph
								ellipsis={{
									rows: 2,
									expandable: true,
									symbol: (
										<span
											className="gx-text-bold gx-text-black gx-pointer"
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												showProduct();
											}}
										>
											More{' '}
											<DoubleRightOutlined style={{ fontSize: '11px' }} />
										</span>
									),
									onExpand: handleOnExpand,
								}}
							>
								{convertString(product?.description)}
							</Paragraph>
						) : null}
					</div>
					{product.publicationDate ? (
						<p>
							Publication Date:&nbsp;
							{moment(product.publicationDate).format('MM/DD/YYYY')}
						</p>
					) : null}
					<div className='listWrapper'>
						{productShareConfig &&
							<DirectLinkModel
								base_url={`${productShareConfig.base_url}?pubid=${product.id}&bundle=1`}
								className='ant-footer-button-hide'
							>
								{productShareConfig &&
									/* product.childrenIds &&
									product.childrenIds.length > 0 && */
									!isEmpty(productShareConfig) &&
									productShareConfig.base_url && (
										<div
											className="gx-d-flex gx-justify-items-center"
											onClick={event => {
												// window.open(
												// 	`${productShareConfig.base_url}?pubid=${product.id}&bundle=1`,
												// );
												event.stopPropagation();
												event.preventDefault();
											}}
											style={{ maxWidth: '5.7rem' }}
										>
											<Link className="gx-mr-2  gx-pointer" style={{ width: '1.2em' }} />
											<p className="gx-pointer" style={{ padding: 0, margin: 0 }}>{productShareConfig.display_text ?? 'Direct Link'}{' '}</p>
										</div>
									)}
							</DirectLinkModel>
						}
					</div>

					<div style={{ marginLeft: '1.5em' }}>
						{!_isEmpty(authorInfo) ? (
							<div className="gx-py-3 description-text gx-d-flex gx-align-items-center">
								<Avatar
									size="medium"
									src={authorInfo.picture}
									icon={<UserOutlined />}
								/>
								<div className="gx-ml-2">{`${authorInfo.firstname} ${authorInfo.lastname}`}</div>
								{authorInfo.degrees ? <div>{`, ${authorInfo.degrees}`}</div> : null}
							</div>
						) : product.author_block ? (
							<div className="gx-d-flex gx-align-items-center">
								<div className="gx-ml-2">{product.author_block}</div>
							</div>
						) : null}
					</div>
				</section>
				<div className="gx-d-flex gx-flex-column">
					{price?.isFetched &&
						printPrice?.isFetched &&
						price?.price_collection?.primary &&
						printPrice?.price_collection?.primary ? (
						// Print and Digital Prices available
						<Button
							onClick={event => {
								event.stopPropagation();
								showProduct();
							}}
							className="gx-mt-auto gx-ml-auto gx-mb-0 button-secondary button-round"
							shape={'round'}
						>
							<div className="gx-px-3 buy-btn-content">See Options</div>
						</Button>
					) : (
						<>
							{printPrice?.isFetched &&
								printPrice?.price_collection?.primary &&
								price.isFetched &&
								!price?.price_collection?.primary ? (
								// Print Price available and digital price NOT available
								// Show Print icon
								<>{renderPriceSection(printPrice, true)}</>
							) : (
								<>{renderPriceSection(price)}</>
							)}
						</>
					)}
				</div>
			</div>
			<div
				className="bottom-price-bar gx-pointer"
				style={{ backgroundColor: styling['primary-dark-color'] || '#993333' }}
				onClick={showProduct}
			>
				{product?.grandChildrenCount ? (
					<div className="gx-text-center gx-d-flex gx-align-items-center gx-justify-content-center">
						<CartPlus style={{ width: '1.2em', fill: '#ffffff' }} />
						<span className="gx-ml-1 gx-font-weight-normal">
							{product?.grandChildrenCount} Items
						</span>
					</div>
				) : null}
			</div>

		</Card>
	);
};

export default ProductCardList;
