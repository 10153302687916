import React from 'react';
import { Radio } from 'antd';

const Switch = ({ value, views = [], handleSwitch = () => { } }) => {
    return (
        <Radio.Group
            onChange={({ target }) => handleSwitch(target.value)}
            value={value}
            className="gx-ml-auto gx-d-flex gx-align-items-center"
        >
            {views.map(({
                key,
                label,
                value,
                disableOnMobile = false,
                Icon
            }, index) => (
                <Radio.Button
                    className={`${index === 0
                        ? "radio-button-left"
                        : (index + 1) === views.length
                            ? "radio-button-right"
                            : "radio-button-center"
                        } ${disableOnMobile ? "list-view-radio-btn" : ""}`
                    }
                    key={key}
                    value={value}
                >
                    <div className="gx-d-flex gx-align-items-center">
                        <Icon
                            className="gx-mr-2"
                            style={{
                                width: '1em',
                            }}
                        />
                        <p className="gx-mb-0">{label}</p>
                    </div>
                </Radio.Button>
            ))}
        </Radio.Group>
    );
}

export default Switch;
