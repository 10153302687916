import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestProductAccessList } from '../../../../appRedux/actions/EcommerceStore';

export const useAccessList = () => {
	const dispatch = useDispatch();

	const appdir = useSelector(state => state.loginInfo.appdir);
	const loading = useSelector(state => state.ecommerce.productAccessListLoading);
	const fetched = useSelector(state => state.ecommerce.productAccessListFetched);
	const productAccessList = useSelector(state => state.ecommerce.productAccessList);
	useEffect(() => {
		if (!appdir) return;
		if (loading) return;
		if (fetched) return;
		dispatch(requestProductAccessList());
	}, [appdir]);

	return [loading, productAccessList];
};
