import {
	CREATE_COMPANY, // REQUEST
	COMPANY_CREATED, // RECEIVE
	FETCH_USER_COMPANY_AFFILIATION, // REQUEST
	USER_COMPANY_AFFILIATION_FETCHED, // RECEIVE
	FETCH_EXHIBITOR_CART_DETAILS, // RECEIVE
	EXHIBITOR_CART_DETAILS_FETCHED, // RECEIVE
	FETCH_MENU, // REQUEST
	MENU_FETCHED, // RECEIVE
	FETCH_COMPANY_MEMBERS, // REQUEST
	COMPANY_MEMBERS_FETCHED, // RECEIVE
	FETCH_COMPANY, // REQUEST
	COMPANY_FETCHED, // RECEIVE
	MAKE_USER_ADMIN, // REQUEST
	USER_ADMIN_MADE, // RECEIVE
	REMOVE_USER_ADMIN, // REQUEST
	USER_ADMIN_REMOVED, // RECEIVE
	REMOVE_USER_FROM_GROUP, // REQUEST
	USER_REMOVED_FROM_GROUP, // RECEIVE
	REMOVE_USER_MEMBERSHIP, // REQUEST
	USER_MEMBERSHIP_REMOVED, // RECEIVE
	UPDATE_COMPANY_PROFILE, // REQUEST
	COMPANY_PROFILE_UPDATED, // RECEIVE
	ADD_USER_MEMBERSHIP, // REQUEST
	USER_MEMBERSHIP_ADDED, // RECEIVE
	FETCH_PAYMENT_SETTINGS, // REQUEST
	PAYMENT_SETTINGS_FETCHED, // RECEIVE
	UPDATE_USER_CONTACT_INFORMATION, // REQUEST
	USER_CONTACT_INFORMATION_UPDATED, // RECEIVE
	ADD_NEW_USER_CONTACT, // REQUEST
	NEW_USER_CONTACT_ADDED, // RECEIVE
	FETCH_EXHIBITOR_CART, // REQUEST
	EXHIBITOR_CART_FETCHED, // RECEIVE
	SEND_EMAIL_TO_USERS, // REQUEST
	EMAIL_TO_USERS_SENT, // RECEIVE
	ADD_REGISTRATION_ITEMS_TO_CART, // REQUEST
	REGISTRATION_ITEMS_ADDED_TO_CART, // RECEIVE
	DELETE_REGISTRATION_ITEM_FROM_CART, // REQUEST
	REGISTRATION_ITEM_DELETED_FROM_CART, // RECEIVE
	FETCH_USER_CONTACT_INFO_FIELD_VALUES, // REQUEST
	USER_CONTACT_INFO_FIELD_VALUES_FETCHED, // RECEIVE
	FETCH_USER_CONTACT_INFO_FIELD_VALUES_ERROR, // ERROR
	UPDATE_COMPANY_LOGO, // REQUEST
	COMPANY_LOGO_UPDATED, // RECEIVE
	RENEW_ALL, // REQUEST
	ALL_RENEWED, // RECEIVE
	ADD_USER_TO_COMPANY, // REQUEST
	USER_ADDED_TO_COMPANY, // RECEIVE
	REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA,
	RECEIVE_ERASE_CORPORATE_MEMBERSHIP_DATA,
	REQUEST_RESET_CART,
	RECEIVE_RESET_CART,
	FETCH_COMPANY_PROFILE_FIELD_VALUES,
	COMPANY_PROFILE_FIELD_VALUES_FETCHED,
	FETCH_COMPANY_MEDIA,
	COMPANY_MEDIA_FETCHED,
	UPLOAD_TO_S3,
	UPLOADED_TO_S3,
	ADD_UPDATE_DELETE_MEDIA_RESOURCE,
	ADDED_UPDATED_DELETED_MEDIA_RESOURCE,
	SORT_MEDIA_RESOURCE,
	SORTED_MEDIA_RESOURCE,
	USER_COMPANY_AFFILIATION_DELETED,
	DELETE_USER_COMPANY_AFFILIATION,
	UPDATE_FINANCIAL_ITEMS_FORM_STATE,
	FINANCIAL_ITEMS_FORM_STATE_UPDATED,
	FETCH_EXHIBITOR_CART_UUID,
	EXHIBITOR_CART_UUID_FETCHED,
	EXHIBITOR_SIGNUP,
	EXHIBITOR_SIGNEDUP,
	MAKE_CART_PUBLIC_TO_PRIVATE,
	CART_MADE_PUBLIC_TO_PRIVATE,
	SET_CURRENT_FINANCIAL_ITEM,
	SEND_MAGIC_LOGIN_LINK,
	DELETE_EXHIBITOR_PAYMENT,
	EXHIBITOR_PAYMENT_DELETED,
	FETCH_ROLES,
	ROLES_FETCHED,
	UPDATE_ROLES,
	ROLES_UPDATED,
	REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID,
	RECEIVE_CORPORATE_MEMBERSHIP_MODULEURLID,
	REQUEST_MAKE_USER_PUBLIC,
	RECEIVE_MAKE_USER_PUBLIC,
	REQUEST_REMOVE_USER_PUBLIC,
	RECEIVE_REMOVE_USER_PUBLIC,
	REQUEST_CLEAR_NEW_USER_ADDED_ERROR,
	REQUEST_CLEAR_NEW_USER_ADDED_SUCCESS,
	REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
	RECEIVE_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
	REQUEST_MINI_PROFILE_FIELDS,
	RECEIVE_MINI_PROFILE_FIELDS,
	REQUEST_UPDATE_MINI_PROFILE_FIELDS,
	RECEIVE_UPDATE_MINI_PROFILE_FIELDS,
	REQUEST_CREATE_MINI_PROFILE_FIELDS,
	RECEIVE_CREATE_MINI_PROFILE_FIELDS,
	REQUEST_ADD_COMPANY_USER,
	RECEIVE_ADD_COMPANY_USER,
} from 'constants/ActionTypes';
import { RECEIVE_CLEAR_NEW_USER_ADDED_SUCCESS } from '../../constants/ActionTypes';

export const requestCorporateMembershipModuleUrlId = data => {
	return {
		type: REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID,
		payload: data,
	};
};

export const receiveCorporateMembershipModuleUrlId = data => {
	return {
		type: RECEIVE_CORPORATE_MEMBERSHIP_MODULEURLID,
		payload: data,
	};
};

export const createCompanySuccess = response => {
	return {
		type: COMPANY_CREATED,
		payload: response,
	};
};

export const requestToCreateCompany = payload => {
	return {
		type: CREATE_COMPANY,
		payload,
	};
};

export const fetchUserCompanyAffiliationSuccess = companyAffiliation => {
	return {
		type: USER_COMPANY_AFFILIATION_FETCHED,
		payload: companyAffiliation,
	};
};

export const requestUserCompanyAffiliationData = payload => {
	return {
		type: FETCH_USER_COMPANY_AFFILIATION,
		payload,
	};
};

export const fetchExhibitorCartDetailsSuccess = companyAffiliation => {
	return {
		type: EXHIBITOR_CART_DETAILS_FETCHED,
		payload: companyAffiliation,
	};
};

export const requestExhibitorCartDetails = payload => {
	return {
		type: FETCH_EXHIBITOR_CART_DETAILS,
		payload,
	};
};

export const fetchMenuSuccess = menu => {
	return {
		type: MENU_FETCHED,
		payload: menu,
	};
};

export const requestMenuData = payload => {
	return {
		type: FETCH_MENU,
		payload,
	};
};

export const fetchCompanyMembersSuccess = companyMembers => {
	return {
		type: COMPANY_MEMBERS_FETCHED,
		payload: companyMembers,
	};
};

export const requestCompanyMembersData = payload => {
	return {
		type: FETCH_COMPANY_MEMBERS,
		payload,
	};
};

export const fetchCompanySuccess = company => {
	return {
		type: COMPANY_FETCHED,
		payload: company,
	};
};

export const requestCompanyData = payload => {
	return {
		type: FETCH_COMPANY,
		payload,
	};
};

export const makeUserAdminSuccess = response => {
	return {
		type: USER_ADMIN_MADE,
		payload: response,
	};
};

export const requestToMakeUserAdmin = payload => {
	return {
		type: MAKE_USER_ADMIN,
		payload,
	};
};

export const removeUserAdminSuccess = response => {
	return {
		type: USER_ADMIN_REMOVED,
		payload: response,
	};
};

export const requestToRemoveUserAdmin = payload => {
	return {
		type: REMOVE_USER_ADMIN,
		payload,
	};
};

export const removeUserFromGroupSuccess = response => {
	return {
		type: USER_REMOVED_FROM_GROUP,
		payload: response,
	};
};

export const requestToRemoveUserFromGroup = payload => {
	return {
		type: REMOVE_USER_FROM_GROUP,
		payload,
	};
};

export const removeUserMembershipSuccess = response => {
	return {
		type: USER_MEMBERSHIP_REMOVED,
		payload: response,
	};
};

export const requestToRemoveUserMembership = payload => {
	return {
		type: REMOVE_USER_MEMBERSHIP,
		payload,
	};
};

export const updateCompanyProfileSuccess = response => {
	return {
		type: COMPANY_PROFILE_UPDATED,
		payload: response,
	};
};

export const requestToUpdateCompanyProfile = payload => {
	return {
		type: UPDATE_COMPANY_PROFILE,
		payload,
	};
};

export const addUserMembershipSuccess = response => {
	return {
		type: USER_MEMBERSHIP_ADDED,
		payload: response,
	};
};

export const requestToAddUserMembership = payload => {
	return {
		type: ADD_USER_MEMBERSHIP,
		payload,
	};
};

export const fetchPaymentSettingsSuccess = response => {
	return {
		type: PAYMENT_SETTINGS_FETCHED,
		payload: response,
	};
};

export const requestToFetchPaymentSettings = payload => {
	return {
		type: FETCH_PAYMENT_SETTINGS,
		payload,
	};
};

export const updateUserContactInformationSuccess = response => {
	return {
		type: USER_CONTACT_INFORMATION_UPDATED,
		payload: response,
	};
};

export const requestToUpdateUserContactInformation = payload => {
	return {
		type: UPDATE_USER_CONTACT_INFORMATION,
		payload,
	};
};

export const addNewUserContactSuccess = response => {
	return {
		type: NEW_USER_CONTACT_ADDED,
		payload: response,
	};
};

export const requestToAddNewUserContact = payload => {
	return {
		type: ADD_NEW_USER_CONTACT,
		payload,
	};
};

export const fetchExhibitorCartSuccess = response => {
	return {
		type: EXHIBITOR_CART_FETCHED,
		payload: response,
	};
};

export const requestExhibitorCart = payload => {
	return {
		type: FETCH_EXHIBITOR_CART,
		payload,
	};
};

export const sendEmailToUsersSuccess = response => {
	return {
		type: EMAIL_TO_USERS_SENT,
		payload: response,
	};
};

export const requestToSendEmailToUsers = payload => {
	return {
		type: SEND_EMAIL_TO_USERS,
		payload,
	};
};

export const addRegistrationItemsToCartSuccess = response => {
	return {
		type: REGISTRATION_ITEMS_ADDED_TO_CART,
		payload: response,
	};
};

export const requestToAddRegistrationItemsToCart = payload => {
	return {
		type: ADD_REGISTRATION_ITEMS_TO_CART,
		payload,
	};
};

export const deleteRegistrationItemFromCartSuccess = response => {
	return {
		type: REGISTRATION_ITEM_DELETED_FROM_CART,
		payload: response,
	};
};

export const requestToDeleteRegistrationItemFromCart = payload => {
	return {
		type: DELETE_REGISTRATION_ITEM_FROM_CART,
		payload,
	};
};

export const fetchUserContactInfoFieldValuesSuccess = response => {
	return {
		type: USER_CONTACT_INFO_FIELD_VALUES_FETCHED,
		payload: response,
	};
};

export const requestToFetchUserContactInfoFieldValues = payload => {
	return {
		type: FETCH_USER_CONTACT_INFO_FIELD_VALUES,
		payload,
	};
};

export const userContactInfoFieldValuesError = payload => {
	return {
		type: FETCH_USER_CONTACT_INFO_FIELD_VALUES_ERROR,
		payload,
	};
};

export const updateCompanyLogoSuccess = response => {
	return {
		type: COMPANY_LOGO_UPDATED,
		payload: response,
	};
};

export const requestToUpdateCompanyLogo = payload => {
	return {
		type: UPDATE_COMPANY_LOGO,
		payload,
	};
};

export const renewAllSuccess = response => {
	return {
		type: ALL_RENEWED,
		payload: response,
	};
};

export const requestToRenewAll = payload => {
	return {
		type: RENEW_ALL,
		payload,
	};
};

export const addUserToCompanySuccess = response => {
	return {
		type: USER_ADDED_TO_COMPANY,
		payload: response,
	};
};

export const requestToAddUserToCompany = payload => {
	return {
		type: ADD_USER_TO_COMPANY,
		payload,
	};
};

export const requestEraseCorporateMembershipData = data => {
	return {
		type: REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA,
		payload: data,
	};
};

export const receiveEraseCorporateMembershipData = data => {
	return {
		type: RECEIVE_ERASE_CORPORATE_MEMBERSHIP_DATA,
		payload: data,
	};
};

export const requestResetCart = data => {
	return {
		type: REQUEST_RESET_CART,
		payload: data,
	};
};

export const receiveResetCart = data => {
	return {
		type: RECEIVE_RESET_CART,
		payload: data,
	};
};

export const fetchCompanyProfileFieldValuesSuccess = response => {
	return {
		type: COMPANY_PROFILE_FIELD_VALUES_FETCHED,
		payload: response,
	};
};

export const requestToFetchCompanyProfileFieldValues = payload => {
	return {
		type: FETCH_COMPANY_PROFILE_FIELD_VALUES,
		payload,
	};
};

export const requestToFetchCompanyMediaValues = payload => {
	return {
		type: FETCH_COMPANY_MEDIA,
		payload,
	};
};

export const fetchCompanyMediaValuesSuccess = response => {
	return {
		type: COMPANY_MEDIA_FETCHED,
		payload: response,
	};
};

export const uploadToS3 = payload => {
	return {
		type: UPLOAD_TO_S3,
		payload,
	};
};

export const uploadTos3SagaSuccess = response => {
	return {
		type: UPLOADED_TO_S3,
		payload: response,
	};
};
export const addUpdateDeleteMediaResource = payload => {
	return {
		type: ADD_UPDATE_DELETE_MEDIA_RESOURCE,
		payload,
	};
};

export const addUpdateDeleteMediaResourceSagaSuccess = (response, operation) => {
	return {
		type: ADDED_UPDATED_DELETED_MEDIA_RESOURCE,
		payload: response,
		operation,
	};
};
export const sortMediaResource = payload => {
	return {
		type: SORT_MEDIA_RESOURCE,
		payload,
	};
};

export const sortMediaResourceSagaSuccess = response => {
	return {
		type: SORTED_MEDIA_RESOURCE,
		payload: response,
	};
};

export const deleteUserCompanyAffiliationSuccess = response => {
	return {
		type: USER_COMPANY_AFFILIATION_DELETED,
		payload: response,
	};
};

export const requestToDeleteUserCompanyAffiliation = payload => {
	return {
		type: DELETE_USER_COMPANY_AFFILIATION,
		payload,
	};
};

export const updateFinancialItemsFormStateSuccess = response => {
	return {
		type: FINANCIAL_ITEMS_FORM_STATE_UPDATED,
		payload: response,
	};
};

export const requestToUpdateFinancialItemsFormState = payload => {
	return {
		type: UPDATE_FINANCIAL_ITEMS_FORM_STATE,
		payload,
	};
};

export const fetchExhibitorCartUuidSuccess = response => {
	return {
		type: EXHIBITOR_CART_UUID_FETCHED,
		payload: response,
	};
};

export const requestToFetchExhibitorCartUuid = payload => {
	return {
		type: FETCH_EXHIBITOR_CART_UUID,
		payload,
	};
};

export const exhibitorSignUpSuccess = response => {
	return {
		type: EXHIBITOR_SIGNEDUP,
		payload: response,
	};
};

export const requestToSignUpExhibitor = payload => {
	return {
		type: EXHIBITOR_SIGNUP,
		payload,
	};
};

export const makeCartPublicToPrivateSuccess = response => {
	return {
		type: CART_MADE_PUBLIC_TO_PRIVATE,
		payload: response,
	};
};

export const requestToMakeCartPublicToPrivate = payload => {
	return {
		type: MAKE_CART_PUBLIC_TO_PRIVATE,
		payload,
	};
};

export const requestToSetCurrentFinancialItem = payload => {
	return {
		type: SET_CURRENT_FINANCIAL_ITEM,
		payload,
	};
};

export const requestToSendMagicLink = payload => {
	return {
		type: SEND_MAGIC_LOGIN_LINK,
		payload,
	};
};

export const deleteExhibitorPaymentSuccess = response => {
	return {
		type: EXHIBITOR_PAYMENT_DELETED,
		payload: response,
	};
};

export const requestToDeleteExhibitorPayment = payload => {
	return {
		type: DELETE_EXHIBITOR_PAYMENT,
		payload,
	};
};

export const fetchRolesSuccess = response => {
	return {
		type: ROLES_FETCHED,
		payload: response,
	};
};

export const requestToFetchRoles = payload => {
	return {
		type: FETCH_ROLES,
		payload,
	};
};

export const updateRolesSuccess = response => {
	return {
		type: ROLES_UPDATED,
		payload: response,
	};
};

export const requestToUpdateRoles = payload => {
	return {
		type: UPDATE_ROLES,
		payload,
	};
};

export const requestMakeUserPublic = payload => {
	return {
		type: REQUEST_MAKE_USER_PUBLIC,
		payload,
	};
};

export const receiveMakeUserPublic = response => {
	return {
		type: RECEIVE_MAKE_USER_PUBLIC,
		payload: response,
	};
};

export const requestRemoveUserPublic = payload => {
	return {
		type: REQUEST_REMOVE_USER_PUBLIC,
		payload,
	};
};

export const receiveRemoveUserPublic = response => {
	return {
		type: RECEIVE_REMOVE_USER_PUBLIC,
		payload: response,
	};
};

export const requestClearNewUserAddedError = data => {
	return {
		type: REQUEST_CLEAR_NEW_USER_ADDED_ERROR,
		payload: data,
	};
};

export const receiveClearNewUserAddedError = data => {
	return {
		type: REQUEST_CLEAR_NEW_USER_ADDED_ERROR,
		payload: data,
	};
};

export const requestClearNewUserAddedSuccess = data => {
	return {
		type: REQUEST_CLEAR_NEW_USER_ADDED_SUCCESS,
		payload: data,
	};
};

export const receiveClearNewUserAddedSuccess = data => {
	return {
		type: RECEIVE_CLEAR_NEW_USER_ADDED_SUCCESS,
		payload: data,
	};
};

export const requestClearCreditCardNotAcceptedMsg = data => {
	return {
		type: REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
		payload: data,
	};
};

export const receiveClearCreditCardNotAcceptedMsg = data => {
	return {
		type: RECEIVE_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
		payload: data,
	};
};

export const requestMiniProfileFields = data => {
	return {
		type: REQUEST_MINI_PROFILE_FIELDS,
		payload: data,
	};
};

export const receiveMiniProfileFields = data => {
	return {
		type: RECEIVE_MINI_PROFILE_FIELDS,
		payload: data,
	};
};

export const requestUpdateMiniProfileFields = data => {
	return {
		type: REQUEST_UPDATE_MINI_PROFILE_FIELDS,
		payload: data,
	};
};

export const receiveUpdateMiniProfileFields = data => {
	return {
		type: RECEIVE_UPDATE_MINI_PROFILE_FIELDS,
		payload: data,
	};
};

export const requestCreateMiniProfileFields = data => {
	return {
		type: REQUEST_CREATE_MINI_PROFILE_FIELDS,
		payload: data,
	};
};

export const receiveCreateMiniProfileFields = data => {
	return {
		type: RECEIVE_CREATE_MINI_PROFILE_FIELDS,
		payload: data,
	};
};

export const requestAddCompanyUser = data => {
	return {
		type: REQUEST_ADD_COMPANY_USER,
		payload: data,
	};
};

export const receiveAddCompanyUser = data => {
	return {
		type: RECEIVE_ADD_COMPANY_USER,
		payload: data,
	};
};
