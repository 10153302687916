import React from 'react';
import { Modal, Button, Row, Col } from 'antd';
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import 'antd/dist/antd.css'


class ConfirmationModal extends React.Component {
   constructor() {
      super();
      this.state = {
         dataSource: []
      }
   }
   numberWithCommas(x) {
      if (x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else return null;
   }
   componentDidMount() {
      let tableInfo = []
      let t_boothData = this.props.selectedBooth['booth_data']
      tableInfo = [...tableInfo, {
         bNo: t_boothData.BoothNo,
         bSize: t_boothData.BoothSize,
         cost: `$${this.numberWithCommas(this.props.selectedBooth['booth_data']['Amount'])}`
      }]
      this.setState({ dataSource: tableInfo })
   }

   handleOnOk = () => {
      this.props.addToCart(this.props.selectedBooth);
      this.props.handleConfirmModalClose();
   }
   render() {
      if (this.props.selectedBooth) {
         let boothNo = this.props.selectedBooth['booth_data']['BoothNo'];
         let boothSize = this.props.selectedBooth['booth_data']['BoothSize'];
         let boothAmount = this.props.selectedBooth['booth_data']['Amount'];
         return (<Modal
            visible={this.props.show}
            footer={[
               <Button key={'modal-ok-bt'} className='modal-ok-btn' onClick={this.handleOnOk}>Yes</Button>,
               <Button key={'primary'} type="primary" onClick={this.props.onHide}>No</Button>,
            ]}
            title={'Confirm'}
            onCancel={this.props.onHide}
         >
            Are you sure you want to add the selected booth to the cart?
            <hr />
            <div>
               {boothNo &&
                  <div className='gx-d-flex gx-my-2'>
                     <div>
                        <b>Booth No: </b>
                     </div>
                     <div>&nbsp;&nbsp;{boothNo}</div>
                  </div>
               }
               {boothSize &&
                  <div className='gx-d-flex gx-my-2'>
                     <div>
                        <b>Booth Size: </b>
                     </div>
                     <div>&nbsp;&nbsp;{boothSize}</div>
                  </div>
               }
               {boothAmount > 0 && 
                  <div className='gx-d-flex gx-my-2'>
                     <div>
                        <b>Cost: </b>
                     </div>
                     <div>&nbsp;&nbsp;${this.numberWithCommas(boothAmount)}</div>
                  </div>
               }
            </div>
         </Modal>);
      } else {
         return null;
      }
   }
}

class ErrorModal extends React.Component {
   constructor() {
      super();
   }
   render() {
      return (<Modal title={'Error'}
         footer={[
            <Button type="primary" onClick={this.props.handleModalClose}>Close</Button>,
         ]}
         visible={this.props.show} style={{ opacity: 1 }} onHide={this.props.onHide}>
         <div className='gx-my-2'>
            {this.props.errorText}
         </div>
      </Modal>);
   }
}

export { ConfirmationModal, ErrorModal };