import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Card, Col, Row, Alert, notification } from 'antd';
import { connect, useSelector } from 'react-redux';
import {
	requestLoginFormData,
	requestToSignUpExhibitor,
	requestToCreateCompany,
	requestToAddUserToCompany,
} from '../../appRedux/actions';
import { bindActionCreators } from 'redux';
import GoBack from '../Common/GoBack';
import { useParams } from 'react-router';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import DuplicateCompanies from './DuplicateCompanies';
import ConsentForm from './ConsentForm';

const ExhibitorSignUp = props => {
	const { moduleUrlId } = useParams();

	const [duplicateView, setDuplicateView] = useState(false);
	const [selectedCompanyUuid, setSelectedCompanyUuid] = useState('');
	const [duplicateCompaniesMessage, setDuplicateCompaniesMessage] = useState('');
	const [duplicateCompanies, setDuplicateCompanies] = useState([]);

	const [invalidEmailAlert, setInvalidEmailAlert] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [email, setEmail] = useState('');
	const [contactConsent, setContactConsent] = useState(false);

	const [submitLoad, setSubmitLoad] = useState(false);

	const authUser = useSelector(state => state.auth.authUser);

	const onInputChange = (event, type) => {
		switch (type) {
			case 'firstName':
				setFirstName(event.target.value);
				break;
			case 'lastName':
				setLastName(event.target.value);
				break;
			case 'email':
				setEmail(event.target.value);
				break;
			case 'companyName':
				setCompanyName(event.target.value);
				break;
			case 'password':
				setPassword(event.target.value);
				break;
			case 'confirmPassword':
				setConfirmPassword(event.target.value);
		}
	};

	const onInvalidEmailAlertClose = () => {
		setInvalidEmailAlert(false);
	};

	useEffect(() => {
		if (props.corporateMembership.companyCreated) {
			goToCompanyProfile();
		}
	}, [props.corporateMembership.companyCreated]);

	useEffect(() => {
		if (selectedCompanyUuid) {
			if (selectedCompanyUuid === 'NA') {
				props.requestToCreateCompany({
					CompanyName: companyName,
					run_add_new_company: true,
				});
			} else {
				props.requestToAddUserToCompany({
					form: {
						compUuid: selectedCompanyUuid,
						uuid: props.auth.contact_uuid,
						new_user_reg_to_company: 1,
					},
					urlId: moduleUrlId,
				});
			}
			goToCompanyProfile();
		}
	}, [selectedCompanyUuid]);

	useEffect(() => {
		if (props.loginInfo.email) {
			setEmail(props.loginInfo.email);
		}
	}, [props.loginInfo.email]);

	useEffect(() => {
		if (props.loginInfo.firstName) {
			setFirstName(props.loginInfo.firstName);
		}
	}, [props.loginInfo.firstName]);

	useEffect(() => {
		if (props.loginInfo.lastName) {
			setLastName(props.loginInfo.lastName);
		}
	}, [props.loginInfo.lastName]);

	useEffect(() => {
		props.requestLoginFormData({
			appdir: props.loginInfo.appdir,
		});
	}, []);

	useEffect(() => {
		if (props.auth.emailExists === 0) {
			setInvalidEmailAlert(true);
		}
	}, [props.auth.emailExists]);

	useEffect(() => {
		if (props.corporateMembership.createdCompany.DUPLICATE_COMPANY_ARRAY) {
			setDuplicateView(true);
			props.corporateMembership.createdCompany.DUPLICATE_COMPANY_ARRAY.push({
				companyName: props.corporateMembership.languageObject
					?.account_company_duplicate_creation_create_new_company_msg
					? props.corporateMembership.languageObject
							?.account_company_duplicate_creation_create_new_company_msg
					: 'None are applicable. Please create new company.',
				compUUID: 'NA',
			});
			setDuplicateCompanies(props.corporateMembership.createdCompany.DUPLICATE_COMPANY_ARRAY);
			setDuplicateCompaniesMessage(props.corporateMembership.createdCompany.MESSAGE);
		}
	}, [props.corporateMembership.createdCompany]);

	const handleBackClick = () => {
		goToSignIn();
	};

	const handleDuplicateCompaniesBackClick = () => {
		setSubmitLoad(false);
		setDuplicateView(false);
	};

	const goToCompanyProfile = () => {
		props.toggleCompanyProfile(true);
		props.toggleSignIn(false);
		props.toggleSignUp(false);
	};

	const goToSignIn = () => {
		props.toggleSignIn(true);
		props.toggleSignUp(false);
		props.toggleCompanyProfile(false);
		props.toggleComeBackToSignInFromSignUp(true);
	};

	const signUp = () => {
		if (password !== confirmPassword) {
			notification['error']({
				message: 'Your password does not match. Please try again.',
			});
			setSubmitLoad(false);
			return;
		} else if (!props.loginInfo.firstName && password === '') {
			notification['error']({
				message: 'Please set your password.',
			});
			setSubmitLoad(false);
			return;
		} else if (companyName === '') {
			notification['error']({
				message: 'Please enter your company name.',
			});
			setSubmitLoad(false);
			return;
		} else if (
			!props.loginInfo.firstName &&
			props.loginInfo.consent_object &&
			!contactConsent
		) {
			notification['error']({
				message: 'Please agree to the system terms and conditions.',
			});
			setSubmitLoad(false);
			return;
		}
		setSubmitLoad(true);
		props.requestToSignUpExhibitor({
			form: {
				firstname: firstName,
				lastname: lastName,
				email: email,
				companyname: companyName,
				password: password,
				contact_consent: contactConsent ? '1' : '0',
			},
			appDir: props.loginInfo.appdir,
			moduleUrlId: moduleUrlId,
			userExists: props.auth.jwt !== '',
		});
	};

	return (
		<Row style={{ height: '100%' }}>
			<Col xl={24} lg={24} md={24} sm={24} xs={24}>
				{!duplicateView && (
					<div className="gx-app-login-container" style={{ textAlign: 'center' }}>
						<Card>
							{props.loginInfo.firstName && (
								<Alert
									message={
										props.corporateMembership.languageObject
											?.account_company_not_found_for_user_msg
											? props.corporateMembership.languageObject
													?.account_company_not_found_for_user_msg
											: 'Company not found'
									}
									type="error"
								/>
							)}
							{invalidEmailAlert ? (
								<Alert
									message={
										props.corporateMembership.languageObject
											?.account_email_not_found_msg
											? props.corporateMembership.languageObject
													?.account_email_not_found_msg
											: 'There is no account associated with this email.'
									}
									type="error"
									closable="true"
									onClose={onInvalidEmailAlertClose}
								/>
							) : (
								''
							)}
							<h2
								style={{
									fontFamily: 'NoirPro, sans-serif',
									fontSize: '14px',
									color: '#535353',
									fontWeight: '600',
									textTransform: 'uppercase',
								}}
							></h2>
							{!window.location.search.includes('token') && (
								<div style={{ width: '10%' }}>
									<GoBack handleBackClick={handleBackClick} />
								</div>
							)}
							<Form
								layout="vertical"
								// onFinish={signUp}
								className="gx-signin-form gx-form-row0"
							>
								{!props.loginInfo.firstName && (
									<div>
										{props.loginInfo.consent_object && (
											<div style={{ marginBottom: '2%' }}>
												<ConsentForm onCheckBoxChange={setContactConsent} />
											</div>
										)}
										<Form.Item
											validateTrigger="onSubmit"
											rules={[
												{
													required: true,
													message: 'Please enter your first name',
												},
											]}
										>
											<Input
												placeholder="First Name"
												onChange={event => {
													onInputChange(event, 'firstName');
												}}
												value={firstName}
											/>
										</Form.Item>
									</div>
								)}
								{!props.loginInfo.lastName && (
									<Form.Item
										validateTrigger="onSubmit"
										rules={[
											{
												required: true,
												message: 'Please enter your last name',
											},
										]}
									>
										<Input
											placeholder="Last Name"
											onChange={event => {
												onInputChange(event, 'lastName');
											}}
											value={lastName}
										/>
									</Form.Item>
								)}
								{!props.loginInfo.firstName && (
									<Form.Item
										validateTrigger="onSubmit"
										rules={[
											{
												required: true,
												message: 'Please enter your password',
											},
											{
												min: 5,
												message: 'Password must be minimum 5 characters',
											},
										]}
									>
										<Input.Password
											placeholder="Password"
											onChange={event => {
												onInputChange(event, 'password');
											}}
											value={password}
											iconRender={visible =>
												visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
											}
										/>
									</Form.Item>
								)}
								{!props.loginInfo.firstName && (
									<Form.Item
										validateTrigger="onSubmit"
										rules={[
											{
												required: true,
												message: 'Please enter your password',
											},
											{
												min: 5,
												message: 'Password must be minimum 5 characters',
											},
										]}
									>
										<Input.Password
											placeholder="Confirm Password"
											onChange={event => {
												onInputChange(event, 'confirmPassword');
											}}
											value={confirmPassword}
											iconRender={visible =>
												visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
											}
										/>
									</Form.Item>
								)}
								<Form.Item
									validateTrigger="onSubmit"
									name="companyName"
									rules={[
										{
											required: true,
											message: 'Please enter your company name',
										},
									]}
								>
									<Input
										placeholder="Company Name"
										onChange={event => {
											onInputChange(event, 'companyName');
										}}
										value={companyName}
									/>
								</Form.Item>
								<div style={{ display: 'flex' }}>
									{/* <i
										className="gx-icon-btn icon icon-edit"
										onClick={() => {
											goToSignIn();
										}}
									/> */}
									<Form.Item style={{ width: '100%' }} validateTrigger="onSubmit">
										<Input
											placeholder="Email"
											onChange={event => {
												onInputChange(event, 'email');
											}}
											disabled={email}
											value={email}
										/>
									</Form.Item>
								</div>
								<Form.Item>
									<Button
										form="myForm"
										type="default"
										key="submit"
										htmlType="submit"
										loading={submitLoad}
										className="login-form-button gx-mt-1 button-primary"
										onClick={signUp}
										style={{
											backgroundColor: props.loginInfo?.login_form
												? props.loginInfo?.login_form.primary_color
												: '#33b4fd',
											color: '#fff',
											width: '100%',
										}}
										shape={'round'}
									>
										{!props.loginInfo.firstName
											? 'Sign Up'
											: props.corporateMembership.languageObject
													?.account_company_not_found_for_user_button_msg}
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</div>
				)}
				{duplicateView && (
					<div className="gx-app-login-container" style={{ textAlign: 'center' }}>
						<Card className="card">
							<DuplicateCompanies
								handleBackClick={handleDuplicateCompaniesBackClick}
								duplicateCompaniesMessage={duplicateCompaniesMessage}
								duplicateCompanies={duplicateCompanies}
								setSelectedCompanyUuid={setSelectedCompanyUuid}
							/>
						</Card>
					</div>
				)}
			</Col>
		</Row>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.auth,
		loginInfo: state.loginInfo,
		settings: state.settings,
		corporateMembership: state.corporateMembership,
	};
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestLoginFormData: requestLoginFormData,
			requestToSignUpExhibitor: requestToSignUpExhibitor,
			requestToCreateCompany: requestToCreateCompany,
			requestToAddUserToCompany: requestToAddUserToCompany,
		}),
		dispatch,
	);
}

export default connect(mapStateToProps, matchDispatchToProps)(ExhibitorSignUp);
