import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Empty, Skeleton } from 'antd';

import { useProducts } from '../hooks/useProducts';

import ConsumptionHeader from '../components/ConsumptionHeader';
import ConsumptionArea from '../components/ConsumptionArea';
import Presentations from '../components/ConsumptionArea/Presentations';
import { storeProductAnalysisRequest } from '../../../appRedux/actions';
import auth from '../../../loginAuth/loginAuth'
import '../styles/custom.less';

import loader from '../../../assets/images/loader.svg'

const ProductPage = () => {
	const { productId } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const [prodsLoading, products] = useProducts();
	const product = useMemo(() => products.find(prod => prod.id === parseInt(productId)), [
		products,
	]);
	const parentBundle = useMemo(() => products.find(prod => prod.id === product?.parentId), [
		products,
	]);
	
	const appdir = useSelector(state => state.loginInfo.appdir);
	const contactUid = useSelector(state => state.auth.contact_uuid);
	const hasOpenAccess = useSelector(state => state.consumption.hasOpenAccess);
	const moduleUrlId = useSelector(state => state.consumption.moduleUrlId);
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const hasDirectAccess = useSelector(state => state.consumption.hasDirectAccess);
	const hasProductAccess = useSelector(state => state.consumption.hasProductAccess);

	const storeProductAnalysis = (product) => {
		dispatch(storeProductAnalysisRequest({
			appdir,
			contactUid: contactUid === ''
				? JSON.parse(auth.getCookie('contact_uuid'))
				: contactUid,
			productId: product.id
		}));
	};

	useEffect(() => {
		if (product && !product?.description && !product?.files?.length)
			storeProductAnalysis(product);
	}, [product]);

	const goBack = () => {
		if (hasOpenAccess && hasDirectAccess && productId) {
			history.push(
				`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/selection?${publicEmbed ? 'public_embed=1' : ""}`
			)		
		} else if (hasProductAccess && productId) {
			history.push(
				`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/product/${productId}${publicEmbed ? '?public_embed=1' : ""}`
			)
		} else if (hasOpenAccess && productId) {
			history.push(
				`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/bundle/${parentBundle?.id}${publicEmbed ? '?public_embed=1' : ""}`
			)
		} else if (parentBundle?.isSuperBundle) {
			history.push(`../selection/${parentBundle?.id}${history.location.search}`);
		} else {
			history.push(`../selection${history.location.search}`);
		}
	};

	return (
		<main className="gx-bg-white gx-d-flex gx-flex-column gx-h-100" /* style={{ minHeight: '100vh' }} */>
			<ConsumptionHeader
				loading={prodsLoading}
				product={product}
				superBundle={parentBundle}
				goBack={goBack}
			/>

			<article className="gx-flex-1">
				{(product?.description || product?.files?.length)
					? (
						<Presentations
							loading={prodsLoading}
							product={product}
						/>
					) : (
						<div>
							{prodsLoading ? (
								<div className='loader'>
									<img src={loader} alt='loader' />
								</div>
							) : <Empty className="gx-h-100 gx-mt-5" />}
						</div>
					)}
			</article>
		</main>
	);
};

export default ProductPage;
