import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { requestToSetCurrentFinancialItem } from '../../appRedux/actions';
import { bindActionCreators } from 'redux';

const ProgressBar = props => {
	const { Step } = Steps;
	const { moduleUrlId } = useParams();

	const navigateToStep = step => {
		if (step.order < props.currentStep) {
			if (step.type === 'registration') {
				props.requestToSetCurrentFinancialItem(step.order);
			}
			// Can only navigate to previous steps.
			const url = `/main/public/company/${moduleUrlId}/${step.type}?public_embed=1`;

			props.history.push(url);
		}
	};

	return (
		<Steps current={props.currentStep}>
			{props.steps.map(step => {
				return (
					<Step
						title={
							<Link
								style={{ color: props.loginInfo.login_form?.primary_color }}
								onClick={() => navigateToStep(step)}
							>
								{step.text}
							</Link>
						}
					/>
				);
			})}
		</Steps>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToSetCurrentFinancialItem: requestToSetCurrentFinancialItem,
		}),
		dispatch,
	);
}

const mapStateToProps = state => {
	return {
		loginInfo: state.loginInfo,
	};
};

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ProgressBar));
