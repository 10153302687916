import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
	REQUEST_SEND_PAYMENT,
	REQUEST_SEND_PAYMENT_EXHIBITOR,
	REQUEST_RESET_INVALID_CREDIT_CARD,
	REQUEST_ERASE_COMMON_DATA,
} from 'constants/ActionTypes';

import {
	receiveSendPayment,
	receiveSendPaymentExhibitor,
	receiveResetInvalidCreditCard,
	receiveEraseCommonData,
	requestPaymentLoader,
} from '../../appRedux/actions/Common';

import CommonAPIs from '../../api/Common';
import { fetchError } from '../actions/Common';

const fetchSendPayment = async context => {
	let jsonData = await CommonAPIs.getMakePayment(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.attenuuid,
		context.data.formData,
		context.data.publicEmbed,
		context.data.publicCheckout,
	);
	jsonData['moduleIdentifier'] = context.data.moduleIdentifier;
	return jsonData;
};

const fetchSendPaymentExhibitor = async context => {
	let jsonData = await CommonAPIs.getMakePaymentExhibitor(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.compuuid,
		context.data.exhibuuid,
		context.data.formData,
	);
	jsonData['moduleIdentifier'] = context.data.moduleIdentifier;
	return jsonData;
};

function* getSendPayment(action) {
	try {
		let paymentInfo = yield call(fetchSendPayment, action);
		yield put(receiveSendPayment(paymentInfo));
		yield put(requestPaymentLoader(false))
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getSendPaymentExhibitor(action) {
	try {
		let paymentInfo = yield call(fetchSendPaymentExhibitor, action);
		yield put(receiveSendPaymentExhibitor(paymentInfo));
		yield put(requestPaymentLoader(false))
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getResetInvalidCreditCard(action) {
	try {
		yield put(receiveResetInvalidCreditCard(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getEraseCommonData(action) {
	try {
		yield put(receiveEraseCommonData(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

export function* sendPayment() {
	yield takeEvery(REQUEST_SEND_PAYMENT, getSendPayment);
}

export function* sendPaymentExhibitor() {
	yield takeEvery(REQUEST_SEND_PAYMENT_EXHIBITOR, getSendPaymentExhibitor);
}

export function* resetInvalidCreditCard() {
	yield takeEvery(REQUEST_RESET_INVALID_CREDIT_CARD, getResetInvalidCreditCard);
}

export function* eraseCommonData() {
	yield takeEvery(REQUEST_ERASE_COMMON_DATA, getEraseCommonData);
}

export default function* mySaga() {
	yield all([
		fork(sendPayment),
		fork(sendPaymentExhibitor),
		fork(resetInvalidCreditCard),
		fork(eraseCommonData),
	]);
}
