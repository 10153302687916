import React, { useState, useEffect, useMemo } from 'react';
import {
	Tag,
	Avatar,
	Empty,
	Card,
	Row,
	Col,
	Pagination as AntDPagination,
	Button,
	Modal,
} from 'antd';
import {
	UserOutlined,
	UnorderedListOutlined,
	FileOutlined,
	PictureFilled,
	LinkOutlined,
	ClockCircleOutlined,
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _map from 'lodash/map';
import _toUpper from 'lodash/toUpper';
import _filter from 'lodash/filter';
import moment from 'moment';
import { Collapse } from 'antd';

import { FILE } from 'components/Common/constants';
import { paginate } from 'components/Common/helpers/helper';

import VideoPlayer from './VideoPlayer';
import PDFViewer from './PDFViewer';
import ImageGallery from './ImageGallery';
import PresentationFileCard from './PresentationFileCard';

import { hitsPerPage } from '../../constants';
import BundleProductCard from '../../SelectionPage/BundleProductCard';
import {
	storeProductAnalysisRequest,
	storeFileAnalysisRequest,
	storeCardsViewType,
	requestToStartVideo,
	requestEndVideo,
} from '../../../../appRedux/actions';
import auth from '../../../../loginAuth/loginAuth';
import { ReactComponent as Left } from 'assets/icons/left.svg';
import { ReactComponent as Right } from 'assets/icons/right.svg';
import { useProducts } from '../../hooks/useProducts';
import PresentationSortBy from './PresentationSortBy';
// import FilterSidebar from '../FilterSidebar';
const { Panel } = Collapse;

export const renderFileDefaultTitle = file => {
	switch (file.type) {
		case FILE.PDF:
			return 'PDF';
		case FILE.VIDEO:
			return 'Video';
		case FILE.LINK:
			return 'Link';
		case FILE.IMAGE:
			return 'Image';
		case FILE.FILE:
		default:
			return 'File';
	}
};

const Presentations = ({
	product,
	page,
	setPage,
	resetTabs,
	expanded,
	handleSelectProduct,
	children,
	loading,
	gotoProduct,
	handleReset,
	superBundle = {}
}) => {
	// const { superBundleId, bundleId, productId } = useParams();
	// const [fileIdx, setFileIdx] = useState(-1);
	const dispatch = useDispatch();
	const history = useHistory();
	// const [selectedFile, setSelectedFile] = useState(null);
	// const [sidebarOpen, setSidebarOpen] = useState(true);
	// const [activeTab, setActiveTab] = useState('0');
	const [isPublic, setIsPublic] = useState(false);
	const [, products] = useProducts();
	const appdir = useSelector(state => state.loginInfo.appdir);
	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
	const contactUid = useSelector(state => state.auth.contact_uuid);
	const consumptionConfig = useSelector(state => state.consumption.config);
	const [showImage, setShowImage] = useState(false);
	const [ImageUrl, setImageUrl] = useState('');
	const [childProduct, setChildProduct] = useState(children);
	const cardSize = product?.authorInfo?.length || product?.author_block ? 16 : 24;
	const hasOpenAccess = useSelector(state => state.consumption.hasOpenAccess);

	const currentIndex = useMemo(() => childProduct.findIndex(items => items.id === product?.id), [
		product,
		childProduct,
	]);

	const grandparentProduct = useMemo(() => products.find((prod) => prod.id === product?.grandparentId), [products, product]);
	const parentProduct = useMemo(() => products.find((prod) => prod.id === product?.parentId), [products, product]);
	const showTimes = product?.showTimes || grandparentProduct?.showTimes || parentProduct?.showTimes;
	const publicationDate = useMemo(() => {
		if (product?.publicationDate) return product.publicationDate
		else if (grandparentProduct?.publicationDate) return grandparentProduct?.publicationDate
		else if (parentProduct?.publicationDate) return parentProduct?.publicationDate
		else return null
	}, [product, grandparentProduct, parentProduct]);

	const storeProductAnalysis = product => {
		dispatch(
			storeProductAnalysisRequest({
				appdir,
				contactUid:
					contactUid === '' ? JSON.parse(auth.getCookie('contact_uuid')) : contactUid,
				productId: product.id,
			}),
		);
	};

	const storeFileAnalysis = selectedFile => {
		if (selectedFile) {
			dispatch(
				storeFileAnalysisRequest({
					appdir,
					contactUid: contactUid === '' ? JSON.parse(auth.getCookie('contact_uuid')) : contactUid,
					productId: product.id,
					title: selectedFile?.url ?? '',
					fileSize: selectedFile?.file_size ?? 0,
				}),
			);
		}
	};

	useEffect(() => {
		const params = new URLSearchParams(history.location.search);
		setIsPublic(!!params.get('public_embed'));
	}, [history]);

	useEffect(() => {
		if (product) {
			storeProductAnalysis(product);
			// handleInitialTab();
		}
	}, [product]);

	useEffect(() => {
		if (resetTabs) {
			// handleInitialTab();
		}
		handleReset();
	}, [resetTabs]);

	useEffect(() => {
		if (superBundle?.quick_view_only && !hasOpenAccess) {
			dispatch(storeCardsViewType({ cardDisplayType: 2 }))
		}
	}, [superBundle])

	const handleInitialTab = () => {
		if (product?.files?.length) {
			let filteredFiles = _filter(product?.files, file =>
				file.hasOwnProperty('hide') ? !file.hide : true,
			);
			let sortedFiles = filteredFiles?.sort(a => (a.type === 'video' ? -1 : 1));
			if (sortedFiles[0]?.type === 'video') {
				storeFileAnalysis(sortedFiles[0]);
			}
		}
	};

	const handleOpen = (activeData, selectedKeys) => {
		if (activeData && selectedKeys.includes(`${product.id}_${activeData.fieldid}`)) {
			if (activeData?.type === FILE.LINK) {
				window.open(activeData?.url);
				return;
			} else {
				storeFileAnalysis(activeData);
			}
		}
	};

	const goBack = () => {
		handleSelectProduct(null);
	};
	const handleAttachmentItemClick = data => {
		if (data.type === FILE.IMAGE) {
			setImageUrl(data.url);
			setShowImage(true);
		} else {
			window.open(data.url);
		}
	};
	const handleSortBy = (sortedProducts) => {
		setChildProduct(sortedProducts);
	};

	const onHandleVideoStart = (videoUrl, runningTime) => {
		dispatch(requestToStartVideo({ contactUid, product, videoUrl: videoUrl?.url ?? videoUrl?.video_url, appdir, runningTime }))
		dispatch(
			storeFileAnalysisRequest({
				appdir,
				contactUid: contactUid === '' ? JSON.parse(auth.getCookie('contact_uuid')) : contactUid,
				productId: product.id,
				title: (videoUrl?.url ? videoUrl?.url : videoUrl?.video_url) ?? '',
				fileSize: videoUrl?.file_size ?? 0,
			}),
		);
	}

	const onHandleVideoPlay = (videoUrl, runningTime) => {
		dispatch(requestToStartVideo({ contactUid, product, videoUrl: videoUrl?.url ?? videoUrl?.video_url, appdir, runningTime }))
	}

	const onHandleVideoEnd = (videoUrl, runningTime) => {
		dispatch(requestEndVideo({ contactUid, product, videoUrl: videoUrl?.url ?? videoUrl?.video_url, appdir, runningTime }));
	}

	// const getExtra = () => <Right style={{ width: '.6rem' }} />
	return (
		<main>
			{!product ? (
				<div className="gx-p-5">
					{/* <h3 className="gx-mb-2 gx-font-weight-semi-bold">Items</h3> */}
					<section className="product-row-grid product-list-wrapper gx-mb-5">
						<div className='gx-d-flex gx-justify-content-end'>
							<PresentationSortBy presentations={children} handleSortPresentation={handleSortBy} />
						</div>
						{paginate(childProduct, page, hitsPerPage).map((child, index) => {
							return (
								<BundleProductCard key={index} product={child} goto={gotoProduct} />
							);
						})}
					</section>
					<div className="gx-d-flex gx-justify-content-center">
						<AntDPagination
							pageSize={hitsPerPage}
							total={childProduct.length}
							current={page + 1}
							onChange={page => {
								setPage(page - 1);
								// scrollToView();
							}}
							showSizeChanger={false}
						/>
					</div>
				</div>
			) : (
				<>
					{childProduct?.length > 1 ? (
						<div
							className={`
									${(currentIndex === 0 || (currentIndex) + 1 === childProduct.length) ?
									'' :
									'all-presentations'} gx-mt-3 gx-ml-4 gx-d-flex`
							}
						>
							{!superBundle?.quick_view_only || hasOpenAccess
								? <div onClick={goBack}>
									<Tag
										className="gx-mb-0 gx-pointer fg-white"
										style={{
											backgroundColor:
												styling['primary-dark-color'] || '#993333',
										}}
									>
										<UnorderedListOutlined className="gx-mx-1" />
										All Presentations
									</Tag>
								</div>
								: <></>
							}
							<div
								className={`
										${(currentIndex === 0 || (currentIndex) + 1 === childProduct.length) ?
										'' :
										'prod-presentation-btn '}gx-d-flex gx-align-items-center gx-justify-content-between gx-w-100`}
							>
								<div>
									{currentIndex - 1 >= 0 ? (
										<div
											onClick={() =>
												handleSelectProduct(childProduct[currentIndex - 1])
											}
										>
											<Tag
												className="gx-mb-0 gx-pointer fg-white"
												style={{
													backgroundColor:
														styling['primary-dark-color'] || '#993333',
												}}
											>
												<Left
													style={{ width: '0.35rem' }}
													className="gx-mx-1"
												/>
												{/* <UnorderedListOutlined className='gx-mx-1' /> */}
												Previous Presentations
											</Tag>
										</div>
									) : null}
								</div>
								{(currentIndex + 1) % childProduct.length ? (
									<div
										onClick={() =>
											handleSelectProduct(childProduct[currentIndex + 1])
										}
									>
										<Tag
											className="gx-mb-0 gx-pointer fg-white gx-mr-3"
											style={{
												backgroundColor:
													styling['primary-dark-color'] || '#993333',
											}}
										>
											Next Presentations
											<Right
												style={{ width: '0.35rem' }}
												className="gx-mx-1"
											/>
										</Tag>
									</div>
								) : null}
							</div>
						</div>
					) : null}
					<div>
						{childProduct.length > 1 ? (
							<section>
								{/* <div className="gx-p-3 gx-my-auto gx-pl-2 gx-bg-light-grey">
                                <div className='gx-d-flex gx-align-items-xl-center'>
                                    <h3 className='gx-text-black gx-font-weight-semi-bold gx-mb-0 gx-ml-2'>
                                        {product?.title}
                                    </h3>
                                </div>
                            </div> */}
								<Card
									className="shadow-1 gx-pointer gx-mb-0 gx-rounded-lg gx-mt-3 gx-mr-3 gx-ml-3"
									bodyStyle={{ padding: '1rem', fontSize: '14px' }}
								>
									<div className="gx-d-flex gx-align-items-xl-center">
										<span className="gx-text-black gx-mb-0 gx-ml-2">
											{product?.title}
										</span>
									</div>
								</Card>
							</section>
						) : null}
						{!product?.files?.length &&
							!product?.authorInfo?.length &&
							!product?.author_block &&
							!product?.description ? (
							<div>
								<Empty description="No Data" />
							</div>
						) : (
							<div>
								{product?.files?.length &&
									product?.files?.some(
										file =>
											((file.hasOwnProperty('hide') ? !file.hide : true) &&
												file.type === FILE.VIDEO) ||
											((file.hasOwnProperty('hide') ? !file.hide : true) &&
												file.type === FILE.PDF) ||
											((file.hasOwnProperty('hide') ? !file.hide : true) &&
												file.type === FILE.FILE)
									) ? (
									<Row style={{ padding: '1rem' }}>
										<Col span={24}>
											<PresentationFileCard
												isPublic={isPublic}
												expanded={expanded}
												product={product}
												consumptionConfig={consumptionConfig}
												handleOpen={handleOpen}
												renderFileDefaultTitle={renderFileDefaultTitle}
												onHandleVideoStart={onHandleVideoStart}
												onHandleVideoEnd={onHandleVideoEnd}
												onHandleVideoPlay={onHandleVideoPlay}
											/>
										</Col>
									</Row>
								) : null}
								<div className="cardWrapper">
									<Row style={{ padding: '1rem' }}>
										{product?.description ? (
											<Col
												span={cardSize}
												xs={24}
												lg={cardSize}
												sm={cardSize}
											>
												<div>
													<Card
														style={{ borderRadius: '5px' }}
														className="shadow-1 gx-pointer gx-rounded-lg"
													>
														<div
															style={{ textAlign: 'justify', fontSize: '1.05em' }}
															className="gx-font-weight-normal fg-grey gx-w-100 "
															dangerouslySetInnerHTML={{
																__html: product?.description,
															}}
														/>
														<div>

														{
														showTimes? product.sessionStartTime > 0 ? <p className="gx-px-4">
														<ClockCircleOutlined /> &nbsp;
														{moment(product.sessionStartTime).format('ddd, MMM D Y, hh:mm A')}
													</p>
														:null:
														publicationDate ? (
															<p className='gx-ml-2 gx-mt-2'>
																Publication Date:&nbsp;
																{moment(publicationDate).format('MM/DD/YYYY')}
															</p>
														) : null
														}
														</div>
													</Card>
												</div>
											</Col>
										) : null}
										<Col span={8} xs={24} lg={8} sm={8}>
											{product?.files?.length &&
												product?.files?.some(
													file =>
														// ((file.hasOwnProperty('hide')
														// 	? !file.hide
														// 	: true) &&
														// 	file.type === FILE.FILE) ||
														((file.hasOwnProperty('hide')
															? !file.hide
															: true) &&
															file.type === FILE.LINK) ||
														((file.hasOwnProperty('hide')
															? !file.hide
															: true) &&
															file.type === FILE.IMAGE) ||
														((file.hasOwnProperty('hide')
															? !file.hide
															: true) &&
															file.type === ''),
												) ? (
												<Card
													style={{ borderRadius: '5px' }}
													className="shadow-1 gx-pointer gx-rounded-lg"
												>
													<h4 className="gx-w-100 gx-font-weight-bold fg-grey">
														Attachments
													</h4>
													{product?.files?.map((data, index, files) => {
														return ((data.hasOwnProperty('hide')
															? !data.hide
															: true) &&
															data.type === FILE.IMAGE) ||
															((data.hasOwnProperty('hide')
																? !data.hide
																: true) &&
																data.type === FILE.LINK) ||
															// ((data.hasOwnProperty('hide')
															// 	? !data.hide
															// 	: true) &&
															// 	data.type === FILE.FILE) ||
															((data.hasOwnProperty('hide')
																? !data.hide
																: true) &&
																data.type === '') ? (
															<Button
																type="secondary"
																onClick={() =>
																	handleAttachmentItemClick(data)
																}
																icon={
																	data.type === FILE.IMAGE ? (
																		<PictureFilled />
																	) : data.type === FILE.LINK ? (
																		<LinkOutlined />
																	) : (
																		<FileOutlined />
																	)
																}
															>
																{data.title
																	? data.title
																	: renderFileDefaultTitle(data)}
															</Button>
														) : null;
													})}
												</Card>
											) : null}

											{product?.authorInfo?.length ||
												product?.author_block ? (
												<Card
													style={{ borderRadius: '5px' }}
													className="shadow-1 gx-mb-0 gx-pointer gx-rounded-lg"
												>
													<h4 className="gx-w-100 gx-font-weight-bold fg-grey">
														Authors
													</h4>
													{product?.authorInfo?.length ? (
														_map(
															product?.authorInfo,
															(author, index) => (
																<div
																	key={index}
																	className="gx-px-4 gx-py-3 description-text gx-d-flex gx-align-items-center"
																>
																	<Avatar
																		size="large"
																		src={author.picture}
																		icon={<UserOutlined />}
																	/>
																	<div className="gx-ml-2">{`${author.firstname} ${author.lastname}`}</div>
																	{author.degrees ? (
																		<div>{`, ${author.degrees}`}</div>
																	) : null}
																</div>
															),
														)
													) : product?.author_block ? (
														<div
															className="gx-m-4"
															dangerouslySetInnerHTML={{
																__html: product?.author_block,
															}}
														/>
													) : null}
												</Card>
											) : null}
										</Col>
									</Row>
								</div>
							</div>
						)}
					</div>
				</>
			)}
			{showImage ? (
				<Modal
					title=""
					visible={showImage}
					footer={null}
					onCancel={() => {
						setImageUrl(false);
						setShowImage(false);
					}}
				>
					<ImageGallery
						url={ImageUrl}
						style={{
							maxHeight: '25rem',
						}}
					/>
				</Modal>
			) : null}
		</main>
	);
};

Presentations.defaultProps = {
	handleReset: () => { },
	children: [],
};

export default Presentations;
