import React from 'react';
import { useDispatch } from 'react-redux'

import { useBundleInfo } from '../hooks/useBundleInfo';

import BundleProductQuickViewRender from './BundleProductQuickViewRender';
import { storeProductData } from '../../../../appRedux/actions';

const BundleProductQuickView = ({ productChildId, product }) => {
    const [
        ,
        children,
    ] = useBundleInfo(productChildId);

    const dispatch = useDispatch();

    const showModal = () => {
        dispatch(storeProductData({ product, isVisible: true }))
    };

    return (
        <div className="gx-p-2">
            <div className='gx-d-flex gx-justify-content-between gx-align-items-center gx-mt-4"'>
                <h3
                    className="gx-page-title gx-font-weight-bold quick-view-link gx-pointer"
                    onClick={showModal}
                >
                    {product.title}
                </h3>
            </div>
            {children.map((child) => {
                return (
                    <div>
                        <BundleProductQuickViewRender
                            child={child}
                            productChildId={child.id}
                        />
                    </div>
                )
            })}
        </div>
    );
};

export default BundleProductQuickView;
