import React, { useEffect, useState } from 'react';
import LeftMenu from './LeftMenu';
import CompanyHeader from './CompanyHeader';
import {
	requestToUpdateCompanyLogo,
	requestUserCompanyAffiliationData,
	requestToUpdateCompanyProfile,
	requestCompanyData,
	requestToFetchCompanyProfileFieldValues,
	requestExhibitorCartDetails,
	requestExhibitorCart,
	requestToMakeCartPublicToPrivate,
	requestAuthenticateUser,
	uploadToS3,
	addUpdateDeleteMediaResource,
	requestToFetchCompanyMediaValues,
	sortMediaResource,
	requestToDeleteRegistrationItemFromCart,
} from '../../appRedux/actions';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '../CircularProgress';
import Widget from '../Widget';
import FormComponent from '../form';
import {
	Button,
	Form,
	Upload,
	Col,
	Row,
	Card,
	Tabs,
	Table,
	Space,
	Modal,
	Menu,
	Dropdown,
	Input,
	message,
	Alert,
	Divider,
} from 'antd';
import { FormOutlined, DownOutlined, UploadOutlined, MenuOutlined } from '@ant-design/icons';
import Title from '../form/components/Title';
import { parseScreen } from '../../util/form';
import { handleImageCompression, goToFirstError, IsJsonString } from './helpers/generalHelpers';
import { useParams } from 'react-router';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import RegistrationItems from '../Common/RegistrationItems';
import ProgressBar from './ProgressBar';
import ExhibitorSignIn from './ExhibitorSignIn';
import ExhibitorSignUp from './ExhibitorSignUp';
import { ReactComponent as FormIcon } from '../../assets/icons/corporateForm.svg';
import { ReactComponent as UploadPlaceholderIcon } from '../../assets/icons/uploadPlaceholder.svg';
import { useMenu } from './hooks/useMenu';
import { ReactComponent as BagIcon } from 'assets/icons/bag.svg';
import { sortableContainer, sortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';
const { TabPane } = Tabs;
const { Column } = Table;

const resourceMap = { video: 'Video', link: 'Link', pdf: 'PDF' };

const DragHandle = sortableHandle(() => (
	<MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);
const ExhibitorCompanyProfile = props => {
	const settings = useSelector(state => state.settings);
	const publicEmbed = settings.publicEmbed || props.location?.search?.includes('public_embed=1');

	const isTokenInUrl = props.location?.search?.includes('token');

	const [comeBackToSignInFromSignUp, setComeBackToSignInFromSignUp] = useState(false);
	const [loader, setLoader] = useState(true);
	const [companyCreated, setCompanyCreated] = useState(false);
	const [signInView, setSignInView] = useState(true);
	const [signUpView, setSignUpView] = useState(false);
	const [companyProfileView, setCompanyProfileView] = useState(false);
	const [companyProfileMenuItems, setCompanyProfileMenuItems] = useState([]);
	const [validate, setValidate] = useState(false);
	const [formState, setFormState] = useState([]);
	const [formControl] = Form.useForm();
	const [validationsObject, setValidationsObject] = useState({
		error: false,
		validationErrors: {},
	});
	const [fileList, setFileList] = useState([]);
	const { moduleUrlId } = useParams();

	const [loading, menu] = useMenu();
	const [currentMenu, setCurrentMenu] = useState([]);
	const corporateMembership = useSelector(state => state.corporateMembership);
	const companyMedia = useSelector(state => state.corporateMembership.company.media);
	const s3UploadStatus = useSelector(state => state.corporateMembership.company.s3UploadStatus);
	const authUser = useSelector(state => state.auth.authUser);
	const auth = useSelector(state => state.auth);
	const loginInfo = useSelector(state => state.loginInfo);
	const compUuid = useSelector(
		state => state.corporateMembership?.userCompanyAffiliation?.comp_uuid,
	);
	const width = useSelector(state => state.settings.width);

	const common = useSelector(state => state.common);
	const [displayCompanyProfileTab] = useState(
		corporateMembership.generalSettings?.DisplayCompanyProfileTab ?? false,
	);
	const mobileThreshold = useSelector(state => state.common.mobileThreshold);

	const [companyVideoFiltered, setCompanyVideoFiltered] = useState([]);
	const [companyPDFFiltered, setCompanyPDFFiltered] = useState([]);
	const [companyLinkFiltered, setCompanyLinkFiltered] = useState([]);
	const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
	const [processingModalResource, setProcessingModalResource] = useState({});
	const [ModalResourceType, setModalResourceType] = useState('');
	const [uploadingFileURL, setUploadingFileURL] = useState('');
	const [activeTabID, setActiveTabID] = useState(1);
	const [resourceForm] = Form.useForm();
	const [uploading, setUploading] = useState(false);
	const [addUpdateResourceProcessing, setAddUpdateResourceProcessing] = useState(false);
	const [deleteResourceProcessing, setDeleteResourceProcessing] = useState(false);

	useEffect(() => {
		if (companyMedia?.length > 0) {
			const filteredMedia = companyMedia.filter(media =>
				['link', 'pdf', 'youtube', 'vimeo'].includes(media.type),
			);
			const videoMedia = [];
			const pdfMedia = [];
			const linkMedia = [];

			filteredMedia.forEach(media => {
				let processedObject = {};
				processedObject.payload = IsJsonString(media.payload);
				processedObject.type = media.type;
				processedObject.Orderof = media.Orderof;
				processedObject.DateAdded = media.DateAdded;
				processedObject.RowID = media.RowID;

				switch (processedObject.type) {
					case 'link':
						linkMedia.push(processedObject);
						break;
					case 'pdf':
						pdfMedia.push(processedObject);
						break;
					case 'vimeo':
						videoMedia.push(processedObject);
						break;
					case 'youtube':
						videoMedia.push(processedObject);
						break;
				}
			});
			setCompanyVideoFiltered(videoMedia);
			setCompanyPDFFiltered(pdfMedia);
			setCompanyLinkFiltered(linkMedia);
		} else {
			setCompanyVideoFiltered([]);
			setCompanyPDFFiltered([]);
			setCompanyLinkFiltered([]);
		}
	}, [companyMedia]);
	useEffect(() => {
		if (isTokenInUrl && loginInfo.appdir) {
			const urlParams = new URLSearchParams(props.location.search);
			let formData = new FormData();
			formData.append('uuid', urlParams.get('uuid'));
			formData.append('sso_token', urlParams.get('token'));
			props.requestAuthenticateUser({
				appdir: loginInfo.appdir,
				formData: formData,
			});
			props.requestExhibitorCart({
				publicEmbed: true,
				cartUuid: urlParams.get('cartuuid'),
				appDir: loginInfo.appdir,
				urlId: moduleUrlId,
			});
		}
	}, [loginInfo.appdir]);

	useEffect(() => {
		if (isTokenInUrl && auth.contact_uuid) {
			setLoader(false);
			toggleCompanyProfile(true);
		}
	}, [auth.contact_uuid]);

	useEffect(() => {
		if (isTokenInUrl) {
			return;
		}
		// If public embed is set then no need of loader. In logged in case, we need the loader and we auto set the page to the company profile view.
		if (publicEmbed) {
			setLoader(false);
		} else {
			toggleCompanyProfile(true);
		}
	}, []);

	useEffect(() => {
		if (
			corporateMembership.userCompanyAffiliationFetched &&
			corporateMembership.userCompanyAffiliation.comp_uuid &&
			publicEmbed &&
			!corporateMembership.exhibitorCartDetails.exhibuuid
		) {
			props.requestExhibitorCartDetails({
				appDir: loginInfo.appdir,
				urlId: moduleUrlId,
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			});
		}
	}, [
		corporateMembership.userCompanyAffiliationFetched,
		corporateMembership.userCompanyAffiliation.comp_uuid,
	]);

	useEffect(() => {
		if (
			publicEmbed &&
			corporateMembership.exhibitorCartDetailsFetched &&
			corporateMembership.exhibitorCartDetails.exhibuuid &&
			common.paymentSuccess !== 1
		) {
			// Public to Private API.
			// delete cart_uuid on success of this.
			props.requestToMakeCartPublicToPrivate({
				moduleUrlId: moduleUrlId,
				appDir: loginInfo.appdir,
				exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				cartUuid: corporateMembership.exhibitorCartDetails.exhibitorCartUuid,
			});
		}
	}, [corporateMembership.exhibitorCartDetailsFetched, companyProfileView]);

	useEffect(() => {
		if (publicEmbed) {
			if (
				corporateMembership.userCompanyAffiliationFetched &&
				corporateMembership.userCompanyAffiliation.comp_uuid === ''
			) {
				// Not associated with any company - so go to Sign Up page.
				toggleSignUp(true);
				toggleSignIn(false);
				toggleCompanyProfile(false);
			}
			if (
				!corporateMembership.companyFetched &&
				corporateMembership.userCompanyAffiliationFetched &&
				corporateMembership.userCompanyAffiliation.comp_uuid
			) {
				props.requestCompanyData({
					compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				});

				// Fetch Company Profile Field Values.
				props.requestToFetchCompanyProfileFieldValues({
					urlId: moduleUrlId,
					compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
					appDir: loginInfo.appdir,
				});
			}
		}
		if (
			!corporateMembership.companyMediaFetched &&
			corporateMembership.companyFetched &&
			corporateMembership.userCompanyAffiliationFetched &&
			corporateMembership.userCompanyAffiliation.comp_uuid
		) {
			props.requestToFetchCompanyMediaValues({
				urlId: moduleUrlId,
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				appDir: loginInfo.appdir,
			});
		}
	}, [
		corporateMembership.companyFetched,
		corporateMembership.userCompanyAffiliationFetched,
		corporateMembership.userCompanyAffiliation,
		corporateMembership.companyMediaFetched,
	]);

	useEffect(() => {
		if (
			!corporateMembership.userCompanyAffiliationFetched &&
			loginInfo.appdir &&
			companyProfileView
		) {
			props.requestUserCompanyAffiliationData({
				appDir: loginInfo.appdir,
				urlId: moduleUrlId,
			});
		}
	}, [loginInfo.appdir, companyProfileView]);

	useEffect(() => {
		if (corporateMembership.userCompanyAffiliationFetched) {
			setLoader(false);
			if (corporateMembership.userCompanyAffiliation['comp_uuid']) {
				setCompanyCreated(true);
			} else if (!publicEmbed) {
				props.history.push('home');
			}
		}
	}, [corporateMembership.userCompanyAffiliationFetched]);

	useEffect(() => {
		if (publicEmbed && !loading) {
			setCompanyProfileMenuItems(currentMenu);
		}
	}, [currentMenu[0]?.fieldvalues, loading, publicEmbed]);

	useEffect(() => {
		if (menu.length) {
			const tempMenu = menu.filter(menu => menu.screentype === 'ContactInfo');
			setCurrentMenu(tempMenu);
		}
	}, [menu]);

	useEffect(() => {
		if (
			publicEmbed &&
			!loading &&
			(corporateMembership.companyProfileFieldValuesFetched ||
				companyProfileMenuItems.length === 0)
		) {
			if (menu.length && currentMenu.length) {
				const companyProfileItems = currentMenu;
				let filteredContactInfoFields;
				if (compUuid) {
					filteredContactInfoFields = companyProfileItems[0].fieldids.filter(item => {
						return (
							item.field_content_type != 'firstname' &&
							item.field_content_type != 'lastname' &&
							item.field_content_type != 'contacttitle' &&
							item.field_content_type != 'email' &&
							item.field_content_type != 'ccemail'
						);
					});
					companyProfileItems[0].fieldids = filteredContactInfoFields;
				}
				setCompanyProfileMenuItems(companyProfileItems);
			}
		}
	}, [menu.length, currentMenu[0]?.fieldvalues]);

	useEffect(() => {
		if (!loading && !publicEmbed) {
			if (menu && currentMenu.length) {
				let companyProfileItems = currentMenu;
				let filteredContactInfoFields;
				if (compUuid) {
					filteredContactInfoFields = companyProfileItems[0].fieldids.filter(item => {
						return (
							item.field_content_type != 'firstname' &&
							item.field_content_type != 'lastname' &&
							item.field_content_type != 'contacttitle' &&
							item.field_content_type != 'email' &&
							item.field_content_type != 'ccemail'
						);
					});
					companyProfileItems[0].fieldids = filteredContactInfoFields;
				}

				setCompanyProfileMenuItems(companyProfileItems);
			} else if (
				corporateMembership.companyProfileFieldValuesFetched ||
				companyProfileMenuItems.length
			) {
				props.history.push(`/main/profilehome`);
			}
		}
	}, [loading, currentMenu]);

	useEffect(() => {
		if (corporateMembership.company.complogo) {
			setFileList([
				{
					url: corporateMembership.company.complogo,
				},
			]);
		}
	}, [corporateMembership.companyFetched, corporateMembership.company.complogo]);

	useEffect(() => {
		if (corporateMembership.company.s3UploadStatus[uploadingFileURL]?.status) {
			setUploading(false);
			setProcessingModalResource({
				...processingModalResource,
				upload: corporateMembership.company.s3UploadStatus[uploadingFileURL].url,
			});
		}
	}, [corporateMembership.company.s3UploadStatus]);

	useEffect(() => {
		if (corporateMembership.company.mediaResourceSubmitResult.data?.success === 1) {
			setProcessingModalResource({});
			setAddUpdateResourceProcessing(false);
			setDeleteResourceProcessing(false);
			resourceForm.resetFields();
			setIsResourceModalOpen(false);
		} else if (corporateMembership.company.mediaResourceSubmitResult.data?.success === 0) {
			setAddUpdateResourceProcessing(false);
			setDeleteResourceProcessing(false);
			message.error(
				`Operation Failed with: ${corporateMembership.company.mediaResourceSubmitResult.data.message}`,
			);
		}
	}, [corporateMembership.company.mediaResourceSubmitResult]);

	const onPreview = options => {
		handleImageCompression(options.file).then(image => {
			let reader = new FileReader();
			reader.onloadend = async function () {
				image = reader.result;
				// Make redux request to post data.
				props.requestToUpdateCompanyLogo({
					compuuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
					base64Img: image,
				});
			};
			reader.readAsDataURL(image);
		});
	};

	const onUploadChange = info => {
		setFileList([...info.fileList]);
	};

	const onRemoveFile = () => {
		setFileList([]);
		props.requestToUpdateCompanyLogo({
			compuuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
			base64Img: '',
		});
	};

	const updateCompanyProfile = () => {
		setValidate(true);

		let postObj = {};
		for (const [key, value] of Object.entries(formState)) {
			if (value) {
				postObj[key] = value;
			}
		}

		if (!validationsObject['error']) {
			// No errors so can make API call
			props.requestToUpdateCompanyProfile({
				form: postObj,
				urlId: moduleUrlId,
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			});
			if (publicEmbed) {
				props.history.push(`/main/public/company/${moduleUrlId}/checkout`);
			}
		} else {
			goToFirstError(validationsObject);
			NotificationManager.warning('You are missing something', 'Validation');
		}
	};

	const ResourceOnClickHandler = (recordDetails = {}, mediaType) => {
		setUploading(false);
		setAddUpdateResourceProcessing(false);
		setDeleteResourceProcessing(false);

		if (mediaType === 'video') {
			const processingObject = {
				resourcetype: 'video',
				orderof: recordDetails.Orderof,
				RowID: recordDetails.RowID,
				label: recordDetails.payload.label,
				videolink: recordDetails.payload.url,
			};
			setModalResourceType(resourceMap.video);

			resourceForm.setFieldsValue({ label: recordDetails.payload.label });
			resourceForm.setFieldsValue({ link: '' });
			resourceForm.setFieldsValue({ videolink: recordDetails.payload.url });
			resourceForm.setFieldsValue({ upload: '' });

			setProcessingModalResource(processingObject);
			setIsResourceModalOpen(true);
		} else if (mediaType === 'link') {
			const processingObject = {
				resourcetype: 'link',
				orderof: recordDetails.Orderof,
				RowID: recordDetails.RowID,
				label: recordDetails.payload.label,
				link: recordDetails.payload.url,
			};
			setModalResourceType(resourceMap.link);

			resourceForm.setFieldsValue({ label: recordDetails.payload.label });
			resourceForm.setFieldsValue({ link: recordDetails.payload.url });
			resourceForm.setFieldsValue({ videolink: '' });
			resourceForm.setFieldsValue({ upload: '' });

			setProcessingModalResource(processingObject);
			setIsResourceModalOpen(true);
		} else if (mediaType === 'pdf') {
			const processingObject = {
				resourcetype: 'pdf',
				orderof: recordDetails.Orderof,
				RowID: recordDetails.RowID,
				label: recordDetails.payload.label,
				upload: recordDetails.payload.url,
			};
			setModalResourceType(resourceMap.pdf);

			resourceForm.setFieldsValue({ label: recordDetails.payload.label });
			resourceForm.setFieldsValue({ link: '' });
			resourceForm.setFieldsValue({ videolink: '' });
			resourceForm.setFieldsValue({ upload: recordDetails.payload.url });

			setProcessingModalResource(processingObject);
			setIsResourceModalOpen(true);
		} else if (mediaType === 'new') {
			setModalResourceType();
			setProcessingModalResource({});
			resourceForm.setFieldsValue({ label: '' });
			resourceForm.setFieldsValue({ link: '' });
			resourceForm.setFieldsValue({ videolink: '' });
			resourceForm.setFieldsValue({ upload: '' });
			setIsResourceModalOpen(true);
		}
	};

	const ModalOkHandler = async e => {
		e.preventDefault();
		let validationResult = await resourceForm.validateFields();
		if (validationResult) {
			let payload = {};
			if (ModalResourceType === resourceMap.video) {
				if (processingModalResource.videolink === '') {
					message.error('Video Link is required');
					return false;
				}
				if (processingModalResource.videolink.includes('youtube.com')) {
					payload = {
						...processingModalResource,
						url: processingModalResource.videolink,
						type: 'youtube',
					};
				} else if (processingModalResource.videolink.includes('vimeo.com')) {
					payload = {
						...processingModalResource,
						url: processingModalResource.videolink,
						type: 'vimeo',
					};
				} else {
					message.error('Unsupported Video link');
					return false;
				}
			} else if (ModalResourceType === resourceMap.link) {
				if (processingModalResource.link === '') {
					message.error('Link is required');
					return false;
				}
				payload = {
					...processingModalResource,
					url: processingModalResource.link,
					type: 'link',
				};
			} else if (ModalResourceType === resourceMap.pdf) {
				if (uploading) {
					message.info('Please wait till file gets uploaded');
					return false;
				} else if (
					corporateMembership.company.s3UploadStatus?.[uploadingFileURL]?.status &&
					processingModalResource.upload
				) {
					payload = {
						...processingModalResource,
						url: processingModalResource.upload,
						type: 'pdf',
					};
				} else {
					message.error('Please upload PDF');
					return false;
				}
			} else {
				message.error('Unsupported Resource Type');
			}

			setAddUpdateResourceProcessing(true);
			props.addUpdateDeleteMediaResource({
				resource: payload,
				compuuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
				appdir: loginInfo.appdir,
			});
		} else {
			return false;
		}
	};
	const deleteItem = item => {
		// Redux action to be dispatched to delete cart item with that id.
		props.requestToDeleteRegistrationItemFromCart({
			form: {
				itemstodelete: {
					field_id: item.fieldId,
					invoice_id: item.invoiceId,
				},
			},
			urlId: moduleUrlId,
			appDir: loginInfo.appdir,
			exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
			compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			publicEmbed: publicEmbed,
			cartUuid: corporateMembership.exhibitorCartDetails.exhibitorCartUuid,
			authUser: authUser,
		});
	};

	const ModalCancelHandler = () => {
		setProcessingModalResource({});
		resourceForm.resetFields();
		setIsResourceModalOpen(false);
	};

	const ModalDeleteHandler = resource => {
		setDeleteResourceProcessing(true);
		props.addUpdateDeleteMediaResource({
			resource: processingModalResource,
			compuuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
			appdir: loginInfo.appdir,
			delete: true,
		});
	};

	const handleResoursedropdownchangeHandler = type => {
		resourceForm.setFieldsValue({ resourcetype: type.key });
		setProcessingModalResource({
			...processingModalResource,
			type: type.key,
		});
		setModalResourceType(resourceMap[type.key]);
	};

	const uploadFile = files => {
		if (files.fileList.length === 0) {
			//deleted the file placed for uploading
			setProcessingModalResource({
				...processingModalResource,
				upload: '',
			});
			return true;
		} else if (files.fileList.length > 1) {
			message.error('Maximum 1 PDF can be uploaded');
			return false;
		}
		const key = `${loginInfo.appdir}/companies/files/${(Math.random() + 1)
			.toString(36)
			.substring(7)}_${files.fileList[0].name}`;
		setUploadingFileURL(key);
		setUploading(true);
		props.uploadToS3({
			'key': key,
			'file': files,
			'Content-Type': 'application/pdf',
		});
	};

	const tabPaneChangeHandler = activeKey => {
		setActiveTabID(activeKey);
	};

	const onVideoSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex === newIndex) return;

		const newData = arrayMove([].concat(companyVideoFiltered), oldIndex, newIndex).filter(
			el => el,
		);

		setCompanyVideoFiltered(newData);
		const sortvideoPayload = { RowOrderList: [], sortType: 'video' };
		for (let i = 0; i < newData.length; i++) {
			sortvideoPayload.RowOrderList.push(newData[i].RowID);
		}

		props.sortMediaResource({
			resource: sortvideoPayload,
			compuuid: corporateMembership.userCompanyAffiliation['comp_uuid'],
			appdir: loginInfo.appdir,
		});
	};

	const onLinkSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex === newIndex) return;

		const newData = arrayMove([].concat(companyLinkFiltered), oldIndex, newIndex).filter(
			el => el,
		);

		setCompanyLinkFiltered(newData);
		const sortlinkPayload = { RowOrderList: [], sortType: 'link' };
		for (let i = 0; i < newData.length; i++) {
			sortlinkPayload.RowOrderList.push(newData[i].RowID);
		}

		props.sortMediaResource({
			resource: sortlinkPayload,
			compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			appdir: loginInfo.appdir,
		});
	};

	const onPDFSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex === newIndex) return;

		const newData = arrayMove([].concat(companyPDFFiltered), oldIndex, newIndex).filter(
			el => el,
		);

		setCompanyPDFFiltered(newData);
		const sortPDFPayload = { RowOrderList: [], sortType: 'pdf' };
		for (let i = 0; i < newData.length; i++) {
			sortPDFPayload.RowOrderList.push(newData[i].RowID);
		}

		props.sortMediaResource({
			resource: sortPDFPayload,
			compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
			appdir: loginInfo.appdir,
		});
	};

	const DraggableVideoBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = companyVideoFiltered.findIndex(x => x.Orderof === restProps['data-row-key']);
		return <SortableItem index={index} {...restProps} />;
	};

	const DraggableLinkBodyRow = ({ className, style, ...restProps }) => {
		const index = companyLinkFiltered.findIndex(x => x.Orderof === restProps['data-row-key']);
		return <SortableItem index={index} {...restProps} />;
	};
	const DraggablePDFBodyRow = ({ className, style, ...restProps }) => {
		const index = companyPDFFiltered.findIndex(x => x.Orderof === restProps['data-row-key']);
		return <SortableItem index={index} {...restProps} />;
	};

	const DraggableVideoContainer = Draggableprops => {
		return (
			<SortableContainer
				useDragHandle
				helperClass="row-dragging"
				onSortEnd={onVideoSortEnd}
				{...Draggableprops}
			/>
		);
	};
	const DraggableLinkContainer = Draggableprops => {
		return (
			<SortableContainer
				useDragHandle
				helperClass="row-dragging"
				onSortEnd={onLinkSortEnd}
				{...Draggableprops}
			/>
		);
	};
	const DraggablePDFContainer = Draggableprops => {
		return (
			<SortableContainer
				useDragHandle
				helperClass="row-dragging"
				onSortEnd={onPDFSortEnd}
				{...Draggableprops}
			/>
		);
	};
	const toggleSignIn = value => {
		setSignInView(value);
	};

	const toggleSignUp = value => {
		setSignUpView(value);
	};

	const toggleCompanyProfile = value => {
		setCompanyProfileView(value);
	};

	const toggleComeBackToSignInFromSignUp = value => {
		setComeBackToSignInFromSignUp(value);
	};
	// loading has to be set to true initially in public embed until the menu is fetched the compuuid

	return (
		<div>
			{!loader && (companyCreated || publicEmbed) && (
				<div className="gx-main-content">
					<div className={publicEmbed ? 'gx-mx-4 gx-my-4' : ''}>
						{publicEmbed && !authUser && (
							<div
								style={{
									height: '100px',
									width: '95%',
									marginTop: '3%',
									marginLeft: '2.5%',
								}}
							>
								<Widget>
									<ProgressBar
										currentStep={menu.findIndex(item => {
											return item.screentype === 'ContactInfo';
										})}
										steps={menu.map(item => {
											return {
												text: item.button,
												order: item.menu_order - 1,
												id: item.menuid,
												type: item.screentype,
											};
										})}
									/>
								</Widget>
							</div>
						)}
						{publicEmbed && signInView && (
							<div
								style={{
									display:
										!auth.jwt &&
										!corporateMembership.userCompanyAffiliation.comp_uuid
											? ''
											: 'none',
								}}
							>
								<ExhibitorSignIn
									toggleSignUp={toggleSignUp}
									toggleSignIn={toggleSignIn}
									toggleCompanyProfile={toggleCompanyProfile}
									comeBackToSignInFromSignUp={comeBackToSignInFromSignUp}
									toggleComeBackToSignInFromSignUp={
										toggleComeBackToSignInFromSignUp
									}
								/>
							</div>
						)}
						{publicEmbed && signUpView && (
							<div
								style={{
									display: !corporateMembership.userCompanyAffiliation.comp_uuid
										? ''
										: 'none',
								}}
							>
								<ExhibitorSignUp
									toggleSignIn={toggleSignIn}
									toggleSignUp={toggleSignUp}
									toggleCompanyProfile={toggleCompanyProfile}
									toggleComeBackToSignInFromSignUp={
										toggleComeBackToSignInFromSignUp
									}
								/>
							</div>
						)}
						{(!publicEmbed || authUser) && companyProfileView && (
							<div style={{ width: '100%' }}>
								<CompanyHeader />
							</div>
						)}
						{companyProfileView && (
							<div
								style={{
									width: '100%',
									display:
										// We don't care about this wall in private mode.
										!publicEmbed ||
										(auth.jwt &&
											corporateMembership.userCompanyAffiliation.comp_uuid)
											? ''
											: 'none',
								}}
							>
								<Row justify="space-between">
									{(!publicEmbed || authUser) && (
										<Col span={width < mobileThreshold ? 24 : 6}>
											<LeftMenu />
										</Col>
									)}
									<Col xl={18} lg={24} md={24} sm={24} xs={24}>
										{!loading &&
											companyProfileMenuItems.length > 0 &&
											corporateMembership.companyMediaFetched &&
											companyProfileMenuItems.map(companyProfileMenuItem => {
												return (
													<Card
														style={{
															margin:
																publicEmbed && !authUser
																	? '3%'
																	: '',
														}}
														className={
															width > mobileThreshold
																? 'gx-card gx-px-3 card gx-ml-5'
																: ''
														}
													>
														<Tabs
															defaultActiveKey={activeTabID}
															onChange={tabPaneChangeHandler}
														>
															<TabPane tab="Profile" key="1">
																<div
																	dangerouslySetInnerHTML={{
																		__html:
																			companyProfileMenuItem.blurb,
																	}}
																></div>
																<div>
																	<div
																		style={{
																			display: 'flow-root',
																		}}
																	>
																		<div
																			style={{
																				float: 'left',
																			}}
																		>
																			<Title
																				label="Company Logo"
																				color={
																					loginInfo
																						.login_form
																						?.primary_color
																				}
																			></Title>
																			<Upload
																				accept="image/*"
																				customRequest={
																					onPreview
																				}
																				listType="picture-card"
																				fileList={fileList}
																				onChange={
																					onUploadChange
																				}
																				className="image-upload-grid"
																				onRemove={
																					onRemoveFile
																				}
																			>
																				{fileList.length >=
																				1 ? null : (
																					<div>
																						<UploadPlaceholderIcon
																							className="fg-secondary-lighter"
																							style={{
																								width:
																									'6em',
																							}}
																						/>
																					</div>
																				)}
																			</Upload>
																		</div>
																		<div
																			style={{
																				float: 'right',
																			}}
																		>
																			<FormIcon
																				className="fg-primary-dark-light"
																				style={{
																					width: '5em',
																				}}
																			/>
																		</div>
																	</div>
																	{Object.keys(
																		companyProfileMenuItem.fieldvalues,
																	).length !== 0 ? (
																		<FormComponent
																			form={formControl}
																			initialValues={
																				companyProfileMenuItem[
																					'fieldvalues'
																				]
																			}
																			formData={parseScreen(
																				companyProfileMenuItem,
																			)}
																			validationConfig={{
																				module: 'company',
																				moduleurlid: moduleUrlId,
																				menuid:
																					companyProfileMenuItem[
																						'menuid'
																					],
																			}}
																			onChange={(
																				validations,
																				all,
																			) => {
																				setValidationsObject(
																					validations,
																				);
																				setFormState(all);
																			}}
																			validate={validate}
																			primaryColor={
																				loginInfo.login_form
																					?.primary_color
																			}
																			secondaryColor={
																				'#808080'
																			}
																			textColor={'#404040'}
																		/>
																	) : (
																		<Card className="card">
																			<CircularProgress className="gx-profile-content" />
																		</Card>
																	)}
																</div>
																<Button
																	loading={
																		corporateMembership.isLoading
																	}
																	style={{
																		boxShadow:
																			'8px 8px 2px rgba(45, 45, 45, 0.65)',
																		border: 'transparent',
																	}}
																	className="login-form-button gx-mt-1 button-primary"
																	shape={'round'}
																	onClick={updateCompanyProfile}
																>
																	UPDATE
																</Button>
															</TabPane>
															{corporateMembership.companyMediaFetched &&
																!publicEmbed &&
																displayCompanyProfileTab && (
																	<TabPane
																		tab="Resources"
																		key="2"
																	>
																		<div
																			dangerouslySetInnerHTML={{
																				__html:
																					companyProfileMenuItem.blurb,
																			}}
																		></div>
																		<div>
																			<div
																				style={{
																					display:
																						'flow-root',
																				}}
																			>
																				<div
																					style={{
																						float:
																							'left',
																					}}
																				></div>
																				<div
																					style={{
																						float:
																							'right',
																					}}
																				>
																					<Button
																						className="fg-primary-dark-light"
																						onClick={() => {
																							ResourceOnClickHandler(
																								{},
																								'new',
																							);
																						}}
																					>
																						Add Resource
																					</Button>
																				</div>
																			</div>
																			<Title
																				label="Videos"
																				color={
																					loginInfo
																						.login_form
																						?.primary_color
																				}
																			></Title>
																			<Divider />
																			<Table
																				dataSource={
																					companyVideoFiltered
																				}
																				pagination={false}
																				columns={[
																					{
																						title:
																							'Sort',
																						dataIndex:
																							'sort',
																						render: () => (
																							<DragHandle />
																						),
																						width:
																							'10%',
																					},
																					{
																						title:
																							'Name',
																						dataIndex:
																							'payload',
																						render: element => (
																							<a
																								href={
																									element.url
																								}
																								target="_blank"
																							>
																								{
																									element.label
																								}
																							</a>
																						),
																						width:
																							'40%',
																					},
																					{
																						title:
																							'Action',
																						key:
																							'payload',
																						render: element => (
																							<FormOutlined
																								onClick={() => {
																									ResourceOnClickHandler(
																										element,
																										'video',
																									);
																								}}
																							/>
																						),
																					},
																				]}
																				rowKey="Orderof"
																				components={{
																					body: {
																						wrapper: DraggableVideoContainer,
																						row: DraggableVideoBodyRow,
																					},
																				}}
																			></Table>

																			<Title
																				label="PDFs"
																				color={
																					loginInfo
																						.login_form
																						?.primary_color
																				}
																			></Title>
																			<Divider />
																			<Table
																				dataSource={
																					companyPDFFiltered
																				}
																				pagination={false}
																				columns={[
																					{
																						title:
																							'Sort',
																						dataIndex:
																							'sort',
																						render: () => (
																							<DragHandle />
																						),
																						width:
																							'10%',
																					},
																					{
																						title:
																							'Name',
																						dataIndex:
																							'payload',
																						render: element => (
																							<a
																								href={
																									element.url
																								}
																								target="_blank"
																							>
																								{
																									element.label
																								}
																							</a>
																						),
																						width:
																							'40%',
																					},
																					{
																						title:
																							'Action',
																						key:
																							'payload',
																						render: element => (
																							<Space size="middle">
																								<FormOutlined
																									onClick={() => {
																										ResourceOnClickHandler(
																											element,
																											'pdf',
																										);
																									}}
																								/>
																							</Space>
																						),
																					},
																				]}
																				rowKey="Orderof"
																				components={{
																					body: {
																						wrapper: DraggablePDFContainer,
																						row: DraggablePDFBodyRow,
																					},
																				}}
																			></Table>
																			<Title
																				label="Links"
																				color={
																					loginInfo
																						.login_form
																						?.primary_color
																				}
																			></Title>
																			<Divider />
																			<Table
																				dataSource={
																					companyLinkFiltered
																				}
																				pagination={false}
																				columns={[
																					{
																						title:
																							'Sort',
																						dataIndex:
																							'sort',
																						render: () => (
																							<DragHandle />
																						),
																						width:
																							'10%',
																					},
																					{
																						title:
																							'Name',
																						dataIndex:
																							'payload',
																						render: element => (
																							<a
																								href={
																									element.url
																								}
																								target="_blank"
																							>
																								{
																									element.label
																								}
																							</a>
																						),
																						width:
																							'40%',
																					},
																					{
																						title:
																							'Action',
																						key:
																							'payload',
																						render: element => (
																							<Space size="middle">
																								<FormOutlined
																									onClick={() => {
																										ResourceOnClickHandler(
																											element,
																											'link',
																										);
																									}}
																								/>
																							</Space>
																						),
																					},
																				]}
																				rowKey="Orderof"
																				components={{
																					body: {
																						wrapper: DraggableLinkContainer,
																						row: DraggableLinkBodyRow,
																					},
																				}}
																			></Table>
																		</div>
																	</TabPane>
																)}
														</Tabs>
													</Card>
												);
											})}
									</Col>
									{publicEmbed && !authUser && (
										<Col span={6} style={{ marginTop: '2.2%' }}>
											<div style={{ marginRight: '10%' }}>
												<Card
													className="card"
													title={
														<div>
															<div
																style={{
																	float: 'left',
																	paddingTop: '2%',
																}}
																className="gx-text-secondary gx-font-weight-semi-bold gx-page-title gx-ml-1 gx-mt-1"
															>
																{
																	menu.find(item => {
																		return (
																			item.screentype ===
																			'Checkout'
																		);
																	})?.button
																}
															</div>
															<BagIcon
																className="fg-primary-dark-light"
																style={{
																	float: 'right',
																	width: '3em',
																}}
															/>
														</div>
													}
													style={{
														margin: publicEmbed ? '3%' : '',
													}}
												>
													<RegistrationItems
														cartItems={
															corporateMembership.exhibitorCart
														}
														onDelete={deleteItem}
														cartProcessView={true}
													/>
												</Card>
											</div>
										</Col>
									)}
								</Row>
							</div>
						)}
					</div>
				</div>
			)}
			{loader && (
				<Card className="card">
					<CircularProgress className="gx-profile-content" />
				</Card>
			)}
			{isResourceModalOpen && (
				<div>
					<Modal
						visible={isResourceModalOpen}
						title={processingModalResource.RowID ? 'Edit Resource' : 'Add Resource'}
						onOk={ModalOkHandler}
						onCancel={ModalCancelHandler}
						onDelete={ModalDeleteHandler}
						footer={[
							<Button
								key="Ok"
								type="primary"
								htmlType="submit"
								onClick={ModalOkHandler}
								loading={addUpdateResourceProcessing}
							>
								{addUpdateResourceProcessing
									? processingModalResource.RowID
										? 'Updating...'
										: 'Submitting...'
									: processingModalResource.RowID
									? 'Update'
									: 'Submit'}
							</Button>,
							<Button
								key="delete"
								type="danger"
								onClick={ModalDeleteHandler}
								loading={deleteResourceProcessing}
								disabled={!processingModalResource.RowID}
							>
								{deleteResourceProcessing ? 'Deleting...' : 'Delete'}
							</Button>,
							<Button key="cancel" onClick={ModalCancelHandler}>
								Cancel
							</Button>,
						]}
					>
						<Form
							form={resourceForm}
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 16 }}
							name="resourceForm"
							initialValues={
								processingModalResource ?? {
									label: '',
									link: '',
									videolink: '',
									upload: '',
								}
							}
							onFinish={ModalOkHandler}
							onFinishFailed={ModalCancelHandler}
						>
							<Form.Item
								label="Resource Type"
								name="resourcetype"
								rules={[{ required: true, message: 'Please select Resource Type' }]}
							>
								<Dropdown
									overlay={
										<Menu onClick={handleResoursedropdownchangeHandler}>
											<Menu.Item key="video" value="Video">
												Video
											</Menu.Item>
											<Menu.Item key="link" value="Link">
												Link
											</Menu.Item>
											<Menu.Item key="pdf" value="PDF">
												PDF
											</Menu.Item>
										</Menu>
									}
								>
									<Button>
										{ModalResourceType ?? 'Select Resource Type'}
										<DownOutlined />
									</Button>
								</Dropdown>
							</Form.Item>
							{ModalResourceType === resourceMap.video && (
								<Alert
									message="Only public videos on YouTube and Vimeo are supported."
									type="info"
									showIcon
								/>
							)}
							<Form.Item
								label="Label"
								name="label"
								rules={[{ required: true, message: 'Please enter Label' }]}
							>
								<Input
									onChange={e => {
										setProcessingModalResource({
											...processingModalResource,
											label: String(e.target.value),
										});
									}}
								/>
							</Form.Item>

							{ModalResourceType === resourceMap.link && (
								<Form.Item
									label="Link"
									name="link"
									rules={[
										{
											required:
												ModalResourceType === resourceMap.link
													? true
													: false,

											pattern: new RegExp(
												/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
											),
											message: 'Please enter valid link',
										},
									]}
								>
									<Input
										onChange={e => {
											setProcessingModalResource({
												...processingModalResource,
												link: String(e.target.value),
											});
										}}
									/>
								</Form.Item>
							)}

							{ModalResourceType === resourceMap.video && (
								<Form.Item
									label="Video Link"
									name="videolink"
									rules={[
										{
											required:
												ModalResourceType === resourceMap.video
													? true
													: false,

											pattern: new RegExp(
												/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
											),
											message: 'Please enter valid Youtube/Vimeo link',
										},
									]}
								>
									<Input
										onChange={e => {
											setProcessingModalResource({
												...processingModalResource,
												videolink: String(e.target.value),
											});
										}}
									/>
								</Form.Item>
							)}
							{ModalResourceType === resourceMap.pdf && (
								<Form.Item
									label="Upload"
									name="upload"
									rules={[{ required: true }]}
								>
									<Upload
										name="resource_uploader"
										accept=".pdf"
										maxCount={1}
										multiple={false}
										defaultFileList={
											processingModalResource?.upload &&
											processingModalResource?.label
												? [
														{
															url: processingModalResource.upload,
															name: processingModalResource.label,
															uid: -1,
															status: 'done',
														},
												  ]
												: []
										}
										beforeUpload={file => {
											if (file.type !== 'application/pdf') {
												message.error(`${file.name} is not a pdf file`);
											}
											return false;
										}}
										onChange={uploadFile}
									>
										<Button
											icon={<UploadOutlined />}
											loading={uploading}
											disabled={processingModalResource.upload}
										>
											{uploading ? 'Uploading' : 'Upload PDF'}
										</Button>
									</Upload>
								</Form.Item>
							)}
						</Form>
					</Modal>
				</div>
			)}
			<NotificationContainer />
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToDeleteRegistrationItemFromCart: requestToDeleteRegistrationItemFromCart,
			requestToUpdateCompanyLogo: requestToUpdateCompanyLogo,
			requestUserCompanyAffiliationData: requestUserCompanyAffiliationData,
			requestToUpdateCompanyProfile: requestToUpdateCompanyProfile,
			requestCompanyData: requestCompanyData,
			requestToFetchCompanyProfileFieldValues: requestToFetchCompanyProfileFieldValues,
			requestExhibitorCartDetails: requestExhibitorCartDetails,
			requestExhibitorCart: requestExhibitorCart,
			requestToMakeCartPublicToPrivate: requestToMakeCartPublicToPrivate,
			requestAuthenticateUser: requestAuthenticateUser,
			uploadToS3,
			addUpdateDeleteMediaResource,
			requestToFetchCompanyMediaValues,
			sortMediaResource,
		}),
		dispatch,
	);
}

export default connect(null, matchDispatchToProps)(ExhibitorCompanyProfile);
