import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormComponent from '../form';
import { parseScreen } from '../../util/form';
import {
	requestToAddNewUserContact,
	requestUpdateMiniProfileFields,
	requestCreateMiniProfileFields,
} from '../../appRedux/actions';
import { useParams } from 'react-router';
import { goToFirstError } from './helpers/generalHelpers';
import { ReactComponent as CloseModalIcon } from '../../assets/icons/modalClose.svg';

import { Modal, Button, Form, Alert } from 'antd';

const ExhibitorAddContact = ({ isButton, actionText, title, formData, compUuid, record }) => {
	const [validate, setValidate] = useState(false);
	const [formAlert, setFormAlert] = useState(false);
	const [formState, setFormState] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const dispatch = useDispatch();

	const corporateMembership = useSelector(state => state.corporateMembership);
	const loginInfo = useSelector(state => state.loginInfo);
	const [formControl] = Form.useForm();

	useEffect(() => {
		setValidate(false);
	}, [corporateMembership.companyMembers, record]);

	const [validationsObject, setValidationsObject] = useState({
		error: false,
		validationErrors: {},
	});
	const { moduleUrlId } = useParams();

	const closeModal = () => {
		setModalVisible(false);
		formControl.resetFields();
	};

	const onUpdateOrAddClicked = () => {
		setValidate(true);

		// manually check for firstName, lastName, email, if not fire alert
		let formData = formState;
		if (formData['firstname'] && formData['firstname'] && formData['email']) {
			formData['add_from'] = 'Corporate Membership';
			dispatch(
				requestCreateMiniProfileFields({
					formData,
				}),
			);
			setModalVisible(false);
		} else {
			setFormAlert(true);
		}
	};

	const onContactInformationClick = () => {
		setModalVisible(true);
		formControl.resetFields();
	};

	const buttonBaseProps = isCancel => {
		return {
			className: `gx-mt-1 ${isCancel ? 'button-secondary-outline' : 'button-primary'}`,
			shape: 'round',
		};
	};

	const closeAlert = () => {
		setFormAlert(false);
	};

	return (
		<div>
			<div className="gx-d-flex gx-align-items-start">
				<div>
					<div>
						<Modal
							className="custom-ant-modal-content"
							// style={{height: '500px', overflow: 'scroll'}}
							destroyOnClose={true}
							closeIcon={
								<CloseModalIcon className="gx-mt-2" style={{ width: '2em' }} />
							}
							visible={modalVisible}
							onCancel={closeModal}
							title={'Add Contact'}
							cancelText={'CANCEL'}
							okText={actionText}
							onOk={() => {
								if (!validationsObject?.error) {
									setModalVisible(false);
								}
								if (onUpdateOrAddClicked) {
									onUpdateOrAddClicked();
								}
							}}
							cancelButtonProps={{
								...buttonBaseProps(true),
							}}
							okButtonProps={{
								...buttonBaseProps(false),
								style: {
									boxShadow: '6px 6px 2px rgba(45, 45, 45, 0.65)',
								},
							}}
							maskClosable={false}
						>
							<FormComponent
								form={formControl}
								formData={
									Array.isArray(formData[0]?.fieldids)
										? parseScreen(formData[0])
										: []
								}
								validationConfig={{
									module: 'contact',
									moduleurlid: moduleUrlId,
									menuid: formData[0]?.menuid,
								}}
								onChange={(validations, all) => {
									setValidationsObject(validations);
									setFormState(all);
								}}
								validate={validate}
								primaryColor={loginInfo.login_form.primary_color}
								secondaryColor={'#808080'}
								textColor={'#404040'}
							/>
							{formAlert && (
								<Alert
									onClose={closeAlert}
									description={<div>Please add all required fields</div>}
									type="error"
									closable={true}
								/>
							)}
						</Modal>
					</div>
				</div>

				<Button
					className="gx-mt-1 button-primary"
					onClick={onContactInformationClick}
					shape={'round'}
					size={'small'}
					style={{
						boxShadow: modalVisible
							? 'inset 5px 5px 2px 1px rgba(45, 45, 45, 0.4)'
							: '',
					}}
				>
					<div style={{ marginTop: '-2px' }}>{'Add Contact'}</div>
				</Button>
			</div>
		</div>
	);
};

export default ExhibitorAddContact;
