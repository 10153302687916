import { useMemo } from 'react';
import Fuse from 'fuse.js';
import _cloneDeep from 'lodash/cloneDeep';
import _uniq from 'lodash/uniq';
import _toLower from 'lodash/toLower';
import { useHistory, useParams } from "react-router-dom";
import moment from 'moment'
import { paginate } from 'components/Common/helpers/helper';

export const useSearchClient = (loading, products, sortType, authorBasedMapping = {}, tagsMapping = {}, categoriesMapping = {}, advanceCategoriesMapping = {}, countryMapping = {}, companyMapping = {}, quick_view_only = false, isDerivedParent = false) => {
	const history = useHistory();
	const { superBundleId } = useParams();
	let searchKeys = [
		'title',
		'description',
		'children.title',
		'authorInfo.fullname',
		'author_block',
		'authorInfo.country',
		'authorInfo.company',
		'sessionStartTime'
	];
	if (superBundleId) {
		searchKeys = [
			...searchKeys,
			'children.description',
			'children.authorInfo.fullname',
			'children.author_block',
			'children.authorInfo.country',
			'children.authorInfo.company'
		];
	}

	const publisedDateList = [
		...new Set(
			products.map(data => {
				let pubDate = new Date(data?.publicationDate)
				let converted = pubDate.getFullYear()
				return converted
			}).sort((a, b) => {
				return b - a;
			}).filter((data) => !isNaN(data)),
		),
	];

	const searchFuse = useMemo(
		() =>
			loading ||
			new Fuse(products, {
				keys: searchKeys,
				includeMatches: true,
				useExtendedSearch: true,
			}),
		[loading, products],
	);
	const keys = ['category', 'tag', 'advanceCategory']

	const search = requests =>
		new Promise((resolve, reject) => {
			if (loading) return reject();

			const { query, page, hitsPerPage, facetFilters = [] } = requests[0].params;
			const { indexName: sortByType } = requests[0];


			const result = searchFuse.search(`'"${query}"`);
			const superBundleRes = result.filter((res) => res.item.isSuperBundle);
			const bundleRes = result.filter((res) => res.item.isBundle && !res.item.isSuperBundle);
			const productRes = result.filter((res) => !res.item.isBundle && !res.item.isSuperBundle);
			const searchedProducts = [...superBundleRes, ...bundleRes, ...productRes].map(({ item, matches }) => {
				const hasChildrenMatch = matches.some(match => match.key === 'children.title');
				const hasChildrenDescriptionMatch = matches.some(match => match.key === 'children.description');
				const hasChildAuthorMatch = matches.some(match => (
					match.key === 'children.authorInfo.fullname'
					|| match.key === 'children.author_block'
					|| match.key === 'children.authorInfo.country'
					|| match.key === 'children.authorInfo.company'
				));
				// const hasAuthorCCMatch = matches.some(match => match.key === 'children.authorInfo.country' || match.key === 'children.authorInfo.company');
				// const hasAuthorCompanyMatch = matches.some(match => match.key === 'children.authorInfo.company');
				const searchedChildren =
					item.children?.filter((_, index) =>
						matches.some(match => match.refIndex === index),
					) ?? [];
				// keep all children if none of the matches are from children.title
				let children = hasChildrenMatch ? searchedChildren : item.children;
				if (hasChildAuthorMatch) {
					children = children.filter(p => (
						p.authorInfo.some(auth => (
							_toLower(auth.fullname).includes(_toLower(query))
							|| _toLower(auth.country).includes(_toLower(query))
							|| _toLower(auth.company).includes(_toLower(query))
						)) || _toLower(p?.author_block).includes(_toLower(query))
					));
				}
				if (hasChildrenDescriptionMatch) {
					children = children.filter(p => (
						_toLower(p.description).includes(_toLower(query))
					));
				}
				return { ...item, children };
			});

			let totalHits = query ? searchedProducts : products.filter(p => !p.parentId);
			// let totalHits = query ? searchedProducts : products;

			const facets = { category: {}, tag: {}, country: {}, company: {}, advanceCategory: {}, publicationStart: {}, publicationEnd: {} };

			let totalProductsMap = [];
			let selectedStartYearKey = facetFilters.filter((data) => data?.[0].includes('publicationStart:'))?.[0];
			let selectedStartYear = Array.isArray(selectedStartYearKey) && selectedStartYearKey?.length
				? selectedStartYearKey[0].split(':')[1] : null;

			totalProductsMap = [...new Set(totalHits.map(p => p.id))];
			const buildCategoryFacets = () => {
				for (const categoryId in categoriesMapping) {
					facets.category[categoryId] = categoriesMapping[categoryId]?.filter(id => totalProductsMap?.includes(id)).length
				}
			};

			const buildAdvanceCategoryFacets = () => {
				for (const categoryId in advanceCategoriesMapping) {
					facets.advanceCategory[categoryId] = advanceCategoriesMapping[categoryId]?.filter(id => totalProductsMap?.includes(id)).length;
				}
			};

			const buildTagFacets = () => {
				for (const tagId in tagsMapping) {
					facets.tag[tagId] = tagsMapping[tagId]?.filter(id => totalProductsMap?.includes(id)).length;
				}
			};

			const buildCountryFacets = () => {
				for (const key in authorBasedMapping.country) {
					if (superBundleId && quick_view_only) {
						// let facetCountryData = [];
						// totalHits.forEach((item) => {
						// 	let tempData = item.children.filter((child) =>
						// 		(authorBasedMapping.country[key]?.has(child.id)));
						// 	facetCountryData = [...facetCountryData, ...tempData];
						// })
						// facets.country[key] = facetCountryData.length;
						facets.country[key] = totalHits.flatMap((item) => item.children)
							.filter((child) => (authorBasedMapping.country[key]?.has(child.id))).length;
					} /* else {
						facets.country[key] = totalHits.filter(p =>
							(authorBasedMapping.country[key]?.has(p.id))).length;
					} */
				}
				if (!superBundleId && !quick_view_only) {
					for (const key in countryMapping) {
						facets.country[key] = countryMapping[key]?.filter(id => totalProductsMap?.includes(id)).length;
					}
				}
			};

			const buildCompanyFacets = () => {
				for (const key in authorBasedMapping.company) {
					if (superBundleId && quick_view_only) {
						facets.company[key] = totalHits.flatMap((item) => item.children)
							.filter((child) => (authorBasedMapping.company[key]?.has(child.id))).length;
						// let facetCompanyData = [];
						// totalHits.forEach((item) => {
						// 	let tempData = item.children.filter((child) =>
						// 		(authorBasedMapping.company[key]?.has(child.id)));
						// 	facetCompanyData = [...facetCompanyData, ...tempData];
						// })
						// facets.company[key] = facetCompanyData.length;
					} /* else {
						facets.company[key] = totalHits.filter(p =>
							(authorBasedMapping.company[key]?.has(p.id))).length;
					} */
				}
				if (!superBundleId && !quick_view_only) {
					for (const key in companyMapping) {
						facets.company[key] = companyMapping[key]?.filter(id => totalProductsMap?.includes(id)).length;
					}
				}
			};

			const buildPublishStartDateFacets = () => {
				publisedDateList.forEach((year) => {
					facets.publicationStart[year] = 0;
				})
			};

			const buildPublishEndDateFacets = () => {
				let publishedFilterList = publisedDateList;
				if (selectedStartYear) {
					publishedFilterList = publisedDateList.filter((data) => data >= selectedStartYear);
				}
				publishedFilterList.forEach((year) => {
					facets.publicationEnd[year] = 0;
				});
			};

			// build facets before filtering first
			buildCategoryFacets();
			buildAdvanceCategoryFacets();
			buildTagFacets();
			buildCountryFacets();
			buildCompanyFacets();
			buildPublishStartDateFacets();
			buildPublishEndDateFacets();

			// facet filtering logic
			for (let i = 0; i < facetFilters.length; i++) {
				const key = facetFilters[i][0].split(':')[0];
				const values = facetFilters[i].map(filter => {
					return keys.includes(key) ? parseInt(filter.split(':')[1]) : filter.split(':')[1]
				});

				switch (key) {
					case 'category':
						totalHits = totalHits.filter(p => !p.parentId).filter(p => values.includes(p.categoryId));
						buildTagFacets(); // filtering by category only affects count of tags
						break;
					case 'advanceCategory':
						totalHits = totalHits.filter(p => values.includes(p.categoryId));
						break;
					case 'tag':
						totalHits = totalHits.filter(p => values.some(id => p.tagIds.map(id => parseInt(id, 10)).includes(id)));
						buildCategoryFacets(); // filtering by tags only affect count of cats
						break;
					case 'country': {
						let countryHits = [];
						if (superBundleId && quick_view_only) {
							countryHits = totalHits.reduce((acc, item) => {
								let constructedHits = [];
								for (const value of values) {
									let mappedValue = authorBasedMapping.country[value];
									let tempHits = item.children.filter((child) => (mappedValue?.has(child.id)));
									constructedHits = [...constructedHits, ...tempHits];
								}
								if (constructedHits.length) {
									return [...acc, { ...item, children: constructedHits }];
								}
								return [...acc];
							}, []);
						} else {
							for (const value of values) {
								let mappedValue = authorBasedMapping.country[value];
								let tempFilteredHits = totalHits.filter(p => mappedValue.has(p.id));
								countryHits = [...countryHits, ...tempFilteredHits];
							}
						}
						totalHits = [...countryHits];
						break;
					}
					case 'company': {
						let companyHits = [];
						if (superBundleId && quick_view_only) {
							companyHits = totalHits.reduce((acc, item) => {
								let constructedHits = [];
								for (const value of values) {
									let mappedValue = authorBasedMapping.company[value];
									let tempHits = item.children.filter((child) =>
										(mappedValue?.has(child.id)));
									constructedHits = [...constructedHits, ...tempHits];
								}
								if (constructedHits.length) {
									return [...acc, { ...item, children: constructedHits }];
								}
								return [...acc];
							}, []);
						} else {
							for (const value of values) {
								let mappedValue = authorBasedMapping.company[value];
								let tempFilteredHits = totalHits.filter(p => mappedValue.has(p.id));
								companyHits = [...companyHits, ...tempFilteredHits]
							}
						}
						totalHits = [...companyHits];
						break;
					}
					case 'publicationEnd': {
						if (selectedStartYear) {
							totalHits = totalHits.filter((data) => {
								let startDate = moment(selectedStartYear).startOf('year')
								let endDate = moment(values[0]).endOf('year')
								let pubDate = moment(data.publicationDate)
								return pubDate.isBetween(startDate, endDate, undefined, "[]")
							});
						}
						break;
					}
				}
			}

			//SortBy--
			const sortBy = (type) => {
				switch (type) {
					case 'sessionOrderOf':
						totalHits = [
							...totalHits.filter((item) => item?.sessionOrderOf).sort((a, b) => parseInt(a?.sessionOrderOf) - parseInt(b?.sessionOrderOf)),
							...totalHits.filter((session) => !session?.sessionOrderOf).sort((a, b) => parseInt(a?.presentationOrderOf) - parseInt(b?.presentationOrderOf))
						];
						break;
					case 'presentationOrderOf':
						totalHits = [...totalHits.sort((a, b) => parseInt(a?.ab_order) - parseInt(b?.ab_order))];
						break;
					case 'product_label':
						totalHits = [...totalHits.sort((a, b) => a.title.localeCompare(b.title))];
						break;
					case 'publication_date':
						totalHits = [
							...totalHits.filter((item) => item.publicationDate).sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)),
							...totalHits.filter((item) => !item.publicationDate).sort((a, b) => a.title.localeCompare(b.title))
						];
						break;
					case 'date_added':
						totalHits = [...totalHits.sort((a, b) => new Date(b.date_added) - new Date(a.date_added))];
						break;
					case 'author_last_name':
						const authSortByPresenter = totalHits.map((item) => {
							if (item?.authorInfo?.length) {
								const authInfo = [...item?.authorInfo];
								return {
									...item,
									authorInfo: [...authInfo.sort((a, b) => a.presenter === b.presenter ? 0 : a.presenter ? -1 : 1)]
								}
							}
							return item
						});
						totalHits = [
							...authSortByPresenter.filter((item) => item?.authorInfo.length)
								.sort((a, b) => a?.authorInfo?.[0]?.lastname.localeCompare(b?.authorInfo?.[0].lastname)),
							...authSortByPresenter.filter((item) => !item?.authorInfo.length).sort((a, b) => a.title.localeCompare(b.title))
						]
						break;
						case 'sessionStartTime':
							totalHits = [...totalHits.sort((a, b) => new Date(a.sessionStartTime) - new Date(b.sessionStartTime))];
						break;
					default:
						break;
				}
			}

			sortBy(sortByType);

			totalProductsMap = [...new Set(totalHits.map(p => p.id))];

			if (!facetFilters.flatMap((e => e)).find((data) => data.includes('category'))) {
				buildAdvanceCategoryFacets();
				buildTagFacets();
				buildCountryFacets();
				buildCompanyFacets();
				buildPublishStartDateFacets();
				buildPublishEndDateFacets();
			} else {
				buildTagFacets();
			}

			if ((!facetFilters.length && !query)
				|| (facetFilters.length === 1
					&& (selectedStartYear
						|| facetFilters.flatMap((e => e)).find((data) => data.includes('publicationEnd')))
				)
			) {
				totalHits = totalHits.filter(p => !p.parentId);
			}
			// pagination
			const constructChildEls = isDerivedParent ? totalHits : totalHits.flatMap(p => p.children);
			const nbHits = superBundleId && quick_view_only ? constructChildEls?.length : totalHits.length;
			const nbPages = Math.ceil(nbHits / hitsPerPage);
			const hits = superBundleId && quick_view_only ? totalHits : paginate(totalHits, page, hitsPerPage);
			resolve({ results: [{ hits, nbHits, page, nbPages, hitsPerPage, facets, totalHits }] });
		});

	return { search };
};
