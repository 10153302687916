import React, { useEffect, useState } from 'react';
import { Button, Input, message, Form, Card } from 'antd';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import _isEmpty from 'lodash/isEmpty';
import IntlMessages from 'util/IntlMessages';
import {
	requestAuthUser,
	requestAuthenticateUser,
	requestValidateEmail,
	requestClearValidateEmail,
	requestInitialAPI,
	requestLoginFormData,
	requestProfileData,
} from 'appRedux/actions';
import CircularProgress from 'components/CircularProgress/index';
import auth from '../loginAuth/loginAuth';
import { Col, Row, Alert } from 'antd';
import Support from './Support';

const SignIn = props => {
	const [localLoader, setlocalLoader] = useState(false);
	const [loginInfo, setLoginInfo] = useState('');
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [submitLoad, setSubmitLoad] = useState(false);
	const [validateEmail, setValidateEmail] = useState(false);
	const [showPasswordInput, setShowPasswordInput] = useState(false);

	const [invalidEmailAlert, setInvalidEmailAlert] = useState(false);
	const [invalidLoginAlert, setInvalidLoginAlert] = useState(false);
	const [invalidTokenAlert, setInvalidTokenAlert] = useState(false);

	const [resetPasswordAlert, setResetPasswordAlert] = useState(false);

	const [email, setEmail] = useState('');

	const { alertMessage, showMessage, loader } = useSelector(({ auth }) => auth);

	const history = useHistory();
	const { moduleUrlId } = useParams();

	var appdir =
		window.location.hostname == 'localhost'
			? 'dfi'
			: window.location.hostname.substr(0, window.location.hostname.indexOf('.'));

	if (appdir == 'dfiapp') {
		appdir = 'dfi';
	}
	const info = props.initialData;

	const isJson = str => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	};

	useEffect(() => {
		let jwtToken = auth.getCookie('jwt');
		if (jwtToken?.length > 5) {
			dispatch(
				requestAuthUser({
					ssotoken: auth.getCookie('ssotoken')
						? JSON.parse(auth.getCookie('ssotoken'))
						: '',
					contact_uuid: auth.getCookie('contact_uuid')
						? JSON.parse(auth.getCookie('contact_uuid'))
						: '',
					jwt: jwtToken ? JSON.parse(jwtToken) : '',
				}),
			);
			setlocalLoader(false);
			//source_hex, jwt, sso_token, contact_uuid
			const previousLocation = auth.getCookie('previous_location');
			if (previousLocation && isJson(previousLocation)) {
				history.push(JSON.parse(previousLocation));
			}
		}
		dispatch(
			requestLoginFormData({
				appdir: appdir,
			}),
		);
		if (props.location.state?.from?.search?.includes('token')) {
			//loggedin user/ sso login
			setSubmitLoad(true);
			const urlParams = new URLSearchParams(props.location.state.from.search);
			let formData = new FormData();
			formData.append('uuid', urlParams.get('uuid'));
			formData.append('sso_token', urlParams.get('token'));
			setlocalLoader(true);
			dispatch(
				requestAuthenticateUser({
					appdir: appdir,
					formData: formData,
				}),
			);
		} else {
			const uuid = auth.getCookie('contact_uuid');
			const ssotoken = auth.getCookie('ssotoken');
			let tempUuid = uuid.replace(/['"]+/g, '');
			let tempSsotoken = ssotoken.replace(/['"]+/g, '');

			if (uuid !== '""' && uuid && ssotoken !== '""' && ssotoken) {
				let formData = new FormData();
				formData.append('uuid', tempUuid);
				formData.append('sso_token', tempSsotoken);
				dispatch(requestAuthenticateUser({ appdir, formData }));
				// the error msg being passed back has to differntiate between whether its a login email password failure,
				//or a sso_token failer so we can appropriately show the proper msg
			}
		}
	}, []);

	//watches for change in showMessage
	useEffect(() => {
		if (props.auth.showMessage) {
			setlocalLoader(false);
		}
	}, [props.auth.showMessage]);

	// watches for consent_object
	useEffect(() => {
		if (props.auth.consent_object !== undefined && props.auth.consent_object !== '') {
			history.push('/main/updateaccount');
		}
	}, [props.auth]);

	//watches change in jwt token or alert message after submit login is clicked
	useEffect(() => {
		if (props.auth.alertMessage === 'Email or Password was incorrect.') {
			setInvalidLoginAlert(true);
			setSubmitLoad(false);
			setlocalLoader(false);
		} else {
			let jwtToken = auth.getCookie('jwt');

			if (
				jwtToken != '' &&
				(props.auth.contact_uuid || JSON.parse(auth.getCookie('contact_uuid'))) &&
				(info.source_hex || JSON.parse(auth.getCookie('source_hex'))) &&
				info.appdir !== ''
			) {
				requestProfile(
					info.source_hex === ''
						? JSON.parse(auth.getCookie('source_hex'))
						: info.source_hex,
					props.auth.contact_uuid === ''
						? JSON.parse(auth.getCookie('contact_uuid'))
						: props.auth.contact_uuid,
				);
			}
		}
	}, [props.auth.contact_uuid, info.source_hex, info.appdir]);

	useEffect(() => {
		if (props.auth.alertMessage === 'Token has expired.') {
			setInvalidTokenAlert(true);
			setlocalLoader(false);
			setSubmitLoad(false);
		} else if (props.auth.alertMessage === '') {
			setlocalLoader(false);
		}

		// if (props.auth.alertMessage === null) {
		// 	setlocalLoader(true);
		// }
	}, [props.auth.alertMessage]);

	//watches for when the api is retrieved the user profile data
	useEffect(() => {
		let jwtToken = auth.getCookie('jwt');
		if (props.initialData.firstName !== '' && jwtToken?.length > 5) {
			// The reason we check whether it is /signin or / is because we need it to be initURL to go to the full app from public corporate membership and potentially ecommerce.
			history.push(
				props.auth.initURL === '/signin' || props.auth.initURL === '/'
					? '/main/profilehome'
					: props.auth.initURL,
			);
		}
	}, [props.initialData.firstName, props.auth.jwt]);

	useEffect(() => {
		// if the user has an email and a password
		if (props.auth.emailExists === 1 && props.auth.password_reset === 0) {
			setShowPasswordInput(true);
			setValidateEmail(true);
		} else if (props.auth.emailExists === 1 && props.auth.password_reset === 1) {
			// if the user has an email and but no password (i.e. someone created an account for them)
			setResetPasswordAlert(true);
		} else if (props.auth.emailExists === 0 && props.initialData.firstName === '') {
			// the email doesn't exist
			setInvalidEmailAlert(true);
		}
	}, [props.auth.emailExists]);

	const checkValidateEmail = () => {
		let formData = new FormData();
		formData.append('login_request', 1);
		formData.append('react_login', 1);

		dispatch(requestValidateEmail({ appdir: appdir, email: email, formData: formData }));
	};

	const onEmailChange = e => {
		setEmail(e.target.value);
	};

	const showSupport = () => {
		setVisible(true);
	};

	const createAccountClick = () => {
		history.push('/createaccount');
	};

	const onInvalidEmailAlertClose = () => {
		setInvalidEmailAlert(false);
		dispatch(requestClearValidateEmail());
	};

	const onInvalidLoginAlertClose = () => {
		setInvalidLoginAlert(false);
	};

	const onInvalidTokenAlertClose = () => {
		setInvalidTokenAlert(false);
	};

	const onResetPasswordAlertClose = () => {
		setResetPasswordAlert(false);
	};

	const requestProfile = (source_hex, contact_uuid) => {
		setSubmitLoad(true);
		dispatch(requestProfileData({ source_hex: source_hex, contact_uuid: contact_uuid }));
	};

	const findAccountClick = () => {
		history.push('/findaccount');
	};

	const submitLogin = values => {
		if (validateEmail === true) {
			setSubmitLoad(true);
			let formData = new FormData();
			formData.append('email', values.email);
			formData.append('password', values.password);
			formData.append('react_login', 1);
			dispatch(
				requestAuthenticateUser({
					appdir: appdir,
					formData: formData,
				}),
			);
		}
	};

	const loginBox = loginForm => {
		return (
			<div className="gx-app-login-container" style={{ textAlign: 'center' }}>
				<Card className="gx-card">
					{loginForm && (
						<div style={{ textAlign: 'center' }}>
							<img src={loginForm.logo} />
						</div>
					)}
					<br />
					<h2
						style={{
							fontFamily: 'NoirPro, sans-serif',
							fontSize: '14px',
							color: '#535353',
							fontWeight: '600',
							textTransform: 'uppercase',
						}}
					>
						{loginForm ? loginForm.help_text : 'Enter your credentials'}
					</h2>
					<Form
						id="myForm"
						initialValues={{
							remember: loginInfo ? JSON.parse(loginInfo).email : false,
						}}
						name="basic"
						layout="vertical"
						onFinish={submitLogin}
						className="gx-signin-form gx-form-row0"
					>
						<Form.Item
							initialValue={loginInfo ? JSON.parse(loginInfo).email : ''}
							validateTrigger="onSubmit"
							rules={[
								{
									type: info.login_form
										? info.login_form.email_input_type
										: 'email',
									message: info.login_form
										? info.login_form.invalid_email_label
										: 'The input is not valid E-mail!',
								},
								{
									required: true,
									message: info.login_form
										? info.login_form.no_email_label
										: 'Please enter your email',
								},
							]}
							name="email"
						>
							<Input
								placeholder="Email"
								onChange={e => {
									onEmailChange(e);
								}}
								value={email}
							/>
						</Form.Item>
						{info.login_form &&
						info.login_form.password &&
						showPasswordInput === true ? (
							<Form.Item
								initialValue={loginInfo ? JSON.parse(loginInfo).password : ''}
								rules={[
									{
										required: true,
										message: info.login_form
											? info.login_form.no_password_label
											: 'Please enter your password!',
									},
								]}
								name="password"
							>
								<Input
									type="password"
									placeholder={
										info.login_form
											? info.login_form.password_label
											: 'Password'
									}
								/>
							</Form.Item>
						) : (
							''
						)}
						{/* <Form.Item  name="remember" valuePropName='checked'>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}
						{invalidEmailAlert && (
							<Alert
								message={
									info
										? info.login_form.email_not_found_msg
										: 'There is no account associated with this email.'
								}
								type="error"
								closable="true"
								onClose={onInvalidEmailAlertClose}
							/>
						)}
						{invalidLoginAlert && (
							<Alert
								message={
									info
										? info.login_form.incorrect_password
										: 'Incorrect login. Please enter a valid login and password.'
								}
								type="error"
								closable="true"
								onClose={onInvalidLoginAlertClose}
							/>
						)}

						{invalidTokenAlert && (
							<Alert
								message={'Token has expired.'}
								type="error"
								closable="true"
								onClose={onInvalidTokenAlertClose}
							/>
						)}
						{resetPasswordAlert === true ? (
							<Alert
								message={
									info
										? info.login_form.incomplete_password
										: 'A link has been sent to your email to reset your password.'
								}
								type="error"
								closable="true"
								onClose={onResetPasswordAlertClose}
							/>
						) : (
							''
						)}

						<Form.Item>
							{validateEmail === false ? (
								<Button
									form="myForm"
									type="default"
									// key="submit"
									// htmlType="submit"
									onClick={checkValidateEmail}
									loading={submitLoad}
									className="login-form-button gx-mt-1"
									style={{
										backgroundColor: loginForm
											? loginForm.primary_color
											: '#33b4fd',
										color: '#fff',
										width: '100%',
									}}
								>
									Continue
								</Button>
							) : (
								<Button
									form="myForm"
									type="default"
									key="submit"
									htmlType="submit"
									loading={submitLoad}
									className="login-form-button gx-mt-1"
									style={{
										backgroundColor: loginForm
											? loginForm.primary_color
											: '#33b4fd',
										color: '#fff',
										width: '100%',
									}}
								>
									{info.login_form ? info.login_form.login_button_label : 'Login'}
								</Button>
							)}
							<br />
							<br />
							<br />

							<Button
								type="default"
								htmlType="button"
								onClick={() => findAccountClick()}
								className="login-form-button gx-mt-1"
								style={{
									backgroundColor: loginForm
										? loginForm.primary_color
										: '#33b4fd',
									color: '#fff',
									width: '40%',
								}}
							>
								{info.login_form
									? info.login_form.reset_password_label
									: 'Find My Account'}
							</Button>
							<br />

							<Button
								type="default"
								htmlType="button"
								onClick={() => createAccountClick()}
								className="login-form-button gx-mt-1"
								style={{
									backgroundColor: loginForm
										? loginForm.primary_color
										: '#33b4fd',
									color: '#fff',
									width: '40%',
								}}
							>
								<IntlMessages id="app.userAuth.signUp" />
							</Button>
							<h2></h2>
						</Form.Item>

						{info.login_form && info.login_form.support_form_enabled && (
							<div
								onClick={() => showSupport()}
								style={{
									position: 'absolute',
									right: 0,
									bottom: 0,
									backgroundColor: '#CECECE',
									color: '#fff',
									borderRadius: '10px 0 0 0',
									padding: '5px 12px',
									cursor: 'pointer',
								}}
							>
								<div>Support</div>
							</div>
						)}

						<Support visible={visible} setVisible={setVisible} config={info} />
					</Form>
				</Card>
			</div>
		);
	};
	const showLoginScreen = () => {
		return (
			<Row style={{ height: '100%' }}>
				{info && !_isEmpty(info.login_form) && info.ads && info.ads.length > 0 && (
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						{loginBox(info.login_form)}
					</Col>
				)}
				{info &&
					!_isEmpty(info.login_form) &&
					((info.ads && info.ads.length == 0) || !info.ads) && (
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							{loginBox(info.login_form)}
						</Col>
					)}
			</Row>
		);
	};
	return (
		<div className="gx-app-login-wrap">
			{loader || localLoader || _isEmpty(info.login_form) ? (
				<div className="gx-loader-view">
					<CircularProgress className="gx-profile-content" />
				</div>
			) : (
				showLoginScreen()
			)}
			{showMessage ? message.error(alertMessage.toString()) : null}
		</div>
	);
};

//export default SignIn;

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

const mapDispatchToProps = dispatch => ({
	requestInitialAPI: () => dispatch(requestInitialAPI()),
});
// https://stackoverflow.com/questions/41754489/when-would-bindactioncreators-be-used-in-react-redux

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
