import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Configure } from 'react-instantsearch-dom';
import _sortBy from 'lodash/sortBy';
import { ReactComponent as Left } from 'assets/icons/left.svg';
import { Card, Button, Modal } from 'antd';
import {
    FileOutlined,
    PictureFilled,
    LinkOutlined,
} from '@ant-design/icons';
import ImageGallery from './ImageGallery';

import SearchBox from 'components/Common/Algolia/SearchBox';
import Pagination from 'components/Common/Algolia/Pagination';

import FilterSidebar from '../FilterSidebar';
import FileList from './FileLists';
import GroupSelectionResults from '../../GroupSelectionPage/GroupSelectionResults';
import { useProducts } from '../../hooks/useProducts';
import { hitsPerPage } from '../../constants';
import { FILE } from '../../../Common/constants';
import AdvancedSearch from '../../../Common/AdvanceSearch';
import { useCategory } from '../../hooks/useCategory';
import { useTags } from '../../hooks/useTags';
import { storeConsumptionSortType } from '../../../../appRedux/actions';

export const renderFileDefaultTitle = file => {
    switch (file.type) {
        case FILE.PDF:
            return 'PDF';
        case FILE.VIDEO:
            return 'Video';
        case FILE.LINK:
            return 'Link';
        case FILE.IMAGE:
            return 'Image';
        case FILE.FILE:
        default:
            return 'File';
    }
};

const BundleDetails = ({ page, setPage, handleTabChange, scrollToView, gotoProduct, selectedFile, handleSelectFile, selectedProduct, searchState }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { bundleId, productId, superBundleId } = useParams();
    const [prodsLoading, products] = useProducts();
    const [tagsEmpty, setTagsEmpty] = useState(false);
    const [cardView, setCardView] = useState(0);
    const [showImage, setShowImage] = useState(false);
    const [ImageUrl, setImageUrl] = useState('');
    const [catsLoading, categoriesMap] = useCategory();
    const [tagsLoading, tagsMap] = useTags();
    const [customRefresh, setCustomRefresh] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const sortType = useSelector((state) => state.consumption.sortType);
    const cardViewType = useSelector((state) => state.consumption.cardDisplayType);

    useEffect(() => {
        // to refresh the search box for reflect searched value in both the places
        setCustomRefresh(true);
        setTimeout(() => {
            setCustomRefresh(false);
        }, 50);
    }, [searchState?.query]);

    const bundle = useMemo(() => {
        let id = (superBundleId && !bundleId) ? superBundleId : bundleId ? bundleId : productId;
        return products.find(prod => prod.id === parseInt(id))
    }, [products]);

    const children = useMemo(
        () => _sortBy(products.filter(p => bundle?.childrenIds.some(childId => childId === p.id)), [(items) => items.title]),
        [products, bundle],
    );

    const constructTagIds = useMemo(() => children.flatMap(p => p.tagIds), [children]);
    const constructChildIds = useMemo(() => {
        if (bundle?.isDerivedParent) {
            return children.map(p => p.id);
        } else {
            return children.flatMap(p => p.childrenIds);
        }
    }, [children]);

    const loading = prodsLoading;

    const hideQvPagination = (view) => {
        setCardView(view)
    }

    const handleAttachmentItemClick = data => {
        if (data.type === FILE.IMAGE) {
            setImageUrl(data.url);
            setShowImage(true);
        } else {
            window.open(data.url);
        }
    };

    const hasAttachments = useMemo(
        () =>
            bundle?.files?.length &&
            bundle?.files?.some(
                file =>
                    ((file.hasOwnProperty('hide') ? !file.hide : true) &&
                        file.type === FILE.FILE) ||
                    ((file.hasOwnProperty('hide') ? !file.hide : true) &&
                        file.type === FILE.LINK) ||
                    ((file.hasOwnProperty('hide') ? !file.hide : true) &&
                        file.type === FILE.IMAGE) ||
                    ((file.hasOwnProperty('hide') ? !file.hide : true) && file.type === ''),
            ),
        [bundle],
    );

    const advancedSearchRefinementCount = useMemo(() => {
        let filterCount = 0;
        if (searchState?.refinementList) {
            let selectedStartYearKey = Object.entries(searchState?.refinementList).filter(([key, item]) => key === 'publicationStart' && item)?.[0];
            Object.entries(searchState?.refinementList).forEach(([key, item]) => {
                if (selectedStartYearKey && key === 'publicationEnd' && item) {
                    filterCount += 1;
                } else if (key !== 'category'
                    && key !== 'publicationStart'
                    && key !== 'publicationEnd'
                    && item?.length) {
                    filterCount += item?.length
                }
            });
        }
        return filterCount;
    }, [searchState?.refinementList]);

    const handleSearch = (value) => {
        if (value && sortType !== '2') {
            dispatch(storeConsumptionSortType({ sortType: '2' }));
        } else if (!value && sortType === '2') {
            dispatch(storeConsumptionSortType({ sortType: '0' }));
        } else {
            dispatch(storeConsumptionSortType({ sortType: '0' }))
        }
    };

    const createMarkup = () => {
        return {
            __html: bundle?.description
        };
    };

    const renderHeader = () => {


        if(!loaded) {
            setTimeout(() => {setLoaded(true)}, 400);
        }
        if(!loaded) {
            return <></>
        } else {
            return <div
            dangerouslySetInnerHTML={createMarkup()}
            className="gx-mb-0"
            // style={{height: 200}}
                >
                </div>
        }

    }
    return (
        <div>
            {bundle?.description || hasAttachments ? (
                <Card className="shadow-1 gx-pointer gx-mb-0 gx-rounded-lg gx-mt-3" >

                    {renderHeader()}

                    <div>
                        {hasAttachments ? (
                            <div
                                style={{ borderRadius: '5px' }}
                                className="gx-pointer gx-mt-3"
                            >
                                <h4 className="gx-w-100 gx-font-weight-bold fg-grey">
                                    Attachments
                                </h4>
                                {bundle?.files?.map((data, index, files) => {
                                    return ((data.hasOwnProperty('hide') ? !data.hide : true) &&
                                        data.type === FILE.IMAGE) ||
                                        ((data.hasOwnProperty('hide') ? !data.hide : true) &&
                                            data.type === FILE.LINK) ||
                                        ((data.hasOwnProperty('hide') ? !data.hide : true) &&
                                            data.type === FILE.FILE) ||
                                        ((data.hasOwnProperty('hide') ? !data.hide : true) &&
                                            data.type === '') ? (
                                        <Button
                                            type="secondary"
                                            onClick={() => handleAttachmentItemClick(data)}
                                            icon={
                                                data.type === FILE.IMAGE ? (
                                                    <PictureFilled />
                                                ) : data.type === FILE.LINK ? (
                                                    <LinkOutlined />
                                                ) : (
                                                    <FileOutlined />
                                                )
                                            }
                                        >
                                            {data.title ? data.title : renderFileDefaultTitle(data)}
                                        </Button>
                                    ) : null;
                                })}
                            </div>
                        ) : null}
                    </div>
                </Card>
            ) : null}
            {bundle?.files?.length ? (
                <div className='gx-w-100'>
                    {selectedFile?.type && bundle.isBundle && bundle.isSuperBundle && superBundleId ? <div
                        onClick={() => handleSelectFile(null)}
                        className="gx-p-3 gx-pointer gx-mr-2 gx-d-flex"
                    >
                        <Left className="gx-my-auto" style={{ width: '.9rem' }} />
                    </div> : null}
                    <div className='gx-mt-3'>
                        <FileList
                            bundle={bundle}
                            selectedFile={selectedFile}
                            handleSelectFile={handleSelectFile}
                        />
                    </div>
                </div>
            ) : null}
            {showImage ? (
                <Modal
                    title=""
                    visible={showImage}
                    footer={null}
                    onCancel={() => {
                        setImageUrl(false);
                        setShowImage(false);
                    }}
                >
                    <ImageGallery
                        url={ImageUrl}
                        style={{
                            maxHeight: '25rem',
                        }}
                    />
                </Modal>
            ) : null}
            {((bundle?.description || hasAttachments) && children.length) ? <hr /> : <div className='gx-py-2'></div>}
            {/* section to display for the grand parent */}
            {bundle?.isSuperBundle && superBundleId && !bundleId && !productId && children.length ? (
                <main className="gx-py-2 gx-d-flex gx-justify-content-end gx-w-100">
                    <section className="gx-flex-1 gx-w-100">
                        <div className="gx-mb-3 gx-d-flex" style={{ gap: 8 }} >
                            {constructTagIds.length
                                ? (<FilterSidebar tags onTags={tags => setTagsEmpty(tags.length === 0)} />)
                                : null
                            }
                            <SearchBox customRefresh={customRefresh} />
                            {cardViewType !== 2 ? <FilterSidebar sortBy /> : null}
                        </div>
                        {bundle.quick_view_only ? <div>
                            <AdvancedSearch
                                categoriesMap={categoriesMap}
                                tagsMap={tagsMap}
                                searchStateValues={searchState}
                                advancedRefinementCount={advancedSearchRefinementCount}
                                customRefresh={customRefresh}
                                quickViewOnly={bundle.quick_view_only}
                                handleCustomSearch={handleSearch}
                            />
                        </div> : null}
                        <div className="gx-mb-4">
                            <GroupSelectionResults
                                loading={loading}
                                gotoProduct={gotoProduct}
                                bundle={bundle}
                                hideQvPagination={hideQvPagination}
                            // cardView={cardView}
                            />
                        </div>
                        <Configure hitsPerPage={cardView === 2 ? constructChildIds.length : hitsPerPage} />

                        <div className="gx-text-center">
                            <Pagination onPageChange={scrollToView} cardView={cardView} />
                        </div>
                    </section>
                </main>
            ) : null}
        </div>
    )
};

export default BundleDetails;
