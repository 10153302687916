import React, { memo, useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import CreateAccount from '../CreateAccount';
import FindAccount from '../FindAccount';
import ExistingAccounts from '../../components/FindAccount/ExistingAccounts';
import Register from '../../components/CreateAccount/Register';

import RetrieveAccount from '../../components/FindAccount/RetrieveAccount';
import TokenLogin from '../../components/FindAccount/TokenLogin';
import ResetPassword from '../../components/FindAccount/ResetPassword';

import CheckoutSignIn from '../../components/Ecommerce/CheckoutSignin/CheckoutSignin';
import SelectionPage from '../../components/Ecommerce/Store/SelectionPage/SelectionPage';
import FreeCheckout from '../../components/Ecommerce/Checkout/FreeCheckout';
import BundlePage from '../../components/Ecommerce/Store/BundlePage/BundlePage.jsx';
import ProductPage from '../../components/Ecommerce/Store/ProductPage/ProductPage.jsx';

import ConsumptionSelectionPage from 'components/Consumption/SelectionPage';
import ConsumptionBundlePage from 'components/Consumption/BundlePage';
import ConsumptionProductPage from 'components/Consumption/ProductPage';
import ConsumptionGroupConsumptionPage from 'components/Consumption/GroupConsumptionPage';
import ConsumptionGroupSelectionPage from 'components/Consumption/GroupSelectionPage';

import RegistrationSelection from '../../components/Registration/index';
import StartHome from '../../components/Registration/Start/index';
import RegistrationCheckout from '../../components/Registration/Checkout/index';

import auth from '../../loginAuth/loginAuth';
import setGenerics from './../setGeneric';

import {
	onLayoutTypeChange,
	onNavStyleChange,
	setThemeType,
	onPublicEmbedChange,
	setInitUrl,
	updateAppdir,
} from 'appRedux/actions';

import CircularProgress from '../../components/CircularProgress';
import _get from 'lodash/get';

import ExhibitorFinancialItems from '../../components/CorporateMembership/ExhibitorFinancialItems';
import ExhibitorCompanyProfile from '../../components/CorporateMembership/ExhibitorCompanyProfile';
import ExhibitorCheckout from '../../components/CorporateMembership/ExhibitorCheckout';
import ExhibitorStaff from '../../components/CorporateMembership/ExhibitorStaff';

import {
	LAYOUT_TYPE_BOXED,
	LAYOUT_TYPE_FRAMED,
	LAYOUT_TYPE_FULL,
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '../../constants/ThemeSetting';

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			authUser ? ( // if I put authUser here instead of true, it redirects to signing but the sidebar doesn't show. Currently there is a cross origin error.
				<Component {...props} />
			) : (
				//setting previous location whenever we do a refresh
				(auth.setCookie('previous_location', JSON.stringify(location.pathname), 7),
				(
					<Redirect
						to={{
							pathname: '/signin',
							state: { from: location },
						}}
					/>
				))
			)
		}
	/>
);

//if authUser exist, let him redirect all the routes he wants to
const App = props => {
	const dispatch = useDispatch();
	const { locale, navStyle, layoutType, publicEmbed } = useSelector(({ settings }) => settings);
	// use the settings in redux to get embed true or false
	const { authUser, initURL, jwt } = useSelector(({ auth }) => auth);
	const { login_form, styling } = useSelector(({ loginInfo }) => loginInfo);
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();
	const [embedParam, setEmbedParam] = useState(false);

	document
		.querySelector(':root')
		.style.setProperty('--primary', `${_get(login_form, 'primary_color', '')}`);

	useEffect(() => {
		document.title = auth.getCookie('app_dir_title');
	}, []);

	useEffect(() => {
		setGenerics({ styling: styling });
	}, [styling]);

	useEffect(() => {
		if (initURL === '') {
			dispatch(setInitUrl(location.pathname));
		}
		const params = new URLSearchParams(location.search);

		if (params.has('theme')) {
			dispatch(setThemeType(params.get('theme')));
		}
		if (params.has('nav-style')) {
			dispatch(onNavStyleChange(params.get('nav-style')));
		}
		if (params.has('layout-type')) {
			dispatch(onLayoutTypeChange(params.get('layout-type')));
		}
		setLayoutType(layoutType);
		setNavStyle(navStyle);
	});

	useEffect(() => {
		let myPathname = history.location.pathname;
		let mySearch = history.location.search;

		const appdir = window.location.hostname.substr(0, window.location.hostname.indexOf('.'));
		dispatch(updateAppdir(appdir.includes('app') ? appdir.split('app')[0] : appdir));
		if (
			myPathname.includes('public_embed=1') ||
			(mySearch.includes('public_embed=1') && embedParam !== true)
		) {
			dispatch(onPublicEmbedChange(true));
			setEmbedParam(true);
		}
	}, []);

	// useEffect watches on the history
	// create another action inside settings

	const setLayoutType = layoutType => {
		if (layoutType === LAYOUT_TYPE_FULL) {
			document.body.classList.remove('boxed-layout');
			document.body.classList.remove('framed-layout');
			document.body.classList.add('full-layout');
		} else if (layoutType === LAYOUT_TYPE_BOXED) {
			document.body.classList.remove('full-layout');
			document.body.classList.remove('framed-layout');
			document.body.classList.add('boxed-layout');
		} else if (layoutType === LAYOUT_TYPE_FRAMED) {
			document.body.classList.remove('boxed-layout');
			document.body.classList.remove('full-layout');
			document.body.classList.add('framed-layout');
		}
	};

	const setNavStyle = navStyle => {
		if (
			navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
			navStyle === NAV_STYLE_DARK_HORIZONTAL ||
			navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
			navStyle === NAV_STYLE_ABOVE_HEADER ||
			navStyle === NAV_STYLE_BELOW_HEADER
		) {
			document.body.classList.add('full-scroll');
			document.body.classList.add('horizontal-layout');
		} else {
			document.body.classList.remove('full-scroll');
			document.body.classList.remove('horizontal-layout');
		}
	};

	useEffect(() => {
		const nonJwtRoutes = [
			'/signin',
			'/createaccount',
			'/findaccount',
			'/existingaccounts',
			'/register',
			'/retrieveaccount',
			'/tokenlogin',
			'/resetpassword',
			'corporateMembershipTokenLogin',
		];

		if (location.pathname == '/') {
			if (authUser === null) {
				history.push('/signin');
			} else if (initURL === '' || initURL === '/' || initURL === '/signin') {
				history.push('/main/profilehome');
			} else {
				// history.push(initURL);
			}
		} else if (
			location.pathname !== '/' &&
			location.pathname !== '' &&
			!location.search?.includes('public_embed=1') &&
			!location.search?.includes('token') &&
			jwt === '' &&
			!publicEmbed &&
			!nonJwtRoutes.includes(location.pathname) // if pathname does not equal to any of the nonJwtRoutes above.
		) {
			history.push('/signin');
		}
	}, [authUser, initURL, location, history, jwt]);

	const currentAppLocale = AppLocale[locale.locale];

	// on checkout, need to signin or create profile with new api
	// iframe resizer
	// add to click not called api, just add to redux
	// checkout clicks directly to find user/ create profile

	return (
		<ConfigProvider locale={currentAppLocale.antd}>
			<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
				<Suspense fallback={<CircularProgress className="gx-profile-content" />}>
					<Switch>
						<Route exact path="/signin" component={SignIn} />
						<Route exact path="/createaccount" component={CreateAccount} />
						<Route exact path="/findaccount" component={FindAccount} />
						<Route exact path="/existingaccounts" component={ExistingAccounts} />
						<Route exact path="/register" component={Register} />
						<Route exact path="/retrieveaccount" component={RetrieveAccount} />
						<Route exact path="/tokenlogin" component={TokenLogin} />
						<Route exact path="/resetpassword" component={ResetPassword} />

						{/* Consumption */}
						<Route
							exact
							path={`/public/consumption/product/:productId`}
							component={ConsumptionProductPage}
						/>
						<Route
							exact
							path={`/public/consumption/bundle/:bundleId`}
							component={ConsumptionBundlePage}
						/>
						<Route
							exact
							path={`/public/consumption/bundle/:bundleId/:productId`}
							component={ConsumptionBundlePage}
						/>
						<Route
							path={`/public/consumption/group/:superBundleId/:bundleId/:productId`}
							component={ConsumptionGroupConsumptionPage}
						/>
						<Route
							path={`/public/consumption/group/:superBundleId/:bundleId`}
							component={ConsumptionGroupConsumptionPage}
						/>
						<Route
							path={`/public/consumption/selection/:superBundleId`}
							component={ConsumptionGroupSelectionPage}
						/>
						<Route
							path="/public/consumption/selection"
							component={ConsumptionSelectionPage}
						/>

						{/* ecommerce */}

						<Route
							path="/public/ecommerce/:moduleUrlId/selection"
							component={SelectionPage}
						/>
						<Route
							path="/public/ecommerce/:moduleUrlId/checkout"
							component={FreeCheckout}
						/>

						<Route
							path={'/public/ecommerce/:moduleUrlId/bundle/:bundleId'}
							component={BundlePage}
						/>

						<Route
							path={'/public/ecommerce/:moduleUrlId/product/:productId'}
							component={ProductPage}
						/>

						<Route
							path="/public/ecommerce/checkout/signin"
							component={CheckoutSignIn}
						/>
						{/* registration */}
						<Route
							path="/public/registration/:moduleUrlId/checkout"
							component={RegistrationCheckout}
						/>

						<Route
							path="/public/registration/checkout/signin"
							component={CheckoutSignIn}
						/>

						<Route
							path="/public/registration/:moduleUrlId/selection"
							component={RegistrationSelection}
						/>

						<Route
							path="/public/registration/:moduleUrlId/starthome"
							component={StartHome}
						/>

						{/* membership */}
						<Route
							path="/main/public/company/:moduleUrlId/registration"
							component={ExhibitorFinancialItems}
						/>

						<Route
							path="/main/public/company/:moduleUrlId/SubMenu"
							component={ExhibitorFinancialItems}
						/>
						<Route
							path="/main/public/company/:moduleUrlId/contactinfo"
							component={ExhibitorCompanyProfile}
						/>
						<Route
							path="/main/public/company/:moduleUrlId/staff"
							component={ExhibitorStaff}
						/>

						<Route
							path="/main/public/company/:moduleUrlId/checkout"
							component={ExhibitorCheckout}
						/>

						<RestrictedRoute
							path={`${match.url}`}
							authUser={authUser}
							location={location}
							component={MainApp}
						/>
					</Switch>
				</Suspense>
			</IntlProvider>
		</ConfigProvider>
	);
};

export default memo(App);
