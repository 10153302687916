import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import auth from '../../../loginAuth/loginAuth';

import { Col, Row, Card, Button, Input } from 'antd';
import MainSection from './MainSection';
import { useParams } from 'react-router';

import { requestProfileData } from '../../../appRedux/actions/LoginPageInfo';
import {
	requestCartInfo,
	requestPaymentSettings,
	requestResetRegistrationUpdated,
} from '../../../appRedux/actions/Ecommerce';

import { requestAttendeeSettings } from '../../../appRedux/actions/EcommerceStore';

import { useHistory } from 'react-router-dom';

import { getRegistrationPublicRoute, getRegistrationRoute } from '../helpers';

import {
	requestAttenuuidInfo,
	requestInvoiceInfo,
	requestSelectionArray,
	requestSetScreenNum,
} from '../../../appRedux/actions/Ecommerce';

import PaymentOptions from '../../Common/PaymentOptions';
import PayNow from '../../Common/PayNow';

import Widget from '../../Widget';
import CircularProgress from '../../CircularProgress';

import { ReactComponent as RegistrationModify } from '../../../assets/icons/RegistrationModify.svg';
import { ReactComponent as RegistrationInvoice } from '../../../assets/icons/RegistrationInvoice.svg';
import { ReactComponent as AttendeeMail } from '../../../assets/icons/AttendeeMail.svg';
import { ReactComponent as RegistrationComplete } from '../../../assets/icons/RegistrationComplete.svg';
import { ReactComponent as AttendeePending } from '../../../assets/icons/AttendeePending.svg';
import { ReactComponent as AttendeeCancelled } from '../../../assets/icons/AttendeeCancelled.svg';
import { ReactComponent as AttendeeIncomplete } from '../../../assets/icons/AttendeeIncomplete.svg';

const StartHome = props => {
	const [loader, setLoader] = useState(true);
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');
	const [paymentResult, setPaymentResult] = useState(false);

	const dispatch = useDispatch();
	const publicEmbed = useSelector(state => state.settings.publicEmbed);

	const firstName = useSelector(state => state.loginInfo.firstName);
	const lastName = useSelector(state => state.loginInfo.lastName);

	const appdir = useSelector(state => state.loginInfo.appdir);
	const attenuuid = useSelector(state => state.ecommerce.attenuuid);
	const cartItems = useSelector(state => state.ecommerce.cartItems);
	const invoiceInfo = useSelector(state => state.ecommerce.invoiceInfo);

	const oldCartItems = useSelector(state => state.ecommerce.oldCartItems);
	const paymentSettings = useSelector(state => state.ecommerce.paymentSettings);
	const paymentSettingsFetched = useSelector(state => state.ecommerce.paymentSettingsFetched);

	const attendeeSettings = useSelector(state => state.ecommerce.attendeeSettings);
	const progress = useSelector(state => state.ecommerce.progress);
	const status = useSelector(state => state.ecommerce.status);
	const selectionArrayFetched = useSelector(state => state.ecommerce.selectionArrayFetched);
	const editableMenuObjects = useSelector(state => state.ecommerce.editableMenuObjects);
	const cartInfoFetched = useSelector(state => state.ecommerce.cartInfoFetched);
	const attendeeSettingsFetched = useSelector(state => state.ecommerce.attendeeSettingsFetched);

	const { moduleUrlId } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!attendeeSettingsFetched) {
			dispatch(requestAttendeeSettings({ appdir, moduleUrlId }));
		}
		if (!paymentSettingsFetched) {
			dispatch(
				requestPaymentSettings({
					appdir,
					moduleUrlId,
					publicEmbed,
				}),
			);
		}
	}, []);

	useEffect(() => {
		if (attenuuid && appdir && !cartInfoFetched) {
			dispatch(
				requestCartInfo({
					appdir,
					moduleUrlId,
					attenuuid,
				}),
			);
		}
	}, [attenuuid, appdir]);

	useEffect(() => {
		// make api call to get all user info if it is not in Redux already
		if (!firstName) {
			dispatch(
				requestProfileData({
					source_hex: JSON.parse(auth.getCookie('source_hex')),
					contact_uuid: JSON.parse(auth.getCookie('contact_uuid')),
				}),
			);
		}
	}, []);

	useEffect(() => {
		// add some config here.. if config doesn't exist.. push to module to skip start page
		// cartItems.PaymentItemsArray?.length === 0 will have to be coming else
		// if checkout array !== 0 or if status === complete or something...
		// ask Chris in include free items to PaymentItemsArray?

		if (cartItems.PaymentItemsArray?.length === 0) {
			history.push(
				(publicEmbed ? getRegistrationPublicRoute : getRegistrationRoute)(moduleUrlId),
			);
		}
	}, [cartItems]);

	useEffect(() => {
		if (!props.ecommerce.attenuuidInfoFetched) {
			dispatch(requestAttenuuidInfo({ appdir, moduleUrlId }));
		}

		if (attenuuid && !invoiceInfo) {
			dispatch(
				requestInvoiceInfo({
					appdir,
					moduleUrlId,
					attenuuid,
				}),
			);
		}
		if (!selectionArrayFetched) {
			dispatch(requestSelectionArray({ appdir, moduleUrlId }));
		}
	}, [props.ecommerce.openShoppingStyle, invoiceInfo, attenuuid]);

	useEffect(() => {
		dispatch(
			requestPaymentSettings({
				appdir,
				moduleUrlId,
			}),
		);
		dispatch(requestResetRegistrationUpdated());
	}, []);

	useEffect(() => {
		if (Object.keys(paymentSettings).length > 0 && selectionArrayFetched) {
			setLoader(false);
		}
	}, [paymentSettings, selectionArrayFetched]);

	const pushToRegistration = screenNum => {
		dispatch(requestSetScreenNum({ screenNum }));
		history.push(getRegistrationRoute(moduleUrlId));
	};

	const onDownloadInvoice = () => {
		window.open(
			`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=attendee&component=attendees&function=download_invoice&moduleurlid=${moduleUrlId}&attenuuid=${attenuuid}`,
		);
	};

	// TO DO - payment settings test half paid item. If netowing == 0 the payment options component doesnt show
	// also were do we have the PayNow component?

	return (
		<div className="">
			{loader && (
				<Widget styleName="gx-card">
					<CircularProgress className="gx-profile-content" />
				</Widget>
			)}
			<div className="gx-ml-5 gx-mt-3">
				<Row>
					<Col xl={12} lg={12} md={24} sm={24} xs={24}>
						<Card className="card">
							<h2 className="gx-text-primary">
								USCAP Annual Meeting 2020 - Hardcoded
							</h2>
							<h3>
								Name: {firstName} {lastName}
							</h3>
							<div className="gx-d-flex gx-align-items-center">
								<h3>Registration Status: {status}</h3>
								{status === 'Complete' ? (
									<RegistrationComplete
										className="gx-ml-2 gx-mb-2 fg-success"
										style={{ width: '1.5rem' }}
									/> // if
								) : status === 'Incomplete' ? (
									<AttendeeIncomplete
										className="gx-ml-2 gx-mb-2 fg-warning"
										style={{ width: '1.5rem' }}
									/>
								) : status === 'Cancelled' ? (
									<AttendeeCancelled
										className="gx-ml-2 gx-mb-2 fg-danger"
										style={{ width: '1.5rem' }}
									/>
								) : status === 'Pending' ? (
									<AttendeePending
										className="gx-ml-2 gx-mb-2 fg-success"
										style={{ width: '1.5rem' }}
									/>
								) : (
									''
								)}
							</div>
						</Card>

						<MainSection companyHtml={invoiceInfo} />
						{parseFloat(cartItems?.NetOwing) > 0 && (
							<PaymentOptions
								renderPaymentComponent={setCurrentPaymentMethod}
								currentPaymentMethod={currentPaymentMethod}
								cartItems={oldCartItems || cartItems}
								paymentSettings={paymentSettings}
							/>
						)}
					</Col>
					<Col xl={12} lg={12} md={24} sm={24} xs={24}>
						<Card className="card" style={{ width: '80%' }}>
							<div className="gx-d-flex gx-align-items-center gx-justify-content-between">
								<h2>Modify Registration</h2>
								<RegistrationModify
									className="gx-ml-3 gx-mb-3"
									style={{ width: '1.5rem', fill: 'white' }}
								/>
							</div>
							<p>
								{progress === 'in_progress' && attendeeSettings ? (
									<div
										dangerouslySetInnerHTML={{
											__html: attendeeSettings?.seestore?.eventdescript,
										}}
									></div>
								) : (
									'Start Browsing'
								)}
							</p>

							{editableMenuObjects.map(item => {
								return (
									<Button
										className="login-form-button gx-mt-1 gx-mr-5 button-primary"
										shape={'round'}
										onClick={() => pushToRegistration(item.screen)}
									>
										{item.button}
									</Button>
								);
							})}
						</Card>
						<Card className="card" style={{ width: '80%' }}>
							<div className="gx-d-flex gx-align-items-center gx-justify-content-between">
								<h2>Invoice Options</h2>
								<RegistrationInvoice
									className="gx-ml-3 gx-mb-3"
									style={{ width: '1.5rem', fill: 'white' }}
								/>
							</div>

							<div style={{ marginBottom: '16px', display: 'flex' }}>
								<Input defaultValue="email" />
								<AttendeeMail className="gx-mb-1" style={{ width: '3rem' }} />
							</div>

							<Button
								className="login-form-button gx-mt-1 gx-mr-5 button-primary"
								shape={'round'}
								onClick={onDownloadInvoice}
							>
								Download Invoice
							</Button>
						</Card>
						<div style={{ width: '80%' }}>
							<PayNow
								cartItems={Object.keys(cartItems).length ? cartItems : oldCartItems}
								paymentSettings={paymentSettings}
								netOwing={
									Object.keys(cartItems).length
										? cartItems?.NetOwing
										: oldCartItems?.NetOwing
								}
								totalPaid={
									Object.keys(cartItems).length
										? cartItems?.TotalPayment
										: oldCartItems?.TotalPayment
								}
								attenuuid={attenuuid}
								moduleIdentifier={'ecommerce'}
								appdir={appdir}
								currentPaymentMethod={currentPaymentMethod}
								setCurrentPaymentMethod={setCurrentPaymentMethod}
								returnPaymentApproved={setPaymentResult}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		ecommerce: myState.ecommerce,
		settings: myState.settings,
	};
};

export default connect(mapStateToProps)(StartHome);
