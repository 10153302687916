import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { requestMenuData } from 'appRedux/actions';

export const useMenu = () => {
	const dispatch = useDispatch();
	const publicEmbed =
		useSelector(state => state.settings.publicEmbed) ||
		window.location?.search?.includes('public_embed=1');

	const appDir = useSelector(state => state.loginInfo.appdir);
	const compUuid = useSelector(
		state => state.corporateMembership.userCompanyAffiliation.comp_uuid,
	);

	const menu = useSelector(state => state.corporateMembership.menu);
	const generalSettings = useSelector(state => state.corporateMembership.generalSettings);
	const languageObject = useSelector(state => state.corporateMembership.languageObject);
	const jwt = useSelector(state => state.auth.jwt);

	const location = useSelector(state => state.router.location);
	const isTokenInUrl = location?.search?.includes('token');

	const exhibUuid = useSelector(
		state => state.corporateMembership.exhibitorCartDetails?.exhibUuid,
	);

	// loading is not fetched.
	const loading = !useSelector(state => state.corporateMembership.menuFetched);

	const { moduleUrlId } = useParams();

	useEffect(() => {
		if (isTokenInUrl) {
			if (appDir && jwt && (publicEmbed || compUuid)) {
				dispatch(
					requestMenuData({
						appDir,
						urlId: moduleUrlId,
						compUuid,
						publicEmbed,
						exhibUuid,
						isTokenInUrl,
					}),
				);
			}
		} else {
			if (appDir && (publicEmbed || compUuid)) {
				dispatch(
					requestMenuData({
						appDir,
						urlId: moduleUrlId,
						compUuid,
						publicEmbed,
						exhibUuid,
					}),
				);
			}
		}
	}, [appDir, compUuid, publicEmbed, jwt]);

	return [loading, menu, generalSettings, languageObject];
};
