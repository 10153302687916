import React, { useState, useEffect } from 'react';
import { Card, Collapse, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { FILE } from 'components/Common/constants';
import VideoPlayer from './VideoPlayer';
import PDFViewer from './PDFViewer';
import OfficeFileViewer from './OfficeFileViewer';
import axios from 'axios';
import auth from '../../../../loginAuth/loginAuth';
import { requestVideoSeekTime } from '../../../../appRedux/actions';

const { Panel } = Collapse;

const PresentationFileCard = ({ isPublic, expanded, product, consumptionConfig, onHandleVideoPlay, handleOpen, renderFileDefaultTitle, onHandleVideoStart = () => { }, onHandleVideoEnd = () => { } }) => {
	const [isLoading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const appdir = useSelector(state => state.loginInfo.appdir);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 10);
	}, [product]);


	useEffect(() => {
		getSeekTime();
	}, []);

	const getSeekTime = async () => {
		let contactUId = JSON.parse(auth.getCookie('contact_uuid'))
		dispatch(requestVideoSeekTime({ contactUId, id: product?.id, appdir }));
	}

	return (
		<Card
			style={{ borderRadius: '5px' }}
			className="shadow-1 gx-pointer gx-mb-0 gx-rounded-lg"
			bodyStyle={{ padding: '10px' }}
		>
			{isLoading
				? <div className="gx-d-flex gx-justify-content-center"><Spin /></div>
				: <>
					{product?.files
						?.sort(a => (a.type === 'video' ? -1 : 1))
						.map((data, index, files) => {
							return ((data.hasOwnProperty('hide') ? !data.hide : true) &&
								data.type === FILE.VIDEO) ||
								((data.hasOwnProperty('hide') ? !data.hide : true) &&
									data.type === FILE.PDF) ||
								((data.hasOwnProperty('hide') ? !data.hide : true) &&
									data.type === FILE.FILE) ? (
								<Collapse
									key={index}
									defaultActiveKey={
										files[0]?.type === 'video' ? `${files[0]?.fieldid}` : ''
									}
									className={index !== 0 ? 'gx-mt-3' : ''}
									destroyInactivePanel
									onChange={e => handleOpen(data, e)}
									// onChange={handleOpen}
								>
									<Panel
										header={
											data.title ? data.title : renderFileDefaultTitle(data)
										}
										key={
											files[index]?.type === 'video'
												? `${data.fieldid}`
												: `${product.id}_${data.fieldid}`
										}
									>
										{(data.hasOwnProperty('hide') ? !data.hide : true) &&
										data.type === FILE.VIDEO ? (
											<VideoPlayer
												url={data.url ?? data?.video_url}
												expanded={expanded}
												style={{
													height: '25rem',
												}}
												transcript={data.transcript}
												product={product}
												onHandleVideoStart={onHandleVideoStart}
												onHandleVideoEnd={onHandleVideoEnd}
												onHandleVideoPlay={onHandleVideoPlay}
											/>
										) : null}
										{(data.hasOwnProperty('hide') ? !data.hide : true) &&
										data.type === FILE.PDF ? (
											<PDFViewer
												url={data.url}
												download={
													data.download ??
													consumptionConfig?.downloadPdf ??
													false
												}
												fullscreen={!isPublic}
												style={{
													height: '25rem',
												}}
											/>
										) : null}
										{(data.hasOwnProperty('hide') ? !data.hide : true) &&
										data.type === FILE.FILE ? (
											['mp4', 'wmv', 'mov'].includes(
												data.url.split('.').pop().toLowerCase(),
											) ? (
												<VideoPlayer
													url={data.url}
													expanded={expanded}
													style={{
														height: '25rem',
													}}
													transcript= {data.transcript}
												/>
											) : ['pdf', 'pdfx'].includes(
													data.url.split('.').pop().toLowerCase(),
											  ) ? (
												<PDFViewer
													url={data.url}
													download={
														data.download ??
														consumptionConfig?.downloadPdf ??
														false
													}
													fullscreen={!isPublic}
													style={{
														height: '25rem',
													}}
												/>
											) : (
												<OfficeFileViewer
													url={data.url}
													expanded={expanded}
													style={{
														height: '25rem',
													}}
												/>
											)
										) : null}
									</Panel>
								</Collapse>
							) : null;
						})}
				</>}
		</Card>
	);
}

export default PresentationFileCard;
