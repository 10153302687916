import auth from '../loginAuth/loginAuth';

const xcdapi = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';

// returns attenuuid
const getAttenuuidInfo = async (appdir, moduleUrlId) => {
	let attenuuidData = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=start&URLID=${moduleUrlId}`,
		{
			method: 'GET',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let attenuuidDataJson = await attenuuidData.json();

	return attenuuidDataJson;
};

// returns the invoice HTML output
const getInvoiceInfo = async (appdir, moduleUrlId, attenuuid) => {
	let invoiceData = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=see_invoice_file&URLID=${moduleUrlId}&attenuuid=${attenuuid}&miniversion=1`,
		{
			method: 'GET',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let invoiceDataJson = await invoiceData.json();
	return invoiceDataJson;
};

/*
returns the attenmenu or the selection array depending on the moduleUrlid
also returns openshoppingcart as a boolean
*/
const getSelectionArray = async (appdir, moduleUrlId, publicEmbed) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendee_menu&function=start&URLID=${moduleUrlId}${
			publicEmbed ? `&public=1` : ''
		}`,
		{
			method: 'GET',
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

//this gets the cartitems
// add &public=1 if publicEmbed &cartUuid

const getCartInfo = async (appdir, moduleUrlId, attenuuid, publicEmbed, cartUuid) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=attendee_data&URLID=${moduleUrlId}${
			!attenuuid ? `&public=1&cart_uuid=${cartUuid}` : `&attenuuid=${attenuuid}`
		}`,
		{
			method: 'GET',
			headers: publicEmbed
				? {}
				: {
						Authorization: JSON.parse(auth.getCookie('jwt')),
				  },
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

/* need seperate apis for registration add to cart and free selection add to cart because for registration, in the reducer we change the screen count */
const addToRegistrationCart = async (
	appdir,
	moduleUrlId,
	attenuuid,
	formData,
	cartUuid,
	publicEmbed,
	existingPayment,
) => {
	// add &public=1 if publicEmbed and cartuuuid

	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=add_to_cart&moduleurlid=${moduleUrlId}${
			publicEmbed ? `&public=1&cart_uuid=${cartUuid}` : `&attenuuid=${attenuuid}`
		}`,
		{
			method: 'POST',
			headers: publicEmbed
				? {}
				: {
						Authorization: JSON.parse(auth.getCookie('jwt')),
				  },
			body: formData,
		},
	);
	let resultJson = await result.json();
	resultJson.existingPayment = existingPayment;
	return resultJson;
};

const addToSelectionCart = async (appdir, moduleUrlId, attenuuid, formData, checkoutForm) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=add_to_cart&moduleurlid=${moduleUrlId}&attenuuid=${attenuuid}${
			checkoutForm === 1 ? `&checkout_form=1` : ''
		}`,
		{
			method: 'POST',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

// delete for cart api, handled the same for both registration and free selection
const deleteCartItem = async (appdir, moduleUrlId, attenuuid, formData) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=delete_from_cart&URLID=${moduleUrlId}&attenuuid=${attenuuid}`,
		{
			method: 'POST',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getPaymentSettings = async (moduleUrlId, appdir, publicEmbed) => {
	let result = await fetch(
		`${xcdapi}?module=attendee&component=attendee_menu&function=payment_settings&URLID=${moduleUrlId}&appdir=${appdir}`,
		{
			method: 'GET',
			headers: publicEmbed
				? {}
				: {
						Authorization: JSON.parse(auth.getCookie('jwt')),
				  },
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const addToSelectionCartNoLogin = async (appdir, moduleUrlId, formData, cartUuid) => {
	let result = await fetch(
		`${xcdapi}?module=attendee&component=attendees&function=add_to_cart&moduleURLID=${moduleUrlId}&public=1&appdir=${appdir}&cart_uuid=${cartUuid}`,
		{
			method: 'POST',
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getPublicToPrivateCart = async (moduleUrlId, cartUuid, attenuuid) => {
	let result = await fetch(
		`${xcdapi}?module=attendee&component=attendees&function=publicCartToPrivate&moduleURLID=${moduleUrlId}&cart_uuid=${cartUuid}&attenuuid=${attenuuid}`,
		{
			method: 'POST',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getDeleteNonProcessedPayment = async (moduleUrlId, attenuuid, formData) => {
	let result = await fetch(
		`${xcdapi}?appdir=demo&module=attendee&component=attendees&function=delete_payment&URLID=${moduleUrlId}&attenuuid=${attenuuid}`,
		{
			method: 'POST',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const EcommerceAPIs = {
	getAttenuuidInfo,
	getInvoiceInfo,
	getSelectionArray,
	getCartInfo,
	addToRegistrationCart,
	addToSelectionCart,
	deleteCartItem,
	getPaymentSettings,
	addToSelectionCartNoLogin,
	getPublicToPrivateCart,
	getDeleteNonProcessedPayment,
};

export default EcommerceAPIs;
