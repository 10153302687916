import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { PlayCircleFilled, PictureFilled, FilePdfFilled, LinkOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FILE } from 'components/Common/constants';
import { ReactComponent as Left } from 'assets/icons/left.svg';
import VideoPlayer from './VideoPlayer';
import PDFViewer from './PDFViewer';
import ImageGallery from './ImageGallery';
import { storeFileAnalysisRequest } from '../../../../appRedux/actions';
import auth from '../../../../loginAuth/loginAuth';

export const renderFileIcon = file => {
	switch (file.type) {
		case FILE.PDF:
			return <FilePdfFilled />;
		case FILE.VIDEO:
			return <PlayCircleFilled />;
		case FILE.LINK:
			return <LinkOutlined />;
		case FILE.IMAGE:
		default:
			return <PictureFilled />;
	}
};

export const renderFileDefaultTitle = file => {
	switch (file.type) {
		case FILE.PDF:
			return 'PDF';
		case FILE.VIDEO:
			return 'Video';
		case FILE.LINK:
			return 'Link';
		case FILE.IMAGE:
			return 'Image';
		case FILE.FILE:
		default:
			return 'File';
	}
};

const FileList = ({ bundle, selectedFile, expanded, handleSelectFile }) => {
	const [isPublic, setIsPublic] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const appdir = useSelector(state => state.loginInfo.appdir);
	const contactUid = useSelector(state => state.auth.contact_uuid);
	const consumptionConfig = useSelector(state => state.consumption.config);
	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

	useEffect(() => {
		const params = new URLSearchParams(history.location.search);
		setIsPublic(!!params.get('public_embed'));
	}, [history]);

	useEffect(() => {
		// File Analysis api depends on file selection
		if (selectedFile && (selectedFile.hasOwnProperty('hide') ? !selectedFile.hide : true)) {
			dispatch(
				storeFileAnalysisRequest({
					appdir,
					contactUid: contactUid === '' ? JSON.parse(auth.getCookie('contact_uuid')) : contactUid,
					productId: bundle.id,
					title: selectedFile?.url ?? '',
					fileSize: selectedFile?.file_size ?? 0,
				}),
			);
		}
	}, [selectedFile]);

	return (
		<div>
			{selectedFile?.type &&
				(selectedFile.hasOwnProperty('hide') ? !selectedFile.hide : true) ? (
				<section>
					{bundle?.files?.length > 1 ? (
						<div className="gx-my-auto gx-pl-2 gx-bg-light-grey gx-d-flex gx-align-items-center">
							<div
								onClick={() => handleSelectFile(null)}
								className="gx-p-3 gx-pointer gx-mr-2 gx-d-flex"
							>
								<Left className="gx-my-auto" style={{ width: '.9rem' }} />
							</div>
							<h3 className="gx-text-black gx-font-weight-semi-bold gx-mb-0 gx-ml-3">
								BACK TO ALL FILES
							</h3>
						</div>
					) : null}
					<article className="content-area">
						<section className="bg-grey-light">
							{selectedFile?.type === FILE.VIDEO && (
								<VideoPlayer
									url={selectedFile.url}
									expanded={expanded}
									style={{ height: 'auto' }}
									transcript={selectedFile.transcript}
								/>
							)}
							{selectedFile?.type === FILE.PDF && (
								<PDFViewer
									url={selectedFile.url}
									download={
										selectedFile.download ??
										consumptionConfig?.downloadPdf ??
										false
									}
									fullscreen={!isPublic}
									style={{ maxHeight: '40rem' }}
								/>
							)}
							{selectedFile?.type === FILE.IMAGE && (
								<ImageGallery url={selectedFile.url} />
							)}
						</section>
					</article>
				</section>
			) : (
				<div>
					{bundle?.files
						?.filter(
							file =>
								((file.hasOwnProperty('hide') ? !file.hide : true) &&
									file.type === FILE.VIDEO) ||
								((file.hasOwnProperty('hide') ? !file.hide : true) &&
									file.type === FILE.PDF),
						)
						.map((item, index) => (
							<div key={index}>
								<Card
									onClick={() => handleSelectFile(item)}
									className="file-card-shadow gx-pointer gx-mb-0"
									style={{ border: `1px solid ${styling['primary-color']}` }}
								>
									<div className="gx-d-flex">
										<span className="filledSvg">{renderFileIcon(item)}</span>
										<span className="gx-mx-3">
											{item?.title
												? item?.title
												: renderFileDefaultTitle(item)}
										</span>
									</div>
								</Card>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

export default FileList;
