import React from 'react';
import { Select } from "antd";
import { connectRefinementList } from 'react-instantsearch-dom';

const CustomRefinementSelect = ({
    containerId = 'selectPopUp',
    items,
    refine,
    currentRefinement,
}) => {
    return (
        <div id={containerId} style={{ position: 'relative' }}>
            <Select style={{ width: '5rem' }}
                allowClear
                defaultValue={currentRefinement[0]}
                value={currentRefinement[0]}
                onChange={value => {
                    refine(value ?? '');
                }}
                getPopupContainer={() => document.getElementById(containerId)}
                placement='topRight'
            >
                {items.map((data, index) => {
                    return (
                        <Select.Option key={index} value={data.label}>
                            {data.label}
                        </Select.Option>
                    )
                }).reverse()}
            </Select>
        </div>
    );
}

export default connectRefinementList(CustomRefinementSelect);