import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import { Card, Skeleton, Image, Empty } from 'antd';

import { hitsPerPage } from '../constants';
import GridView from './GridView';

const StateResults = ({ searchResults, loading, gotoProduct, handleGrid }) => {
	const searchLoading = loading || !searchResults;
	// const categoriesMap = useSelector(state => state.consumption.categoriesMap);
	
	if (searchLoading)
		return (
			<div>
				<div className="gx-d-flex gx-justify-content-between">
					{/* <Skeleton active title paragraph={{ rows: 0 }}></Skeleton> */}
					<div></div>
					<div className='gx-mt-2'>
						<Skeleton.Button
							className="gx-d-block gx-ml-auto"
							active
							size="large"
							shape="round"
						/>
					</div>
				</div>
				<div className="gx-d-flex gx-justify-content-between">
					<Skeleton active title paragraph={{ rows: 0 }}></Skeleton>
					<div className='gx-mt-2'>
						<Skeleton.Button
							className="gx-d-block gx-ml-auto"
							active
							size="large"
							shape="round"
						/>
					</div>
				</div>
				<section className="product-grid">
					{[...Array(hitsPerPage)].map((_, index) => (
						<Card key={index} className="card gx-mb-0 shadow-1 gx-mt-3">
							<div className="gx-d-flex">
								<Image
									className="card-image"
									style={{ height: '7em' }}
									alt="placeholder"
									src={require('assets/images/placeholder-image.png')}
								/>
								<Skeleton className="gx-ml-3" active paragraph={{ rows: 3 }}></Skeleton>
							</div>
							<Skeleton.Button
								className="gx-d-block gx-ml-auto"
								active
								size="large"
								shape="round"
							/>
						</Card>
					))}
				</section>
			</div>

		);
	if (searchResults.nbHits === 0) return <Empty style={{ margin: '20em 0' }} />;

	return (
		<div>
			<div className="gx-mb-3 gx-d-flex gx-align-items-center">
				{/* <CurrentRefinements categoriesMap={categoriesMap} /> */}
			</div>
			<div>
				<GridView  
					gotoProduct={gotoProduct}
					searchResults={searchResults}
					handleGrid={handleGrid}
				/>
			</div>
			{/* <section className="product-grid product-list-wrapper">
				{searchResults.hits.map((product, index) => (
					<ProductCard product={product} goto={gotoProduct} key={index} />
				))}
			</section> */}
		</div>
	);
};

export default connectStateResults(StateResults);
