import React, { useEffect, useMemo, useRef, useState } from "react";

import { Popconfirm } from 'antd';
import { ReactComponent as LinkSvg } from '../../../../assets/icons/link.svg';
import { useSelector } from 'react-redux'

const DirectLinkModel = ({ modalVisible, base_url, children, className = "" }) => {

    const [copied, setCopied] = useState(false);
    const divRef = useRef(null);
    const { styling } = useSelector(({ loginInfo }) => loginInfo);

    useEffect(() => {
        if (modalVisible) {
            setCopied(false)
        }
    }, [modalVisible])

    function handleCopy() {
        const div = divRef.current;
        if (div != null) {
            const range = document.createRange();
            range.selectNode(div);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            setCopied(true)
        }
    }

    const description = useMemo(() => {
        return <>
            <div className="modelContainer" style={{alignItems:"center"}}>
                <LinkSvg className="gx-mr-2 linkSvg gx-pointer" style={{ width: '1.3em', fill: 'lightgray' }} />
                <div className="baseUrlText" ref={divRef}>{base_url}</div>
                <button className="modelButton" onClick={handleCopy} style={{ backgroundColor: styling['primary-dark-color'] }} > {copied ? "Copied!" : 'Copy'}</button>
            </div>
        </>
    }, [base_url, copied])

    const handleChange = (value) => {
        if (!value) {
            setCopied(false);
        }
    }

    return (
        <Popconfirm
            icon={null}
            title={description}
            trigger='click'
            onOpenChange={handleChange}
            placement="right"
            okButtonProps={{ className }}
            cancelButtonProps={{ className }}
        >
            {children}
        </Popconfirm>
    )
}

export default DirectLinkModel;
