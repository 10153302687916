export const paramsToSearchState = params => {
	const urlParams = new URLSearchParams(params);
	const searchState = {
		query: urlParams.get('query'),
		page: parseInt(urlParams.get('page')) || 1,
		refinementList: {
			category: urlParams.get('categories')?.split(',') ?? [],
			advanceCategory: urlParams.get('advanceCategories')?.split(',') ?? [],
			tag: urlParams.get('tags')?.split('---') ?? [],
			company: urlParams.get('companies')?.split('---') ?? [],
			country: urlParams.get('countries')?.split('---') ?? [],
			publicationStart: urlParams.get('publicationStart') ?? '',
			publicationEnd: urlParams.get('publicationEnd') ?? '',
			sortBy: urlParams.get('sortBy') ?? '',
		},
	};
	return searchState;
};

export const searchStateToParams = state => {
	const query = state.query;
	// const page = state.page;
	const publicationStart = state.refinementList?.publicationStart
	const publicationEnd = state.refinementList?.publicationEnd
	const categories = state.refinementList?.category;
	const advanceCategories = state.refinementList?.advanceCategory;
	const tags = state.refinementList?.tag;
	const companies = state.refinementList?.company;
	const countries = state.refinementList?.country
	const fileType = state.refinementList?.fileType;
	const sortBy = state.sortBy;
	const searchParams = new URLSearchParams({ });
	if (query) searchParams.append('query', query);
	if (categories?.length) searchParams.append('categories', categories);
	if (advanceCategories?.length) searchParams.append('advanceCategories', advanceCategories);
	if (tags?.length) searchParams.append('tags', tags.join('---'));
	if (companies?.length) searchParams.append('companies', companies.join('---'));
	if (countries?.length) searchParams.append('countries', countries.join('---'));
	if (fileType?.length) searchParams.append('fileType', fileType);
	if (publicationStart) searchParams.append('publicationStart', publicationStart)
	if (publicationEnd) searchParams.append('publicationEnd', publicationEnd)
	if (sortBy) searchParams.append('sortBy', sortBy)
	// searchParams.append('public_embed', 1)
	return searchParams;
};
