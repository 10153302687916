import { useEffect } from 'react';

export const useGetExhibitorCart = (
	corporateMembership,
	moduleUrlId,
	requestExhibitorCart,
	isUserLoggedIn,
) => {
	useEffect(() => {
		if (
			corporateMembership.exhibitorCartDetailsFetched &&
			!corporateMembership.exhibitorCartFetched &&
			isUserLoggedIn
		) {
			requestExhibitorCart({
				urlId: moduleUrlId,
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				exhibUuid: corporateMembership.exhibitorCartDetails.exhibuuid,
			});
		}
	}, [corporateMembership.exhibitorCartDetailsFetched]);
};
