import {
	FORM_VALIDATION_FETCHING,
	FORM_VALIDATION_FETCH_SUCCESS,
	FORM_VALIDATION_FETCH_ERROR,
} from 'constants/ActionTypes';

import { createValidationHashKey } from '../../util/form';

const initialData = {
	data: {},
};

const initialFetchData = {
	isFetching: false,
	isFetched: false,
	fetchError: false,
};

const createHash = action => ({
	[createValidationHashKey(action.info)]: action.payload,
});

const formValidation = (state = initialData, action) => {
	let formObj;
	let key;

	switch (action.type) {
		case FORM_VALIDATION_FETCHING:
			key = createValidationHashKey(action.info);
			formObj = state.data[key];
			if (!formObj) {
				formObj = initialFetchData;
			}
			return {
				...state,
				data: {
					...state.data,
					[key]: {
						...formObj,
						isFetching: action.flag,
					},
				},
			};
		case FORM_VALIDATION_FETCH_SUCCESS:
			formObj = createHash(action);
			formObj[createValidationHashKey(action.info)].isFetched = true;
			formObj[createValidationHashKey(action.info)].isFetching = true;
			return {
				...state,
				data: {
					...state.data,
					...formObj,
				},
			};
		case FORM_VALIDATION_FETCH_ERROR:
			key = createValidationHashKey(action.info);
			return {
				...state,
				data: {
					...state.data,
					[key]: {
						...state.data[key],
						fetchError: true,
						isFetching: false,
					},
				},
			};

		default:
			return state;
	}
};

export default formValidation;
