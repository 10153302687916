import React, { useEffect, useState } from 'react';
import LeftMenu from './LeftMenu';
import CompanyHeader from './CompanyHeader';
import {
	requestUserCompanyAffiliationData,
	requestCompanyMembersData,
	requestToMakeUserAdmin,
	requestToRemoveUserAdmin,
	requestToRemoveUserFromGroup,
	requestToRemoveUserMembership,
	requestToFetchRoles,
	requestMakeUserPublic,
	requestRemoveUserPublic,
	requestClearNewUserAddedError,
	requestClearNewUserAddedSuccess,
	requestMiniProfileFields,
	requestAddCompanyUser,
} from '../../appRedux/actions';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '../CircularProgress';
import Widget from '../Widget';
import { Table, Checkbox, Popconfirm, Card, Button, Col, Row, notification, Alert } from 'antd';

import { ReactComponent as RemoveMembershipIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as RemoveCompanyMemberIcon } from '../../assets/icons/removeCompanyMember.svg';

import UserContactInformation from './UserContactInformation';
import ExhibitorAddContact from './ExhibitorAddContact';

import AddMembershipType from './AddMembershipType';
import moment from 'moment';
import { useParams, useHistory } from 'react-router';
import RenewAll from './RenewAll';
import ManageRoles from './ManageRoles';
import { returnRemainingMemberships } from './helpers/generalHelpers';

const ExhibitorStaff = props => {
	const [loader, setLoader] = useState(true);
	const [companyCreated, setCompanyCreated] = useState(false);
	const [staffItems, setStaffMenuItems] = useState([]);
	const [displayRenewAll, setDisplayRenewAll] = useState(true);
	const [companyExpired, setCompanyExpired] = useState(false);
	const [rolesExist, setRolesExist] = useState(true);
	const [showExpireMemberAlert, setShowExpireMemberAlert] = useState(false);

	const { moduleUrlId } = useParams();
	const width = useSelector(state => state.settings.width);
	const companyExpireDate = useSelector(state => state.corporateMembership?.company?.expiry_date);
	const corporateMembership = useSelector(state => state.corporateMembership);
	const newMemberAddFailure = useSelector(state => state.corporateMembership.newMemberAddFailure);
	const newMemberAddSuccess = useSelector(state => state.corporateMembership.newMemberAddSuccess);
	const generalSettings = useSelector(state => state?.corporateMembership?.generalSettings);
	const languageObj = useSelector(state => state?.corporateMembership?.languageObject);
	const newUserContactAdded = useSelector(state => state.corporateMembership.newUserContactAdded);

	const loginInfo = useSelector(state => state.loginInfo);
	const dispatch = useDispatch();
	const history = useHistory();
	const todayDate = new Date();
	const mobileThreshold = useSelector(state => state.common.mobileThreshold);

	const expiringMembersCheck = corporateMembership?.companyMembers?.some(
		member => member.expirationDate && member.expirationDate !== companyExpireDate,
	);
	const settings = useSelector(state => state.settings);

	const publicEmbed = settings.publicEmbed || props.location?.search?.includes('public_embed=1');

	useEffect(() => {
		if (newUserContactAdded) {
			let newestMember = corporateMembership.companyMembers.slice(-1)[0];
			dispatch(
				requestAddCompanyUser({
					compuuid: corporateMembership?.userCompanyAffiliation?.comp_uuid,
					companycontactUUID: newestMember?.uuid,
				}),
			);
		}
	}, [newUserContactAdded]);

	useEffect(() => {
		if (expiringMembersCheck) {
			setShowExpireMemberAlert(true);
		}
	}, [expiringMembersCheck]);

	useEffect(() => {
		if (corporateMembership?.roles?.length) {
			setRolesExist(true);
		}
	}, [corporateMembership.roles]);

	useEffect(() => {
		if (newMemberAddFailure) {
			failureNotificationWithIcon();
		}
	}, [newMemberAddFailure]);

	useEffect(() => {
		if (newMemberAddSuccess) {
			successNotificationWithIcon();
		}
	}, [newMemberAddSuccess]);

	useEffect(() => {
		if (loginInfo.appdir && !corporateMembership.miniProfileFieldsFetched) {
			dispatch(
				requestMiniProfileFields({
					appdir: loginInfo.appdir,
				}),
			);
		}
	}, [loginInfo.appdir]);

	const failureNotificationWithIcon = () => {
		notification.open({
			message: <h2 className="fg-primary">Membership Not Added</h2>,
			description: newMemberAddFailure,
			onClose: () => {
				dispatch(requestClearNewUserAddedError(props));
			},
		});
	};

	const successNotificationWithIcon = () => {
		setTimeout(() => {
			notification.open({
				message: <h2 style={{ color: '#aed581' }}>Success</h2>,
				description: newMemberAddSuccess,
			});
			dispatch(requestClearNewUserAddedSuccess(props));
		}, 500);
	};

	const staffMembersTablecolumns = [
		// {
		// 	dataIndex: 'contact_picture',
		// 	key: 'contact_picture',
		// 	width: '6%',
		// 	render: url => {
		// 		return url ? <Avatar src={url} /> : '';
		// 	},
		// },
		{
			title: <div className="exhibitor-staff-header-row">Name</div>,
			key: 'name',
			width: width > mobileThreshold ? '18%' : 100,
			render: record => {
				return (
					<div className="exhibitor-staff-row">
						<div>{`${record?.lastName}, ${record?.firstName}`}</div>
						<div className="exhibitor-staff-row-email">
							<div>{`${record?.email}`}</div>

							{record.roles && (
								<div>{`${record?.roles
									?.map(roleObj => {
										return corporateMembership.roles.find(role => {
											return role.roleid === roleObj.roleid;
										})?.label;
									})
									.join(', ')}`}</div>
							)}
						</div>
					</div>
				);
			},
			sorter: (a, b) => {
				return a.lastName.localeCompare(b.lastName);
			},
		},
		{
			title: <div className="exhibitor-staff-header-row">Member Type</div>,
			key: 'groupName',
			width: width > mobileThreshold ? '12.5%' : 100,
			sorter: (a, b) => {
				return a.groupName.localeCompare(b.groupName);
			},
			render: record => {
				const membershipType = corporateMembership.company?.memberships_available?.find(
					membershipType => {
						return membershipType?.groupid == record?.groupID;
					},
				);

				return (
					<div className="exhibitor-staff-row">
						{membershipType ? (
							// Display AddMembershipType only if the user is within the dues renew date or doesn't have an associated membership(groupID)
							// That is, the difference between Expiration Date and today's date is below renewal_period_days(usually, 90)
							moment(record?.expirationDate).diff(moment.now(), 'days') <
							corporateMembership.generalSettings?.renewal_period_days ? (
								<div>
									<h6 className="gx-font-weight-normal">
										{membershipType?.group_name}
									</h6>
									{corporateMembership.company.memberships_available.filter(
										obj =>
											returnRemainingMemberships(
												obj,
												corporateMembership.companyMembers,
												companyExpireDate,
											) > 0,
									).length > 0 &&
										!membershipType && <AddMembershipType record={record} />}
								</div>
							) : (
								<h6 className="gx-font-weight-normal">
									{membershipType?.group_name}
								</h6>
							)
						) : (
							<div>
								{corporateMembership.company.memberships_available.filter(
									obj =>
										returnRemainingMemberships(
											obj,
											corporateMembership.companyMembers,
											companyExpireDate,
										) > 0,
								).length > 0 && <AddMembershipType record={record} />}
							</div>
						)}
					</div>
				);
			},
		},
		{
			title: (
				<div className="exhibitor-staff-header-row">
					<div>Expiration</div>
				</div>
			),
			key: 'expirationDate',

			width: width > mobileThreshold ? '12%' : 100,
			render: record => {
				return (
					<div className="gx-d-flex" style={{ fontSize: '11px' }}>
						{record?.expirationDate
							? moment(record?.expirationDate).format('MM/DD/YYYY')
							: ''}
						{record?.expirationDate &&
						moment(companyExpireDate) > moment(record?.expirationDate) ? (
							<h2 className="fg-primary gx-mb-5">*</h2>
						) : (
							''
						)}

						<div>{Date.parse(record?.expirationDate) < todayDate && 'Expired'}</div>
					</div>
				);
			},
			sorter: (a, b) => {
				return moment(a.expirationDate).unix() - moment(b.expirationDate).unix();
			},
		},
		{
			title: <div className="exhibitor-staff-header-row">Admin</div>,
			key: 'compAdmin',
			render: record => {
				return (
					<Checkbox
						checked={record?.compAdmin === 1 ? true : false}
						onChange={event => {
							if (event.target.checked) {
								dispatch(
									requestToMakeUserAdmin({
										compuuid:
											corporateMembership.userCompanyAffiliation.comp_uuid,
										companycontactUUID: record?.uuid,
									}),
								);
							} else {
								dispatch(
									requestToRemoveUserAdmin({
										compuuid:
											corporateMembership.userCompanyAffiliation.comp_uuid,
										companycontactUUID: record?.uuid,
									}),
								);
							}
						}}
					/>
				);
			},
			width: width > mobileThreshold ? '8.5%' : 80,
			sorter: (a, b) => {
				return a.compAdmin - b.compAdmin;
			},
		},
		{
			title: <div className="exhibitor-staff-header-row">Public</div>,
			key: 'compPublic',
			render: record => {
				return (
					<Checkbox
						checked={record?.public_contact === true ? true : false}
						onChange={event => {
							if (event.target.checked) {
								dispatch(
									requestMakeUserPublic({
										compuuid:
											corporateMembership.userCompanyAffiliation.comp_uuid,
										companycontactUUID: record?.uuid,
									}),
								);
							} else {
								dispatch(
									requestRemoveUserPublic({
										compuuid:
											corporateMembership.userCompanyAffiliation.comp_uuid,
										companycontactUUID: record?.uuid,
									}),
								);
							}
						}}
					/>
				);
			},
			width: width > mobileThreshold ? '8.5%' : 80,
			sorter: (a, b) => {
				return a.public_contact - b.public_contact;
			},
		},
		{
			title: (
				<div className="exhibitor-staff-header-row">
					<div>Contact</div>
				</div>
			),
			render: record => {
				return (
					staffItems.length > 0 && (
						<div className="exhibitor-staff-row">
							<UserContactInformation
								isButton={false}
								actionText={'UPDATE'}
								title={'Contact Information'}
								formData={corporateMembership.miniProfileFields}
								compUuid={corporateMembership.userCompanyAffiliation.comp_uuid}
								record={record}
							/>
						</div>
					)
				);
			},
			width: width > mobileThreshold ? '9.5%' : 80,
		},
		{
			title: <div className="exhibitor-staff-header-row">Remove Membership</div>,
			render: record => {
				return (
					record?.groupID !== '' && (
						<Popconfirm
							title="Are you sure you want to remove this member's membership?"
							okText="Yes"
							cancelText="No"
							onConfirm={event => {
								dispatch(
									requestToRemoveUserMembership({
										compuuid:
											corporateMembership.userCompanyAffiliation.comp_uuid,
										groupid: record?.groupID,
										companycontactUUID: record?.uuid,
									}),
								);
							}}
						>
							<RemoveMembershipIcon
								className="gx-icon-btn icon exhibitor-staff-row-icon fg-secondary-lighter"
								title="Remove Membership"
							/>
						</Popconfirm>
					)
				);
			},
			width: width > mobileThreshold ? '10.5%' : 80,
		},
		{
			title: <div className="exhibitor-staff-header-row">Remove Company Member</div>,
			render: record => {
				return (
					<Popconfirm
						title="Are you sure you want to remove this member from the group?"
						okText="Yes"
						cancelText="No"
						onConfirm={event => {
							dispatch(
								requestToRemoveUserFromGroup({
									compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
									companycontactUUID: record?.uuid,
								}),
							);
						}}
					>
						<RemoveCompanyMemberIcon
							className="gx-icon-btn icon exhibitor-staff-row-icon fg-secondary-lighter"
							title="Remove Company Member"
						/>
					</Popconfirm>
				);
			},
			width: width > mobileThreshold ? '11%' : 100,
		},
		{
			title: rolesExist ? <div className="exhibitor-staff-header-row">Manage Roles</div> : '',
			render: rolesExist
				? record => {
						return <ManageRoles record={record} />;
				  }
				: '',
			width: rolesExist ? '8%' : '0%',
		},
	];

	useEffect(() => {
		if (!corporateMembership.userCompanyAffiliationFetched && loginInfo.appdir) {
			dispatch(
				requestUserCompanyAffiliationData({
					appDir: loginInfo.appdir,
					urlId: moduleUrlId,
				}),
			);
		}
	}, [loginInfo.appdir]);

	useEffect(() => {
		if (!corporateMembership.userCompanyAffiliationFetched) {
			return;
		}
		setLoader(false);
		if (!corporateMembership.userCompanyAffiliation?.comp_uuid) {
			history.push('home');
			return;
		}
		setCompanyCreated(true);
		dispatch(
			requestToFetchRoles({
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				sourceHex: loginInfo.source_hex,
			}),
		);
		dispatch(
			requestCompanyMembersData({
				compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				urlId: moduleUrlId,
			}),
		);
	}, [corporateMembership.userCompanyAffiliationFetched]);

	useEffect(() => {
		if (!corporateMembership.menuFetched) {
			return;
		}
		if (corporateMembership.menu) {
			setStaffMenuItems(
				corporateMembership.menu.filter(menu => {
					return menu.screentype === 'Staff';
				}),
			);
		} else {
			history.push(`/main/profilehome`);
		}
	}, [corporateMembership.menuFetched]);

	useEffect(() => {
		if (corporateMembership.companyMembersFetched && corporateMembership.menuFetched) {
			// Renew All button is visible only if expiry date of all contacts are within 90 day period from now.
			corporateMembership.companyMembers.forEach(companyMember => {
				if (
					moment(companyMember?.expirationDate).diff(moment.now(), 'days') >
					corporateMembership.generalSettings?.renewal_period_days
				) {
					// setDisplayRenewAll(false);
				}
			});
		}
	}, [corporateMembership.companyMembersFetched, corporateMembership.menuFetched]);

	useEffect(() => {
		// Renew All should not be visible if number of people you are trying to renew exceeds the memberships available.
		if (
			corporateMembership.companyFetched &&
			corporateMembership.companyMembersFetched &&
			corporateMembership.company?.memberships_available?.length > 0
		) {
			const totalAvailableMemberships = corporateMembership.company[
				'memberships_available'
			].reduce((a, b) => {
				return a + b?.count;
			}, 0);

			if (
				corporateMembership.companyMembers.filter(companyMember => {
					return companyMember.groupID !== '';
				}).length > totalAvailableMemberships
			) {
				// setDisplayRenewAll(false);
			}
		}
	}, [corporateMembership.companyFetched, corporateMembership.companyMembersFetched]);

	useEffect(() => {
		if (corporateMembership.companyFetched && !corporateMembership.company.expiry_date) {
			setCompanyExpired(true);
		}
	}, [corporateMembership.companyFetched]);

	return (
		<div>
			{!loader && companyCreated && (
				<div className="gx-main-content">
					<div className={publicEmbed ? 'gx-mx-4 gx-my-4' : ''}>
						<div style={{ width: '100%' }}>
							<CompanyHeader />
						</div>
						<div style={{ width: '100%' }}>
							<Row justify="space-between">
								<Col xl={6} lg={24} md={24} sm={24} xs={24}>
									<LeftMenu />
								</Col>

								<Col span={width < mobileThreshold ? 24 : 18}>
									{corporateMembership.menuFetched && (
										<Card className={`card`}>
											<div className="gx-mb-3">
												<h3
													style={{
														paddingLeft: 15,
														float: 'left',
													}}
												>
													{staffItems[0]?.button}
												</h3>
											</div>

											{companyExpired ||
											moment(companyExpireDate).diff(
												moment(todayDate),
												'days',
											) < generalSettings.renewal_period_days ? (
												<div>
													<br />
													<div className="gx-mt-2">
														Please upgrade your membership to view your
														staff.
													</div>
													{
														<div>
															<br></br>
															<Button
																onClick={() => {
																	history.push(
																		`/main/company/${moduleUrlId}/submenu`,
																	);
																}}
															>
																CONTINUE
															</Button>
														</div>
													}
												</div>
											) : (
												''
											)}
											{!companyExpired &&
												moment(companyExpireDate).diff(
													moment(todayDate),
													'days',
												) > generalSettings.renewal_period_days && (
													<div>
														{corporateMembership.companyMembersFetched && (
															<div>
																<br />
																<div
																	className="gx-mt-2"
																	dangerouslySetInnerHTML={{
																		__html:
																			staffItems[0]?.blurb,
																	}}
																></div>
																<Row>
																	<Col
																		xl={12}
																		lg={24}
																		md={24}
																		sm={24}
																		xs={24}
																	>
																		{showExpireMemberAlert && (
																			<Alert
																				description={
																					<div>
																						<div
																							dangerouslySetInnerHTML={{
																								__html:
																									languageObj?.general_renew_corporate_member_staff_message,
																							}}
																						/>
																					</div>
																				}
																				type="error"
																			/>
																		)}
																	</Col>

																	<Col
																		xl={12}
																		lg={24}
																		md={24}
																		sm={24}
																		xs={24}
																	>
																		<div className="gx-d-flex gx-justify-content-end gx-mr-3">
																			{displayRenewAll && (
																				<div>
																					<RenewAll />
																				</div>
																			)}

																			{/* 
																hidden for now because the send email button doesn't work yet. Backend issue
																*/}
																			{/* {corporateMembership.menuFetched && (
																	<div className="gx-pl-3">
																		<CommunicateWithUsers
																			subject={
																				corporateMembership
																					.generalSettings
																					?.CorpMemberIndividualEmailTemplate
																					?.subject
																			}
																			messageBody={
																				corporateMembership
																					.generalSettings
																					?.CorpMemberIndividualEmailTemplate
																					?.message
																			}
																			hotkeys={`[Firstname] [Lastname] [Email] [CompanyName] [MembershipType] [ExpiryDate]`}
																		/>
																	</div>
																)} */}

																			{staffItems?.length >
																				0 && (
																				<div className="gx-pl-3">
																					<ExhibitorAddContact
																						isButton={
																							true
																						}
																						actionText={
																							'ADD'
																						}
																						title={
																							'Add Contact'
																						}
																						formData={
																							corporateMembership.miniProfileFields
																						}
																						compUuid={
																							corporateMembership
																								.userCompanyAffiliation
																								.comp_uuid
																						}
																					/>
																				</div>
																			)}
																		</div>
																	</Col>
																</Row>
																<Table
																	rowKey="uuid"
																	columns={
																		staffMembersTablecolumns
																	}
																	dataSource={
																		corporateMembership.companyMembers
																	}
																	scroll={{ x: 130 }}
																/>
															</div>
														)}
													</div>
												)}
										</Card>
									)}
								</Col>
							</Row>
						</div>
					</div>
				</div>
			)}
			{loader && (
				<Widget styleName="gx-card">
					<CircularProgress className="gx-profile-content" />
				</Widget>
			)}
		</div>
	);
};

export default ExhibitorStaff;
