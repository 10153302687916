import React, { useEffect, useState } from "react";
import { Card, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from "react-router-dom";
import _sortBy from 'lodash/sortBy';

import ProductCardList from '../SelectionPage/ProductCardList';
import ProductCardGrid from '../SelectionPage/ProductCardGrid';
import BundleProductCardList from "../GroupSelectionPage/BundleProductCardList";
import BundleProductCardGrid from "../GroupSelectionPage/BundleProductCardGrid";
import BundleProductQuickView from "../GroupSelectionPage/BundleProductQuickView";

import { ReactComponent as List } from '../../../assets/icons/list.svg';
import { ReactComponent as Grid } from '../../../assets/icons/grid.svg';
import { ReactComponent as QuickView } from '../../../assets/icons/quick-view.svg';
import { storeCardsViewType, storeConsumptionSortType } from "../../../appRedux/actions";
import { paramsToSearchState, searchStateToParams } from "../helpers";

const VIEWS = [
    { key: 'QUICK', label: 'Quick View', value: 2, disableOnMobile: false, Icon: QuickView },
    { key: 'GRID', label: 'Grid', value: 0, disableOnMobile: false, Icon: Grid },
    { key: 'LIST', label: 'List', value: 1, disableOnMobile: true, Icon: List },
];

const GridView = ({
    searchResults,
    gotoProduct,
    bundleCard,
    showQuickView = false,
    bundle = {},
    hideQvPagination = () => { },
}) => {
    const [views, setViewTypes] = useState(VIEWS);
    const { superBundleId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const cardView = useSelector((state) => state.consumption.cardDisplayType);
    const sortType = useSelector((state) => state.consumption.sortType);

    useEffect(() => {
        if (!showQuickView) {
            setViewTypes(VIEWS.filter(v => v.key !== 'QUICK'))
        }
    }, [showQuickView]);

    useEffect(() => {
        hideQvPagination(cardView);

        if (cardView !== 2 && bundle?.quick_view_only) {
            dispatch(storeCardsViewType({ cardDisplayType: 2 }))
        }
        if (cardView === 2 && !superBundleId)
            dispatch(storeCardsViewType({ cardDisplayType: 0 }));
    }, [cardView, superBundleId]);

    const handleSwitch = (value) => {
        const searchParams = paramsToSearchState(location.search);
        if (value === 2 && searchParams?.page) {
            searchParams.page = 1;
            history.push(`?${searchStateToParams(searchParams)}`);
        }
        dispatch(storeCardsViewType({ cardDisplayType: value }));
    };

    const handleSort = (value) => {
        dispatch(storeConsumptionSortType({ sortType: value }));
    };

    const renderItems = (view) => {
        switch (view) {
            case 2:
                return (
                    <div className="gx-p-2">
                        <Card className="shadow-1">
                            {searchResults?.hits?.filter(a => a.isBundle).map((product, index) => (
                                <div className="gx-p-2 gx-mt-4" key={index}>
                                    <h3 className="gx-page-title gx-font-weight-bold">{product.title}</h3>
                                    <div className="gx-p-2">
                                        {product?.children?.length
                                            ? (_sortBy(product?.children, 'title')?.map((child, key) => (
                                                <div key={key} className="gx-p-2">
                                                    <BundleProductQuickView
                                                        product={child}
                                                        goto={gotoProduct}
                                                    />
                                                </div>
                                            ))
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ))}
                            {searchResults?.hits?.filter(a => !a.isBundle && !a.isSuperBundle).map((product, index) => {
                                return (
                                    <div key={index}>
                                        {index === 0 ? <h3 className="gx-page-title gx-font-weight-bold">Others</h3> : null}
                                        <div className="gx-p-2">
                                            <div className="gx-p-2">
                                                <BundleProductQuickView
                                                    product={product}
                                                    goto={gotoProduct}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Card>
                    </div>
                );
            case 1:
                return (
                    <div>
                        {searchResults?.hits?.filter((product) => !product.hide_card_only).map((product, index) => (
                            <div key={index}>
                                {!bundleCard ? <ProductCardList
                                    product={product}
                                    goto={gotoProduct}
                                /> : <BundleProductCardList
                                    product={product}
                                    goto={gotoProduct}
                                />
                                }
                            </div>
                        ))}
                    </div>
                );
            case 0:
            default:
                return (
                    <div className="product-grid">
                        {searchResults?.hits?.filter((product) => !product.hide_card_only).map((product, index) => (
                            <div key={index}>
                                {!bundleCard ? <ProductCardGrid
                                    product={product}
                                    goto={gotoProduct}
                                /> : <BundleProductCardGrid
                                    product={product}
                                    goto={gotoProduct}
                                />}
                            </div>
                        ))}
                    </div>
                );
        }
    };

    return (
        <div>
            <div className='gx-d-flex gx-justify-content-end gx-mb-3 selectionFilter'>
                {bundle?.quick_view_only
                    ? <></>
                    : (<Radio.Group
                        onChange={({ target }) => handleSwitch(target.value)}
                        value={cardView}
                        className="gx-ml-auto gx-d-flex gx-align-items-center"
                    >
                        {views.map(({ key, label, value, disableOnMobile, Icon }, index) => (
                            <Radio.Button
                                className={`${index === 0
                                    ? "radio-button-left"
                                    : (index + 1) === views.length
                                        ? "radio-button-right"
                                        : "radio-button-center"
                                    } ${disableOnMobile ? "list-view-radio-btn" : ""}`
                                }
                                key={key}
                                value={value}
                            >
                                <div className="gx-d-flex gx-align-items-center">
                                    <Icon
                                        className="gx-mr-2"
                                        style={{
                                            width: '1em',
                                        }}
                                    />
                                    <p className="gx-mb-0">{label}</p>
                                </div>
                            </Radio.Button>
                        ))}
                    </Radio.Group>)}
            </div>
            <div>
                {renderItems(cardView)}
            </div>
        </div>
    )
}

export default GridView;