import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { InstantSearch } from 'react-instantsearch-dom';
import { useSelector, useDispatch } from 'react-redux';
import _sortBy from 'lodash/sortBy';

import { useProducts } from '../hooks/useProducts';
import { useSearchClient } from '../hooks/useSearchClient';
import ConsumptionHeader from '../components/ConsumptionHeader';
import ConsumptionArea from '../components/ConsumptionArea';
import { paramsToSearchState } from '../helpers';

import '../styles/custom.less';
import { storeCardsViewType, requestStoreSearchState } from '../../../appRedux/actions';

const GroupSelectionPage = () => {
	const { superBundleId } = useParams();

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const [prodsLoading, products] = useProducts();
	const superBundle = useMemo(() => products.find(prod => prod.id === parseInt(superBundleId)), [
		products,
	]);
	const [searchState, setSearchState] = useState(paramsToSearchState(location.search));
	const [refresh, onRefresh] = useState(false);
	const sortType = useSelector((state) => state.consumption.sortType);
	const storedSearchState = useSelector((state) => state.consumption.searchState);
	const hasOpenAccess = useSelector(state => state.consumption.hasOpenAccess);
	const hasDirectAccess = useSelector(state => state.consumption.hasDirectAccess);
	const tagsMapping = useSelector(state => state.consumption.tagsMapping);
	const categoriesMapping = useSelector(state => state.consumption.categoriesMapping);
	const advanceCategoriesMapping = useSelector(state => state.consumption.advanceCategoriesMapping);
	const countryMapping = useSelector(state => state.consumption.countryMapping);
	const companyMapping = useSelector(state => state.consumption.companyMapping);
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const moduleUrlId = useSelector(state => state.consumption.moduleUrlId);

	useEffect(() => {
		return () => {
			dispatch(requestStoreSearchState({ searchState: paramsToSearchState('') }));
		}
	}, []);

	useEffect(() => {
		onRefresh(true);
	}, [searchState?.query]);

	const children = useMemo(() => _sortBy(products.filter(prod => superBundle?.childrenIds.some(childId => childId === prod.id)).map(p => {
		const tempChild = products.filter(prod => p?.childrenIds.some(childId => childId === prod.id))
			.map(({ childrenIds, description, id, tagIds, title, uuid, ...rest }) => ({
				childrenIds,
				description,
				id,
				tagIds,
				title,
				uuid,
				children: rest?.children ?? [],
				authorInfo: rest?.authorInfo ?? [],
				author_block: rest?.author_block ?? '',
				isBundle: rest?.isBundle,
				isSuperBundle: rest?.isSuperBundle,
				parentId: rest?.parentId,
				sessionStartTime: rest?.sessionStartTime,
				sessionEndTime: rest?.sessionEndTime,
				showTimes: rest?.showTimes,
			}));
		return {
			childrenIds: p.childrenIds,
			description: p.description ?? "",
			id: p.id,
			tagIds: p.tagIds,
			title: p.title,
			uuid: p.uuid,
			children: tempChild ?? [],
			publicationDate: p.publicationDate,
			date_added: p.date_added,
			author_block: p.author_block,
			authorInfo: p.authorInfo,
			isBundle: p.isBundle,
			isSuperBundle: p.isSuperBundle,
			sessionOrderOf: p?.sessionOrderOf,
			presentationOrderOf: p?.presentationOrderOf,
			credits_array: (p.isBundle && !p.isSuperBundle) ? p?.credits_array : {},
            sessionStartTime: p.sessionStartTime,
            sessionEndTime: p.sessionEndTime,
            showTimes: p.showTimes,
			ab_order: p?.ab_order,
		}
	}), [(data) => data.title]), [
		products, superBundle
	]);

	const authorBasedMapping = useMemo(() => children.reduce((acc, p) => {
		let temp = { ...acc };
		p.children.forEach((child) => {
			child.authorInfo.forEach((author) => {
				if (author.country) {
					if (!temp.country[author.country]) {
						temp.country[author.country] = new Set();
					}
					temp.country[author.country].add(child.id)
				}
				if (author.company) {
					if (!temp.company[author.company]) {
						temp.company[author.company] = new Set();
					}
					temp.company[author.company].add(child.id)
				}
			})
		})
		return temp;
	}, { country: {}, company: {} }), [children]);

	const searchClient = useSearchClient(prodsLoading, children, sortType, authorBasedMapping, tagsMapping, categoriesMapping, advanceCategoriesMapping, countryMapping, companyMapping, superBundle?.quick_view_only, superBundle?.isDerivedParent);

	const loading = prodsLoading;

	const searchStateChange = state => {
		const newState = { ...state };
		if (newState?.refinementList
			&& newState?.refinementList?.publicationEnd
			&& newState?.refinementList?.publicationStart
			&& parseInt(newState?.refinementList?.publicationEnd, 10) < parseInt(newState?.refinementList?.publicationStart, 10)
		) {
			newState.refinementList.publicationEnd = "";
		}
		setSearchState(newState);
		dispatch(requestStoreSearchState({ searchState: newState }));
		// history.push(`?${searchStateToParams(state)}`);
	};

	return (
		<InstantSearch
			indexName="consumption/group-selection"
			searchClient={searchClient}
			onSearchStateChange={searchStateChange}
			searchState={storedSearchState}
		>
			<header /* style={{ maxHeight: '100vh', overflowY: 'auto' }} */>
				<ConsumptionHeader
					loading={loading}
					product={superBundle}
					goBack={() => {
						if (hasDirectAccess && hasOpenAccess) {
							history.push(
								`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/selection?${publicEmbed ? 'public_embed=1' : ""}`
							)
						} else if (hasOpenAccess) {
							history.push(
								`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/bundle/${superBundleId}${publicEmbed ? '?public_embed=1' : ""}`
							)
						} else {
							history.push(`../selection${history.location.search}`)
						}
					}}
				/>

				<section className="gx-flex-1 gx-d-flex gx-h-100">
					<article
						// className="content-area"
						style={{
							// width: `calc(100% - ${toggleWidth + (sidebarOpen ? sidebarWidth : 0)}px)`,
							width: `100%`,
						}}
					>
						<ConsumptionArea
							loading={loading || !superBundle}
							product={superBundle}
							searchState={searchState}
						/>
					</article>
				</section>
			</header>
		</InstantSearch>
	);
};

export default GroupSelectionPage;
