import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestProducts, requestConfig } from 'appRedux/actions/Consumption';

export const useProducts = () => {
	const dispatch = useDispatch();
	const status = useSelector(state => state.consumption.productsStatus);
	const products = useSelector(state => state.consumption.products);
	const config = useSelector(state => state.consumption.config);
	const loading = status === 'loading' || status === 'idle';
	const [filteredProducts, setFilteredProducts] = useState(products);

	useEffect(() => {
		//This will hide the product and its children
		setFilteredProducts(products.filter(p => !p.hide_product));
	}, [products]);

	useEffect(() => {
		if (status === 'idle') dispatch(requestProducts());
		dispatch(requestConfig());
	}, [status]);

	return [loading, filteredProducts, config];
};
