import React, { useMemo } from 'react';
import { Button, Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import BundleProductRender from './BundleProductRender';
import { ReactComponent as Left } from '../../../../assets/icons/left.svg';
import { useBundleInfo } from '../hooks/useBundleInfo';
import { ReactComponent as Remove } from '../../../../assets/icons/remove.svg';
import { useShoppingCart } from '../hooks/useShoppingCart';
import { isEmpty } from 'lodash';
import { useAccessList } from '../hooks/useAccessList';

const BundleChildRender = ({ product, handleSelectBundle, handleAccess }) => {
	const [
		bundle,
		children,
		bundlePrice,
		,
		totalPrice,
		bundleSavings,
		savingsPercentage,
	] = useBundleInfo(product.id);
	const [addToCart, , cart, removeFromCart] = useShoppingCart();
	const [, productAccessList] = useAccessList();

	const addedToCart = useMemo(
		() => cart.some(invoice => invoice.field_id === bundlePrice?.fieldid),
		[cart],
	);
	const storeConfig = useSelector(
		state => state.ecommerce.attendeeSettings?.attendeesiteadmin?.storeconfigobject,
	);
	
	return (
		<>
			<div className="gx-d-flex gx-align-items-end gx-justify-content-between card-list-details">
				<div className="gx-d-flex gx-align-items-center">
					<Button
						className="button-secondary"
						size="small"
						shape="round"
						style={{ height: 'auto' }}
						onClick={() => handleSelectBundle({})}
					>
						<Left style={{ width: '0.5em' }} />
						<span className="gx-ml-1">Back</span>
					</Button>
					<h1 className="gx-mb-0 gx-mr-2">{bundle.title}</h1>
				</div>
				<div className="gx-mr-4 product-card-btn-wrapper">
					{/* TODO: Add Access Now button logic */}
					{bundlePrice.user_has_open_access ? (
						<div>
							<Tag className="gx-mb-0">
								{storeConfig?.free_for_members_message &&
									!isEmpty(storeConfig?.free_for_members_message)
									? `${storeConfig?.free_for_members_message}`
									: 'You already have access'}
							</Tag>
						</div>
					) : (
						<>
							{bundlePrice.open_access_tease &&
								bundlePrice.open_access_tease.length > 0 && !product.exclude_open_access_tease_message
								 && (
									<>
										<Tooltip title={bundlePrice.open_access_tease.join(', ')}>
											<Tag className="gx-mb-0">
												{storeConfig?.open_access_tease_message &&
													!isEmpty(storeConfig?.open_access_tease_message)
													? `${storeConfig?.open_access_tease_message}`
													: 'Open access available for members'}
											</Tag>
										</Tooltip>
										<br />
										<br />
									</>
								)}
							{bundlePrice?.price_collection ? (
									<Button
										className="button-primary gx-w-100"
										shape="round"
										onClick={e =>
											addedToCart
												? removeFromCart(bundlePrice)
												: addToCart(bundlePrice)
										}
									>
										<div className="gx-d-flex gx-justify-content-center gx-align-items-center">
											{addedToCart ? (
												<p>
													<Remove
														className="gx-mr-2"
														style={{ width: '0.9em' }}
													/>
													Remove
												</p>
											) : (
												bundlePrice?.price_collection && (
													<>
														<p className="gx-mb-0">
															{bundlePrice.price_collection.primary
																.price === 0
																? 'FREE'
																: storeConfig?.buy_now_button_label &&
																	!isEmpty(
																		storeConfig?.buy_now_button_label,
																	)
																	? `${storeConfig?.buy_now_button_label} ($${bundlePrice.price_collection.primary.price})`
																	: `BUY $${bundlePrice.price_collection.primary.price}`}
														</p>
													</>
												)
											)}
										</div>
									</Button>
								) : null}
						</>
					)}
				</div>
			</div>
			<hr
				style={{
					background: 'lightgray',
				}}
			/>
			{children.map(child => (
				<BundleProductRender
					key={child.id}
					product={child}
					handleSelectBundle={handleSelectBundle}
				/>
			))}
		</>
	);
};

export default BundleChildRender;
