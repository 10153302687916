import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Menu, Skeleton } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { RefinementList } from 'react-instantsearch-dom';
import { useParams } from 'react-router';

import FilterContent from './FilterContent';
import { useTags } from '../hooks/useTags';
import { useCategory } from '../hooks/useCategory';
import { useProducts } from '../hooks/useProducts';
import CustomSortBy from '../../Common/Algolia/CustomSortBy';

const items = [
	{ value: 'sessionOrderOf', label: 'Default' },
	{ value: 'product_label', label: 'Alphabetic' },
	{ value: 'publication_date', label: 'Publication Date' },
	{ value: 'date_added', label: 'Date Added' },
	{ value: 'author_last_name', label: 'Author Last Name' },
]

const FilterSidebar = ({ categories, tags, onTags, sortBy }) => {
	const [hideOverlay, setHideOverlay] = useState(true);
	const [visible, setVisibleFlag] = useState(true);
	const [tagsLoading, tagsMap] = useTags();
	const [catsLoading, categoriesMap] = useCategory();
	const [prodsLoading, products] = useProducts();
	const { superBundleId } = useParams();

	const loading = tagsLoading || catsLoading || prodsLoading;

	useEffect(() => {
		setVisibleFlag(false);
	}, []);

	const handleVisibleChange = (flag) => {
		setHideOverlay(false);
		setVisibleFlag(flag);
	};

	const sortByItems = useMemo(() => {
		let labels = ['Default', 'Author Last Name'];
		let constructedData = [];
		if (!superBundleId) {
			constructedData = [
				...items.filter((item) => !labels.includes(item.label))
					.sort((a) => a.label !== 'Publication Date' ? 1 : -1)
			];
		} else {
			const prod = products.find(p => p.id == superBundleId);
			if(!prod?.showTimes){
				constructedData = [
                    ...items.filter((item) =>!labels.includes(item.label))
                        // .sort((a) => a.label!== 'Session Time'? 1 : -1)
                ];
			}
			else{
				// Update the default sort flag to sessionStartTime
				constructedData = items.map(item => {
					if (item.label === 'Default') {
					  return { ...item, value: "sessionStartTime" };
					}
					return item;
				  });
			}
		}
		return constructedData;
	}, [superBundleId]);
	
	const MenuItem = useMemo(() => {
		if (loading || _isEmpty(categoriesMap)) {
			return (
				<main className="refinements-filter-body-wrapper shadow-2">
					<div className="gx-p-4">
						<Skeleton.Button active size="large" shape="round"></Skeleton.Button>
						{categories && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-5">Category</h3>
								<hr className="gx-mt-0 filter-divider" />
								<Skeleton active title={false} paragraph={{ rows: 6 }}></Skeleton>
							</>
						)}
						{tags && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-5">Tags</h3>
								<hr className="gx-mt-0 filter-divider" />
								<Skeleton active title={false} paragraph={{ rows: 6 }}></Skeleton>
							</>
						)}
					</div>
				</main>
			);
		}
		return (
			<Menu className="refinements-filter-body-wrapper">
				<Menu.Item>
					<div className="gx-algolia-sidebar">
						{categories && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Category</h3>
								<hr className="gx-mt-0 filter-divider" />
								<RefinementList
									className="gx-algolia-refinementList"
									attribute="category"
									limit={Number.POSITIVE_INFINITY}
									transformItems={categories =>
										categories
											.sort((a, b) => parseInt(a.label) - parseInt(b.label))
											.map(category => ({
												...category,
												label: categoriesMap[category.label] ?? category.label,
											}))
									}
								/>
							</>
						)}
						{tags && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Tags</h3>
								<hr className="gx-mt-0 filter-divider" />
								<RefinementList
									className="gx-algolia-refinementList"
									attribute="tag"
									limit={Number.POSITIVE_INFINITY}
									transformItems={tags => {
										onTags && onTags(tags);
										return tags
											.sort((a, b) => parseInt(a.label) - parseInt(b.label))
											.map(tag => ({
												...tag,
												label: tagsMap[tag.label] ?? tag.label,
											}));
									}}
								/>
							</>
						)}
						{sortBy && (
							<>
								<h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Sort By</h3>
								<hr className="gx-mt-0 filter-divider" />
								<CustomSortBy
									defaultRefinement={sortByItems[0].value}
									items={sortByItems}
								/>
							</>
						)}
					</div>
				</Menu.Item>
			</Menu>
		);
	}, [loading, categories, tags, categoriesMap, sortBy]);

	return (
		<div className="refinements-filter-wrapper gx-mr-2">
			<Dropdown
				trigger={"click"}
				overlay={MenuItem}
				visible={visible}
				overlayClassName={hideOverlay ? "filter-body-wrapper-hide" : ""}
				onVisibleChange={handleVisibleChange}
			>
				<div>
					<FilterContent popOverVisible={visible} sortBy={sortBy} />
				</div>
			</Dropdown>
		</div>
	);
};

export default FilterSidebar;
