import React, { useState } from 'react';

import { connectPagination } from 'react-instantsearch-dom';

import { Pagination as AntPagination } from 'antd';

const Pagination = ({ currentRefinement, nbPages, refine, onPageChange, cardView = 0, handlePageSize = () => { }, pageSize = 1 }) => {
	const handleChange = (page, pageSize) => {
		handlePageSize(pageSize)
		refine(page, pageSize);
		if (onPageChange) {
			onPageChange(page, pageSize);
		}
	}
	return (
		<AntPagination
			current={currentRefinement}
			onChange={handleChange}
			total={nbPages}
			pageSize={pageSize}
			hideOnSinglePage={cardView === 2 ? true : false}
		/>
	);
}

export default connectPagination(Pagination);
