import React, { useState, useEffect } from "react";
import { Dropdown, Menu } from "antd";
import { useSelector } from 'react-redux';
import { ReactComponent as Up } from '../../../../assets/icons/up.svg';
import { CaretDownFilled } from '@ant-design/icons';


const items = [
    { value: 'presentationOrderOf', label: 'Default', isRefined: true },
    { value: 'product_label', label: 'Alphabetic' },
    { value: 'publication_date', label: 'Publication Date' },
    { value: 'date_added', label: 'Date Added' },
    { value: 'author_last_name', label: 'Author Last Name' }]

const PresentationSortBy = ({ presentations, handleSortPresentation }) => {
    const [visible, setVisibleFlag] = useState(true);
    const [hideOverlay, setHideOverlay] = useState(true);
    const [sortByItem, setSortByItem] = useState(items);

    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

    useEffect(() => {
        setVisibleFlag(false);
    }, []);

    const handleVisibleChange = (flag) => {
        setHideOverlay(false);
        setVisibleFlag(flag);
    };

    const sortByProduct = (sortByKey) => {
        let sortedProduct = [];
        switch (sortByKey) {
            case 0:
                sortedProduct = [...presentations.sort((a, b) => parseInt(a?.ab_order) - parseInt(b?.ab_order))];
                break;
            case 1:
                sortedProduct = [...presentations.sort((a, b) => a.title.localeCompare(b.title))];
                break;
            case 2:
                sortedProduct = [
                    ...presentations.filter(e => e.publicationDate).sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)),
                    ...presentations.filter(e => !e.publicationDate).sort((a, b) => a.title.localeCompare(b.title))
                ];
                break;
            case 3:
                sortedProduct = [...presentations.sort((a, b) => new Date(b.date_added) - new Date(a.date_added))];
                break;
            case 4:
                const authSortByPresenter = presentations.map((item) => {
                    if (item?.authorInfo?.length) {
                        const authInfo = [...item?.authorInfo];
                        return {
                            ...item,
                            authorInfo: [...authInfo.sort((a, b) => a.presenter === b.presenter ? 0 : a.presenter ? -1 : 1)]
                        }
                    }
                    return item
                });
                sortedProduct = [
                    ...authSortByPresenter.filter((item) => item?.authorInfo?.length)
                        .sort((a, b) => a?.authorInfo?.[0]?.lastname.localeCompare(b?.authorInfo?.[0].lastname)),
                    ...authSortByPresenter.filter((item) => !item?.authorInfo?.length).sort((a, b) => a.title.localeCompare(b.title))
                ]
            default:
                break;
        }
        // setChildProduct(sortedProduct);
        handleSortPresentation(sortedProduct);
    }

    useEffect(() => {
        const refinedKey = sortByItem.findIndex((item) => item.isRefined);
        sortByProduct(refinedKey);
    }, [sortByItem])

    const handleSortBy = (e, key) => {
        e.preventDefault();
        setSortByItem((prev) => {
            return prev.map((item, index) => {
                if (key === index) {
                    return { ...item, isRefined: true }
                } else {
                    return { ...item, isRefined: false }
                }
            })
        });
    }

    const MenuItem = () => {
        return (
            <Menu className="refinements-filter-body-wrapper">
                <Menu.Item>
                    <div className="gx-algolia-sidebar">
                        <h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Sort By</h3>
                        <hr className="gx-mt-0 filter-divider" />
                        {sortByItem?.map((item, index) => (
                            <div key={item.value}>
                                <a
                                    style={{ fontWeight: item?.isRefined ? 'bold' : '' }}
                                    onClick={(e) => handleSortBy(e, index)}
                                >
                                    <div style={{ color: item?.isRefined ? styling['primary-dark-color'] || "#993333" : "#1d3063", paddingBottom: 10 }}>{item.label}</div>
                                </a>
                            </div>
                        ))}
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <div className="refinements-filter-wrapper gx-mr-2">
            <Dropdown
                trigger={"click"}
                overlay={MenuItem}
                visible={visible}
                overlayClassName={hideOverlay ? "filter-body-wrapper-hide" : ""}
                onVisibleChange={handleVisibleChange}
            >
                <div>
                    <div
                        className={
                            visible
                                ? "gx-d-flex gx-align-items-center gx-justify-content-between filter-header filter-header-active"
                                : "gx-d-flex gx-align-items-center gx-justify-content-between filter-header"
                        }
                    >
                        <div>{'SortBy'}</div>
                        <div className="gx-d-flex gx-align-items-center">
                            {visible ? <Up style={{ width: '1em' }} /> : <CaretDownFilled />}
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>
    )
}

export default PresentationSortBy;
