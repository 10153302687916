import React, { useEffect, useState } from 'react';
import LeftMenu from './LeftMenu';
import CompanyHeader from './CompanyHeader';
import ExhibitorCatalogue from './ExhibitorCatalogue';
import CreateCompany from './CreateCompany';

import {
	requestUserCompanyAffiliationData,
	requestExhibitorCartDetails,
	requestExhibitorCart,
	requestToAddRegistrationItemsToCart,
	requestResetCart,
	requestLoginFormData,
	requestToUpdateFinancialItemsFormState,
	requestToFetchExhibitorCartUuid,
	requestToDeleteRegistrationItemFromCart,
	requestAuthenticateUser,
} from '../../appRedux/actions';

import { requestEraseCommonData } from '../../appRedux/actions/Common';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '../CircularProgress';
import Widget from '../Widget';
import { Button, Form, Row, Col, Card } from 'antd';
import FormComponent from '../form';
import { parseScreen } from '../../util/form';

import { useGetExhibitorCart } from './hooks/useGetExhibitorCart';
import { useParams } from 'react-router';
import RegistrationItems from '../Common/RegistrationItems';
import ProgressBar from './ProgressBar';
import { ReactComponent as FormIcon } from '../../assets/icons/corporateForm.svg';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { goToFirstError, generateItems } from './helpers/generalHelpers';
import { useMenu } from './hooks/useMenu';
import { ReactComponent as BagIcon } from 'assets/icons/bag.svg';
import MiddlewareLMap from '../FloorPlan/MiddlewareLMap';

const ExhibitorFinancialItems = props => {
	const publicEmbed = props.settings.publicEmbed;

	const [loader, setLoader] = useState(true);
	const [companyCreated, setCompanyCreated] = useState(false);
	const [financialItemsMenuItems, setFinancialItemsMenuItems] = useState([]);

	const [currentFinancialItem, setCurrentFinancialItem] = useState({});
	const [validate, setValidate] = useState(false);
	const [formState, setFormState] = useState({});
	const [initialValues, setInitialValues] = useState({});

	const [formControl] = Form.useForm();
	const [isInGoodStanding, setIsInGoodStanding] = useState(false);
	const [validationsObject, setValidationsObject] = useState({
		error: false,
		validationErrors: {},
	});
	const { moduleUrlId } = useParams();
	const [loading, menu, generalSettings] = useMenu();
	const [screenType, setScreenType] = useState('');
	const [subMenuNum, setSubMenuNum] = useState(null);

	const screenWidth = props.settings.width;
	const appdir = useSelector(state => state.loginInfo.appdir);
	const authUser = useSelector(state => state.auth.authUser);
	const isTokenInUrl = props.location?.search?.includes('token');
	const mobileThreshold = props.common.mobileThreshold;

	useEffect(() => {
		if (isTokenInUrl && props.loginInfo.appdir) {
			const urlParams = new URLSearchParams(props.location.search);
			let formData = new FormData();
			formData.append('uuid', urlParams.get('uuid'));
			formData.append('sso_token', urlParams.get('token'));
			props.requestAuthenticateUser({
				appdir: props.loginInfo.appdir,
				formData: formData,
			});
		}
	}, [props.loginInfo.appdir]);

	useEffect(() => {
		if (
			props.corporateMembership.currentFinancialItem > -1 &&
			financialItemsMenuItems.length > 0
		) {
			setCurrentFinancialItem(
				financialItemsMenuItems[props.corporateMembership.currentFinancialItem],
			);
		}
	}, [props.corporateMembership.currentFinancialItem, financialItemsMenuItems]);

	useEffect(() => {
		if (props.corporateMembership.exhibitorCart?.simpleInvoiceObj) {
			setInitialValues(props.corporateMembership.exhibitorCart.simpleInvoiceObj);
		}
		props.requestEraseCommonData(props);
	}, []);

	useEffect(() => {
		if (props.corporateMembership.exhibitorCart?.simpleInvoiceObj) {
			setInitialValues(props.corporateMembership.exhibitorCart?.simpleInvoiceObj);
		}
	}, [props.corporateMembership?.exhibitorCart?.simpleInvoiceObj]);

	useEffect(() => {
		if (publicEmbed) {
			setLoader(false);
			props.requestLoginFormData({
				appdir: props.loginInfo.appdir,
			});
		}
	}, [loading, publicEmbed, appdir]);

	useEffect(() => {
		if (publicEmbed && !loading) {
			const menuItems = menu.filter(menu => menu.screentype === 'SubMenu');

			setFinancialItemsMenuItems(menuItems[0].submenu);
			setCurrentFinancialItem(menuItems[0].submenu[0]);
		}
	}, [loading, publicEmbed]);

	useEffect(() => {
		if (publicEmbed && !isTokenInUrl) {
			props.requestToFetchExhibitorCartUuid({
				appDir: props.loginInfo.appdir,
				moduleUrlId: moduleUrlId,
				publicEmbed: true,
			});
		}
		if (props.corporateMembership.firstPaymentMade && !publicEmbed) {
			props.requestResetCart(props);
		}
	}, [publicEmbed]);

	useEffect(() => {
		if (
			!props.corporateMembership.userCompanyAffiliationFetched &&
			props.loginInfo.appdir &&
			props.auth.jwt
		) {
			props.requestUserCompanyAffiliationData({
				appDir: props.loginInfo.appdir,
				urlId: moduleUrlId,
			});
		}
	}, [props.loginInfo.appdir, publicEmbed, props.auth.jwt]);

	useEffect(() => {
		if (props.corporateMembership.userCompanyAffiliationFetched) {
			setLoader(false);
			if (
				props.corporateMembership.userCompanyAffiliation['comp_uuid'] &&
				(!publicEmbed || isTokenInUrl)
			) {
				setCompanyCreated(true);

				if (!props.corporateMembership.exhibitorCartDetailsFetched) {
					props.requestExhibitorCartDetails({
						appDir: props.loginInfo.appdir,
						urlId: moduleUrlId,
						compUuid: props.corporateMembership.userCompanyAffiliation['comp_uuid'],
					});
				}
			} else if (!publicEmbed) {
				props.history.push('home');
			}
		}
	}, [
		props.corporateMembership.userCompanyAffiliationFetched,
		publicEmbed,
		props.corporateMembership.userCompanyAffiliation?.comp_uuid,
	]);

	useGetExhibitorCart(
		props.corporateMembership,
		moduleUrlId,
		props.requestExhibitorCart,
		!publicEmbed,
	);

	useEffect(() => {
		if (!publicEmbed && !loading) {
			if (menu) {
				const menuItems = menu.filter(menu => menu.screentype === 'SubMenu');
				setScreenType(menu.screentype);
				setFinancialItemsMenuItems(menuItems[0].submenu);

				setCurrentFinancialItem(menuItems[0].submenu[0]);
			} else {
				props.history.push(`/main/profilehome`);
			}
		}
	}, [loading, publicEmbed]);

	//LEGACY CODE, LEAVING COMMENT OUT IN CASE WE NEED TO COME BACK LATER ON
	//COMPANY EXPIRY CHECK DONE ON THE BACKEND. WE DON'T NEED isInGoodStanding variable
	// useEffect(() => {
	// 	if (
	// 		!publicEmbed &&
	// 		!loading &&
	// 		props.corporateMembership.companyFetched &&
	// 		moment(props.corporateMembership.company.expiry_date).diff(moment.now(), 'days') >
	// 			generalSettings.renewal_period_days
	// 	) {
	// 		setIsInGoodStanding(true);
	// 	}
	// }, [loading, props.corporateMembership.companyFetched, publicEmbed]);

	useEffect(() => {
		if (props.corporateMembership.exhibitorCartFetched) {
			setInitialValues(props.corporateMembership.exhibitorCart.simpleInvoiceObj);
		}
	}, [
		props.corporateMembership.exhibitorCartFetched,
		props.corporateMembership.exhibitorCart.simpleInvoiceObj,
	]);

	// watches for when cart is changed
	useEffect(() => {
		if (props.corporateMembership.exhibitorCart.hasOwnProperty('SUCCESS') && !publicEmbed) {
			if (props.corporateMembership.exhibitorCart.SUCCESS === 1) {
				if (!props.corporateMembership.exhibitorCartDetailsFetched) {
					props.requestExhibitorCartDetails({
						appDir: props.loginInfo.appdir,
						urlId: moduleUrlId,
						compUuid: props.corporateMembership.userCompanyAffiliation['comp_uuid'],
					});
				}
			}
		}
	}, [props.corporateMembership.exhibitorCart, publicEmbed]);

	useEffect(() => {
		if (props.corporateMembership.exhibitorCart.hasOwnProperty('SUCCESS') && !publicEmbed) {
			if (
				props.corporateMembership.exhibitorCart.SUCCESS === 1 &&
				props.corporateMembership.exhibitorCartDetails.exhibuuid !==
					props.corporateMembership.oldExhibitorUuid
			) {
				props.requestExhibitorCart({
					compUuid: props.corporateMembership.userCompanyAffiliation['comp_uuid'],
					urlId: moduleUrlId,
					exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
				});
			}
		}
	}, [props.corporateMembership.exhibitorCartDetails.exhibuuid, publicEmbed]);

	useEffect(() => {
		if (isTokenInUrl) {
			if (Object.keys(formState).length > 0) {
				const itemsToAdd = generateItems(formState);
				props.requestToUpdateFinancialItemsFormState(itemsToAdd);
				props.requestToAddRegistrationItemsToCart({
					form: {
						itemstoadd: itemsToAdd,
					},
					urlId: moduleUrlId,
					appDir: props.loginInfo.appdir,
					isTokenInUrl,
					compUuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
					exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
				});
			}
		} else {
			if (
				publicEmbed &&
				Object.keys(formState).length > 0 &&
				props.corporateMembership.exhibitorCartDetails.exhibitorCartUuid
			) {
				const itemsToAdd = generateItems(formState);
				props.requestToUpdateFinancialItemsFormState(itemsToAdd);
				props.requestToAddRegistrationItemsToCart({
					form: {
						itemstoadd: itemsToAdd,
					},
					urlId: moduleUrlId,
					appDir: props.loginInfo.appdir,
					publicEmbed: publicEmbed,
					cartUuid: props.corporateMembership.exhibitorCartDetails.exhibitorCartUuid,
				});
			}
		}
	}, [formState]);

	const deleteItem = item => {
		// Redux action to be dispatched to delete cart item with that id.
		props.requestToDeleteRegistrationItemFromCart({
			form: {
				itemstodelete: {
					field_id: item.fieldId,
					invoice_id: item.invoiceId,
				},
			},
			urlId: moduleUrlId,
			appDir: props.loginInfo.appdir,
			exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
			compUuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
			publicEmbed: publicEmbed,
			cartUuid: props.corporateMembership.exhibitorCartDetails.exhibitorCartUuid,
			authUser: authUser,
		});
	};

	const clickBack = () => {
		let financialItem = '';

		financialItem = financialItemsMenuItems.find(financialItem => {
			return financialItem.menu_order === currentFinancialItem.menu_order - 1;
		});

		setCurrentFinancialItem(financialItem);
	};

	const handleSubmit = event => {
		//prevent double submit of button, it can't differenate between react and html button so sometimes it submits twice

		event.preventDefault();

		let topElement = document.querySelector('#root > div > div > div > div:nth-child(1) > div');
		if (topElement) {
			if (topElement.scrollIntoViewIfNeeded) {
				topElement.scrollIntoViewIfNeeded();
			} else {
				topElement.scrollIntoView();
			}
		}

		setValidate(true);
		if (validationsObject['error']) {
			goToFirstError(validationsObject);
			NotificationManager.warning('You are missing something', 'Validation');
			return;
		}

		const financialItem = financialItemsMenuItems.find(financialItem => {
			return financialItem.menu_order === currentFinancialItem.menu_order + 1;
		});

		setSubMenuNum(financialItem?.menu_order);

		if (financialItem) {
			setCurrentFinancialItem(financialItem);
			// return;
		}

		if (publicEmbed) {
			if (!financialItem) {
				props.history.push(`/main/public/company/${moduleUrlId}/contactinfo`);
			}
			return;
		}
		const itemsToAdd = generateItems(formState);
		props.requestToAddRegistrationItemsToCart({
			form: {
				itemstoadd: itemsToAdd,
			},
			urlId: moduleUrlId,
			exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
			compUuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
		});

		if (financialItemsMenuItems.length === 1) {
			props.history.push(`/main/company/${moduleUrlId}/checkout`);
		}

		if (!subMenuNum) {
			return;
		}

		if (subMenuNum === financialItemsMenuItems.length) {
			props.history.push(`/main/company/${moduleUrlId}/checkout`);
		}
	};

	return (
		<div>
			{!loader && (companyCreated || publicEmbed) && (
				<div className="gx-main-content">
					<div className={publicEmbed ? 'gx-mx-4 gx-my-4' : ''}>
						{publicEmbed && !authUser && (
							<div
								style={{
									height: '100px',
									width: '95%',
									marginTop: '3%',
									marginLeft: '2.5%',
									marginBottom: `${screenWidth > 900 ? 0 : '20%'}`,
								}}
							>
								<Card>
									<ProgressBar
										currentStep={currentFinancialItem?.menu_order - 1}
										steps={menu.map(item => {
											return {
												text: item.button,
												order: item.menu_order - 1,
												id: item.menuid,
												type: item.screentype,
											};
										})}
									/>
								</Card>
							</div>
						)}
						{(!publicEmbed || authUser) && (
							<div style={{ width: '100%' }}>
								<CompanyHeader />
							</div>
						)}
						<div style={{ width: '100%' }}>
							<Row justify="space-between">
								{(!publicEmbed || authUser) && (
									<Col span={screenWidth < mobileThreshold ? 24 : 6}>
										<LeftMenu />
									</Col>
								)}
								<Col span={publicEmbed && !authUser ? 24 : 18}>
									<div
										className={
											screenWidth > 900
												? 'gx-d-flex'
												: 'gx-d-flex gx-flex-column'
										}
									>
										<div style={{ 'flex-basis': '75%' }}>
											{isInGoodStanding && (
												<Card
													className={`card ${
														screenWidth > mobileThreshold
															? 'gx-ml-5'
															: ''
													}`}
												>
													<div
														dangerouslySetInnerHTML={{
															__html:
																generalSettings.store_blocked_message,
														}}
													/>
												</Card>
											)}
											{!isInGoodStanding && (
												<div>
													{loading ? (
														<CircularProgress />
													) : (
														financialItemsMenuItems.length > 0 && (
															<Card
																style={{
																	margin:
																		publicEmbed && !authUser
																			? '3%'
																			: '',
																}}
																className={`card`}
																key={currentFinancialItem?.menuid}
															>
																<div className="gx-d-flex gx-justify-content-between">
																	{(currentFinancialItem?.menu_order >
																		1 ||
																		currentFinancialItem?.catalogue_order >
																			1) && (
																		<Button
																			onClick={clickBack}
																			className="login-form-button gx-mt-1 gx-mb-3 button-primary"
																			style={{
																				boxShadow:
																					'6px 6px 2px rgba(45, 45, 45, 0.65)',
																				border:
																					'transparent',
																			}}
																			shape={'round'}
																		>
																			Back
																		</Button>
																	)}

																	{currentFinancialItem.screentype ===
																		'catalogue' && (
																		<Button
																			onClick={handleSubmit}
																			className="login-form-button gx-mt-1 gx-mb-3 button-primary"
																			style={{
																				boxShadow:
																					'6px 6px 2px rgba(45, 45, 45, 0.65)',

																				border:
																					'transparent',
																			}}
																			shape={'round'}
																		>
																			Next
																		</Button>
																	)}
																</div>
																{currentFinancialItem.button &&
																	currentFinancialItem.screentype !==
																		'catalogue' && (
																		<div>
																			<h2 className="fg-primary">
																				{
																					currentFinancialItem?.button
																				}
																			</h2>
																		</div>
																	)}

																<div
																	style={{ display: 'flow-root' }}
																>
																	<div
																		style={{ float: 'left' }}
																		key={
																			currentFinancialItem?.menuid
																		}
																		dangerouslySetInnerHTML={{
																			__html:
																				currentFinancialItem?.blurb,
																		}}
																	></div>
																	<div
																		className="gx-d-flex gx-flex-row-reverse gx-mb-3"
																		style={{
																			float: 'right',
																		}}
																	>
																		<FormIcon
																			className="fg-primary-dark-light fg-primary-dark-light"
																			style={{
																				width: '3em',
																			}}
																		/>
																	</div>
																</div>
																<div>
																	{publicEmbed &&
																		authUser &&
																		!props.corporateMembership
																			?.userCompanyAffiliation
																			?.comp_uuid && (
																			<CreateCompany />
																		)}
																</div>
																{/* this is only used in the exhibitor module, Mari built a specific subItem in the formComponent to handle this*/}
																{currentFinancialItem.screentype ==
																	'Floorplan' && <hr />}
																<div>
																	{
																		<div
																			style={{
																				marginLeft: '2%',
																			}}
																		>
																			{props.loginInfo
																				.source_hex &&
																				currentFinancialItem.screentype !==
																					'Floorplan' &&
																				(props
																					.corporateMembership
																					?.userCompanyAffiliation
																					?.comp_uuid ||
																					!authUser) && (
																					<FormComponent
																						form={
																							formControl
																						}
																						initialValues={
																							initialValues
																						}
																						formData={parseScreen(
																							currentFinancialItem,
																						)}
																						validationConfig={{
																							module:
																								'exhibitor',
																							moduleurlid: moduleUrlId,
																							menuid:
																								currentFinancialItem[
																									'menuid'
																								],
																						}}
																						onChange={(
																							validations,
																							all,
																						) => {
																							setValidationsObject(
																								validations,
																							);
																							let tmpFormState = {
																								...formState,
																								...{
																									...all,
																								},
																							};
																							Object.keys(
																								tmpFormState,
																							).forEach(
																								key =>
																									tmpFormState[
																										key
																									] ===
																										undefined ||
																									tmpFormState[
																										key
																									] ==
																										-1
																										? delete tmpFormState[
																												key
																										  ]
																										: {},
																							);
																							setFormState(
																								tmpFormState,
																							);
																							setInitialValues(
																								{
																									...initialValues,
																								},
																							);
																						}}
																						validate={
																							validate
																						}
																					/>
																				)}

																			{currentFinancialItem.screentype ===
																				'catalogue' && (
																				<ExhibitorCatalogue
																					catalogue={
																						currentFinancialItem
																					}
																				/>
																			)}

																			{currentFinancialItem.screentype ===
																				'Floorplan' && (
																				<div>
																					<MiddlewareLMap
																						handleSubmit={
																							handleSubmit
																						}
																					/>
																				</div>
																			)}
																		</div>
																	}

																	{currentFinancialItem.screentype !=
																		'Floorplan' && (
																		<Button
																			onClick={handleSubmit}
																			className="login-form-button gx-mt-1 button-primary"
																			style={{
																				boxShadow:
																					'6px 6px 2px rgba(45, 45, 45, 0.65)',

																				border:
																					'transparent',

																				width: '10rem',
																			}}
																			shape={'round'}
																		>
																			Next
																		</Button>
																	)}
																</div>
															</Card>
														)
													)}
												</div>
											)}
										</div>

										{publicEmbed && !authUser && (
											<div
												className="gx-mt-4"
												style={{ 'flex-basis': '25%' }}
											>
												<Card
													className="card"
													title={
														<div>
															<div
																style={{
																	float: 'left',
																	paddingTop: '2%',
																}}
																className="gx-text-secondary gx-font-weight-semi-bold gx-page-title gx-ml-1 gx-mt-1"
															>
																{
																	menu.find(item => {
																		return (
																			item.screentype ===
																			'Checkout'
																		);
																	})?.button
																}
															</div>
															<BagIcon
																className="fg-primary-dark-light"
																style={{
																	float: 'right',
																	width: '3em',
																}}
															/>
														</div>
													}
													style={{
														margin: publicEmbed ? '3%' : '',
													}}
												>
													<RegistrationItems
														cartItems={
															props.corporateMembership.exhibitorCart
														}
														onDelete={deleteItem}
														cartProcessView={true}
														blockDelete={true}
													/>
												</Card>
											</div>
										)}
									</div>
								</Col>

								{/* If we're logged in we're not to use this part */}
								{/* {publicEmbed && authUser && (
									<Col xl={5} lg={24} md={24} sm={24} xs={24}>
										<div className="gx-mt-4 gx-mr-5">
											<Card
												className="card"
												title={
													<div>
														<div
															style={{
																float: 'left',
																paddingTop: '2%',
															}}
															className="gx-text-secondary gx-font-weight-semi-bold gx-page-title gx-ml-1 gx-mt-1"
														>
															{
																menu.find(item => {
																	return (
																		item.screentype ===
																		'Checkout'
																	);
																})?.button
															}
														</div>
														<BagIcon
															className="fg-primary-dark-light"
															style={{
																float: 'right',
																width: '2em',
															}}
														/>
													</div>
												}
												style={
													{
														// margin: publicEmbed ? '3%' : '',
													}
												}
											>
												<RegistrationItems
													cartItems={
														props.corporateMembership.exhibitorCart
													}
													onDelete={deleteItem}
													blockDelete={true}
													cartProcessView={true}
												/>
											</Card>
										</div>
									</Col>
								)} */}
							</Row>
						</div>
					</div>
					<NotificationContainer />
				</div>
			)}
			{loader && (
				<Widget styleName="gx-card">
					<CircularProgress className="gx-profile-content" />
				</Widget>
			)}
		</div>
	);
};
function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestUserCompanyAffiliationData: requestUserCompanyAffiliationData,
			requestExhibitorCartDetails: requestExhibitorCartDetails,
			requestExhibitorCart: requestExhibitorCart,
			requestToAddRegistrationItemsToCart: requestToAddRegistrationItemsToCart,
			requestLoginFormData: requestLoginFormData,
			requestToUpdateFinancialItemsFormState: requestToUpdateFinancialItemsFormState,
			requestToFetchExhibitorCartUuid: requestToFetchExhibitorCartUuid,
			requestResetCart: requestResetCart,
			requestToDeleteRegistrationItemFromCart: requestToDeleteRegistrationItemFromCart,
			requestEraseCommonData: requestEraseCommonData,
			requestAuthenticateUser: requestAuthenticateUser,
		}),
		dispatch,
	);
}
function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
		loginInfo: state.loginInfo,
		settings: state.settings,
		auth: state.auth,
		common: state.common,
	};
}
export default connect(mapStateToProps, matchDispatchToProps)(ExhibitorFinancialItems);
