import {
	REQUEST_CONSUMPTION_PRODUCTS,
	RECEIVE_CONSUMPTION_PRODUCTS,
	REQUEST_CONSUMPTION_TAGS,
	RECEIVE_CONSUMPTION_TAGS,
	REQUEST_CONSUMPTION_CATEGORIES,
	RECEIVE_CONSUMPTION_CATEGORIES,
	REQUEST_CONSUMPTION_PRODUCT_INFO,
	RECEIVE_CONSUMPTION_PRODUCT_INFO,
	REQUEST_CONSUMPTION_BUNDLE_INFO,
	RECEIVE_CONSUMPTION_BUNDLE_INFO,
	REQUEST_CONSUMPTION_PRODUCT_ANALYSIS,
	RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS,
	REQUEST_CONSUMPTION_FILES_ANALYSIS,
	RECEIVE_CONSUMPTION_FILES_ANALYSIS,
	STORE_CARDS_VIEW_TYPE,
	STORE_SORT_TYPE,
	STORE_EMBED_MODE,
	RECEIVE_OPEN_ACCESS_CONFIG,
	REQUEST_STORE_SEARCH_STATE,
	RECEIVE_VIDEO_START,
	REQUEST_VIDEO_START,
	RECEIVE_END_VIDEO,
	REQUEST_END_VIDEO,
	REQUEST_VIDEO_SEEK_TIME,
	RECEIVE_VIDEO_SEEK_TIME
} from 'constants/ActionTypes';

export const LOADING_CONSUMPTION_PRODUCTS = 'LOADING_CONSUMPTION_PRODUCTS';
export const LOADING_CONSUMPTION_TAGS = 'LOADING_CONSUMPTION_TAGS';
export const LOADING_CONSUMPTION_CATEGORIES = 'LOADING_CONSUMPTION_CATEGORIES';

export const requestProducts = payload => ({
	type: REQUEST_CONSUMPTION_PRODUCTS,
	payload,
});

export const loadingProducts = payload => ({
	type: LOADING_CONSUMPTION_PRODUCTS,
	payload,
});

export const receiveProducts = payload => ({
	type: RECEIVE_CONSUMPTION_PRODUCTS,
	payload,
});

export const requestTags = payload => ({
	type: REQUEST_CONSUMPTION_TAGS,
	payload,
});

export const loadingTags = payload => ({
	type: LOADING_CONSUMPTION_TAGS,
	payload,
});

export const receiveTags = payload => ({
	type: RECEIVE_CONSUMPTION_TAGS,
	payload,
});

export const requestCategories = payload => ({
	type: REQUEST_CONSUMPTION_CATEGORIES,
	payload,
});

export const loadingCategories = payload => ({
	type: LOADING_CONSUMPTION_CATEGORIES,
	payload,
});

export const receiveCategories = payload => ({
	type: RECEIVE_CONSUMPTION_CATEGORIES,
	payload,
});

export const requestProductInfo = payload => ({
	type: REQUEST_CONSUMPTION_PRODUCT_INFO,
	payload,
});

export const receiveProductInfo = payload => ({
	type: RECEIVE_CONSUMPTION_PRODUCT_INFO,
	payload,
});

export const requestBundleInfo = payload => ({
	type: REQUEST_CONSUMPTION_BUNDLE_INFO,
	payload,
});

export const receiveBundleInfo = payload => ({
	type: RECEIVE_CONSUMPTION_BUNDLE_INFO,
	payload,
});

export const storeProductAnalysisRequest = payload => ({
	type: REQUEST_CONSUMPTION_PRODUCT_ANALYSIS,
	payload,
});

export const storeProductAnalysisSuccess = payload => ({
	type: RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS,
	payload,
});

export const storeFileAnalysisRequest = payload => ({
	type: REQUEST_CONSUMPTION_FILES_ANALYSIS,
	payload,
});

export const storeFileAnalysisSuccess = payload => ({
	type: RECEIVE_CONSUMPTION_FILES_ANALYSIS,
	payload,
});

export const storeCardsViewType = payload => ({
	type: STORE_CARDS_VIEW_TYPE,
	payload,
});

export const REQUEST_CONSUMPTION_CONFIG = 'REQUEST_CONSUMPTION_CONFIG';
export const LOADING_CONSUMPTION_CONFIG = 'LOADING_CONSUMPTION_CONFIG';
export const RECEIVE_CONSUMPTION_CONFIG = 'RECEIVE_CONSUMPTION_CONFIG';

export const requestConfig = payload => ({
	type: REQUEST_CONSUMPTION_CONFIG,
	payload,
});

export const loadingConfig = payload => ({
	type: LOADING_CONSUMPTION_CONFIG,
	payload,
});

export const receiveConfig = payload => ({
	type: RECEIVE_CONSUMPTION_CONFIG,
	payload,
});

export const storeConsumptionSortType = payload => ({
	type: STORE_SORT_TYPE,
	payload
});

export const requestStoreEmbedMode = payload => ({
	type: STORE_EMBED_MODE,
	payload
});

export const requestStoreSearchState = payload => ({
	type: REQUEST_STORE_SEARCH_STATE,
	payload
})

export const requestStoreAccessConfig = payload => ({
	type: RECEIVE_OPEN_ACCESS_CONFIG,
	payload
});

export const requestToStartVideo = payload => ({
	type: REQUEST_VIDEO_START,
	payload
});

export const storeVideoStartSuccess = payload => ({
	type: RECEIVE_VIDEO_START,
	payload
});

export const requestEndVideo = payload => ({
	type: REQUEST_END_VIDEO,
	payload
});

export const receiveEndVideo = payload => ({
	type: RECEIVE_END_VIDEO,
	payload
});

export const requestVideoSeekTime = payload => ({
	type: REQUEST_VIDEO_SEEK_TIME,
	payload
});

export const receiveVideoSeekTime = payload => ({
	type: RECEIVE_VIDEO_SEEK_TIME,
	payload
});