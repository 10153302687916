import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import {
	createCompanySuccess,
	fetchUserCompanyAffiliationSuccess,
	fetchExhibitorCartDetailsSuccess,
	fetchMenuSuccess,
	fetchCompanyMembersSuccess,
	fetchCompanySuccess,
	makeUserAdminSuccess,
	removeUserAdminSuccess,
	removeUserFromGroupSuccess,
	removeUserMembershipSuccess,
	updateCompanyProfileSuccess,
	addUserMembershipSuccess,
	fetchPaymentSettingsSuccess,
	updateUserContactInformationSuccess,
	addNewUserContactSuccess,
	fetchExhibitorCartSuccess,
	sendEmailToUsersSuccess,
	addRegistrationItemsToCartSuccess,
	deleteRegistrationItemFromCartSuccess,
	fetchUserContactInfoFieldValuesSuccess,
	userContactInfoFieldValuesError,
	updateCompanyLogoSuccess,
	renewAllSuccess,
	addUserToCompanySuccess,
	receiveEraseCorporateMembershipData,
	receiveResetCart,
	fetchCompanyProfileFieldValuesSuccess,
	deleteUserCompanyAffiliationSuccess,
	updateFinancialItemsFormStateSuccess,
	fetchExhibitorCartUuidSuccess,
	exhibitorSignUpSuccess,
	makeCartPublicToPrivateSuccess,
	deleteExhibitorPaymentSuccess,
	fetchRolesSuccess,
	updateRolesSuccess,
	fetchCompanyMediaValuesSuccess,
	uploadTos3SagaSuccess,
	addUpdateDeleteMediaResourceSagaSuccess,
	sortMediaResourceSagaSuccess,
	receiveCorporateMembershipModuleUrlId,
	receiveRemoveUserPublic,
	receiveMakeUserPublic,
	receiveMiniProfileFields,
	receiveUpdateMiniProfileFields,
	receiveCreateMiniProfileFields,
	receiveAddCompanyUser,
} from '../actions/CorporateMembership';
import { exhibitorAuthSignUpSuccess } from '../actions/Auth';
import auth from '../../loginAuth/loginAuth';

import {
	CREATE_COMPANY,
	FETCH_USER_COMPANY_AFFILIATION,
	FETCH_EXHIBITOR_CART_DETAILS,
	FETCH_MENU,
	FETCH_COMPANY_MEMBERS,
	FETCH_COMPANY,
	MAKE_USER_ADMIN,
	REMOVE_USER_ADMIN,
	REMOVE_USER_FROM_GROUP,
	REMOVE_USER_MEMBERSHIP,
	UPDATE_COMPANY_PROFILE,
	ADD_USER_MEMBERSHIP,
	FETCH_PAYMENT_SETTINGS,
	UPDATE_USER_CONTACT_INFORMATION,
	ADD_NEW_USER_CONTACT,
	FETCH_EXHIBITOR_CART,
	SEND_EMAIL_TO_USERS,
	ADD_REGISTRATION_ITEMS_TO_CART,
	DELETE_REGISTRATION_ITEM_FROM_CART,
	FETCH_USER_CONTACT_INFO_FIELD_VALUES,
	UPDATE_COMPANY_LOGO,
	RENEW_ALL,
	ADD_USER_TO_COMPANY,
	REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA,
	REQUEST_RESET_CART,
	FETCH_COMPANY_PROFILE_FIELD_VALUES,
	DELETE_USER_COMPANY_AFFILIATION,
	UPDATE_FINANCIAL_ITEMS_FORM_STATE,
	FETCH_EXHIBITOR_CART_UUID,
	EXHIBITOR_SIGNUP,
	MAKE_CART_PUBLIC_TO_PRIVATE,
	SEND_MAGIC_LOGIN_LINK,
	DELETE_EXHIBITOR_PAYMENT,
	FETCH_ROLES,
	UPDATE_ROLES,
	FETCH_COMPANY_MEDIA,
	UPLOAD_TO_S3,
	ADD_UPDATE_DELETE_MEDIA_RESOURCE,
	SORT_MEDIA_RESOURCE,
	REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID,
	REQUEST_MAKE_USER_PUBLIC,
	REQUEST_REMOVE_USER_PUBLIC,
	REQUEST_MINI_PROFILE_FIELDS,
	REQUEST_UPDATE_MINI_PROFILE_FIELDS,
	REQUEST_CREATE_MINI_PROFILE_FIELDS,
	REQUEST_ADD_COMPANY_USER,
} from '../../constants/ActionTypes';
import api from '../../api';
import { fetchError } from '../actions/Common';

const createCompany = async context => {
	return await api.createCompany(context.payload).then(res => {
		return res.data;
	});
};

const fetchUserCompanyAffiliation = async context => {
	return await api.getUserCompanyAffiliation().then(res => {
		return res.data;
	});
};

const fetchExhibitorCartDetails = async context => {
	return await api
		.getExhibitorCartDetails(
			context.payload.appDir,
			context.payload.urlId,
			context.payload.compUuid,
		)
		.then(res => {
			return res.data;
		});
};

const fetchMenu = async context => {
	return await api
		.getMenu(
			context.payload.appDir,
			context.payload.urlId,
			context.payload.compUuid,
			context.payload.publicEmbed,
			context.payload.exhibUuid,
			context.payload.isTokenInUrl,
		)
		.then(res => {
			return res.data;
		});
};

const fetchCompanyProfileFields = async context => {
	return await api
		.getCompanyProfileFields(
			context.payload.urlId,
			context.payload.appDir,
			context.payload.publicEmbed,
		)
		.then(res => {
			return res.data;
		});
};

const fetchUserContactInfoFields = async context => {
	return await api
		.getUserContactInfoFields(
			context.payload.urlId,
			context.payload.appDir,
			context.payload.publicEmbed,
		)
		.then(res => {
			return res.data;
		});
};

const fetchCompanyProfileFieldValues = async context => {
	return await api
		.getCompanyProfileFieldValues(context.payload.urlId, context.payload.compUuid)
		.then(res => {
			return res.data;
		});
};

const fetchCompanyMediaValues = async context => {
	const result = await api.getCompanyMediaValues(
		context.payload.urlId,
		context.payload.appDir,
		context.payload.compUuid ?? context.payload.compuuid,
	);

	return result.data;
};

const fetchS3signatureAndUpload = async context => {
	// key, bucket, content_type
	//FETCH SIGNATURE FOR S3 UPLOAD
	const result = await api.getS3Signature(
		context.payload.key,
		'amz.xcdsystem.com',
		context.payload['Content-Type'],
	);

	//UPLOAD TO S3
	const uploadResult = await api.uploadTos3(
		context.payload.key, //key
		'amz.xcdsystem.com', //bucket
		context.payload['Content-Type'], //Content Type
		context.payload.file, //File to Upload
		result, // S3 sign params(policy, signature)
	);

	return uploadResult;
};

const validateVideoLink = async context => {
	if (context.payload.resource.type === 'youtube') {
		try {
			const youtubeResponse = await api.validateYoutubeVideo(context.payload.resource.url);
			return youtubeResponse.status === 200;
		} catch (e) {
			return false;
		}
	} else if (context.payload.resource.type === 'vimeo') {
		try {
			const vimeoResponse = await api.validateVimeoVideo(context.payload.resource.url);
			return vimeoResponse.status === 200;
		} catch (e) {
			return false;
		}
	}
	return false;
};

const deleteMediaResource = async context => {
	const updateResult = await api.deleteMediaResource(
		context.payload.appdir,
		context.payload.compuuid,
		context.payload.resource,
	);

	return updateResult;
};
const updateMediaResource = async context => {
	const updateResult = await api.updateMediaResource(
		context.payload.appdir,
		context.payload.compuuid,
		context.payload.resource,
	);

	return updateResult;
};
const addMediaResource = async context => {
	const addResult = await api.addMediaResource(
		context.payload.appdir,
		context.payload.compuuid,
		context.payload.resource,
	);

	return addResult;
};

const sortMediaResource = async context => {
	const sortResult = await api.sortMediaResource(
		context.payload.appdir,
		context.payload.compuuid,
		context.payload.resource,
	);

	return sortResult;
};

const fetchCompanyMembers = async context => {
	return await api
		.getCompanyMembers({
			compuuid: context.payload.compuuid,
		})
		.then(res => {
			return res.data;
		});
};

const fetchUserContactInfoFieldValues = async context => {
	return await api
		.getUserContactInfoFieldValues(context.payload.urlId, context.payload.userUuid)
		.then(res => {
			return res.data;
		});
};

const fetchCompany = async context => {
	return await api.getCompany(context.payload.compuuid).then(res => {
		return res.data;
	});
};

const makeUserAdmin = async context => {
	return await api
		.makeUserAdmin({
			compuuid: context.payload.compuuid,
			companycontactUUID: context.payload.companycontactUUID,
		})
		.then(res => {
			return res.data;
		});
};

const removeUserAdmin = async context => {
	return await api
		.removeUserAdmin({
			compuuid: context.payload.compuuid,
			companycontactUUID: context.payload.companycontactUUID,
		})
		.then(res => {
			return res.data;
		});
};

const requestMakeUserPublic = async context => {
	return await api
		.makeUserPublic({
			compuuid: context.payload.compuuid,
			companycontactUUID: context.payload.companycontactUUID,
		})
		.then(res => {
			return res.data;
		});
};

const requestRemoveUserPublic = async context => {
	return await api
		.removeUserPublic({
			compuuid: context.payload.compuuid,
			companycontactUUID: context.payload.companycontactUUID,
		})
		.then(res => {
			return res.data;
		});
};

const removeUserFromGroup = async context => {
	return await api
		.removeUserFromGroup({
			compuuid: context.payload.compuuid,
			companycontactUUID: context.payload.companycontactUUID,
		})
		.then(res => {
			return res.data;
		});
};

const removeUserMembership = async context => {
	return await api
		.removeUserMembership({
			compuuid: context.payload.compuuid,
			groupid: context.payload.groupid,
			companycontactUUID: context.payload.companycontactUUID,
		})
		.then(res => {
			return res.data;
		});
};

const updateCompanyProfile = async context => {
	return await api
		.updateCompanyProfile(context.payload.form, context.payload.urlId, context.payload.compUuid)
		.then(res => {
			return res.data;
		});
};

const addUserMembership = async context => {
	return await api.addUserMembership(context.payload).then(res => {
		return res.data;
	});
};

const fetchPaymentSettings = async context => {
	return await api.getPaymentSettings(context.payload.appDir, context.payload.urlId).then(res => {
		return res.data;
	});
};

const updateUserContactInformation = async context => {
	return await api
		.updateUserContactInformation(context.payload.form, context.payload.urlId)
		.then(res => {
			return res.data;
		});
};

const addNewUserContact = async context => {
	return await api.addNewUserContact(context.payload.form, context.payload.urlId).then(res => {
		return res.data;
	});
};

const fetchExhibitorCart = async context => {
	return await api
		.fetchExhibitorCart(
			context.payload.urlId,
			context.payload.exhibUuid,
			context.payload.compUuid,
			context.payload.publicEmbed,
			context.payload.cartUuid,
			context.payload.appDir,
		)
		.then(res => {
			return res.data;
		});
};

const sendEmailToUsers = async context => {
	return await api.sendEmailToUsers(context.payload.form, context.payload.urlId).then(res => {
		return res.data;
	});
};

const addRegistrationItemsToCart = async context => {
	return await api
		.addRegistrationItemsToCart(
			context.payload.form,
			context.payload.urlId,
			context.payload.exhibUuid,
			context.payload.compUuid,
			context.payload.appDir,
			context.payload.publicEmbed,
			context.payload.cartUuid,
			context.payload.isTokenInUrl,
		)
		.then(res => {
			return res.data;
		});
};

const deleteRegistrationItemFromCart = async context => {
	return await api
		.deleteRegistrationItemFromCart(
			context.payload.form,
			context.payload.urlId,
			context.payload.exhibUuid,
			context.payload.compUuid,
			context.payload.appDir,
			context.payload.publicEmbed,
			context.payload.cartUuid,
			context.payload.authUser,
		)
		.then(res => {
			return res.data;
		});
};

const updateCompanyLogo = async context => {
	return await api.updateCompanyLogo(context.payload).then(res => {
		return res.data;
	});
};

const renewAll = async context => {
	return await api.renewAll(context.payload).then(res => {
		return res.data;
	});
};

const addUserToCompany = async context => {
	return await api.addNewUserContact(context.payload.form, context.payload.urlId).then(res => {
		return res.data;
	});
};

const fetchExhibitorCartUuid = async context => {
	return await api
		.fetchExhibitorCartUuid(
			context.payload.appDir,
			context.payload.moduleUrlId,
			context.payload.publicEmbed,
		)
		.then(res => {
			return res.data;
		});
};

const exhibitorSignUp = async context => {
	const jsonData = await api.exhibitorSignUp(
		context.payload.form,
		context.payload.appDir,
		context.payload.moduleUrlId,
		context.payload.userExists,
	);
	if (jsonData.data.UUID) {
		auth.setCookie('contact_uuid', JSON.stringify(jsonData.data.UUID), 7);
		auth.setCookie('jwt', JSON.stringify(jsonData.data.jwt), 7);
		auth.setCookie('ssotoken', JSON.stringify(jsonData.data.sso_token), 7);
	}

	return jsonData.data;
};

const makeCartPublicToPrivate = async context => {
	return await api
		.makeCartPublicToPrivate(
			context.payload.moduleUrlId,
			context.payload.appDir,
			context.payload.cartUuid,
			context.payload.exhibUuid,
			context.payload.compUuid,
		)
		.then(res => {
			return res.data;
		});
};

const sendMagicLink = async context => {
	return await api
		.sendMagicLink(
			context.payload.appDir,
			context.payload.cartUuid,
			context.payload.moduleUrlId,
			context.payload.email,
		)
		.then(res => {
			NotificationManager.success(res.data?.message, 'Success');
		});
};

const deleteExhibitorPayment = async ({ moduleUrlId, exhibUuid, paymentId }) => {
	return await api.deleteExhibitorPayment(moduleUrlId, exhibUuid, paymentId).then(res => {
		return res.data;
	});
};

const fetchRoles = async ({ compUuid, sourceHex }) => {
	return await api.fetchRoles(compUuid, sourceHex).then(res => {
		return res.data;
	});
};

const updateRoles = async ({ compUuid, sourceHex, uuid, roleIds }) => {
	return await api.updateRoles(compUuid, sourceHex, uuid, roleIds).then(res => {
		return res.data;
	});
};

const fetchMiniProfileFields = async context => {
	return await api.getMiniProfileFields(context.payload.appDir).then(res => {
		return res.data;
	});
};

const fetchUpdateMiniProfileFields = async context => {
	return await api.updateMiniProfileFields(context.payload).then(res => {
		return res.data;
	});
};

const fetchCreateMiniProfileFields = async context => {
	return await api.createMiniProfileFields(context.payload.formData).then(res => {
		return res.data;
	});
};

const fetchAddCompanyUser = async context => {
	return await api.addCompanyUser(context.payload).then(res => {
		return res.data;
	});
};

function* getAddCompanyUserSaga(context) {
	try {
		const result = yield call(fetchAddCompanyUser, context);
		yield put(receiveAddCompanyUser(result));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* getMiniProfileFieldsSaga(context) {
	try {
		const result = yield call(fetchMiniProfileFields, context);
		yield put(receiveMiniProfileFields(result));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* updateMiniProfileFieldsSaga(context) {
	try {
		const result = yield call(fetchUpdateMiniProfileFields, context);
		yield put(receiveUpdateMiniProfileFields(result));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* createMiniProfileFieldsSaga(context) {
	try {
		const result = yield call(fetchCreateMiniProfileFields, context);
		yield put(receiveCreateMiniProfileFields(result));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* createCompanySaga(context) {
	try {
		const company = yield call(createCompany, context);
		yield put(createCompanySuccess(company));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* getCorporateMembershipModuleUrlId(action) {
	try {
		yield put(receiveCorporateMembershipModuleUrlId(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* fetchUserCompanyAffiliationSaga(context) {
	try {
		const userCompanyAffiliation = yield call(fetchUserCompanyAffiliation, context);
		yield put(fetchUserCompanyAffiliationSuccess(userCompanyAffiliation));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchExhibitorCartDetailsSaga(context) {
	try {
		const exhibitorCartDetails = yield call(fetchExhibitorCartDetails, context);
		yield put(fetchExhibitorCartDetailsSuccess(exhibitorCartDetails));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchMenuSaga(context) {
	try {
		let menuItems = yield call(fetchMenu, context);
		const companyProfileFields = yield call(fetchCompanyProfileFields, context);
		const userContactInfoFields = yield call(fetchUserContactInfoFields, context);

		let companyProfileFieldValues = {};
		if (context.payload.compUuid) {
			companyProfileFieldValues = yield call(fetchCompanyProfileFieldValues, context);
		}

		menuItems['attenmenu'] = menuItems['attenmenu'].map(menuItem => {
			if (menuItem['screentype'] === 'ContactInfo') {
				menuItem['fieldids'] = companyProfileFields;
				menuItem['fieldvalues'] = companyProfileFieldValues;
			} else if (menuItem['screentype'] === 'Staff') {
				menuItem['fieldids'] = userContactInfoFields;
			}

			return menuItem;
		});

		yield put(fetchMenuSuccess(menuItems));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchCompanyMembersSaga(context) {
	try {
		const companyMembers = yield call(fetchCompanyMembers, context);
		yield put(fetchCompanyMembersSuccess(companyMembers));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchCompanySaga(context) {
	try {
		const company = yield call(fetchCompany, context);
		yield put(fetchCompanySuccess(company));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* makeUserAdminSaga(context) {
	try {
		const response = yield call(makeUserAdmin, context);
		yield put(makeUserAdminSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* makeUserPublicSaga(context) {
	try {
		const response = yield call(requestMakeUserPublic, context);
		yield put(receiveMakeUserPublic(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* removeUserPublicSaga(context) {
	try {
		const response = yield call(requestRemoveUserPublic, context);
		yield put(receiveRemoveUserPublic(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* removeUserAdminSaga(context) {
	try {
		const response = yield call(removeUserAdmin, context);
		yield put(removeUserAdminSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* removeUserFromGroupSaga(context) {
	try {
		const response = yield call(removeUserFromGroup, context);
		yield put(removeUserFromGroupSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* removeUserMembershipSaga(context) {
	try {
		const response = yield call(removeUserMembership, context);
		yield put(removeUserMembershipSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* updateCompanyProfileSaga(context) {
	try {
		const response = yield call(updateCompanyProfile, context);
		NotificationManager.success('Profile updated.', 'Success');
		yield put(updateCompanyProfileSuccess(response));
	} catch (error) {
		NotificationManager.warning('Profile update failed', 'Failed');
		yield put(fetchError(error));
	}
}

function* addUserMembershipSaga(context) {
	try {
		const response = yield call(addUserMembership, context);
		yield put(addUserMembershipSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchPaymentSettingsSaga(context) {
	try {
		const response = yield call(fetchPaymentSettings, context);
		yield put(fetchPaymentSettingsSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* updateUserContactInformationSaga(context) {
	try {
		const response = yield call(updateUserContactInformation, context);
		yield put(updateUserContactInformationSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* addNewUserContactSaga(context) {
	try {
		const response = yield call(addNewUserContact, context);
		yield put(addNewUserContactSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchExhibitorCartSaga(context) {
	try {
		const response = yield call(fetchExhibitorCart, context);
		yield put(fetchExhibitorCartSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* sendEmailToUsersSaga(context) {
	try {
		const response = yield call(sendEmailToUsers, context);
		yield put(sendEmailToUsersSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* addRegistrationItemsToCartSaga(context) {
	try {
		const response = yield call(addRegistrationItemsToCart, context);
		yield put(addRegistrationItemsToCartSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* deleteRegistrationItemsFromCartSaga(context) {
	try {
		const response = yield call(deleteRegistrationItemFromCart, context);
		yield put(deleteRegistrationItemFromCartSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchUserContactInfoFieldValuesSaga(context) {
	try {
		const response = yield call(fetchUserContactInfoFieldValues, context);
		yield put(fetchUserContactInfoFieldValuesSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
		yield put(userContactInfoFieldValuesError(context.payload));
	}
}

function* updateCompanyLogoSaga(context) {
	try {
		const response = yield call(updateCompanyLogo, context);
		yield put(updateCompanyLogoSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* renewAllSaga(context) {
	try {
		let response = yield call(renewAll, context);
		yield put(renewAllSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* addUserToCompanySaga(context) {
	try {
		let response = yield call(addUserToCompany, context);
		yield put(addUserToCompanySuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* getEraseCorporateMembershipDataSaga(context) {
	try {
		yield put(receiveEraseCorporateMembershipData(context));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getResetCartSaga(context) {
	try {
		yield put(receiveResetCart(context));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* fetchCompanyProfileFieldValuesSaga(context) {
	try {
		const response = yield call(fetchCompanyProfileFieldValues, context);
		yield put(fetchCompanyProfileFieldValuesSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchCompanyProfileMediaSaga(context) {
	try {
		const response = yield call(fetchCompanyMediaValues, context);
		yield put(fetchCompanyMediaValuesSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* uploadTos3Saga(context) {
	try {
		const response = yield call(fetchS3signatureAndUpload, context);

		yield put(uploadTos3SagaSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* addUpdateDeleteMediaSaga(context) {
	try {
		if (context.payload.delete) {
			// Call Delete API for Video, PDF, Link
			let response = yield call(deleteMediaResource, context);
			response.context = context;
			yield put(addUpdateDeleteMediaResourceSagaSuccess(response, 'delete'));
			return;
		}
		if (['youtube', 'vimeo'].includes(context.payload.resource.type)) {
			// ONLY ADD and UPDATE OPERATION FOR VIDEO
			const validationResult = yield call(validateVideoLink, context);

			if (!validationResult) {
				yield put(
					addUpdateDeleteMediaResourceSagaSuccess(
						{
							data: {
								success: 0,
								message: 'Video link is incorrect',
							},
						},
						'',
					),
				);
				return;
			}

			if (context.payload.resource.RowID) {
				// Update Video Resource
				const response = yield call(updateMediaResource, context);
				yield put(addUpdateDeleteMediaResourceSagaSuccess(response, 'update'));
				return;
			}

			// Add the Video resource
			const response = yield call(addMediaResource, context);
			yield put(addUpdateDeleteMediaResourceSagaSuccess(response, 'add'));
			return;
		}
		if (context.payload.resource.RowID) {
			// Update PDF, Link Resource
			const response = yield call(updateMediaResource, context);
			yield put(addUpdateDeleteMediaResourceSagaSuccess(response, 'update'));
			return;
		}
		// Add PDF, Link resource
		const response = yield call(addMediaResource, context);
		yield put(addUpdateDeleteMediaResourceSagaSuccess(response, 'add'));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* sortMediaResourceSaga(context) {
	try {
		const response = yield call(sortMediaResource, context);
		yield put(sortMediaResourceSagaSuccess(response));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* deleteUserCompanyAffiliationSaga(context) {
	try {
		yield put(deleteUserCompanyAffiliationSuccess(context));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* updateFinancialItemsFormStateSaga(context) {
	try {
		yield put(updateFinancialItemsFormStateSuccess(context.payload));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* fetchExhibitorCartUuidSaga(context) {
	try {
		let response = yield call(fetchExhibitorCartUuid, context);
		yield put(fetchExhibitorCartUuidSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* exhibitorSignUpSaga(context) {
	try {
		let response = yield call(exhibitorSignUp, context);
		if (response.contactcreated === 1) {
			yield put(exhibitorAuthSignUpSuccess(response));
		}
		yield put(exhibitorSignUpSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* makeCartPublicToPrivateSaga(context) {
	try {
		let response = yield call(makeCartPublicToPrivate, context);
		yield put(makeCartPublicToPrivateSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* sendMagicLinkSaga(context) {
	try {
		yield call(sendMagicLink, context);
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* deleteExhibitorPaymentSaga(context) {
	try {
		let response = yield call(deleteExhibitorPayment, context.payload);
		yield put(
			deleteExhibitorPaymentSuccess({
				...response,
				paymentId: context.payload.paymentId,
			}),
		);
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* fetchRolesSaga(context) {
	try {
		let response = yield call(fetchRoles, context.payload);
		yield put(fetchRolesSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

function* updateRolesSaga(context) {
	try {
		let response = yield call(updateRoles, context.payload);
		yield put(updateRolesSuccess(response));
	} catch (error) {
		yield put(fetchError(error));
	}
}

export function* getAddCompanyUser() {
	yield takeLatest(REQUEST_ADD_COMPANY_USER, getAddCompanyUserSaga);
}

export function* getMiniProfileFields() {
	yield takeLatest(REQUEST_MINI_PROFILE_FIELDS, getMiniProfileFieldsSaga);
}

export function* updateMiniProfileFields() {
	yield takeLatest(REQUEST_UPDATE_MINI_PROFILE_FIELDS, updateMiniProfileFieldsSaga);
}

export function* createMiniProfileFields() {
	yield takeLatest(REQUEST_CREATE_MINI_PROFILE_FIELDS, createMiniProfileFieldsSaga);
}

export function* corporateMembershipModuleUrlId() {
	yield takeLatest(REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID, getCorporateMembershipModuleUrlId);
}

export function* createCompanyActionWatcher() {
	yield takeLatest(CREATE_COMPANY, createCompanySaga);
}

export function* fetchUserCompanyAffiliationActionWatcher() {
	yield takeLatest(FETCH_USER_COMPANY_AFFILIATION, fetchUserCompanyAffiliationSaga);
}

export function* fetchExhibitorCartDetailsActionWatcher() {
	yield takeLatest(FETCH_EXHIBITOR_CART_DETAILS, fetchExhibitorCartDetailsSaga);
}

export function* fetchMenuActionWatcher() {
	yield takeLatest(FETCH_MENU, fetchMenuSaga);
}

export function* fetchCompanyMembersActionWatcher() {
	yield takeLatest(FETCH_COMPANY_MEMBERS, fetchCompanyMembersSaga);
}

export function* fetchCompanyActionWatcher() {
	yield takeLatest(FETCH_COMPANY, fetchCompanySaga);
}

export function* fetchCompanyProfileMediaActionWatcher() {
	yield takeLatest(FETCH_COMPANY_MEDIA, fetchCompanyProfileMediaSaga);
}

export function* uploadTos3Watcher() {
	yield takeLatest(UPLOAD_TO_S3, uploadTos3Saga);
}

export function* addUpdateDeleteMediaResourceWatcher() {
	yield takeLatest(ADD_UPDATE_DELETE_MEDIA_RESOURCE, addUpdateDeleteMediaSaga);
}

export function* sortMediaResourceWatcher() {
	yield takeLatest(SORT_MEDIA_RESOURCE, sortMediaResourceSaga);
}

export function* makeUserAdminActionWatcher() {
	yield takeLatest(MAKE_USER_ADMIN, makeUserAdminSaga);
}

export function* makeUserPublicActionWatcher() {
	yield takeLatest(REQUEST_MAKE_USER_PUBLIC, makeUserPublicSaga);
}

export function* removeUserPublicActionWatcher() {
	yield takeLatest(REQUEST_REMOVE_USER_PUBLIC, removeUserPublicSaga);
}

export function* removeUserAdminActionWatcher() {
	yield takeLatest(REMOVE_USER_ADMIN, removeUserAdminSaga);
}

export function* removeUserFromGroupActionWatcher() {
	yield takeLatest(REMOVE_USER_FROM_GROUP, removeUserFromGroupSaga);
}

export function* removeUserMembershipActionWatcher() {
	yield takeLatest(REMOVE_USER_MEMBERSHIP, removeUserMembershipSaga);
}

export function* updateCompanyProfileActionWatcher() {
	yield takeLatest(UPDATE_COMPANY_PROFILE, updateCompanyProfileSaga);
}

export function* addUserMembershipActionWatcher() {
	yield takeLatest(ADD_USER_MEMBERSHIP, addUserMembershipSaga);
}

export function* fetchPaymentSettingsActionWatcher() {
	yield takeLatest(FETCH_PAYMENT_SETTINGS, fetchPaymentSettingsSaga);
}

export function* updateUserContactInformationActionWatcher() {
	yield takeLatest(UPDATE_USER_CONTACT_INFORMATION, updateUserContactInformationSaga);
}

export function* addNewUserContactActionWatcher() {
	yield takeLatest(ADD_NEW_USER_CONTACT, addNewUserContactSaga);
}

export function* fetchExhibitorCartActionWatcher() {
	yield takeLatest(FETCH_EXHIBITOR_CART, fetchExhibitorCartSaga);
}

export function* sendEmailToUsersActionWatcher() {
	yield takeLatest(SEND_EMAIL_TO_USERS, sendEmailToUsersSaga);
}

export function* addRegistrationItemsToCartActionWatcher() {
	yield takeLatest(ADD_REGISTRATION_ITEMS_TO_CART, addRegistrationItemsToCartSaga);
}

export function* deleteRegistrationItemFromCartActionWatcher() {
	yield takeLatest(DELETE_REGISTRATION_ITEM_FROM_CART, deleteRegistrationItemsFromCartSaga);
}

export function* fetchUserContactInfoFieldValuesActionWatcher() {
	yield takeLatest(FETCH_USER_CONTACT_INFO_FIELD_VALUES, fetchUserContactInfoFieldValuesSaga);
}

export function* updateCompanyLogoActionWatcher() {
	yield takeLatest(UPDATE_COMPANY_LOGO, updateCompanyLogoSaga);
}

export function* renewAllActionWatcher() {
	yield takeLatest(RENEW_ALL, renewAllSaga);
}

export function* addUserToCompanyActionWatcher() {
	yield takeLatest(ADD_USER_TO_COMPANY, addUserToCompanySaga);
}

export function* eraseCorporateMembershipDataActionWatcher() {
	yield takeLatest(REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA, getEraseCorporateMembershipDataSaga);
}

export function* resetCartActionWatcher() {
	yield takeLatest(REQUEST_RESET_CART, getResetCartSaga);
}

export function* fetchCompanyProfileFieldValuesActionWatcher() {
	yield takeLatest(FETCH_COMPANY_PROFILE_FIELD_VALUES, fetchCompanyProfileFieldValuesSaga);
}

export function* deleteUserCompanyAffiliationActionWatcher() {
	yield takeLatest(DELETE_USER_COMPANY_AFFILIATION, deleteUserCompanyAffiliationSaga);
}

export function* updateFinancialItemsFormStateActionWatcher() {
	yield takeLatest(UPDATE_FINANCIAL_ITEMS_FORM_STATE, updateFinancialItemsFormStateSaga);
}

export function* fetchExhibitorCartUuidActionWatcher() {
	yield takeLatest(FETCH_EXHIBITOR_CART_UUID, fetchExhibitorCartUuidSaga);
}

export function* exhibitorSignUpActionWatcher() {
	yield takeLatest(EXHIBITOR_SIGNUP, exhibitorSignUpSaga);
}

export function* makeCartPublicToPrivateActionWatcher() {
	yield takeLatest(MAKE_CART_PUBLIC_TO_PRIVATE, makeCartPublicToPrivateSaga);
}

export function* sendMagicLinkActionWatcher() {
	yield takeLatest(SEND_MAGIC_LOGIN_LINK, sendMagicLinkSaga);
}

export function* deleteExhibitorPaymentActionWatcher() {
	yield takeLatest(DELETE_EXHIBITOR_PAYMENT, deleteExhibitorPaymentSaga);
}

export function* fetchRolesActionWatcher() {
	yield takeLatest(FETCH_ROLES, fetchRolesSaga);
}

export function* updateRolesActionWatcher() {
	yield takeLatest(UPDATE_ROLES, updateRolesSaga);
}

export default function* rootSaga() {
	yield all([
		fork(getMiniProfileFields),
		fork(corporateMembershipModuleUrlId),
		fork(createCompanyActionWatcher),
		fork(fetchUserCompanyAffiliationActionWatcher),
		fork(fetchExhibitorCartDetailsActionWatcher),
		fork(fetchMenuActionWatcher),
		fork(fetchCompanyMembersActionWatcher),
		fork(fetchCompanyActionWatcher),
		fork(makeUserAdminActionWatcher),
		fork(removeUserAdminActionWatcher),
		fork(removeUserFromGroupActionWatcher),
		fork(removeUserMembershipActionWatcher),
		fork(updateCompanyProfileActionWatcher),
		fork(addUserMembershipActionWatcher),
		fork(fetchPaymentSettingsActionWatcher),
		fork(updateUserContactInformationActionWatcher),
		fork(addNewUserContactActionWatcher),
		fork(fetchExhibitorCartActionWatcher),
		fork(sendEmailToUsersActionWatcher),
		fork(addRegistrationItemsToCartActionWatcher),
		fork(deleteRegistrationItemFromCartActionWatcher),
		fork(fetchUserContactInfoFieldValuesActionWatcher),
		fork(updateCompanyLogoActionWatcher),
		fork(renewAllActionWatcher),
		fork(addUserToCompanyActionWatcher),
		fork(eraseCorporateMembershipDataActionWatcher),
		fork(resetCartActionWatcher),
		fork(fetchCompanyProfileFieldValuesActionWatcher),
		fork(fetchCompanyProfileMediaActionWatcher),
		fork(uploadTos3Watcher),
		fork(addUpdateDeleteMediaResourceWatcher),
		fork(sortMediaResourceWatcher),
		fork(deleteUserCompanyAffiliationActionWatcher),
		fork(updateFinancialItemsFormStateActionWatcher),
		fork(fetchExhibitorCartUuidActionWatcher),
		fork(exhibitorSignUpActionWatcher),
		fork(makeCartPublicToPrivateActionWatcher),
		fork(sendMagicLinkActionWatcher),
		fork(deleteExhibitorPaymentActionWatcher),
		fork(fetchRolesActionWatcher),
		fork(updateRolesActionWatcher),
		fork(makeUserPublicActionWatcher),
		fork(removeUserPublicActionWatcher),
		fork(updateMiniProfileFields),
		fork(createMiniProfileFields),
		fork(getAddCompanyUser),
	]);
}
