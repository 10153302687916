import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Table, Card, Tooltip, Modal, Button } from 'antd';
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import { DeleteTwoTone, setTwoToneColor, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const Cart = ({ selectedBooths, registeredBooths, checkoutLength, maximumSelectionAllowed, invoiceItemArray, ...props }) => {
    const [cartItems, setCartItems] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteInfo, setDeleteInfo] = useState({})
    setTwoToneColor("red")
    const numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else return null;
    }
    useEffect(() => {
        let invoiceItems = []
         _map(props.booths, (boothInfo, key) => {
            const {booth_data: booth} = boothInfo;
			_filter(invoiceItemArray, item =>{
                return booth.BoothID === item?.booth_id && item.booth_checkout_time
            }).map(invoice => {
                invoiceItems.push({ ...invoice, ...boothInfo, boothNo: booth.BoothNo, confirmed: booth.Confirmed, boothSize: booth.BoothSize, bDelete: boothInfo })
            })
        })
        setCartItems(invoiceItems)
    }, [props.invoiceItemArray])

    const renderSubmitForm = () => {
        let listOfValueIDs = [];
        for (let i in selectedBooths) {
            listOfValueIDs.push(selectedBooths[i]['booth_data']['ValueID']);
        }
        for (let i in registeredBooths) {
            listOfValueIDs.push(registeredBooths[i]['ValueID']);
        }
        listOfValueIDs = listOfValueIDs.join(',');
        return (
            <form name="Go" action="#StartView" method="post">
                <input type="hidden" name="BoothSelectionList" value={listOfValueIDs} />
                <input type="hidden" name="Addother" value="1" />
                <input type="hidden" name="ConfID" value={props.SESSION.confid} />
                <input type="hidden" name="CompID" value={props.SESSION.compid} />
                <input type="hidden" name="MenuID" value={props.MenuID} />
                <input type="hidden" name="Screen" value={props.Screen} />
                <input type="submit" disabled={true} className="btn btn-warning" name="submit" value="Continue Registration" />
            </form>);
    }
    const handleOk = () => {
        if (!_isEmpty(deleteInfo)) {
            props.removeFromCart(deleteInfo)
        } else {
            setShowDeleteModal(false)
        }

    }
    const handleDelete = (record) => {
        setShowDeleteModal(true)
        setDeleteInfo(record)
    }
    const columns = [{
        title: 'No',
        dataIndex: 'boothNo',
        key: 'boothNo',
        render: (props) => props ?? ""
    },
    {
        title: 'Size',
        dataIndex: 'boothSize',
        key: 'boothSize',
        render: (props) => props ?? ""
    },
    {
        title: 'Cost',
        dataIndex: 'amount',
        key: 'amount',
        render: (props) => props ? `$${numberWithCommas(props)}` : ""
    },
    {
        title: 'Status',
        dataIndex: 'confirmed',
        key: 'confirmed',
        render: (props) => {
            let text = props == 1 ? 'Confirmed' : 'Pending';
            return !props ? <Tooltip title={text} key={`t_${props.boothNo}`}><div className='gx-text-center'  key={`td_${props.boothNo}`}><ExclamationCircleOutlined className='exclam-icon' /></div></Tooltip> : <Tooltip title={text} key={`t_${props.boothNo}`} ><div className='gx-text-center'  key={`td_${props.boothNo}`}><CheckCircleOutlined className='tick-icon' /></div></Tooltip>
        },
        width: '24%'
    },
    {
        title: '',
        key: 'action',
        width: '10%',
        render: (info) => info.confirmed != 1 && <Tooltip key={`ta_${info.boothNo}`} title={"Delete"} ><DeleteTwoTone key={`tdt_${info.boothNo}`} style={{ color: "red", fontSize: "large" }} onClick={() => handleDelete(info)} /></Tooltip>
    }]
    let maximumReached = cartItems.length >= checkoutLength;
    return (<>
        <Card title="Booth Selection List" className="booth-selection-list gx-my-5">
            {!_isEmpty(cartItems) &&
                <Table columns={columns} dataSource={cartItems} pagination={false}  size="middle" className="gx-mb-5"/>}
            {(selectedBooths.length > 0) && (<div>
                <p style={{ "fontWeight": "bold", "color": "black", "fontSize": "14px" }}>
                    <em>To secure space you must complete checkout now
                    </em></p>
                <p style={{ "fontSize": "12px" }}>
                    You have {checkoutLength}{' '}
                    minutes to complete purchase of any non-confirmed booths or they may no longer be available.
                </p></div>)}
            {_isEmpty(cartItems) && !maximumReached && (<div className='gx-text-center'><b>No booths currently selected.</b></div>)}
            {maximumReached && (<span style={{ "color": "red", "fontSize": "12px", "fontWeight": "bold" }}>You have reached the maximum number of booths allowed to be purchased.</span>)}
            {/* <div className='gx-text-center gx-my-3'>
                {renderSubmitForm()}
            </div> */}
            {showDeleteModal && 
                <Modal
                    footer={[
                        <Button key={`bt_yes`} className='modal-ok-btn' onClick={handleOk}>Yes</Button>,
                        <Button key={`bt_no`} type="primary" onClick={() => setShowDeleteModal(false)}>No</Button>,
                    ]}
                    title="Remove Booth"
                    visible={showDeleteModal}
                    onCancel={() => setShowDeleteModal(false)}
                >
                    <p>Are you sure you want to remove?</p>
                </Modal>
            }
        </Card>

    </>
    )
}

export default Cart