import React from 'react';

import { Image } from 'antd';

const ImageGallery = ({ url, style = {} }) => (
	<main className="gx-d-flex gx-justify-content-center gx-m-1">
		<Image
			className="shadow-2 gx-p-2 gx-bg-light"
			style={{
				maxHeight: '60vh',
				objectFit: 'scale-down',
				width: 'auto',
				...style
			}}
			src={url}
		/>
	</main>
);

export default ImageGallery;
