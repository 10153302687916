import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'

import { useProducts } from '../hooks/useProducts';

const BundleProductQuickView = ({ product }) => {
    const history = useHistory();
    const publicEmbed = useSelector(state => state.settings.publicEmbed);
    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
    const [_, products] = useProducts();
    const bundle = useMemo(() => products.find(prod => prod.id === parseInt(product.id)), [products, product.id]);
    const isDirectProduct = bundle?.grandparentId && bundle?.parentId && bundle?.grandparentId === bundle?.parentId;

    return (
        <div>
            <h4 className='gx-font-italic gx-task-title'>
                <Link
                    className="quick-view-link"
                    style={{ color: styling['primary-dark-color'] || "#993333" }}
                    to={`/${publicEmbed ? "public" : "main"}/consumption${isDirectProduct
                        ? `/product/${product.id}${history.location.search}`
                        : `/group/${bundle?.grandparentId}/${bundle?.parentId}/${bundle?.id}${history.location.search}`}`}
                >
                    {bundle?.title}
                </Link>
            </h4>
            <h5 className='gx-font-italic'>
                {bundle?.authorInfo?.length ? (
                    bundle?.authorInfo?.map(author => `${author.firstname} ${author.lastname}`).join(', ')
                ) : bundle?.author_block ? (
                    <div
                        className="gx-task-title gx-mb-1"
                        dangerouslySetInnerHTML={{
                            __html: bundle?.author_block,
                        }}
                    />
                ) : null}
            </h5>
        </div>
    );
};

export default BundleProductQuickView;
