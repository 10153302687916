import React, { useEffect, useState } from 'react';
import { Table, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { requestToRenewAll } from '../../appRedux/actions';
import OpenModal from '../Common/OpenModal';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { returnRemainingMemberships } from './helpers/generalHelpers';

const RenewAll = props => {
	const [userArray, setUserArray] = useState([]);
	const [newExpiryDate, setNewExpiryDate] = useState(moment());
	const [dataSource, setDataSource] = useState([]);
	const [remainingMemberships, setRemainingMemberships] = useState({});
	const [unallocatedMembers, setUnallocatedMembers] = useState({});
	const [checked, setChecked] = useState({});

	const corporateMembership = useSelector(state => state.corporateMembership);
	const companyExpireDate = useSelector(state => state.corporateMembership.company.expiry_date);
	const renewPeriod = useSelector(
		state => state.corporateMembership.generalSettings?.renewal_period_days,
	);
	const todayDate = moment();

	useEffect(() => {
		let tempDict = unallocatedMembers;
		let tempChecked = checked;

		for (let i in corporateMembership.companyMembers) {
			tempChecked[corporateMembership.companyMembers[i].reviewID] = false;
			let currMember = corporateMembership.companyMembers[i];
			if (currMember.groupID) {
				if(moment(currMember.expirationDate).diff(todayDate, 'days') < renewPeriod) {
					if (tempDict[currMember.groupID]) {
						tempDict[currMember.groupID] += 1;
					} else {
						tempDict[currMember.groupID] = 1;
					}
				}
			}
		}

		let tempDict2 = remainingMemberships;
		for (let i in corporateMembership.company.memberships_available) {
			let remainingMembers = returnRemainingMemberships(
				corporateMembership.company.memberships_available[i],
				props.corporateMembership.companyMembers,
				companyExpireDate,
			);
			tempDict2[
				corporateMembership.company.memberships_available[i].groupid
			] = remainingMembers;

		}
		setChecked(tempChecked);
		setUnallocatedMembers(tempDict);
		setRemainingMemberships(tempDict2);
	}, [corporateMembership.companyMembers?.length, corporateMembership.company.memberships_available?.length]);


	const renewAllTableColumns = [
		{
			title: <div className="renew-all-header-row">Name</div>,
			key: 'name',
			width: '27%',
			render: record => {
				return (
					<div className="exhibitor-staff-row">
						<div>{`${record?.lastName}, ${record?.firstName}`}</div>
						<div className="exhibitor-staff-row-email">{`${record?.email}`}</div>
					</div>
				);
			},
		},
		{
			title: <div className="renew-all-header-row">Member Type</div>,
			key: 'groupName',
			width: '37%',
			render: record => {
				const membershipType = props.corporateMembership.company[
					'memberships_available'
				].find(membershipType => {
					return membershipType.groupid === record.groupID;
				});
				return membershipType ? (
					<div className="exhibitor-staff-row">{`${membershipType.group_name}`}</div>
				) : (
					''
				);
			},
		},
		{
			title: <div className="renew-all-header-row">New Expiry </div>,
			key: 'expirationDate',
			width: '30%',
			render: () => {
				return (
					<div className="exhibitor-staff-row">{newExpiryDate.format('MM/DD/YYYY')}</div>
				);
			},
		},
		{
			title: <div className="renew-all-header-row">Renew</div>,
			width: '30%',
			render: record => {
				return (
					<Checkbox
						key={record.reviewID}
						onChange={event => {
							onChange(event, record);
						}}
						defaultChecked={
							remainingMemberships[record.groupID] <
							unallocatedMembers[record.groupID]
								? false
								: true
						}
						disabled={
							remainingMemberships[record.groupID] < 1 && !checked[record.reviewID]
								? true
								: false
						}
					/>
				);
			},
		},
	];

	useEffect(() => {
		if (props.corporateMembership.companyFetched) {
			setNewExpiryDate(moment(props.corporateMembership.company.expiry_date));
		}
	}, [props.corporateMembership.companyFetched]);

	useEffect(() => {

		const source = props.corporateMembership.companyMembers.filter(companyMember => {
			return (
				companyMember.groupID &&
				moment(companyMember.expirationDate).diff(todayDate, 'days') < renewPeriod
			);
		});

		const users = props.corporateMembership.companyMembers.filter(companyMember => {
			return (
				companyMember.groupID &&
				moment(companyMember.expirationDate).diff(todayDate, 'days') < renewPeriod
				&&
				(checked[companyMember.reviewID] ||
					remainingMemberships[companyMember?.groupID] >=
						unallocatedMembers[companyMember?.groupID])
			);
		});
		setUserArray(users);
		setDataSource(source);
	}, [props.corporateMembership.companyMembers, checked]);

	const onChange = (event, record) => {
		event.target.checked
			? setUserArray(userArray.concat(record))
			: setUserArray(
					userArray.filter(user => {
						return user.uuid !== record.uuid;
					}),
			  );

		let tempChecked = checked;
		tempChecked[record.reviewID] = !tempChecked[record.reviewID];

		let tempRemainingMemberships = remainingMemberships;
		if (tempChecked[record.reviewID] === true) {
			tempRemainingMemberships[record.groupID] -= 1;
		} else {
			tempRemainingMemberships[record.groupID] += 1;
		}

		setRemainingMemberships(tempRemainingMemberships);
	};

	const renewAll = () => {
		props.requestToRenewAll({
			compuuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
			array_of_members: userArray.map(user => {
				return {
					uuid: user.uuid,
					groupid: user.groupID,
					newExpiryDate: newExpiryDate.format('YYYY-MM-DD'),
				};
			}),
		});

		for (let i in corporateMembership.companyMembers) {
			let tempChecked = checked;
			tempChecked[corporateMembership.companyMembers[i].reviewID] = false;
			setChecked(tempChecked);
		}
	};

	const refreshAll = () => {
		for (let i in corporateMembership.companyMembers) {
			let tempChecked = checked;
			tempChecked[corporateMembership.companyMembers[i].reviewID] = false;
			setChecked(tempChecked);
		}
		for (let i in corporateMembership.company.memberships_available) {
			let remainingMembers = returnRemainingMemberships(
				corporateMembership.company.memberships_available[i],
				props.corporateMembership.companyMembers,
			);

			let tempDict = remainingMemberships;
			tempDict[
				corporateMembership.company.memberships_available[i].groupid
			] = remainingMembers;
			setRemainingMemberships(tempDict);
		}
		setUserArray([]);
	};

	return (
		<div>
			{props.corporateMembership.companyMembersFetched && Object.keys(checked).length > 0 && (
				<div>
					<OpenModal
						key={'renewAll'}
						isButton={true}
						title={'Renew All'}
						action={'RENEW'}
						modalContent={
							<Table
								rowKey="uuid"
								columns={renewAllTableColumns}
								dataSource={dataSource}
							/>
						}
						okClicked={renewAll}
						okCancel={refreshAll}
					/>
				</div>
			)}
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToRenewAll: requestToRenewAll,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(RenewAll));
