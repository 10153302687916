import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OpenModal from '../Common/OpenModal';
import { bindActionCreators } from 'redux';
import { Select } from 'antd';
import { requestToAddUserMembership } from '../../appRedux/actions';
import { returnRemainingMemberships } from './helpers/generalHelpers';

const AddMembershipType = props => {
	const [currentMembershipType, setCurrentMembershipType] = useState(
		props.corporateMembership.company.memberships_available.filter(
			obj => returnRemainingMemberships(obj, props.corporateMembership.companyMembers) > 0,
		)[0],
	);
	const companyExpireDate = props.corporateMembership?.company?.expiry_date;

	const onAddClick = () => {
		if (
			returnRemainingMemberships(
				currentMembershipType,
				props.corporateMembership.companyMembers,
			) > 0
		) {
			props.requestToAddUserMembership({
				groupid: currentMembershipType.groupid.toString(),
				companycontactUUID: props.record['uuid'],
				compuuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
			});
		}
	};

	const membershipsAvailableAfterAllocation = props.corporateMembership.company.memberships_available.filter(
		obj =>
			returnRemainingMemberships(
				obj,
				props.corporateMembership.companyMembers,
				companyExpireDate,
			) > 0,
	);

	useEffect(() => {
		setCurrentMembershipType(membershipsAvailableAfterAllocation?.[0]);
	}, [membershipsAvailableAfterAllocation?.length]);

	return (
		<OpenModal
			icon={'add'}
			title={'Membership Type'}
			action={'ADD'}
			modalContent={
				membershipsAvailableAfterAllocation.length > 0 && (
					<div>
						<div>Please choose the membership type to add:</div>
						<br></br>
						<Select
							defaultValue={membershipsAvailableAfterAllocation?.[0]?.groupid}
							style={{ width: 'auto' }}
							onChange={value => {
								setCurrentMembershipType(
									membershipsAvailableAfterAllocation.find(membership => {
										return membership.groupid === value;
									}),
								);
							}}
						>
							{membershipsAvailableAfterAllocation.map(membership => {
								return (
									<Select.Option
										key={membership['groupid']}
										value={membership['groupid']}
									>
										{membership['group_name']}
									</Select.Option>
								);
							})}
						</Select>
					</div>
				)
			}
			okClicked={onAddClick}
		/>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToAddUserMembership: requestToAddUserMembership,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(AddMembershipType);
