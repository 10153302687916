export const generateRowsForForm = formItems => {
	// Algorithm to handle the case where the input item has a Horizontal Class.
	// Define a new 2D array that at level 1 consists of all rows in the grid.
	// The level 2 is all columns in the rows given in level 1.
	let grid = [[]];

	// This variable is used to keep track of the current row we are iterating over.
	let currentRow = 0;

	// To handle case where an empty string or undefined maybe sandwiched between a start and end tag.
	let isSandwiched = false;

	for (const item of formItems) {
		// Push the item in to the current row(in case of iteration 1, it will be pushed to row 0)
		grid[currentRow].push(item);

		if (!item.horizontalClass && !isSandwiched) {
			// If item.horizontalClass is undefined or is an empty string, we just push it to the existing row(done above) and instantiate to go to the next row.
			currentRow++;
			grid.push([]);
		}
		if (item.horizontalClass === 'Start') {
			// If you detect the start of a new row, set isSandwiched to true as everything you encounter after this will be in the same row as the current one(until you encounter an End).
			isSandwiched = true;
		}
		if (item.horizontalClass === 'End') {
			// If you detect the end of existing row, set isSandwiched to false and go to the next row as everything you encounter after this will be in the next row.
			currentRow++;
			isSandwiched = false;
			grid.push([]);
		}
	}

	return grid;
};
