import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import _isEmpty from 'lodash/isEmpty';

import { storeProductData } from "../../../../appRedux/actions";

const BundleProductQuickViewRender = ({ child, productChildId }) => {
    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
    const dispatch = useDispatch();

    const showModal = () => {
        dispatch(storeProductData({ product: child, isVisible: true }));
    };

    return (
        <div className="gx-my-3">
            <h4 className='gx-font-italic gx-task-title' onClick={showModal}>
                <div
                    className="quick-view-link gx-pointer"
                    style={{ color: styling['primary-dark-color'] || "#993333" }}
                >
                    {child?.title}
                </div>
            </h4>
            <h5 className='gx-font-italic'>
                {child?.author_info_array?.length ? (
                    child?.author_info_array.map(author => `${author.firstname} ${author.lastname}`).join(', ')
                ) : child?.author_block ? (
                    <div
                        className="gx-mb-1"
                        dangerouslySetInnerHTML={{
                            __html: child?.author_block,
                        }}
                    />
                ) : null}
            </h5>
        </div>
    )
}

export default BundleProductQuickViewRender;