import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Card, Divider, Typography, Avatar } from 'antd';
import { PlayCircleOutlined, PictureOutlined, FilePdfOutlined, UserOutlined, DoubleRightOutlined, FileOutlined, ClockCircleOutlined } from '@ant-design/icons';
import _reduce from 'lodash/reduce';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _capitalize from 'lodash/capitalize';
import moment from 'moment';
import { ReactComponent as Certificate } from '../../../assets/icons/Certificate.svg';
import { ReactComponent as BulletIcon } from '../../../assets/icons/dot.svg';

import { useProducts } from '../hooks/useProducts';
import { FILE } from 'components/Common/constants';

/**
 * Convert a template string into HTML DOM nodes
 * @param  {String} str The template string
 * @return {Node}       The template HTML
 */
const stringToHTML = function (str) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(str, 'text/html');
	return doc.body;
};

const extractText = function (str = '') {
	const elements = stringToHTML(str);
	return elements.innerText;
};

const { Paragraph } = Typography;

const BundleProductCardGrid = ({ product, goto }) => {
	const [, products] = useProducts();
	const appdir = useSelector(state => state.loginInfo.appdir);

	const bundle = useMemo(() => products.find(prod => prod.id === parseInt(product.id)), [products, product.id]);

	const grandparentProduct = useMemo(() => products.find((prod) => prod.id === bundle?.grandparentId), [products, bundle]);
	const parentProduct = useMemo(() => products.find((prod) => prod.id === bundle?.parentId), [products, bundle]);
	const showTimes = bundle?.showTimes || grandparentProduct?.showTimes || parentProduct?.showTimes;
	const publicationDate = useMemo(() => {
		if (product?.publicationDate) return product.publicationDate
		else if (grandparentProduct?.publicationDate) return grandparentProduct?.publicationDate
		else if (parentProduct?.publicationDate) return parentProduct?.publicationDate
		else return null
	}, [product, grandparentProduct, parentProduct]);

	const [authorInfo, setAuthorInfo] = useState({});

	const { styling } = useSelector(({ loginInfo }) => loginInfo);

	useEffect(() => {
		if (product && product.authorInfo && product.authorInfo.length) {
			let data = _filter(product.authorInfo, (prod) => prod.presenter)[0];
			setAuthorInfo(data);
		} else {
			setAuthorInfo({});
		}
	}, [product]);

	const getChildProducts = (bundleInfo, isCredit = false) => {
		let childData = [];
		let bundleData = isCredit ? bundleInfo?.credits_array?.length : bundleInfo?.files?.length;
		// this is the scenario to filter the bundle which ishaving the same files as it's only child.
		if (bundleData &&
			(bundleInfo.isSuperBundle // to allow the grandParent from the restriction
				|| (!bundleInfo.isSuperBundle && !bundleInfo.isBundle) // to allow the products from the restriction
				|| (!bundleInfo.isSuperBundle &&
					bundleInfo.isBundle && // to check the bundle with single child
					bundleInfo?.childrenIds?.length > 1)
			)
		) {
			childData = [bundleInfo];
		}
		if (bundleInfo?.childrenIds?.length) {
			_forEach(_filter(products, (p) => bundleInfo?.childrenIds.includes(p.id)), (p) => {
				childData = [...childData, ...getChildProducts(p, isCredit)]
			});
		}
		return childData;
	};

	const constructItemCount = () => {
		const children = getChildProducts(bundle);
		let stateCount = _reduce(children, (total, child) => {
			let filteredChild = {
				...child,
				files: _filter(child.files, file => (file.hasOwnProperty("hide") ? !file.hide : true)),
			};
			let contents = _groupBy(filteredChild?.files, 'type');
			let temp = { ...total };
			_forEach(contents, (content, key) => {
				if (key == '') {
					temp['file'] = (total['file'] || 0) + content.length;
				} else {
					temp[key] = (total[key] || 0) + content.length;
				}
			});
			return temp;
		}, {});
		return stateCount;
	};

	const constructCreditCount = useCallback(() => {
		let isCredit = true;
		const children = getChildProducts(bundle, isCredit);
		let creditCount = _reduce(children, (total, child) => {
			let contents = _groupBy(child?.credits_array, 'label');
			_forEach(contents, (content, key) => {
				if (!total[key]) {
					content.forEach((item) => {
						total[key] = item.value;
					})
				} else {
					content.forEach((item) => {
						total[key] += item.value;
					})
				}
			})
			return total;
		}, {})
		return creditCount;
	}, [bundle])

	const productCount = useMemo(constructItemCount, [bundle, products]);

	const creditCount = useMemo(constructCreditCount, [bundle, product]);

	const handleOnExpand = (event) => {
		event.stopPropagation();
		goto(bundle, product);
	};

	return (
		<Card
			style={{
				borderLeft: `6px solid ${styling?.['primary-dark-color']}`,
				borderRadius: '5px',
			}}
			className="gx-mb-0 card-padding-0 shadow-2 gx-pointer gx-h-100"
			size="large"
			onClick={() => {
				goto(bundle, product);
			}}
			bodyStyle={{ height: '100%' }}
		>
			<div
				// style={{ minHeight: '16rem' }}
				// className={`${Object.keys(productCount)?.length ?
				//     'bundle-card-wrapper gx-d-flex gx-flex-column gx-justify-content-between' :
				//     'gx-pb-1'} bundle-card-wrapper`}
				className={
					Object.keys(productCount)?.length
						? 'bundle-card-wrapper gx-d-flex gx-flex-column gx-justify-content-between gx-h-100'
						: 'bundle-wrapper gx-pb-1 gx-h-100'
				}
			>
				<div>
					<div className="gx-px-4 gx-pt-3 gx-pb-2">
						<h2 className="gx-mb-2 gx-mr-2 bundle-title gx-font-weight-semi-bold">
							{product.title}
						</h2>
					</div>
					{product.description ? (
						<div className="gx-px-4 gx-pt-1 gx-pb-1 description-text">
							<Paragraph
								ellipsis={{
									rows: 3,
									expandable: true,
									symbol: (
										<span className="gx-text-bold gx-text-black">
											{`More `}
											<DoubleRightOutlined style={{ fontSize: '11px' }} />
										</span>
									),
									onExpand: handleOnExpand,
								}}
							>
								{extractText(product?.description)}
							</Paragraph>
						</div>
					) : null}
					{showTimes? (
						bundle.sessionStartTime > 0 ? (
						<p className="gx-px-4">
							<ClockCircleOutlined /> &nbsp;
							{moment(bundle.sessionStartTime).format('ddd, MMM D Y, hh:mm A')}
						</p>
						):null
					):
					publicationDate ? (
						<p className="gx-px-4">
							Publication Date:&nbsp;
							{moment(publicationDate).format('MM/DD/YYYY')}
						</p>
					) : null
					}
					<div style={{ marginLeft: '1.5em' }}>
						{!_isEmpty(authorInfo) ? (
							<div className="gx-py-3 description-text gx-d-flex gx-align-items-center">
								<Avatar
									size="medium"
									src={authorInfo.picture}
									icon={<UserOutlined />}
								/>
								<div className="gx-ml-2">{`${authorInfo.firstname} ${authorInfo.lastname}`}</div>
								{authorInfo.degrees ? <div>{`, ${authorInfo.degrees}`}</div> : null}
							</div>
						) : null}
					</div>
				</div>
				{Object.keys(productCount)?.length ? (
					<div className="gx-mx-4 gx-mb-2 gx-d-flex gx-align-items-end gx-justify-content-between bundle-card-content">
						<div>
							<div className='bundle-product-card'>
								<Divider orientation="left" plain>
									Includes
								</Divider>
								<div className="bundle-text-content gx-mb-2 gx-d-flex" style={{ gap: '0.30rem' }}>
									<div>
										<span>
											{_map(productCount, (count, key) => {
												let fileName = count > 1 && key !== FILE.PDF ? key.concat("s") : key;
												return (
													<span key={key}>
														{key === FILE.PDF && (
															<span className="gx-ml-2">
																<FilePdfOutlined /> {count}{' '}
																{fileName?.toUpperCase()?.concat(count > 1 ? 's' : '')}
															</span>
														)}
														{key === FILE.VIDEO && (
															<span className="gx-ml-2">
																<PlayCircleOutlined /> {count}{' '}
																{_capitalize(fileName)}
															</span>
														)}
														{key === FILE.IMAGE && (
															<span className="gx-ml-2">
																<PictureOutlined /> {count}{' '}
																{_capitalize(fileName)}
															</span>
														)}
														{key === FILE.FILE && (
															<span className="gx-ml-3">
																<FileOutlined /> {count}{' '}
																{_capitalize(fileName)}
															</span>
														)}
													</span>)
											})}
											{!_isEmpty(creditCount) && appdir === 'demo' ?
												<span className="gx-ml-3">
													<Certificate style={{ width: '1rem' }} />{' '}
													{_map(creditCount, (count, key) => {
														let fileName = count > 1 ? key + "s" : key;
														return (
															<span>
																{count}{' '}
																{fileName}
																{Object.keys({ ...creditCount }).pop() !== key ? (
																	<BulletIcon style={{ width: '1rem' }} />
																)
																	: null}
															</span>
														)
													})}
												</span> : null}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</Card>
	);
};

export default BundleProductCardGrid;
