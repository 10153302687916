// Todo:
// * Convert renderbooths to a component.
// * Convert renderMarkers to a component.
/*eslint-disable no-undef*/
import L, { divIcon, CRS } from 'leaflet';
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Map as LeafletMap, Popup, ImageOverlay, FeatureGroup, Rectangle, Polygon, Marker } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import moment from 'moment';

import Cart from './Cart';
import { Card, Col, Row, Modal, Button, Drawer, Tag, Spin } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import "antd/dist/antd.compact.css";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { NotificationManager } from 'react-notifications';
import { handleBoothListLoader, requestExhibitorCart } from '../../appRedux/actions';
import { ConfirmationModal, ErrorModal } from './Modal';
import auth from '../../../src/loginAuth/loginAuth';

let g_confId = null;
let g_conferenceId = null;
let g_source_hex = '';
let fullscreen = true;
let floorplan_id = null;
let g_compid = null;
let masterapp = 'https://xcdsystem.com/masterapp_summer2012';
let cart_url = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm'
let xcd_cart_url = 'https://xcdsystem.com/masterapp_summer2012/apiv2/index.cfm'
let g_comp_uuid = null
let g_exhib_uuid = null
let api_url, cart_api_url;
if (masterapp.startsWith("http")) {
    api_url = masterapp + "/admin2015/exhibitors/new_floorplan/booth_ajax.cfc";
    cart_api_url = masterapp + "/exhibitor_v2/floorplan_v2/cart_ajax.cfm";
} else {
    api_url = "/" + masterapp + "/admin2015/exhibitors/new_floorplan/booth_ajax.cfc";
    cart_api_url = "/" + masterapp + "/exhibitor_v2/floorplan_v2/cart_ajax.cfm";
}

var guideLayers = new Array();

class LMap extends React.Component {
    // Constructor
    constructor(props) {
        super(props);
        this.state = {
            showNewBoothModal: false,
            showExhibitorModal: false,
            lastDrawingEvent: null,
            form: {
                ConfID: g_confId, //NTBJ
                Class: 'Booth',
                Confirmed: 0
            },
            exhibitors: [],
            fontSize: 8,
            showCompany: false,
            editMode: false,
            disableZoom: false,
            zoom: 0,
            booths: {},
            labels: {},
            undrawn_booths: [],
            undrawnMapped: false,
            modalType: 'new',
            populatedForm: {},
            unmapped_booths: {},
            legacy_booths: {},
            ConfID: g_confId,
            activeTab: 1,
            discounts: [],
            selectedExhibitor: null,
            filterText: '',
            sortKey: 'BoothNo',
            sortOrder: true,
            onlySponsor: false,
            selectedBoothID: 0,
            headerHeight: 100,
            color: {
                taken: "#fffac8",
                available: "#aaffc3"
            },
            booth_color: {},
            cartList: [],
            registeredList: [],
            showError: false,
            advancedSearch: {},
            exhibitorsInfo: {},
            highlightBoothNo: null,
            drawerVisible: false,
            showDelete: false,
            cartCount: 0,
            addToCartloading: false,
            invoiceBoothItems: []
        };

        // binds
        this.handleShow = this.handleShow.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveBooth = this.saveBooth.bind(this);
        this.onCreated = this.onCreated.bind(this);
        this.onEdited = this.onEdited.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.editToggle = this.editToggle.bind(this);
        this.selectPolygon = this.selectPolygon.bind(this);
        this.delete = this.delete.bind(this);
        this.highlightBooth = this.highlightBooth.bind(this);
        this.changeSortKey = this.changeSortKey.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.handleErrorModalClose = this.handleErrorModalClose.bind(this);

        // loads
        this.load_booths()

        // refs
        this.featureGroup = React.createRef();
    }
    
    getUrlId = () => {
        return this.props.match.params?.moduleUrlId || "";
    }

    editToggle = event => {
        if (!this.state.editMode) {
            this.setState({
                editMode: true,
                disableZoom: true,
                fontSize: 16,
                showCompany: false
            });
        } else {
            this.setState({
                editMode: false,
                disableZoom: false,
                fontSize: this.setTextSize(this.state.zoom)
            });
        }
    }

    // Invoked from componentDidMount()
    updateDimensions() {
        const height = window.innerWidth >= 992 ? window.innerHeight : 400;
        this.setState({
            height: height
        });
    }

    // Lifecycle
    componentWillMount() {
        this.updateDimensions();
    }

    // Invoked from load_booths, It will remove the expired booths from cart, based the checkoutLength
    removeExpiredBooth = (booths, invoiceItemArray) => {
        let invoiceItems = []
        let invoiceBoothItems = []
        _map(booths, boothInfo => {
            _map(invoiceItemArray, item => {
                if (boothInfo.BoothID === item?.booth_id && item.booth_checkout_time) {
                    invoiceBoothItems.push({ booth_data: boothInfo })
                    let addCheckoutMinute = moment(item.booth_checkout_time).add(this.props?.boothConfiguration?.checkoutLength, 'minutes');
                    if (moment().isAfter(addCheckoutMinute) && boothInfo.Confirmed != 1) {
                        invoiceItems.push({ ...item, ...boothInfo, boothNo: boothInfo.BoothNo, confirmed: boothInfo.Confirmed, boothSize: boothInfo.BoothSize, bDelete: boothInfo, booth_data: boothInfo })
                    }
                }
            })
        })
        if (!_isEmpty(invoiceItems)) {
            this.removeFromCart(invoiceItems[0], false)
        }
        this.setState({ invoiceBoothItems, cartList: invoiceBoothItems })
    }

    // Lifecycle
    componentDidMount() {
        let color = this.state.color;
        if (this.props.public_config.hasOwnProperty('AvailableBoothColor') && this.props.public_config.hasOwnProperty('NotAvailableBoothColor')) {
            color.taken = this.props.public_config['NotAvailableBoothColor'];
            color.available = this.props.public_config['AvailableBoothColor'];
        }
        if (this.props.public_config.hasOwnProperty('classes')) {
            this.props.public_config.classes.map((classx) => {
                color[classx.value] = classx.color;
            });
        }
        this.setState({ color: color });
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if (this.header_div) {
            this.setState({ headerHeight: this.header_div.clientHeight });
        } else {
            this.setState({ headerHeight: 0 });
        }
        this.refs.map.leafletElement.setView({ lng: 0, lat: this.props.image_height }, 0);
    }

    // Lifecycle
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else return null;
    }

    async load_booths() {
        // If mode is cart, let's load registerd booths ValueIDs
            let sending_data = {
                ConfID: g_confId
            };
            this.props.handleBoothListLoader(true);
        await fetch(api_url + "?method=getBooths&returnFormat=json&Source=" + g_source_hex, {
                body: JSON.stringify(sending_data),
                method: "POST"
            }).then(function (response) {
                return response.json()
            }).then(function (data) {
                this.props.handleBoothListLoader(false);
                let undrawn_booths = [];
                let legacy_booths = {};
                let labels = {};
                Object.keys(data).map((key) => {
                    if (data[key].hasOwnProperty('DrawBounds')) {
                        // if the booth is part of new floorplan app.
                        data[key]['DrawBounds'] = JSON.parse(data[key]['DrawBounds']);
                        // we don't have label bounds as well so...
                        labels[data[key]['BoothID']] = {};
                        undrawn_booths.push(data[key]);

                        // Handling CheckOutDateTime
                        let CheckOutDateTime = Date.parse(data[key]['CheckOutDateTime']);
                        if (CheckOutDateTime) {
                            let now = Date.parse(new Date());
                            let diff = (now - CheckOutDateTime) / 60000;
                            if (typeof CheckoutLength !== 'undefined') {
                                if (diff < CheckoutLength && data[key]['Confirmed'] != 1) {
                                    data[key]['CompID'] = 99999;
                                }
                            }

                        }
                    
                    } else {
                        // assuming legacy booth.
                        legacy_booths = { ...legacy_booths, [data[key]['BoothID']]: data[key] };
                    }
                });
                this.removeExpiredBooth(data, this.props.invoiceItemArray)
                this.setState({
                    undrawn_booths: undrawn_booths,
                    legacy_booths: legacy_booths,
                    labels: labels
                });
                this.mapUndrawnBooths(false);
            }.bind(this));
    }

    // cancel model handler
    cancel() {
        if (this.state.modalType == 'new') {
            if (this.state.lastDrawingEvent.hasOwnProperty('layer')) {
                this.state.lastDrawingEvent.layer.remove();
            }
            this.setState({
                lastDrawingEvent: null,
                labelLocation: null
            });
        }
        this.setState({
            showNewBoothModal: false
        });
        this.setState({
            form: {},
            populatedForm: {},
        });
    }

    handleShow() {
        this.setState({
            showNewBoothModal: true
        });
    }

    ajaxCallCart(action, { CompID = 0, ...rest }) {
        return fetch(xcd_cart_url + `?module=exhibitor&component=exhibitors&function=${action}&moduleURLID=${this.getUrlId()}&exhibuuid=${g_exhib_uuid}&compuuid=${g_comp_uuid}`, {
            body: JSON.stringify(rest),
            method: "POST",
            headers: {
                authorization: JSON.parse(auth.getCookie('jwt'))
            },
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            return data;
        }.bind(this));
    }

    addToCart(booth) {
        this.setState({ addToCartloading: true })
        let bInfo = booth['booth_data']
        this.ajaxCallCart('add_to_cart', { "itemstoadd": { [bInfo['Fieldname']]: { "valueid": [bInfo['ValueID']], "qty": 1 } } }
        ).then((result) => {
            if (!result['error']) {
                this.state.cartList.push(booth);
                booth['polygon_layer'].setStyle({
                    fillColor: this.state.color['taken'],
                    color: "#ffa769",
                    opacity: 1,
                    fillOpacity: 1,
                    weight: 3
                });
                this.props.requestExhibitorCart({
                    urlId: this.getUrlId(),
                    compUuid: g_comp_uuid,
                    exhibUuid: g_exhib_uuid,
                });
                NotificationManager.success('Booth added successfully to the cart')
                this.setState({ cartList: this.state.cartList, addToCartloading: false, invoiceItemArray: result.InvoiceItemArray});
            } else {
                booth['polygon_layer'].setStyle({
                    fillColor: this.state.color['taken'],
                    color: "#000",
                    opacity: 1,
                    fillOpacity: 1,
                    weight: 2
                });
                this.state.booths[booth['booth_data'].BoothID]['booth_data'].CompID = 999999;
                this.setState({ booths: this.state.booths, showError: true, errorText: 'Sorry, the booth are you trying to add is no longer available.', addToCartloading: false });
            }
        });

    }

    removeFromCart(booth, notify = true) {
        this.ajaxCallCart('delete_from_cart', {
            "itemstodelete": { 'field_id': booth?.field_id, 'invoice_id': booth?.invoice_id }
        }
        ).then((result) => {
            if (!result['error']) {
                booth['polygon_layer'] && booth['polygon_layer'].setStyle({
                    fillColor: this.state.color['available'],
                    color: "#000",
                    opacity: 1,
                    fillOpacity: 1,
                    weight: 2
                });
                if (this.state.cartList.indexOf(booth) != -1) {
                    this.state.cartList.splice(this.state.cartList.indexOf(booth), 1);
                }
                this.state.cartList=this.state.cartList.filter(item=>item['booth_data'].BoothID!=booth['booth_data'].BoothID);
                if (this.state.registeredList.indexOf(booth['booth_data']) != -1) {
                    this.state.registeredList.splice(this.state.registeredList.indexOf(booth['booth_data']), 1);
                }
                // Handling registeredList
                let target_index = -1;
                for (let i in this.state.registeredList) {
                    if (this.state.registeredList[i]['BoothID'] == booth['booth_data']['BoothID']) {
                        target_index = i;
                    }
                }
                if (target_index != -1) {
                    this.state.registeredList.splice(target_index, 1);
                }
                this.props.requestExhibitorCart({
                    urlId: this.getUrlId(),
                    compUuid: g_comp_uuid,
                    exhibUuid: g_exhib_uuid,
                });
                if (notify) {
                    NotificationManager.success("Booth deleted successfully from the cart")
                }
                this.setState({ cartList: this.state.cartList, registeredBooths: this.state.registeredList,drawerVisible: false });
            } else {
                this.setState({ showError: true, errorText: 'Sorry, unable to remove the booth from your cart' });
            }
        });

    }

    // Render: Marker
    renderMarkers() {
        return Object.keys(this.state.labels).map((booth_id) => {
            if (this.state.booths.hasOwnProperty(booth_id)) {
                // let's create a booth obj for convinence.
                let booth_obj = this.state.booths[booth_id]['booth_data'];
                let company_label = "";
                if (this.state.showCompany) {
                    // if booth object has CompID
                    if (booth_obj.hasOwnProperty('CompID')) {
                        //if we have information on this CompID
                        if (this.props.exhibitors.hasOwnProperty(booth_obj.CompID)) {
                            // yes, we do have.
                            company_label = this.props.exhibitors[booth_obj.CompID].Companyname.replace(" ", "&nbsp;");
                        }
                    }
                } else {
                    company_label = "";
                }
                let booth_no;
                if (booth_obj.hasOwnProperty('BoothNo')) {
                    booth_no = booth_obj['BoothNo'].toString().replace(' ', '&nbsp;');
                } else {
                    booth_no = 'Not&nbsp;Set';
                }
                if (booth_obj.hasOwnProperty('OnHold')) {
                    if (booth_obj['OnHold'] == 1) {
                        booth_no = booth_no + "(on&nbsp;hold)";
                    }
                }
                const icon = divIcon({
                    className: 'map-label',
                    // iconSize: null,
                    iconAnchor: booth_obj['DrawBounds']['label'],
                    html: `<div style='font-size: ${this.state.fontSize}px; text-align: center !important;'><b>${booth_no}</b><br>${company_label}</div>`
                });
                return (<Marker
                    position={booth_obj['DrawBounds']['label']}
                    icon={icon}
                    key={booth_id}
                    alt={booth_id}
                    onMouseOver={(e) => {
                        this.state.booths[booth_id].polygon_layer.openPopup();
                    }}
                    onClick={(e) => {
                        this.selectPolygon({ 'layer': this.state.booths[booth_id].polygon_layer });
                    }}
                ></Marker>);
            } else {
                return null;
            }
        });
    }

    // Action called from create booth modal.
    // Objective of the method is to call createBooth method in coldfusion.
    // + update action.
    saveBooth(e) {
        // e is form here.
        if (e.currentTarget.checkValidity()) {
            this.setState({
                showNewBoothModal: false,
                text: 'Syncing changes...'
            });

            console.log('make ajax call to server to save the new booth');
            e.preventDefault();
            e.stopPropagation();
            this.updateBooth();
        }
    }

    resetForm() {
        // Appending DiscountID
        let discounts = {};
        if (this.state.discounts.length > 0) {
            for (let i = 1; i <= this.state.discounts.length; i++) {
                discounts['Discount' + i] = this.state.discounts[i - 1]['DiscountID'];
            }
        }
        this.setState({
            form: {
                ...discounts,
                ConfID: g_confId, Class: 'Booth', Confirmed: 0, FloorplanID: floorplan_id
            }, populatedForm: {}, activeTab: 1
        });
    }

    updateBooth() {

        let discounts = {};
        if (this.state.discounts.length > 0) {
            for (let i = 1; i <= this.state.discounts.length; i++) {
                discounts['Discount' + i] = this.state.discounts[i - 1]['DiscountID'];
            }
        }
        // If transfer of booth is requested.
        if (this.state.activeTab == 2) {
            // populate form with data from legacybooths
            this.state.form.legacy_BoothID = parseInt(this.state.form.legacy_BoothID);
            this.state.form = { ...this.state.legacy_booths[this.state.form.legacy_BoothID] };
        }

        // Fix empty CompID to null.
        if (this.state.form.hasOwnProperty('CompID')) {
            if (this.state.form.CompID == '') {
                this.state.form.CompID = null;
            }
        }
        let sending_data = {};
        // Handling Drawing bounds for new booth.
        if (this.state.modalType == 'new') {
            sending_data = {
                ...this.state.form,
                ...discounts,
                DrawBounds: JSON.stringify({
                    polygon: this.state.lastDrawingEvent.layer.getLatLngs(),
                    label: this.state.labelLocation
                }),
            };
        } else if (this.state.modalType == 'update') {
            // Handing Drawing bounds for existing booth.
            sending_data = {
                ...this.state.form,
                ...discounts,
                DrawBounds: JSON.stringify(this.state.booths[this.state.form['BoothID']]['booth_data']['DrawBounds'])
            };
        }
        fetch(api_url + "?method=createBooth&returnFormat=json&Source=" + g_source_hex, {
            body: JSON.stringify(sending_data),
            method: "POST"
        }).then(function (response) {
            return response.json()
        }).then(function (data) {
            this.setState({
                text: 'Changes synced'
            });
            let t_data = data;
            t_data['DrawBounds'] = JSON.parse(t_data['DrawBounds']);
            // Sync newly created booth with local list of booth list.
            if (!this.state.booths.hasOwnProperty(data.BoothID)) {
                this.setState({
                    booths: {
                        ...this.state.booths,
                        [data.BoothID]: {
                            'booth_data': t_data,
                            'polygon_layer': this.state.lastDrawingEvent.layer
                        }
                    }
                });
                this.drawBooth(data, this.state.lastDrawingEvent.layer);
            } else {
                this.setState({
                    booths: {
                        ...this.state.booths,
                        [data.BoothID]: {
                            'booth_data': t_data,
                            'polygon_layer': this.state.booths[data.BoothID]['polygon_layer']
                        }
                    }
                });
                this.drawBooth(data, this.state.booths[data.BoothID]['polygon_layer']);
            }
            // if transfering legacy booth.
            if (this.state.activeTab == 2) {
                delete this.state.legacy_booths[data.BoothID];
                this.setState({ legacy_booths: this.state.legacy_booths });
            }
            // clearing the form state.
            this.resetForm();
        }.bind(this));

    }

    // delete booth.
    delete() {
        let booth_id = this.state.form.BoothID;
        let sending_data = { 'BoothID': this.state.form.BoothID };
        this.state.booths[booth_id].polygon_layer.remove();
        fetch(api_url + "?method=delete&returnFormat=json&Source=" + g_source_hex, {
            body: JSON.stringify(sending_data),
            method: "POST"
        }).then(function (response) {
            return response.json()
        }).then(function (data) {


            delete this.state.labels[booth_id];
            delete this.state.booths[booth_id];
            this.state.form = {};
            this.state.populatedForm = {};
            this.setState(() => { return this.state });
        }.bind(this));
        this.setState({ showDelete: false }, () => { this.setState({ showNewBoothModal: false }) })
    }

    // Draw booth method
    // well the polygon is already been drawn on layer
    // we set some extra parameters based on coldfusion
    // feedback.
    drawBooth(booth_obj, polygon_layer) {
        console.log('Drawing newly created booth');
        let color_key = '';
        if (booth_obj.Class == 'Booth') {
            if (booth_obj.hasOwnProperty('CompID')) {
                // this is a taken booth.
                color_key = 'taken';
            } else {
                // else booth is available.
                color_key = 'available';
            }
        } else {
            color_key = booth_obj.Class;
        }
        polygon_layer.setStyle({
            fillColor: this.state.color[color_key],
            color: "#000",
            opacity: 1,
            fillOpacity: 1,
            weight: 2
        });
        // append booth_id to label
        this.setState({ labels: { ...this.state.labels, [booth_obj.BoothID]: {} } });
    }

    // ToDo
    // Method to get list of existing booth objects.
    listBooths() {
    }

    changeHandler = event => {
        const name = event.target.name;
        const value =
            event.target.value;
        let extra = {};
        if (this.state.populatedForm.hasOwnProperty('booth_data')) {
            extra = {
                booth_data: {
                    ...this.state.populatedForm.booth_data,
                    [name]: value
                }
            };
        } else {
            extra = { booth_data: { ...extra, [name]: value } }
        }
        console.log('extra', extra);
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
                FloorplanID: 7
            },
            populatedForm: {
                ...extra
            }
        });

    }


    onCreated = event => {
        //clear form
        this.resetForm();
        console.log('onCreated');
        // Center of the polygon drawn (or rectange which is a disciplined polygon with neat 90 degree).
        let polygon_center = event.layer.getCenter();
        let temp_cords = [...event.layer.getLatLngs()[0]];
        // Sorting coordinates to get left and top most coordinate of polygon,
        // which will act anchor to our exhibitor label.
        temp_cords.sort((a, b) => (a.lat > b.lat) ? 1 : -1);
        temp_cords.sort((a, b) => (a.lng > b.lng) ? 1 : -1);
        // Average out coordinate to
        // suggest label location.
        let suggested_label_location = {
            lat: (temp_cords[0]['lat'] + polygon_center['lat']) / 2,
            lng: (temp_cords[0]['lng'] + polygon_center['lng']) / 2
        };
        // setting state of `show` which hopefully render
        // modal with form to create a new form.
        console.log("opening up new booth model");
        this.setState({ showNewBoothModal: true, modalType: 'new' });
        this.setState({
            lastDrawingEvent: event,
            labelLocation: suggested_label_location
        });
        // /console.log(event.layer.remove());
        //event.stopPropagation();
    }

    onEdited = event => {
        Object.keys(event.layers._layers).map((layer_id) => {
            let sending_data = {};
            let layer = event.layers._layers[layer_id];
            //determine type
            // responsibility of each condition is to curate sending_data
            if (event.layers._layers[layer_id].hasOwnProperty('dragging') &&
                event.layers._layers[layer_id]['dragging'].hasOwnProperty('_marker')) {
                // it is marker
                let booth_id = parseInt(layer.options.alt);
                let new_location = layer._latlng;
                let booth = { ...this.state.booths[booth_id]['booth_data'] };
                booth['DrawBounds']['label'] = new_location;
                sending_data = booth;
                sending_data['DrawBounds'] = JSON.stringify(sending_data['DrawBounds']);
            } else {
                //it has to be shape

                Object.keys(this.state.booths).map((booth_id) => {
                    if (this.state.booths[booth_id].polygon_layer == layer) {
                        let booth = { ...this.state.booths[booth_id]['booth_data'] };
                        let new_location = layer._latlngs;
                        booth['DrawBounds']['polygon'] = new_location;
                        sending_data = booth;
                        sending_data['DrawBounds'] = JSON.stringify(sending_data['DrawBounds']);
                    }
                })
            }
            fetch(api_url + "?method=createBooth&returnFormat=json&Source=" + g_source_hex, {
                body: JSON.stringify(sending_data),
                method: "POST"
            }).then(function (response) {
                return response.json()
            }).then(function (data) {
                this.setState({
                    text: 'Changes synced'
                });
            }.bind(this));
        });
    }

    // Function for rendering the companies for the drop down.
    // Fulfilling expecation set by load_exhibitors.
    renderCompanies = () => {
        let return_array = [];
        Object.keys(this.props.exhibitors).map((key, index) => {
            return_array.push({ key: key, value: this.props.exhibitors[key].Companyname });
        });
        return_array.sort(function (a, b) {
            return a.value.localeCompare(b.value);
        })
        return return_array.map((key, index) => {
            return (<option value={key.key} key={key.key}>{key.value}</option>);
        });
    }

    // On zoom change, we need to adjust the font size
    // because people can get cranky with small font sizes.
    setTextSize = (zoom_level) => {
        if (zoom_level < 2) {
            if (zoom_level == 1) {
                this.setState({
                    fontSize: 12
                });
            } else {
                this.setState({
                    fontSize: 10
                });
            }
        } else {
            this.setState({
                fontSize: 16
            });
        }
        if (zoom_level > 2) {
            this.setState({
                showCompany: true
            });
        } else {
            this.setState({
                showCompany: false
            });
        }
    }

    onZoomChange = (event) => {
        if (this.state.zoom != event.zoom) {
            if (!this.state.disableZoom) {
                this.setTextSize(event.zoom);
            }
            this.setState({
                zoom: event.zoom
            });
        }
    }

    renderBooths() {
        let enablePopup = this.props.mode == 'public' || this.props.mode == 'cart';
        function isRectangle(coords) {
            function sqr(x) {
                return x * x;
            }
            var cx, cy;
            var dd1, dd2, dd3, dd4;
            cx = (parseInt(coords[0].lat) + parseInt(coords[1].lat) + parseInt(coords[2].lat) + parseInt(coords[3].lat)) / 4;
            cy = (parseInt(coords[0].lng) + parseInt(coords[1].lng) + parseInt(coords[2].lng) + parseInt(coords[3].lng)) / 4;
            dd1 = sqr(cx - parseInt(coords[0].lat)) + sqr(cy - parseInt(coords[0].lng));
            dd2 = sqr(cx - parseInt(coords[1].lat)) + sqr(cy - parseInt(coords[1].lng));
            dd3 = sqr(cx - parseInt(coords[2].lat)) + sqr(cy - parseInt(coords[2].lng));
            dd4 = sqr(cx - parseInt(coords[3].lat)) + sqr(cy - parseInt(coords[3].lng));
            return dd1 == dd2 && dd1 == dd3 && dd1 == dd4;
        }

        return this.state.undrawn_booths.map((booth) => {
            let work_booth = booth;
            let ref_ = "ID" + work_booth['BoothID'];
            let companyname = 'Available';
            let companyLogo = '';
            if (work_booth.hasOwnProperty('CompID') && work_booth['CompID'] != 0) {
                if (this.props.exhibitors.hasOwnProperty(work_booth['CompID'])) {
                    companyname = this.props.exhibitors[work_booth['CompID']].Companyname;
                    companyLogo = this.props.exhibitors[work_booth['CompID']].CompLogo
                } else {
                    companyname = "Not available"
                    companyLogo = '';
                }
            }
            let isRec = isRectangle(work_booth['DrawBounds']['polygon'][0]);
            let show_cost = (this.numberWithCommas(work_booth['Amount']) != null);

            let popUp = (<Popup>
                {fullscreen && this.props.exhibitors[work_booth['CompID']] && this.props.exhibitors[work_booth['CompID']].CompLogo && (<img src={companyLogo} style={{ height: '100%', width: '100%' }} />)}
                <h4 className='gx-my-2'><b>{companyname}</b></h4>
                {work_booth?.BoothNo && (
                    <><br /><div>Booth: {work_booth.BoothNo}</div></>
                )}
                {work_booth?.BoothSize && (
                    <>Size: {work_booth['BoothSize']}<br/></>
                )}
                {this.props.exhibitors[work_booth['CompID']] && this.props.exhibitors[work_booth['CompID']].sponsor && (<span className="label label-info">Sponsor</span>)}
                {/* {this.props.mode === 'cart' && !(work_booth.hasOwnProperty('CompID') && work_booth.CompID != 0) && show_cost && (<div>Cost: ${this.numberWithCommas(work_booth['Amount'])}</div>)} */}
            </Popup>);
            let color_key = 'taken';
            let color = '#000'
            if (work_booth.Class == 'Booth') {
                if (work_booth.hasOwnProperty('CompID') && work_booth.CompID != 0) {
                    // this is a taken booth.
                    color_key = 'taken';
                } else {
                    // else booth is available.
                    color_key = 'available';
                }
            } else {
                color_key = work_booth.Class;
            }
            this.state.invoiceBoothItems.map(invoiceBooth => {
                if (invoiceBooth['booth_data'].BoothID === work_booth.BoothID && work_booth.Class == 'Booth' && work_booth.Confirmed != 1) {
                    return color_key = 'taken',
                        color = '#ffa769'
                }
            })

            let popupDisabled = false;
            if (this.props.public_config.hasOwnProperty('ClickableNonSoldBooths') && this.props.public_config.ClickableNonSoldBooths && color_key == 'available') {
                popupDisabled = true;
            }
            let props = {
                color,
                weight: 2,
                fillOpacity: 1,
                fillColor: this.state.color[color_key],
                ref: ref_,
                key: ref_,
                onMouseOver:
                    (e) => {
                        e.target.openPopup();
                    }
                ,
                onMouseOut:
                    (e) => {
                        e.target.closePopup();
                    }

            };
            if (!isRec) {
                return (<Polygon positions={work_booth['DrawBounds']['polygon'][0]}
                    {...props}
                >{enablePopup && work_booth.Class == 'Booth' && !popupDisabled && popUp}</Polygon>);
            }
            else {
                return (<Rectangle bounds={work_booth['DrawBounds']['polygon'][0]}
                    {...props}
                >{enablePopup && work_booth.Class == 'Booth' && !popupDisabled && popUp}</Rectangle>)
            }

        });
    }

    renderLegacy() {
        let return_array = [];
        Object.keys(this.state.legacy_booths).map((key, index) => {
            let current = this.state.legacy_booths[key];
            let companyname = 'Unassigned';
            if (!current.hasOwnProperty('CompID') || current['CompID'] != 0) {
                if (this.props.exhibitors.hasOwnProperty(current['CompID'])) {
                    companyname = this.props.exhibitors[current['CompID']].Companyname;
                }
            }
            return_array.push({ key: key, BoothNo: current['BoothNo'], size: current['BoothSize'], name: companyname });
        });

        return_array.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
        return return_array.map((obj) => {
            return (<option value={obj.key} key={obj.key}>{obj.BoothNo} (Size: {obj.size}) ({obj.name})</option>);
        });

    }

    renderDiscounts() {
        if (this.state.discounts.length > 0) {
            let i = 0;
            return this.state.discounts.map((discount) => {
                i++;
                return (<div className="form-group" key={`Discount${i}Num`}>
                    <label className="col-md-4 control-label" htmlFor={`Discount${i}Num`} style={{ fontSize: 12 }}>Discount: {discount.DiscountName}</label>
                    <div className="col-md-6">
                        <input id={`Discount${i}Num`} name={`Discount${i}Num`} type="number" placeholder="" defaultValue={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data['Discount' + i + 'Num'] : ''} className="form-control input-md" onChange={this.changeHandler} />
                    </div>
                </div>);
            });
        } else {
            return null;
        }
    }

    renderClasses() {
        if (this.props.public_config.hasOwnProperty('classes')) {
            return this.props.public_config.classes.map((classx) => {
                return (<option value={classx.value} key={classx.value}>{classx.label}</option>);
            });
        } else { return null; }
    }

    mapUndrawnBooths(undrawnMappedFlag) {
        // Map undrawn booths or persisted booths, whatever we call to
        // master booths state. Hopefully, this method will be invoked from
        // componentDidUpdate().
        this.state.undrawn_booths.map((booth) => {
            this.state.booths[booth['BoothID']] = {
                'booth_data': booth,
                'polygon_layer': this.refs.hasOwnProperty('ID' + booth['BoothID']) ? this.refs['ID' + booth['BoothID']].leafletElement : null
            };
            guideLayers.push(this.refs.hasOwnProperty('ID' + booth['BoothID']) ? this.refs['ID' + booth['BoothID']].leafletElement : null);
        });
        this.setState({ booths: this.state.booths, undrawnMapped: undrawnMappedFlag });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.zoom != nextState.zoom) {
            return false;
        }
        if (this.state.undrawnMapped != nextState.undrawnMapped) {
            console.log('render cancelled');
            return false;
        }
        if (this.state.form != nextState.form && this.state.showNewBoothModal && !nextState.form == {}) {
            console.log('render cancelled');
            return false;
        }
        return true;
    }

    // Invokes on polygon click.
    selectPolygon(event) {
        if (!this.state.undrawnMapped) {
            this.mapUndrawnBooths(true);
        }
        let booth = null;
        // if component was initialized with edit option.
        Object.keys(this.state.booths).forEach(function (booth) {
            if (this.state.booths[booth].polygon_layer == event.layer) {
                // This booth has been clicked.
                // Todo convert this finding loop into a method.
                //
                // if edit mode.
                if (this.props.mode == 'edit' && this.state.booths[booth].booth_data.Class == 'Booth') {
                    this.setState({
                        modalType: 'update',
                        showNewBoothModal: true,
                        form: {
                            ...this.state.booths[booth].booth_data
                        },
                        populatedForm: {
                            ...this.state.booths[booth]
                        }
                    });
                    // if cart mode.
                } else if (this.props.mode == 'cart' && this.state.booths[booth].booth_data.Class == 'Booth' && this.state.cartList.indexOf(this.state.booths[booth]) == -1) {
                    let boothId = []
                    this.state.cartList.forEach(item => {
                        boothId.push(`${item['booth_data'].BoothID}`)
                    })
                    if (!boothId.includes(booth)) {
                        // Open allow positiveAction only if booth is NOT assigned.
                        let positiveAction = () => {
                            // if (!((this.state.cartList.length + this.state.registeredList.length) >= MaxBoothSelection)) {
                            //     this.setState({ selectedBoothID: booth, showCartConfirmationModal: true });
                            // }
                            if (this.props.mode == 'cart') {
                                this.setState({ selectedBoothID: booth, showCartConfirmationModal: true });
                            }
                        }
                        if (!this.state.booths[booth].booth_data.hasOwnProperty('CompID')) {
                            positiveAction();
                        } else if (this.state.booths[booth].booth_data['CompID'] == 0) {
                            positiveAction();
                        }
                    }
                }
            }
        }, this);

    }

    highlightBooth(booth_id) {
        this.setState({ showExhibitorModal: false, highlightBoothNo: booth_id });
        this.state.booths[booth_id].polygon_layer.openPopup();

    }

    // Lifecycle Method: componentDidUpdate
    componentDidUpdate(prevProps, prevState) {
        if (prevState.drawerVisible && window.innerWidth > 991) {
            this.setState({ drawerVisible: false })
        }
    }


    renderEditControl() {
        return (<EditControl
            position='topright'
            onCreated={(event) => { this.onCreated(event) }}
            showArea={false}
            showLength={false}
            draw={{
                polyline: false,
                polygon: { showArea: false, allowInteraction: false, guideLayers: guideLayers, snapDistance: 100 },
                rectangle: { showArea: false, allowInteraction: false },
                circle: false,
                marker: false,
                circlemarker: false
            }}
            onEditStart={this.editToggle}
            onEditStop={this.editToggle}
            ref='editControl'
            edit={{ remove: false }}
            onEdited={this.onEdited}
        />);
    }

    handleConfirmModalClose() {
        this.setState({ showCartConfirmationModal: false });
    }

    handleErrorModalClose() {
        this.setState({ showError: false });
    }
    handleClose = () => {
        this.setState({ showNewBoothModal: false });
    }

    handleDrawerState = (value) => {
        this.setState({ drawerVisible: value });
    }

    changeSortKey(key) {
        if (this.state.sortKey == key) {
            console.log("Changing sort order");
            this.setState({ sortOrder: !this.state.sortOrder });
        } else {
            this.setState({ 'sortKey': key });
        }
    }


    getCartButtonCount = () => {
        let invoiceItems = 0
        let booths = this.state.booths;
        _map(booths, (boothInfo, key) => {
            const {booth_data: booth} = boothInfo
            _map(this.props.invoiceItemArray, item =>{
               if(booth.BoothID === item?.booth_id && item.booth_checkout_time){
                invoiceItems = invoiceItems + 1
               }
            })
        })

        return invoiceItems
    }

    // Lifecycle Method: render
    render() {
        let image_height = this.props.image_height;
        let image_width = this.props.image_width;
        let floorplan = this.props.floorplan;
        let cartCount = this.getCartButtonCount();

        if (image_height && image_width && floorplan) {
            return (
                <div>
                    <BlockUi
                        tag="div"
                        blocking={this.state.addToCartloading}
                        keepInView={true}
                        renderChildren={true}
                        loader={<Spin tip={'Adding to cart'} />}
                    >
                        <div>
                            {/* If fullscreen and public view show the header */}
                            <Row>
                                <Col span={24}>
                                    <div>
                                        {this.props.mode == 'cart' &&
                                            <div className="gx-d-flex gx-justify-content-xl-end">
                                                <Button
                                                    className="gx-d-flex gx-align-items-center view-cart-button"
                                                    type="primary"
                                                    onClick={() => this.handleDrawerState(true)}
                                                    icon={<ShoppingCartOutlined style={{ fontSize: "25px" }} />}
                                                    size="large"
                                                    style={{
                                                        boxShadow:
                                                            '6px 6px 2px rgba(45, 45, 45, 0.65)',
                                                        border: 'transparent',
                                                    }}
                                                    shape={'round'}
                                                >
                                                    <span className="gx-ml-2">Current Booths</span>
                                                    {cartCount > 0 &&
                                                        <span className="gx-ml-2">
                                                            <Tag color="#cd201f" className="cart-count-tag">
                                                                {cartCount}
                                                            </Tag>
                                                        </span>
                                                    }
                                                </Button>
                                                <Button
                                                    className="gx-d-flex gx-align-items-center view-cart-button"
                                                    type="primary"
                                                    onClick={this.props.handleSubmit}
                                                    size="large"
                                                    style={{
                                                        boxShadow:
                                                            '6px 6px 2px rgba(45, 45, 45, 0.65)',
                                                        border: 'transparent',
                                                    }}
                                                    shape={'round'}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        }
                                        <Card className='gx-card-widget card-border-radius floor-card-shadow gx-mb-5'>
                                            <LeafletMap
                                                onViewportChanged={this.onZoomChange}
                                                attributionControl={false}
                                                drawControl={false}
                                                bounds={[[0, 0], [image_height, image_width]]}
                                                maxBounds={[[0, 0], [image_height, image_width]]}
                                                zoomControl={false}
                                                maxZoom={4}
                                                minZoom={0}
                                                ref="map"
                                                crs={CRS.Simple}
                                                style={{ height: image_height }}
                                            >
                                                <ImageOverlay url={floorplan} bounds={[[0, 0], [image_height, image_width]]} />
                                                <FeatureGroup ref={this.featureGroup} onClick={this.selectPolygon}>
                                                    {this.props.mode == 'edit' ? this.renderEditControl() : null}
                                                    {this.renderBooths()}
                                                    {this.renderMarkers()}
                                                </FeatureGroup>
                                            </LeafletMap>
                                        </Card>
                                        {this.props.mode == 'cart' && this.state.drawerVisible && (
                                            <Drawer
                                                placement="right"
                                                closable={true}
                                                onClose={() => this.handleDrawerState(false)}
                                                visible={this.state.drawerVisible}
                                                getContainer={false}
                                            >
                                                <Cart selectedBooths={this.state.cartList}
                                                    removeFromCart={this.removeFromCart.bind(this)}
                                                    booths={this.state.booths}
                                                    registeredBooths={this.state.registeredList}
                                                    checkoutLength={this.props?.boothConfiguration?.checkoutLength}
                                                    maximumSelectionAllowed={this.props.boothConfiguration.maximumSelectionAllowed}
                                                    MenuID={null}
                                                    Screen={Screen}
                                                    SESSION={{}}
                                                    invoiceItemArray={this.props.invoiceItemArray}
                                                />
                                            </Drawer>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <div className="row">
                                <div className="col-sm-12">{this.state.text}</div>
                            </div>

                            {/* Modals */}
                            {/* Modal: Add or update booth */}
                            <Modal
                                visible={this.state.showNewBoothModal}
                                title={this.state.modalType == 'new' ? 'New' : 'Update'}
                                footer={false}
                                onCancel={this.handleClose}
                                width={600}
                            >
                                <div className='gx-px-3'>
                                    <div>
                                        <div role="tabpanel" className="tab-pane active" id="new_booth">
                                            <Row>
                                                <Col span={24}>
                                                    <form className="form-horizontal" onSubmit={this.saveBooth}>
                                                        <fieldset style={{ paddingTop: 10 }}>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="BoothNo">Booth Label *</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <input id="BoothNo" name="BoothNo" type="text" placeholder="" value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.BoothNo : ''} className="form-control input-md" required onChange={this.changeHandler} />
                                                                </Col>
                                                            </Row>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="BoothSize">Booth Size</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <input id="BoothSize" name="BoothSize" type="text" placeholder="" value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.BoothSize : ''} className="form-control input-md" onChange={this.changeHandler} />
                                                                </Col>
                                                            </Row>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="Amount">Amount</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <input id="Amount" name="Amount" type="number" placeholder="" value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.Amount : ''} className="form-control input-md" onChange={this.changeHandler} />
                                                                </Col>
                                                            </Row>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="Deposit">Deposit amount</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <input id="Deposit" name="Deposit" type="number" placeholder="" value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.Deposit : ''} className="form-control input-md" onChange={this.changeHandler} />
                                                                </Col>
                                                            </Row>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="selectbasic">Company</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <select id="selectbasic" name="CompID" className="form-control" onChange={this.changeHandler} value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.CompID : ''} >
                                                                        <option value="">Unassigned</option>
                                                                        {this.renderCompanies()}
                                                                    </select>
                                                                </Col>
                                                            </Row>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="selectbasic">Booth Type</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <select id="selectbasic" name="Class" className="form-control" onChange={this.changeHandler} value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.Class : ''} >
                                                                        <option value="Booth">Booth</option>
                                                                        {this.renderClasses()}
                                                                    </select>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="OnHold">On Hold</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <select id="selectbasic" name="OnHold" className="form-control" onChange={this.changeHandler} value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.OnHold : ''}>
                                                                        <option value="0">No</option>
                                                                        <option value="1">Yes</option>
                                                                    </select>
                                                                </Col>
                                                            </Row>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="BoothNotes">Booth Notes</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <textarea id="BoothNotes" name="BoothNotes" placeholder="" value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.BoothNotes : ''} className="form-text-area form-control input-md" onChange={this.changeHandler} />
                                                                </Col>
                                                            </Row>
                                                            <Row className='gx-m-2'>
                                                                <Col span={6}>
                                                                    <label className="col-md-4 control-label" htmlFor="Description">Description</label>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <textarea id="Description" name="Description" placeholder="" value={this.state.populatedForm.hasOwnProperty('booth_data') ? this.state.populatedForm.booth_data.Description : ''} className="form-text-area form-control input-md" onChange={this.changeHandler} />
                                                                </Col>
                                                            </Row>
                                                            {this.renderDiscounts()}
                                                        </fieldset>
                                                        <hr />
                                                        <div className='gx-text-right'>
                                                            {this.state.activeTab == 1 &&
                                                                <>
                                                                    <Button htmlType="submit" className='form-btn-primary'>Save</Button>
                                                                    <Button type="primary" danger onClick={this.cancel}>Cancel</Button>
                                                                    {
                                                                        this.state.modalType == 'update' &&
                                                                        <Button type="primary" onClick={() => this.setState({ showDelete: true })}>Delete</Button>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </form>
                                                </Col>
                                                <Modal title={'Confirm deletion.'} onOk={this.delete} onCancel={() => this.setState({ showDelete: false })} cancelButtonProps={{ style: { marginRight: 0, color: '#fff', backgroundColor: '#626262', borderColor: '#555' } }} visible={this.state.showDelete} okText={'Delete'} >
                                                    <div className="modal-content">
                                                        <div className="modal-body">
                                                            Please confirm that you want to delete the booth. Association with exhibitor will be lost if the booth is assigned.
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            {/* Model: Confirmation Modal */}
                            {this.state.showCartConfirmationModal && <ConfirmationModal show={this.state.showCartConfirmationModal}
                                onHide={this.handleConfirmModalClose.bind(this)}
                                handleConfirmModalClose={this.handleConfirmModalClose.bind(this)}
                                selectedBooth={this.state.booths[this.state.selectedBoothID]}
                                addToCart={this.addToCart.bind(this)}
                            />}
                            {this.state.showError && <ErrorModal show={this.state.showError}
                                errorText={this.state.errorText}
                                handleModalClose={this.handleErrorModalClose.bind(this)}
                                onHide={this.handleErrorModalClose.bind(this)}
                            />}
                        </div>
                    </BlockUi>
                </div>
            );
        }
        else {
            return (<div></div>);
        }
    }
}


const mapStateToProps = ({ exhibFloor, corporateMembership, loginInfo }) => {
    const { confid = null, conferenceid = null } = corporateMembership?.generalSettings;
    const { compid, comp_uuid } = corporateMembership?.userCompanyAffiliation;
    const { exhibuuid } = corporateMembership?.exhibitorCartDetails;
    g_source_hex = loginInfo?.source_hex
    g_confId = confid
    g_conferenceId = conferenceid
    g_compid = compid
    g_comp_uuid = comp_uuid
    g_exhib_uuid = exhibuuid
    return {
        exhibitors: exhibFloor.exhibitors,
        boothConfiguration: exhibFloor.boothConfiguration,
        invoiceItemArray : corporateMembership.exhibitorCart.InvoiceItemArray || [],
    }
}


export default connect(mapStateToProps, { handleBoothListLoader, requestExhibitorCart  })(withRouter(LMap));