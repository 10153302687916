import React from 'react';
import { Form, Select } from 'antd';
import Title from '../components/Title';
import Description from '../components/Description';
import useRules from '../hooks/useRules';
import NormalText from '../components/NormalText';

const { Option } = Select;

interface Props {
	name: string;
	label: string;
	description: string;
	options: Array<{
		id: any;
		label: string;
		value: any;
		disabled: boolean;
		icon?: any;
	}>;
	value: any;
	onChange: any;
	defintion: string;
	required: string;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	error: string;
	display: boolean;
	fieldmetajson: any;
}

export const DropDown = ({
	label,
	name,
	options,
	value,
	onChange,
	description,
	defintion,
	required,
	primaryColor,
	secondaryColor,
	textColor,
	error,
	display,
	fieldmetajson,
}: Props) => {
	const rules = useRules(required);

	if (!required) {
		options = [
			{
				id: -1,
				label: '',
				value: -1,
				disabled: false,
				icon: '',
			},
		].concat(
			options.map(option => {
				return {
					...option,
					id: option?.id,
					label: option?.label,
					value: option?.value,
					disabled: option?.disabled,
					icon: option?.icon,
				};
			}),
		);
	}

	return (
		<div>
			<Title
				label={label}
				color={primaryColor}
				error={error}
				required={required}
				name={name}
			/>
			<Description label={description} color={secondaryColor} />

			<Form.Item rules={rules} name={name}>
				<Select
					onClick={onChange}
					style={{ width: '100%' }}
					placeholder={'Select one item'}
					value={value}
					showSearch
					optionFilterProp="label"
				>
					{options.map((option, index) => (
						<Option key={option.id} value={option.id} label={option.label}>
							<NormalText color={textColor} label={option.label} />
						</Option>
					))}
				</Select>
			</Form.Item>
		</div>
	);
};
