import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RefinementList } from 'react-instantsearch-dom';
import { Divider, Button, Row, Col, Spin } from "antd";
import _toLower from 'lodash/toLower';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';

import SearchInput from './SearchInput';
import SearchBox from "../Algolia/SearchBox"
import ClearRefinements from "../Algolia/ClearRefinements";
import { searchStateToParams } from '../../Consumption/helpers';
import { requestStoreSearchState } from "../../../appRedux/actions";
import CustomRefinementSelect from "./CustomRefinementSelect";
import DirectLinkModel from "../../Ecommerce/Store/components/DirectLinkModel";
import { ShareAltOutlined } from "@ant-design/icons";

const AdvanceSearch = ({
    searchState,
    advancedRefinementCount,
    categoriesMap = {},
    tagsMap = {},
    totalHitsCountInit = 0,
    facetsValue = [],
    handleShowResults,
    customRefresh,
    quickViewOnly = false,
    handleCustomSearch = () => { },
    showTags = false
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    const loading = useSelector(state => state.ecommerce.storePricesLoading);
    const [searchValues, setSearchValues] = useState({ company: '', country: '', tags: '' });

    useEffect(() =>{
        if(loading === true){
            setIsLoading(true)
        }else{
            setIsLoading(false)
        }
    },[loading])

    const handleAddToURL = (state) => {
        if (quickViewOnly) {
            dispatch(requestStoreSearchState({ searchState: state }));
        } else {
            history.push(`?${searchStateToParams(state)}`);
        }
    };

    const filterShareConfig = useSelector(
        (state) => state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.filter_share_config
    ) 
    const addToUrl = () => {
        let state = {
            ...searchState,
        }
        handleAddToURL(state);
        handleShowResults();
    }

    const clearSelectedValues = () => {
        handleAddToURL(searchStateToParams({}));
    };

    const categoriesFilter = useMemo(() => {
        return (
            <RefinementList
                className="gx-algolia-refinementList"
                attribute="advanceCategory"
                limit={Number.POSITIVE_INFINITY}
                transformItems={categories => {
                    return (
                        _orderBy(categories, ['isRefined', 'count'], ['desc', 'desc'])
                            .map(category => ({
                                ...category,
                                label: categoriesMap[category.label] ?? category.label
                            }))
                    )
                }
                }
            />
        )
    }, [categoriesMap])

    const tagsFilter = useMemo(() => {
        return (
            <RefinementList
                className="gx-algolia-refinementList"
                attribute="tag"
                limit={Number.POSITIVE_INFINITY}
                transformItems={tags => {
                    // onTags && onTags(tags);
                    return (
                        _orderBy(tags, ['isRefined', 'count'], ['desc', 'desc'])
                            .map(tag => ({
                                ...tag,
                                label: tagsMap[tag.label] ?? tag.label,
                            }))
                            .filter(tag => _toLower(tag.label).includes(searchValues.tags)))
                }}
            />
        )
    }, [tagsMap, searchValues.tags])

    const companyFilter = useMemo(() => {
        return (
            <RefinementList
                className="gx-algolia-refinementList"
                attribute="company"
                limit={Number.POSITIVE_INFINITY}
                transformItems={companies => {
                    return (_orderBy(companies, ['isRefined', 'count'], ['desc', 'desc'])
                        .filter(company => _toLower(company.label).includes(searchValues.company)))
                }}
            />
        )
    }, [searchValues.company])

    const countryFilter = useMemo(() => {
        return (
            <RefinementList
                className="gx-algolia-refinementList"
                attribute="country"
                limit={Number.POSITIVE_INFINITY}
                transformItems={countries => {
                    return (
                        _orderBy(countries, ['isRefined', 'count'], ['desc', 'desc'])
                            .filter(country => _toLower(country.label).includes(searchValues.country))
                    )
                }}
            />
        )
    }, [searchValues.country])

    const fileTypeFilter = useMemo(() => {
        return (
            <RefinementList
                className="gx-algolia-refinementList filterType"
                attribute="fileType"
                limit={Number.POSITIVE_INFINITY}
            />
        )
    }, []);

    const handleSearch = (key, value) => {
        setSearchValues(prev => ({ ...prev, [key]: _toLower(value) }));
    };

    const advanceSearch = [
        {
            key: false,
            text: 'Search Keywords',
            searchBox: <SearchBox
                customRefresh={customRefresh}
                handleCustomSearch={handleCustomSearch}
            />,
        },
        {
            key: 'fileType',
            text: 'File Type',
            filter: fileTypeFilter,
            searchBox: false
        },
        {
            key: 'category',
            text: 'Category',
            filter: categoriesFilter,
            searchBox: false
        },
        {
            key: 'tag',
            text: 'Tag',
            searchBox: <SearchInput
                name="tags"
                defaultValue={searchValues.tags}
                handleSearch={handleSearch}
            />,
            filter: !showTags ? tagsFilter : null
        },
        {
            key: 'company',
            text: 'Company',
            searchBox: <SearchInput
                name="company"
                defaultValue={searchValues.company}
                handleSearch={handleSearch}
            />,
            filter: companyFilter
        },
        {
            key: 'country',
            text: 'Country',
            searchBox: <SearchInput
                name="country"
                defaultValue={searchValues.country}
                handleSearch={handleSearch}
            />,
            filter: countryFilter
        }
    ]

    return (
        <Spin spinning={isLoading} >
        <div>
            <div
                className={"gx-d-flex gx-align-items-center gx-justify-content-between filter-header filter-header-active"}
            >
                <div className="gx-d-flex gx-align-items-sm-baseline">
                    {advancedRefinementCount ? (
                        <ClearRefinements
                            clearSelectedValues={clearSelectedValues}
                            showCount
                            currentRefinementListCount={advancedRefinementCount}
                        />
                    ) : null}
                </div>
            </div>
            <div className="gx-mt-3">
                {advanceSearch.map((item, index) => {
                    return (
                        <div key={index}>
                            <Row gutter={[16, 16]}>
                                <Col
                                    span={5}
                                    xs={24}
                                    xl={5}
                                >
                                    {(facetsValue.includes(item.key) || !item.key || item.key === 'category') ? <div>{item.text}</div> : null}
                                </Col>
                                <Col
                                    span={19}
                                    xs={24}
                                    xl={19}
                                >
                                    {item.searchBox ?
                                        (<div>
                                            {(facetsValue.includes(item.key) || !item.key) ? <div>{item.searchBox}</div> : null}
                                            <div className="gx-mt-3 filtersWrapper">{item.filter}</div>
                                        </div>) :
                                        <div className="filtersWrapper">{item.filter}</div>}
                                </Col>
                            </Row>
                            {((index > 0) && (facetsValue.includes(item.key) || !item.key)) ? <Divider /> : null}
                        </div>
                    )
                })}
            </div>
            <Row gutter={[16, 16]}>
                <Col
                    span={5}
                    xs={24}
                    xl={5}
                    className="gx-d-flex gx-align-items-center"
                >
                    <div>Publication Date</div>
                </Col>
                <Col
                    span={19}
                    xs={24}
                    xl={19}
                >
                    <>
                        <div className="advance-select-wrapper gx-d-flex gx-align-items-center">
                            <div>Start (Optional) : &nbsp;</div>
                            <CustomRefinementSelect
                                containerId="selectStartDatePopUp"
                                attribute="publicationStart"
                                limit={Number.POSITIVE_INFINITY}
                            />
                            <div className="publication-select-end gx-d-flex gx-align-items-center" style={{ marginLeft: '4rem' }}>
                                <div>End (Optional)  : &nbsp;</div>
                                <CustomRefinementSelect
                                    containerId="selectEndDatePopUp"
                                    attribute="publicationEnd"
                                    limit={Number.POSITIVE_INFINITY}
                                />
                            </div>
                        </div>
                    </>
                </Col>
            </Row>
            <div className="result-btn" style={{display:'flex', flexDirection:'row' ,justifyContent:'center'}}>
                <Button type="primary" onClick={addToUrl}>
                    Show {(advancedRefinementCount || searchState?.query) ? totalHitsCountInit : 0} Results
                </Button>
                {(filterShareConfig?.base_url && advancedRefinementCount > 0)  && <DirectLinkModel
                    base_url={`${filterShareConfig?.base_url}${window.location.search}`}
                    className='ant-footer-button-hide'
                >
                    <div className="gx-d-flex gx-justify-items-center">
                        <Button icon={<ShareAltOutlined />} type="secondary">{filterShareConfig?.display_text}</Button>
                    </div>
                </DirectLinkModel>}
            </div>
        </div>
        </Spin>
    )
}

export default AdvanceSearch