import React, { useEffect, useState } from 'react';
import {
	requestToAddRegistrationItemsToCart,
	requestToDeleteRegistrationItemFromCart,
} from '../../appRedux/actions';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dropdown, Menu, Card, Modal, Alert } from 'antd';

import { useParams } from 'react-router';

import { ReactComponent as CloseModalIcon } from '../../assets/icons/modalClose.svg';
import { ReactComponent as RegistrationComplete } from '../../assets/icons/RegistrationComplete.svg';

import { formatPayment } from '../Common/helpers/helper';

import { DownOutlined } from '@ant-design/icons';
import './styles/catalogue.css';
import { InstantSearch } from 'react-instantsearch-dom';

import SearchBox from '../../components/Common/Algolia/SearchBox';

import Fuse from 'fuse.js';

import 'instantsearch.css/themes/algolia.css';

const ExhibitorCatalogue = props => {
	const [modalOpen, setModalOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState('');
	const [allItems, setAllItems] = useState([]);
	const [allItemsArrayFormat, setAllItemsArrayFormat] = useState([]);
	const [results, setResults] = useState([]);
	const [cancelFilterDisplay, setCancelFilterDisplay] = useState(false);
	const [loading, setLoading] = useState(false);
	const [query, setQuery] = useState('');

	const { moduleUrlId } = useParams();

	const cartUuid = useSelector(
		state => state.corporateMembership.exhibitorCartDetails.exhibitorCartUuid,
	);
	const authUser = useSelector(state => state.auth.authUser);

	const languageObject = useSelector(state => state.corporateMembership.languageObject);

	const InvoiceItemArray = useSelector(
		state => state.corporateMembership.exhibitorCart.InvoiceItemArray,
	);

	const cartFieldIds = InvoiceItemArray.map(obj => obj.field_id);
	const cartValueIds = InvoiceItemArray.map(obj => obj.value_id);

	const appDir = useSelector(state => state.loginInfo.appdir);
	const compUuid = useSelector(
		state => state.corporateMembership.userCompanyAffiliation.comp_uuid,
	);
	const exhibUuid = useSelector(
		state => state.corporateMembership.exhibitorCartDetails.exhibuuid,
	);

	const publicEmbed =
		useSelector(state => state.settings.publicEmbed) ||
		window.location.search.includes('public_embed=1'); // on the refresh the redux doesn't set public_embed, need windows.location.search for safety

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.catalogue) {
			setResults(props.catalogue.catalogue_screens);
			setAllItems(props.catalogue.catalogue_screens);
		}
	}, []);

	useEffect(() => {
		const valueItems = [];
		for (const item of allItems) {
			const itemClone = { ...item };
			itemClone.fieldids = [];
			for (const subItem of item.fieldids) {
				const subItemClone = { ...subItem };
				subItemClone.valueids = [];
				for (const value of subItem.valueids) {
					valueItems.push({ ...value, parent: subItemClone, grandParent: itemClone });
				}
			}
		}
		setAllItemsArrayFormat(valueItems);
	}, [allItems]);

	const handleMenuClick = e => {
		const newResult = results.filter(category => category.button === e.key);
		setResults(newResult);
		setCancelFilterDisplay(true);
	};

	const removeFilter = () => {
		setCancelFilterDisplay(false);
		setResults(allItems);
	};

	const menu = (
		<Menu onClick={handleMenuClick}>
			{results.map(catagory => {
				return <Menu.Item key={catagory.button}>{catagory.button}</Menu.Item>;
			})}
		</Menu>
	);

	const addToCart = item => {
		const itemsToAdd = {};

		itemsToAdd[item.fieldname] = {
			valueid: currentItem.valueid,
			qty: 1,
		};

		dispatch(
			requestToAddRegistrationItemsToCart({
				form: {
					itemstoadd: itemsToAdd,
				},
				urlId: moduleUrlId,
				appDir,
				publicEmbed,
				cartUuid,
				compUuid,
				exhibUuid,
			}),
		);
	};

	const deleteFromCart = item => {
		const cartItem = InvoiceItemArray.filter(obj => {
			return obj.value_id === currentItem.valueid;
		});

		const itemsToDelete = {
			field_id: cartItem[0].field_id,
			invoice_id: cartItem[0].invoice_id,
		};

		dispatch(
			requestToDeleteRegistrationItemFromCart({
				form: {
					itemstodelete: itemsToDelete,
				},
				urlId: moduleUrlId,
				appDir: appDir,
				publicEmbed: publicEmbed,
				cartUuid: cartUuid,
				compUuid: compUuid,
				exhibUuid: exhibUuid,
				authUser: authUser,
			}),
		);
	};

	const closeModal = () => {
		setModalOpen(false);
		setCurrentItem('');
	};

	const openModal = value => {
		setCurrentItem(value);
		setModalOpen(true);
	};

	const options = {
		// search definitions for title and desc of product
		keys: ['keywordsearch'],
		includeMatches: true,
	};

	const fuse = new Fuse(allItems, options);

	const customSearchClient = {
		search: requests =>
			new Promise((resolve, reject) => {
				if (loading) return reject();

				const { query, facetFilters = [] } = requests[0].params;

				setQuery(query);

				// title and desc search, definitions in ecommerce redux

				let totalHits = query === '' ? allItems : fuse.search(query).map(r => r.item);

				const nbHits = totalHits.length;
				const hits = totalHits;

				resolve({ results: [{ hits, nbHits }] });
			}),
	};

	return (
		<div>
			<div className="gx-d-flex">
				<InstantSearch indexName="exhibitorCatalogue" searchClient={customSearchClient}>
					<section className="gx-d-flex" style={{ overflow: 'visible' }}>
						<div className="gx-flex-1" style={{ overflow: 'visible' }}>
							<div className="gx-mb-3">
								<SearchBox />
							</div>
						</div>
					</section>
				</InstantSearch>
				<Dropdown overlay={menu} className="gx-ml-5">
					<Button>
						Filter <DownOutlined />
					</Button>
				</Dropdown>
				{cancelFilterDisplay && (
					<CloseModalIcon
						className="gx-mb-3"
						onClick={removeFilter}
						style={{ width: '2em', cursor: 'pointer' }}
					/>
				)}
			</div>
			{results.map(category => {
				return (
					<div>
						{category.fieldids.length &&
							category.screenkeywordsearch
								.toLowerCase()
								.includes(query.toLowerCase()) && <h1>{category.button}</h1>}
						<div className="catalogue-grid gx-mb-5">
							{category.fieldids.map(item => {
								return item.valueids.map(value => {
									return (
										value.keywordsearch
											.toLowerCase()
											.includes(query.toLowerCase()) && (
											<div>
												<Card
													title={item.fieldlabel}
													headStyle={{
														color: 'white',
														borderRadius: '2rem',
													}}
													style={{ cursor: 'pointer' }}
													className="gx-h-100 card catalogue-card catalogue-card-header"
													onClick={() => openModal(value)}
												>
													<div
														className="gx-d-flex gx-flex-column gx-mb-0 "
														style={{ height: '70%' }}
													>
														<div
															style={{
																margin: 'auto',
																textAlign: 'center',
															}}
															className="gx-mb-0 gx-mt-0"
														>
															<p>{value.rowlabel}</p>
														</div>

														<img
															src={value.imgURL}
															className="gx-mb-5"
														/>

														<div className="gx-d-flex gx-justify-content-between">
															<p>
																{value.soldout ? (
																	'Sold Out'
																) : (
																	<span className="fg-primary">
																		{cartValueIds.includes(
																			value.valueid,
																		) ? (
																			<div className="gx-d-flex gx-align-items-center gx-mb-3">
																				<RegistrationComplete
																					className="gx-mr-2 gx-mt-1"
																					style={{
																						width:
																							'1.5rem',
																					}}
																				/>
																				<span
																					style={{
																						color:
																							'#4CAF50',
																					}}
																				>
																					Purchased
																				</span>
																			</div>
																		) : value.quantity_remaining >
																		  0 ? (
																			`Only ${value.quantity_remaining} left`
																		) : (
																			''
																		)}
																	</span>
																)}
															</p>
															${formatPayment(value.rowvalue)}
														</div>
													</div>
												</Card>

												{currentItem &&
													currentItem?.valueid === value.valueid && (
														<Modal
															className="custom-ant-modal-content"
															mask={false}
															visible={
																currentItem.valueid ===
																	value.valueid && modalOpen
															}
															closeIcon={
																<CloseModalIcon
																	className="gx-mt-2"
																	style={{ width: '2em' }}
																/>
															}
															footer={
																<div>
																	<div>
																		{cartValueIds.some(r =>
																			currentItem?.TheseValueFieldsBlockedIfThisValueIDSelected.includes(
																				r,
																			),
																		) && (
																			<Alert
																				message={
																					<div
																						className="gx-mt-2"
																						style={{
																							textAlign:
																								'left',
																						}}
																					>
																						{
																							languageObject.registration_value_blocked_mutually_exclusive_msg
																						}{' '}
																						{currentItem.TheseValueFieldsBlockedIfThisValueIDSelected.map(
																							obj => (
																								<div>
																									{allItemsArrayFormat
																										.filter(
																											x =>
																												x.valueid ===
																												obj,
																										)
																										.map(
																											ele => (
																												<p>
																													{
																														ele.rowlabel
																													}
																												</p>
																											),
																										)}
																								</div>
																							),
																						)}
																					</div>
																				}
																				// message={
																				// 	languageObject.registration_value_blocked_mutually_exclusive_msg
																				// }
																				type="warning"
																				closable={false}
																				// onClose={onClose}
																			/>
																		)}

																		{cartFieldIds.includes(
																			item.fieldid,
																		) && (
																			<Alert
																				message={InvoiceItemArray.filter(
																					obj =>
																						(obj.field_id =
																							item.fieldid),
																				)
																					.slice(0, 1)
																					.map(obj => (
																						<p
																							className="gx-mt-2"
																							style={{
																								textAlign:
																									'left',
																							}}
																						>
																							{
																								languageObject.registration_value_change_mutually_exclusive_msg
																							}{' '}
																							{
																								obj.invoice_description
																							}{' '}
																						</p>
																					))}
																				type="warning"
																				closable={false}
																			/>
																		)}
																	</div>

																	<div className="gx-d-flex gx-justify-content-between">
																		{cartValueIds?.includes(
																			currentItem?.valueid,
																		) ? (
																			<Button
																				className="gx-ml-auto button-primary gx-font-weight-semi-bold shadow-2"
																				onClick={() =>
																					deleteFromCart(
																						value,
																					)
																				}
																			>
																				Delete From Cart
																			</Button>
																		) : (
																			!cartValueIds.some(r =>
																				currentItem?.TheseValueFieldsBlockedIfThisValueIDSelected.includes(
																					r.toString(),
																				),
																			) && (
																				<Button
																					className="gx-ml-auto button-primary gx-font-weight-semi-bold shadow-2"
																					onClick={() =>
																						addToCart(
																							item,
																						)
																					}
																				>
																					Purchase
																				</Button>
																			)
																		)}

																		<Button
																			onClick={() =>
																				closeModal()
																			}
																		>
																			Close
																		</Button>
																	</div>
																</div>
																//also need language object here for radio case, warning it will replace existing item
															}
															onCancel={() => closeModal()}
															destroyOnClose={true}
														>
															<div className="gx-d-flex gx-justify-content-center gx-mb-3">
																<h2>
																	{currentItem?.rowlabel ??
																		item?.fieldlabel}
																</h2>

																<h2
																	className="fg-primary"
																	style={{ marginLeft: '0.5rem' }}
																>
																	$
																	{formatPayment(
																		currentItem?.rowvalue,
																	)}
																</h2>
															</div>

															<div
																style={{
																	margin: 'auto',
																	width: '50%',
																}}
															>
																{currentItem.imgURL && (
																	<img
																		src={currentItem?.imgURL}
																		className="gx-mb-5"
																	/>
																)}
															</div>
															<div
																dangerouslySetInnerHTML={{
																	__html: currentItem.rowtext,
																}}
															></div>
														</Modal>
													)}
											</div>
										)
									);
								});
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ExhibitorCatalogue;
