import React, { useEffect, useState } from 'react';
import OpenModal from '../Common/OpenModal';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { requestToUpdateRoles } from 'appRedux/actions';

const ManageRoles = ({ record }) => {
	const [checkedList, setCheckedList] = useState([]);

	const onChange = list => {
		setCheckedList(list);
	};

	useEffect(() => {
		setCheckedList(
			record.roles?.map(role => {
				return role.roleid;
			}),
		);
	}, [record]);

	const corporateMembership = useSelector(state => state.corporateMembership);
	const loginInfo = useSelector(state => state.loginInfo);
	const dispatch = useDispatch();

	const updateRoles = () => {
		dispatch(
			requestToUpdateRoles({
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				sourceHex: loginInfo.source_hex,
				uuid: record.uuid,
				roleIds: checkedList,
			}),
		);
	};

	return (
		<OpenModal
			icon={'manage'}
			title={'Manage Roles'}
			action={'UPDATE'}
			modalContent={
				<div>
					<Checkbox.Group
						options={corporateMembership.roles.map(role => {
							return {
								label: role.label,
								value: role.roleid,
							};
						})}
						value={checkedList}
						onChange={onChange}
					/>
				</div>
			}
			okClicked={updateRoles}
		/>
	);
};

export default ManageRoles;
