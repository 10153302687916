import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { InstantSearch, Configure, Stats } from 'react-instantsearch-dom';
import { Button, Drawer, Result } from 'antd';

import { useProducts } from '../hooks/useProducts';
import { useTags } from '../hooks/useTags';

import SelectionResults from './SelectionResults';
import SelectionSidebar from './SelectionSidebar';
import SelectionFilter from './SelectionFilters';
import SelectionCartList from './SelectionCartList';
import SearchBox from '../../../Common/Algolia/SearchBox';
import Pagination from '../../../Common/Algolia/Pagination';
import StoreHeader from '../components/StoreHeader';

import { productsPerPage } from '../helpers';
import { paginate } from 'components/Common/helpers/helper';

import 'instantsearch.css/themes/algolia.css';
import { receiveModularUrlId, storeCardsViewType, storeConsumptionSortType, storeSortType } from '../../../../appRedux/actions';
import { paramsToSearchState, searchStateToParams } from '../helpers';
import AdvancedSearch from '../../../Common/AdvanceSearch';

const SelectionPage = () => {
	const history = useHistory();
	const location = useLocation();
	const { moduleUrlId, bundleId } = useParams();
	const [productsLoading, products] = useProducts();
	const [tagsLoading, tagsMap, , getFilterTagIds] = useTags();
	const dispatch = useDispatch();
	const searchFuse = useSelector(state => state.ecommerce.storeSearchFuse);
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const sortType = useSelector(state => state.ecommerce.cardSortType);
	const sortQuery = useSelector(state => state.ecommerce.sortQuery);
	const categoriesMap = useSelector(state => state.ecommerce.storeCategoriesMap);
	const cardViewType = useSelector((state) => state.consumption.cardDisplayType);
	const showTags = useSelector(
		state =>
			state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
	);
	const hideCategoryFilter = useSelector(
		(state) => 
		state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_main_category_filter
	)

	const productTagsFetch = useSelector((state) => state.ecommerce?.receiveProductTagsFetch)

	const attendeeSettingsFetch = useSelector((state) => state.ecommerce?.receiveAttendeeFetch)
	
	const storeProductsFetch = useSelector((state) => state.ecommerce?.receiveStoreProductsFetch)
	
	const productPricesFetch = useSelector((state) => state.ecommerce?.receiveProductPricesFetch)
	
	const accessListFetch = useSelector((state) => state.ecommerce?.receiveAccessListFetch)

	const errorMessage = useSelector((state) => state.ecommerce?.errorMessage)

	const selectableProducts = useMemo(() => products.filter(p => p.show), [products]);
	const [showFilters, setShowFilters] = useState(false);
	const [searchState, setSearchState] = useState(paramsToSearchState(location.search));
	const [publicationDateFilter, setDateYearsList] = useState([]);
	const [customRefresh, setCustomRefresh] = useState(false);
	const [pageSize, setPageSize] = useState(1);
	const keys = ['category', 'tag', 'advanceCategory']

	const loading = tagsLoading || productsLoading;

	useEffect(() => {
		let querySearch = new URLSearchParams(history.location.search).get("query")
		if (sortType === '2' && !querySearch) {
			dispatch(storeSortType({ cardSortType: '0' }));
		}
	}, [history.location.search]);

	useEffect(() => {
		if (cardViewType === 2) {
			dispatch(storeCardsViewType({ cardDisplayType: 0 }))
		}
	}, [cardViewType])

	const advancedSearchRefinementCount = useMemo(() => {
		let filterCount = 0;
		if (searchState?.refinementList) {
			let selectedStartYearKey = Object.entries(searchState?.refinementList).filter(([key, item]) => key === 'publicationStart' && item)?.[0];
			Object.entries(searchState?.refinementList).forEach(([key, item]) => {
				if (selectedStartYearKey && key === 'publicationEnd' && item) {
					filterCount += 1;
				} else if (key !== 'category'
					&& key !== 'publicationStart'
					&& key !== 'publicationEnd'
					&& item?.length) {
					filterCount += item?.length
				}
			});
		}
		return filterCount;
	}, [searchState?.refinementList]);

	useEffect(() => {
		const publisedStartDateList = [
			...new Set(
				products.map(data => {
					let pubDate = new Date(data?.publicationDate)
					let converted = pubDate.getFullYear()
					return converted
				}).sort((a, b) => {
					return b - a;
				}).filter((data) => !isNaN(data)),
			),
		];
		setDateYearsList(publisedStartDateList);
	}, [products])

	useEffect(() => {
		// to refresh the search box for reflect searched value in both the places
		setCustomRefresh(true);
		setTimeout(() => {
			setCustomRefresh(false);
		}, 50);
	}, [searchState?.query]);

	useEffect(() => {
		dispatch(receiveModularUrlId({ moduleUrlId }))
	}, [moduleUrlId])

	const authorBasedMapping = useMemo(() => products.reduce((acc, p) => {
		let temp = { ...acc };
		for (const author of p.author_info_array ?? []) {
			if (author.country) {
				if (!temp.country[author.country]) {
					temp.country[author.country] = new Set();
				}
				temp.country[author.country].add(p.id)
			}
			if (author.company) {
				if (!temp.company[author.company]) {
					temp.company[author.company] = new Set();
				}
				temp.company[author.company].add(p.id)
			}
		}
		return temp;
	}, { country: {}, company: {} }), [products]);

	const customSearchClient = {
		search: requests =>
			new Promise((resolve, reject) => {
				if (loading) return reject();

				const { query, page, hitsPerPage, facetFilters = [] } = requests[0].params;

				// title and desc search, definitions in ecommerce redux
				let totalHits =
					query === '' ? products : searchFuse.search(`'"${query}"`).map(r => r.item);
				const facets = { category: {}, tag: {}, advanceCategory: {}, country: {}, company: {}, publicationStart: {}, publicationEnd: {}, fileType: {} };
				const categorySet = new Set(selectableProducts.map(p => p.categoryId));
				const tagSet = new Set(selectableProducts.flatMap(p => getFilterTagIds(p)));
				let selectedStartYearKey = facetFilters.filter((data) => data?.[0].includes('publicationStart:'))?.[0];
				let selectedStartYear = Array.isArray(selectedStartYearKey) && selectedStartYearKey?.length
					? selectedStartYearKey[0].split(':')[1] : null;

				const buildCategoryFacets = () => {
					for (const categoryId of categorySet) {
						const count = totalHits.filter(p => p.categoryId === categoryId && !p.parentIds.length).length;
						facets.category[categoryId] = count;
					}
				};

				const buildTagFacets = () => {
					for (const tagId of tagSet) {
						const count = totalHits.filter(p => getFilterTagIds(p).includes(tagId))
							.length;
						facets.tag[tagId] = count;
					}
				};

				const buildAdvanceCategoryFacets = () => {
					for (const categoryId of categorySet) {
						const count = totalHits.filter(p => p.categoryId === categoryId).length;
						facets.advanceCategory[categoryId] = count;
					}
				};

				const buildCountryFacets = () => {
					for (const key in authorBasedMapping.country) {
						facets.country[key] = totalHits.filter(p =>
							(authorBasedMapping.country[key]?.has(p.id))).length;
					}
				};

				const buildCompanyFacets = () => {
					for (const key in authorBasedMapping.company) {
						facets.company[key] = totalHits.filter(p =>
							(authorBasedMapping.company[key]?.has(p.id))).length;
					}
				};

				const buildPublishStartDateFacets = () => {
					publicationDateFilter.forEach((year) => {
						facets.publicationStart[year] = 0;
					})
				};

				const buildPublishEndDateFacets = () => {
					let publishedFilterList = publicationDateFilter;
					if (selectedStartYear) {
						publishedFilterList = publicationDateFilter.filter((data) => data >= selectedStartYear);
					}
					publishedFilterList.forEach((year) => {
						facets.publicationEnd[year] = 0;
					});
				};

				const buildFileTypeFacets = () => {
					facets.fileType['PDF'] = 0;
					facets.fileType['Video'] = 0;
				}

				// build facets before filtering first
				buildCategoryFacets();
				buildTagFacets();
				buildAdvanceCategoryFacets();
				buildCountryFacets();
				buildCompanyFacets();
				buildPublishStartDateFacets();
				buildPublishEndDateFacets();
				buildFileTypeFacets();

				// facet filtering logic
				for (let i = 0; i < facetFilters.length; i++) {
					const key = facetFilters[i][0].split(':')[0];
					const values = facetFilters[i].map(filter => {
						return keys.includes(key) ? parseInt(filter.split(':')[1]) : filter.split(':')[1]
					});

					switch (key) {
						case 'category':
							totalHits = totalHits.filter((p) => !p.parentIds.length).filter(p => values.includes(p.categoryId));
							buildTagFacets(); // filtering by category only affects count of tags
							break;
						case 'tag':
							totalHits = totalHits.filter(p =>
								values.some(id => getFilterTagIds(p).includes(id)),
							);
							buildCategoryFacets(); // filtering by tags only affect count of cats
							break;
						case 'advanceCategory':
							totalHits = totalHits.filter(p => values.includes(p.categoryId));
							break;
						case 'country': {
							let countryHits = [];
							for (const value of values) {
								let mappedValue = authorBasedMapping.country[value];
								let tempFilteredHits = totalHits.filter(p => mappedValue.has(p.id));
								countryHits = [...countryHits, ...tempFilteredHits];
							}
							totalHits = [...countryHits];
							break;
						}
						case 'company': {
							let companyHits = [];
							for (const value of values) {
								let mappedValue = authorBasedMapping.company[value];
								let tempFilteredHits = totalHits.filter(p => mappedValue.has(p.id));
								companyHits = [...companyHits, ...tempFilteredHits]
							}
							totalHits = [...companyHits];
							break;
						}
						case 'publicationEnd': {
							if (selectedStartYear) {
								totalHits = totalHits.filter((data) => {
									let startDate = moment(selectedStartYear).startOf('year')
									let endDate = moment(values[0]).endOf('year')
									let pubDate = moment(data.publicationDate)
									return pubDate.isBetween(startDate, endDate, undefined, "[]")
								});
							}
							break;
						}
						case 'fileType': {
							if (values.includes('PDF')) {
								let tempProd = [];
								totalHits.forEach((item) => {
									if (item.childrenIds.length) {
										let children = products.filter((data) => item.childrenIds.includes(data.id) && data.files.length && data.files.some((i) => i.type === 'pdf'));
										if (children.length) {
											tempProd = [...tempProd, item];
										}
									}
								});
								totalHits = [...tempProd];
							}
							if (values.includes('Video')) {
								let tempProd = [];
								totalHits.forEach((item) => {
									if (item.childrenIds.length) {
										let children = products.filter((data) => item.childrenIds.includes(data.id) && data.files.length && data.files.some((i) => i.type === 'video'));
										if (children.length) {
											tempProd = [...tempProd, item];
										}
									}
								});
								totalHits = [...tempProd];
							}
							break;
						}
					}
				}

				// sort
				if (sortType === '0' && sortType !== '2') {
					totalHits = [
						...totalHits.filter(e => e.publicationDate).sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)),
						...totalHits.filter(e => !e.publicationDate).sort((a, b) => {
							if (a.title < b.title) {
								return -1;
							}
							if (a.title > b.title) {
								return 1;
							}

							// names must be equal
							return 0;
						})
					];
				} else if (sortType !== '2') {
					totalHits = [..._cloneDeep(totalHits).sort((a, b) => new Date(b.date_added) - new Date(a.date_added))];
				}

				if (!facetFilters.flatMap((e => e)).find((data) => data.includes('category'))) {
					buildAdvanceCategoryFacets();
					buildTagFacets();
					buildCountryFacets();
					buildCompanyFacets();
				}

				if ((!facetFilters.length && !query)
					|| (facetFilters.length === 1
						&& (selectedStartYear
							|| facetFilters.flatMap((e => e)).find((data) => data.includes('publicationEnd')))
					)
				) {
					totalHits = totalHits.filter(p => !p.parentIds.length);
				}
				// pagination
				const nbHits = totalHits.length;
				const nbPages = Math.ceil(nbHits / hitsPerPage);
				const hits = paginate(totalHits, page, hitsPerPage);
				resolve({ results: [{ hits, nbHits, page, nbPages, hitsPerPage, facets, totalHits }] });
			}),
	};

	const searchStateChange = state => {
		setSearchState({ ...state });
		history.push(`?${searchStateToParams({ ...state })}`);
	};

	const handlePageSize = (size) => {
		setPageSize(size)
	}

	const handleSearch = (value) => {
		if (!value) {
			dispatch(storeSortType({ cardSortType: '0' }));
		} else {
			dispatch(storeSortType({ cardSortType: '2' }));
		}
	};

	return (
		<main className={publicEmbed ? 'gx-m-4' : 'container'}>
			{ (productTagsFetch === false) || (attendeeSettingsFetch === false) || (storeProductsFetch === false) || (productPricesFetch === false) || (accessListFetch === false)  ? <Result
				status="warning"
				title={errorMessage}
			/> : 
			<InstantSearch indexName="ecommerce" searchClient={customSearchClient} onSearchStateChange={searchStateChange} searchState={searchState} >
				{/* <StoreHeader /> */}
				<section className="gx-d-flex gx-w-100" style={{ overflow: 'visible', gap: '2em' }}>
					<Drawer
						placement="right"
						visible={showFilters}
						onClose={() => setShowFilters(false)}
					>
						<div className="gx-algolia-sidebar gx-mx-0 gx-pt-5 gx-pt-4">
							<SelectionCartList />
						</div>
					</Drawer>

					<div className="gx-flex-1 gx-w-100" style={{ overflow: 'visible' }}>
						<div className="gx-d-flex gx-justify-content-end">
							<Button className="gx-d-lg-none" onClick={() => setShowFilters(true)}>
								My Shopping Cart
							</Button>
						</div>

						<div className="gx-mb-3 gx-d-flex selectionFilter">
							{ hideCategoryFilter === true || advancedSearchRefinementCount ? null : <SelectionFilter />}
							<SearchBox customRefresh={customRefresh} handleCustomSearch={handleSearch} />
						</div>
						{!loading ? (
							<div>
								<AdvancedSearch
									searchStateValues={searchState}
									advancedRefinementCount={advancedSearchRefinementCount}
									categoriesMap={categoriesMap}
									tagsMap={tagsMap}
									customRefresh={customRefresh}
									handleCustomSearch={handleSearch}
									handlePageSize={handlePageSize}
									showTags={showTags}
								/>
							</div>
						) : null}
						<div className="gx-mb-4 selection-result-wrapper">
							<SelectionResults />
						</div>

						<Configure hitsPerPage={pageSize === 1 ? productsPerPage : pageSize} />

						<div className="gx-text-center gx-pt-3">
							<Pagination handlePageSize={handlePageSize} pageSize={pageSize} />
						</div>
					</div>
					<aside className="gx-d-none gx-d-lg-block cart-display-wrapper">
						<div className="gx-algolia-sidebar gx-mx-0 gx-p-3 card shadow-2">
							<SelectionCartList />
						</div>
					</aside>
				</section>
			</InstantSearch>
            }
		</main>
	);
};

export default SelectionPage;
