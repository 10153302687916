import React, { useEffect, useState } from 'react';
import CompanyHeader from './CompanyHeader';
import {
	requestUserCompanyAffiliationData,
	requestToFetchPaymentSettings,
	requestExhibitorCartDetails,
	requestExhibitorCart,
	requestToDeleteRegistrationItemFromCart,
	requestToDeleteExhibitorPayment,
} from '../../appRedux/actions';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '../CircularProgress';
import Widget from '../Widget';
import RegistrationItems from '../Common/RegistrationItems';
import PaymentOptions from '../Common/PaymentOptions';
import FreeItemPayment from '../Common/FreeItemPayment';

import PayNow from '../Common/PayNow';
import { useGetExhibitorCart } from './hooks/useGetExhibitorCart';
import { useParams } from 'react-router';
import { Button, Alert, Card } from 'antd';
import ProgressBar from './ProgressBar';
import GoBack from '../Common/GoBack';
import { useMenu } from './hooks/useMenu';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BagIcon } from 'assets/icons/bag.svg';

const ExhibitorCheckout = props => {
	// if cart is empty, show msg you have no amount due, show manage staff
	const publicEmbed = props.settings.publicEmbed;
	const history = useHistory();
	const width = props.settings.width;
	const mobileThreshold = useSelector(state => state.common.mobileThreshold);

	const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');
	const [loader, setLoader] = useState(true);
	const [companyCreated, setCompanyCreated] = useState(false);
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	const { moduleUrlId } = useParams();
	const [existingPayment, setExistingPayment] = useState(false);

	const [loading, menu, , languageObject] = useMenu();
	const authUser = useSelector(state => state.auth.authUser);
	const contactUuid = useSelector(state => state.auth.contact_uuid);
	const ssotoken = useSelector(state => state.auth.ssotoken);

	const status = useSelector(state => state.corporateMembership.exhibitorCartDetails.status);
	const paymentSuccess = useSelector(state => state.common?.paymentSuccess);

	const simpleinvoiceobj = useSelector(
		state => state.corporateMembership.exhibitorCart.simpleInvoiceObj,
	);

	const cartItems = useSelector(state => state.corporateMembership.exhibitorCart);

	const appdir = useSelector(state => state.loginInfo.appdir);

	const compUuid = useSelector(
		state => state.corporateMembership.userCompanyAffiliation.comp_uuid,
	);
	const exhibUuid = useSelector(
		state => state.corporateMembership.exhibitorCartDetails.exhibuuid,
	);

	const clickBackCorporateMembership = () => {
		history.push(`./staff`);
	};

	const ssoLoginToFullApp = () => {
		window.open(
			`https://${appdir}.xcdapp.io/main/company/${moduleUrlId}/staff?token=${ssotoken}&uuid=${contactUuid}`,
		);
	};

	useEffect(() => {
		if (appdir) {
			if (!props.corporateMembership.userCompanyAffiliationFetched) {
				props.requestUserCompanyAffiliationData({
					appDir: appdir,
					urlId: moduleUrlId,
				});
			}

			props.requestToFetchPaymentSettings({
				appDir: appdir,
				urlId: moduleUrlId,
			});
		}
	}, [appdir]);

	useEffect(() => {
		if (props.corporateMembership.userCompanyAffiliationFetched) {
			setLoader(false);
			if (props.corporateMembership.userCompanyAffiliation.comp_uuid) {
				setCompanyCreated(true);
				if (!props.corporateMembership.exhibitorCartDetailsFetched) {
					props.requestExhibitorCartDetails({
						appDir: appdir,
						urlId: moduleUrlId,
						compUuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
					});
				}
			} else {
				props.history.push('home');
			}
		}
	}, [props.corporateMembership.userCompanyAffiliationFetched]);

	useEffect(() => {
		if (
			props.corporateMembership?.exhibitorCart?.PaymentItemsArray?.length > 0 ||
			props.corporateMembership?.oldExhibitorCart?.PaymentItemsArray?.length
		) {
			setExistingPayment(true);
		}
	}, [props.corporateMembership?.exhibitorCart, props.corporateMembership?.oldExhibitorCart]);

	useGetExhibitorCart(props.corporateMembership, moduleUrlId, props.requestExhibitorCart, true);

	const deletePayment = paymentId => {
		props.requestToDeleteExhibitorPayment({
			moduleUrlId,
			exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
			paymentId,
		});
	};

	const deleteItem = item => {
		// Redux action to be dispatched to delete cart item with that id.
		props.requestToDeleteRegistrationItemFromCart({
			form: {
				itemstodelete: {
					field_id: item.fieldId,
					invoice_id: item.invoiceId,
				},
			},
			urlId: moduleUrlId,
			exhibUuid: props.corporateMembership.exhibitorCartDetails.exhibuuid,
			compUuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
			authUser: authUser,
		});
	};

	const returnPaymentApproved = success => {
		setPaymentSuccessful(success);
	};

	const handleBackClick = () => {
		props.history.goBack();
	};

	const closeAlert = () => {};

	return (
		<div>
			{!loader && (companyCreated || publicEmbed) && (
				<div className="gx-main-content">
					<div className={publicEmbed ? 'gx-mx-4 gx-my-4' : ''}>
						{publicEmbed && !authUser && (
							<div
								style={{
									height: '100px',
									width: '95%',
									marginTop: '3%',
									marginLeft: '2.5%',
								}}
							>
								{!paymentSuccessful && !authUser && (
									<Widget>
										<ProgressBar
											currentStep={props.corporateMembership.menu.findIndex(
												item => {
													return item.screentype === 'Checkout';
												},
											)}
											steps={props.corporateMembership.menu.map(item => {
												return {
													text: item.button,
													order: item.menu_order - 1,
													id: item.menuid,
													type: item.screentype,
												};
											})}
										/>
									</Widget>
								)}
							</div>
						)}
						{(!publicEmbed || authUser) && (
							<div
								style={{
									width: '100%',
								}}
							>
								<CompanyHeader />
							</div>
						)}
						<div
							style={{
								width:
									parseFloat(cartItems.netOwing) !== 0 || width < mobileThreshold
										? '100%'
										: '50%',
							}}
						>
							<div
								className={
									parseFloat(cartItems.netOwing) !== 0 ? `gx-d-lg-flex` : ''
								}
							>
								{
									<div
										style={{
											flexBasis: `${
												existingPayment ||
												parseFloat(cartItems.NetOwing) === 0
													? ''
													: '58%'
											}`,
										}}
									>
										{
											<div>
												{props.corporateMembership.paymentSettingsFetched &&
													props.corporateMembership
														.exhibitorCartFetched &&
													!loading &&
													!existingPayment &&
													!paymentSuccessful &&
													!paymentSuccess &&
													cartItems &&
													simpleinvoiceobj &&
													Object.keys(simpleinvoiceobj)?.length > 0 && (
														<Card
															className="card"
															style={{
																margin: publicEmbed ? '3%' : '',
															}}
														>
															<div>
																{!publicEmbed && (
																	<div
																		style={{
																			textAlign: 'left',
																		}}
																	>
																		<GoBack
																			handleBackClick={
																				handleBackClick
																			}
																		/>
																	</div>
																)}
																<div
																	style={{
																		float: 'left',
																	}}
																	className="gx-text-secondary gx-font-weight-semi-bold gx-page-title gx-ml-1"
																>
																	{
																		menu.find(item => {
																			return (
																				item.screentype ===
																				'Checkout'
																			);
																		})?.button
																	}
																</div>
																<BagIcon
																	className="fg-primary-dark-light"
																	style={{
																		float: 'right',
																		width: '3em',
																	}}
																/>
															</div>
															{/* cart gets reset after payment, so we keep the oldcart just to still display previous items to user
															any new items will be added to the new cart
															*/}
															<div className="gx-mb-3">
																<RegistrationItems
																	languageObject={{
																		invoice_items_header:
																			languageObject?.checkout_invoice_items_header,
																		invoice_amount_header:
																			languageObject?.checkout_invoice_amount_header,
																		total_invoiced_header:
																			languageObject?.checkout_total_invoiced_header,
																		payments_header:
																			languageObject?.checkout_payments_header,
																		non_processed_payment:
																			languageObject?.checkout_non_processed_payment,
																		net_owing_header:
																			languageObject?.checkout_net_owing_header,
																	}}
																	currentPaymentMethod={
																		currentPaymentMethod
																	}
																	cartItems={
																		props.corporateMembership
																			.oldExhibitorCart
																			? props
																					.corporateMembership
																					.oldExhibitorCart
																			: props
																					.corporateMembership
																					?.exhibitorCart
																	}
																	paymentSettings={
																		props.corporateMembership
																			.paymentSettings
																	}
																	renderPaymentComponent={value =>
																		setCurrentPaymentMethod(
																			value,
																		)
																	}
																	onDeletePayment={deletePayment}
																	onDelete={deleteItem}
																/>
															</div>

															{simpleinvoiceobj && (
																<div>
																	{Object.entries(
																		simpleinvoiceobj,
																	).length &&
																		parseInt(
																			cartItems?.NetOwing,
																		) === 0 && (
																			<div className="gx-mt-5">
																				<FreeItemPayment
																					moduleUrlId={
																						moduleUrlId
																					}
																					compuuid={
																						compUuid
																					}
																					exhibuuid={
																						exhibUuid
																					}
																					moduleIdentifier={
																						'corporateMembership'
																					}
																					paymentSettings={
																						props
																							.corporateMembership
																							.paymentSettings
																					}
																				/>
																			</div>
																		)}
																</div>
															)}

															<PaymentOptions
																renderPaymentComponent={value =>
																	setCurrentPaymentMethod(value)
																}
																languageObject={{
																	net_owing_header:
																		languageObject?.checkout_net_owing_header,
																	pay_now_by:
																		languageObject?.checkout_pay_now_by,
																}}
																currentPaymentMethod={
																	currentPaymentMethod
																}
																cartItems={
																	props.corporateMembership
																		.oldExhibitorCart ||
																	props.corporateMembership
																		.exhibitorCart
																}
																paymentSettings={
																	props.corporateMembership
																		.paymentSettings
																}
																moduleIdentifier={'exhibitor'}
															/>
														</Card>
													)}
											</div>
										}
									</div>
								}
								<div>
									{simpleinvoiceobj && (
										<div>
											{Object.keys(simpleinvoiceobj)?.length === 0 &&
												!paymentSuccessful && (
													<Card>
														<p>You have no outstanding items.</p>
														<Button
															className="login-form-button gx-mt-1 button-primary"
															shape={'round'}
															onClick={
																publicEmbed
																	? ssoLoginToFullApp
																	: clickBackCorporateMembership
															}
														>
															Manage Staff
														</Button>
													</Card>
												)}
										</div>
									)}
								</div>
								{parseFloat(cartItems.NetOwing) !== 0 && (
									<div style={{ flexBasis: '42%' }}>
										{(currentPaymentMethod ||
											paymentSuccessful ||
											parseFloat(
												props.corporateMembership.exhibitorCart.NetOwing,
											) <= 0 ||
											parseFloat(
												props.corporateMembership.exhibitorCart
													.TotalPayment,
											) !== 0) &&
											props.corporateMembership.paymentSettingsFetched &&
											props.corporateMembership.exhibitorCartFetched && (
												<div
													style={{
														margin: publicEmbed ? '3%' : '',
													}}
												>
													<div
														className={
															existingPayment ||
															width < mobileThreshold
																? ''
																: 'card gx-ml-5 gx-mr-4'
														}
													>
														<PayNow
															currentPaymentMethod={
																currentPaymentMethod
															}
															setCurrentPaymentMethod={
																setCurrentPaymentMethod
															}
															returnPaymentApproved={
																returnPaymentApproved
															}
															appdir={props.loginInfo.appdir}
															netOwing={parseFloat(
																props.corporateMembership
																	.exhibitorCart.NetOwing,
															)}
															cartItems={
																props.corporateMembership
																	.exhibitorCart
															}
															totalPaid={parseFloat(
																props.corporateMembership
																	.exhibitorCart.TotalPayment,
															)}
															paymentSettings={
																props.corporateMembership
																	.paymentSettings
															}
															moduleIdentifier={'exhibitor'}
															compuuid={
																props.corporateMembership
																	.userCompanyAffiliation
																	.comp_uuid
															}
															exhibuuid={
																props.corporateMembership
																	.exhibitorCartDetails.exhibuuid
															}
															publicEmbed={publicEmbed}
															status={status}
														/>
													</div>
												</div>
											)}
									</div>
								)}

								{/* </Col> */}
							</div>
							{/* </Row> */}
						</div>
					</div>
				</div>
			)}
			{loader && (
				<Card className="card">
					<CircularProgress className="gx-profile-content" />
				</Card>
			)}
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestUserCompanyAffiliationData,
			requestToFetchPaymentSettings,
			requestExhibitorCartDetails,
			requestExhibitorCart,
			requestToDeleteRegistrationItemFromCart,
			requestToDeleteExhibitorPayment,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
		loginInfo: state.loginInfo,
		settings: state.settings,
		auth: state.auth,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(ExhibitorCheckout);
