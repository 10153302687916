import React from "react";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Modal } from "antd";
import ModalContent from "./ModalContent";

const BundleProductQuickViewModal = ({
    selectedBundle,
    handleCancel
}) => {
    const history = useHistory();
    const { bundleId, moduleUrlId } = useParams();
    const storeConfig = useSelector(
        state => state.ecommerce.attendeeSettings?.attendeesiteadmin?.storeconfigobject,
    );
    const publicEmbed = useSelector(state => state.settings.publicEmbed);

    const selectedProduct = useSelector(
        state => state.ecommerce.selectedProduct.product
    );

    const modalVisible = useSelector(
        state => state.ecommerce.selectedProduct.isVisible
    );

    const handleAccess = () => {
        const searchParams = new URLSearchParams({ openAccess: true, moduleUrlId });
        if (!selectedProduct?.parentIds?.length && !selectedBundle.id) {
            history.push(`/${publicEmbed ? 'public' : 'main'}/consumption/selection/${selectedProduct.id}?${searchParams}`);
        } else if (selectedBundle.id && selectedProduct?.parentIds?.length) {
            history.push(`/${publicEmbed ? 'public' : 'main'}/consumption/group/${bundleId}/${selectedProduct?.parentIds[0]}/${selectedProduct.id}?${searchParams}`);
        } else if (selectedProduct?.parentIds?.length) {
            history.push(`/${publicEmbed ? 'public' : 'main'}/consumption/group/${selectedProduct?.parentIds[0]}/${selectedProduct.id}?${searchParams}`);
        }
    };

    return (
        <div className="gx-p-2">
            <Modal
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose
            >
                <ModalContent
                    product={selectedProduct}
                    storeConfig={storeConfig}
                    handleAccess={handleAccess}
                    handleClose={handleCancel}
                />
            </Modal>
        </div>
    )
}

export default BundleProductQuickViewModal;