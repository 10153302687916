import React, { useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { useProducts } from '../hooks/useProducts';
// import { useBundleInfo } from '../hooks/useBundleInfo';
// import { useSearchClient } from '../hooks/useSearchClient';
import GroupContext from './GroupContext';

// import FilterSidebar from '../components/FilterSidebar';
import ConsumptionHeader from '../components/ConsumptionHeader';
import ConsumptionArea from '../components/ConsumptionArea/';
// import SidebarToggle from '../components/SidebarToggle';
// import ConsumptionResults from './ConsumptionResults';
// import Pagination from 'components/Common/Algolia/Pagination';
// import SearchBox from 'components/Common/Algolia/SearchBox';

import { paramsToSearchState, searchStateToParams } from '../helpers';
// import { sidebarWidth, toggleWidth, hitsPerPage } from '../constants';

// import { Button } from 'antd';
// import { ReactComponent as Filter } from 'assets/icons/filter.svg';

import '../styles/custom.less';
import { storeCardsViewType } from '../../../appRedux/actions';

const GroupConsumptionPage = () => {
	const { superBundleId, bundleId, productId } = useParams();
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [searchState, setSearchState] = useState(paramsToSearchState(location.search));
	const hasOpenAccess = useSelector(state => state.consumption.hasOpenAccess);
	const [sidebarOpen, setSidebarOpen] = useState(true);
	// const [filterOpen, setFilterOpen] = useState(false);
	const cardViewType = useSelector((state) => state.consumption.cardDisplayType);

	const [prodsLoading, products] = useProducts();
	const superBundle = useMemo(() => products.find(prod => prod.id === parseInt(superBundleId)), [
		products,
	]);
	// const [infoLoading, bundleInfo] = useBundleInfo(prodsLoading, superBundle?.uuid);
	const bundle = useMemo(() => products.find(child => child.id === parseInt(bundleId)), [
		products,
		bundleId,
	]);
	const product = useMemo(
		() => products.find(child => child.id === parseInt(productId)),
		[products, productId],
	);

	let querySearch = new URLSearchParams(history.location.search).get("query");
	const consumingProduct = bundleId ? bundle : superBundle;
	const publicEmbed = useSelector(state => state.settings.publicEmbed);

	const moduleUrlId = useSelector(state => state.consumption.moduleUrlId);
	const hasDirectAccess = useSelector(state => state.consumption.hasDirectAccess);

	// const searchClient = useSearchClient(infoLoading, bundleInfo);

	// const loading = infoLoading || prodsLoading || !bundle;

	// const searchStateChange = state => {
	// 	setSearchState(state);
	// 	const urlParams = searchStateToParams(state);
	// 	history.push(`?${urlParams}`);
	// };

	const advancedSearchRefinementCount = useMemo(() => {
		let filterCount = 0;
		if (searchState?.refinementList) {
			let selectedStartYearKey = Object.entries(searchState?.refinementList).filter(([key, item]) => key === 'publicationStart' && item)?.[0];
			Object.entries(searchState?.refinementList).forEach(([key, item]) => {
				if (selectedStartYearKey && key === 'publicationEnd' && item) {
                    filterCount += 1;
                } else if (key !== 'category'
                    && key !== 'publicationStart'
                    && key !== 'publicationEnd'
                    && item?.length) {
                    filterCount += item?.length
                }
			});
		}
		return filterCount;
	}, [searchState?.refinementList]);

	return (
		<GroupContext.Provider value={{ superBundle, bundle, product }}>
			{/* <InstantSearch
				indexName="consumption/group-consumption"
				searchClient={searchClient}
				onSearchStateChange={searchStateChange}
				searchState={searchState}
			> */}
			{/* <Configure hitsPerPage={hitsPerPage} /> */}

			<main
				className="main-consumption-wrapper gx-d-flex gx-flex-column gx-h-100"
			// style={{ minHeight: '100vh', maxHeight: '100vh', overflowY: 'auto' }}
			>
				<ConsumptionHeader
					loading={prodsLoading}
					product={consumingProduct}
					superBundle={superBundle}
					goBack={() => {
						if (hasDirectAccess && hasOpenAccess) {
							history.push(
								`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/selection?${publicEmbed ? 'public_embed=1' : ""}`
							)
						} else if (hasOpenAccess) {
							history.push(
								`/${publicEmbed ? 'public' : 'main'}/ecommerce/${moduleUrlId}/bundle/${superBundleId}${publicEmbed ? '?public_embed=1' : ""}`
							)
						} else if (querySearch || advancedSearchRefinementCount) {
							history.push(
								`/${history.location.pathname.split('/')[1]
								}/consumption/selection?${searchStateToParams(
									searchState,
								)}`,
							)
						} else if (superBundleId && bundleId && productId && cardViewType !== 2) {
							history.push(
								`/${history.location.pathname.split('/')[1]
								}/consumption/group/${superBundle.id}/${bundleId}?${searchStateToParams(
									searchState,
								)}`,
							)
						} else {
							history.push(
								`/${history.location.pathname.split('/')[1]
								}/consumption/selection/${superBundle.id}?${searchStateToParams(
									searchState,
								)}`,
							)
						}
					}}
				/>

				<section className="gx-flex-1 gx-d-flex gx-h-100">
					<article
						className="content-area gx-h-100"
						style={{
							width: `100%`,
							// width: `calc(100% - ${
							// 	toggleWidth + (sidebarOpen ? sidebarWidth : 0)
							// }px)`,
						}}
					>
						<ConsumptionArea
							loading={prodsLoading || !consumingProduct}
							product={consumingProduct}
							expanded={!sidebarOpen}
						/>
					</article>

					{/* <SidebarToggle
							isOpen={sidebarOpen}
							close={() => setSidebarOpen(show => !show)}
						/> */}

					{/* <aside style={{ overflowX: 'hidden', flex: '1 0' }}>
							<div style={{ width: sidebarWidth, position: 'relative' }}>
								<aside className="gx-px-4 gx-py-5">
									<div className="gx-d-flex gx-align-items-center gx-pb-5">
										<h2 className="gx-text-primary gx-font-weight-semi-bold gx-mb-0">
											Content
										</h2>
										<Button
											onClick={() => setFilterOpen(true)}
											className="shadow-1 button-white gx-mb-0 gx-ml-auto gx-d-flex gx-align-items-center"
											shape="round"
											style={{ border: 'none' }}
										>
											<Filter
												className="gx-my-auto gx-mr-2"
												style={{ width: '.9rem' }}
											/>
											Filters
										</Button>
									</div>

									<p
										onClick={() =>
											history.push(
												`/${
													history.location.pathname.split('/')[1]
												}/consumption/group/${
													superBundle.id
												}?${searchStateToParams(searchState)}`,
											)
										}
										className="gx-mb-4 gx-pointer"
										style={{
											fontSize: '.9rem',
											textDecoration:
												consumingProduct?.id === superBundle?.id
													? 'underline'
													: '',
										}}
									>
										{superBundle?.title}
									</p>

									<ConsumptionResults loading={prodsLoading} />
									<div className="gx-text-center gx-pt-2">
										<Pagination />
									</div>
								</aside>

								<aside
									className={`${
										filterOpen ? 'filter-sidebar-show' : 'filter-sidebar-hide'
									} gx-bg-white gx-px-4 gx-py-5 gx-w-100`}
								>
									<div className="gx-text-right gx-mb-3">
										<Button
											onClick={() => setFilterOpen(false)}
											className="shadow-1 button-white"
											shape="round"
										>
											Close
										</Button>
									</div>

									<div className="gx-mb-3">
										<SearchBox />
									</div>

									<div
										className="gx-algolia-sidebar"
										style={{ width: sidebarWidth }}
									>
										<FilterSidebar tags />
									</div>
								</aside>
							</div>
						</aside> */}
				</section>
			</main>
			{/* </InstantSearch> */}
		</GroupContext.Provider>
	);
};

export default GroupConsumptionPage;
