import React from 'react';
import { useSelector } from 'react-redux';
import { connectSortBy } from 'react-instantsearch-dom';

const CustomSortBy = ({ items = [], currentRefinement, refine }) => {
    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);
    return (
        <>
            {items?.map(item => (
                <div key={item.value}>
                    <a
                        // href={createURL(item.value)}
                        style={{ fontWeight: item.isRefined ? 'bold' : '' }}
                        onClick={event => {
                            event.preventDefault();
                            refine(item.value);
                        }}
                    >
                        <div style={{ color: item?.isRefined ? styling['primary-dark-color'] : "#1d3063", paddingBottom: 10 }}>{item.label}</div>
                    </a>
                </div>
            ))}
        </>
    )
}

export default connectSortBy(CustomSortBy);
