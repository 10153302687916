import imageCompression from 'browser-image-compression';
import form from '../../form';
import { useSelector } from 'react-redux';

const todayDate = new Date();

/*
conditionals to count as remaining member:
	1. company group id has to match membership group id
	2. the member's expiration date has to be after today
	3. the member's expiration date has to be great or equal to the company's expiration date
*/

export const returnRemainingMemberships = (membership, companyMembers, companyExpireDate) => {
	return (
		membership.count -
		companyMembers.filter(companyMember => {
			return (
				companyMember.groupID == membership.groupid && // leave this == because its str vs int
				Date.parse(companyMember.expirationDate) > todayDate &&
				companyMember.expirationDate >= companyExpireDate
			);
		}).length
	);
};

export const handleImageCompression = file => {
	return new Promise(function (resolve, reject) {
		imageCompression(file, {
			maxSizeMB: 1,
			maxWidthOrHeight: 1000,
			useWebWorker: true,
		})
			.then(function (compressedFile) {
				resolve(compressedFile);
			})
			.catch(function (error) {
				console.log(error.message);
			});
	});
};

export const goToFirstError = validationsObject => {
	const element = document.getElementById(
		`${Object.keys(validationsObject?.validationErrors)[0]}_title`,
	);
	if (element) {
		element.scrollIntoView();
	}
};

export const generateItems = formState => {
	let itemsToAdd = {};
	for (let key in formState) {
		if (formState[key] && formState[key] > 0) {
			itemsToAdd[key] = {
				valueid: formState[key],
				qty: 1,
			};
		}

		if (key.includes('Multidrop')) {
			if (formState[key]?.label) {
				itemsToAdd[key] = {
					valueid: formState[key].value,
					qty: formState[key].label.props.label,
				};
			}
		}
	}

	return itemsToAdd;
};

export const IsJsonString = str => {
	try {
		JSON.parse(str);
	} catch (e) {
		//TODO JSON with ' failing to parse. Need to find a solution
		console.error('JSON Parsing failed:', str, e);
		return false;
	}
	return JSON.parse(str);
};
