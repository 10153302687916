import React from 'react';
// import { useSelector } from 'react-redux';
import { connectStateResults } from 'react-instantsearch-dom';
import { Card, Skeleton, Image, Empty } from 'antd';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';

import { hitsPerPage } from '../constants';
// import CurrentRefinements from '../../Common/Algolia/CurrentRefinements';
// import BundleProductCard from '../SelectionPage/BundleProductCard';
import GridView from '../components/GridView';
// import { useProducts } from '../hooks/useProducts';
// import { paginate } from '../../Common/helpers/helper';

const GroupSelectionResults = ({ searchResults, loading, gotoProduct, bundle, hideQvPagination }) => {
	const searchLoading = loading || !searchResults;
	// const tagsMap = useSelector(state => state.consumption.tagsMap);
	// const [prodsLoading, products] = useProducts();

	// let children = useMemo(
	// 	() => _sortBy(products.filter(p => bundle?.childrenIds.some(childId => childId === p.id)), [(items) => items.title]),
	// 	[products, bundle],
	// );

	if (searchLoading)
		return (
			<section className="">
				{[...Array(hitsPerPage)].map((_, index) => (
					<Card key={index} className="card gx-mb-0 shadow-1">
						<div className="gx-d-flex">
							<Image
								className="card-image"
								style={{ height: '7em' }}
								alt="placeholder"
								src={require('assets/images/placeholder-image.png')}
							/>
							<Skeleton className="gx-ml-3" active paragraph={{ rows: 3 }}></Skeleton>
						</div>
						<Skeleton.Button
							className="gx-d-block gx-ml-auto"
							active
							size="large"
							shape="round"
						/>
					</Card>
				))}
			</section>
		);
	if (searchResults.nbHits === 0) return <Empty style={{ margin: '20em 0' }} />;

	return (
		<div>
			{/* <div className="gx-mb-3 gx-d-flex gx-align-items-center">
				<CurrentRefinements categoriesMap={tagsMap} />
			</div> */}
			<div>
				<GridView
					gotoProduct={gotoProduct}
					searchResults={searchResults}
					bundle={bundle}
					bundleCard
					showQuickView
					hideQvPagination={hideQvPagination}
				/>
			</div>
			{/* <section className="product-row-grid product-list-wrapper">
				{searchResults.hits.map((product, index) => (
					<BundleProductCard product={product} goto={gotoProduct} key={index} />
				))}
			</section> */}
		</div>
	);
}

export default connectStateResults(GroupSelectionResults);
