/*eslint-disable no-undef*/
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from "react-redux";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import url from 'url';
import './cart.less';

import CircularProgress from '../CircularProgress';
import LMap from './LMap';
import {
   fetchExhibFloorInfoRequest,
   fetchBoothConfigurationRequest,
   fetchCompanyInfoRequest
} from '../../appRedux/actions/floorPlan';

function MiddlewareLMap({
   // config,
   tagsList,
   boothConfiguration,
   boothConfigurationLoading,
   generalSettings,
   appdir,
   handleSubmit
}) {
   const mode = 'cart';
   const dispatch = useDispatch();

   useEffect(() => {
      const {confid = null, conferenceid = null} = generalSettings;
      if (confid && appdir) {
         dispatch(fetchBoothConfigurationRequest({ source_hex: appdir, confid }));
      }
      if(confid && conferenceid){
         dispatch(fetchCompanyInfoRequest({confid, conferenceid}));
      }
   },[])
   
   const { base_image_url, image_info, floorplan_id, PublicConfig } = boothConfiguration || {};
   return (
      <>
         {boothConfigurationLoading && <Spin indicator={<div className='font-size gx-text-black'>Loading...</div>} />}
         {!boothConfigurationLoading && !_isEmpty(boothConfiguration) &&
            <LMap
               floorplan={base_image_url}
               image_width={image_info.width}
               image_height={image_info.height}
               mode={mode}
               floorplan_id={floorplan_id}
               public_config={PublicConfig ? JSON.parse(PublicConfig) : {}}
               tags={tagsList}
               handleSubmit={handleSubmit}
            />
         }
      </>
   )
}

const mapStateToProps = ({ exhibFloor, corporateMembership, loginInfo }) => {
   return {
      // config: exhibFloor.config,
      tagsList: exhibFloor.tagsList,
      boothConfiguration: exhibFloor.boothConfiguration,
      boothConfigurationLoading: exhibFloor.boothConfigurationLoading,
      generalSettings: corporateMembership?.generalSettings,
      appdir: loginInfo.appdir
   }
}

export default connect(mapStateToProps, { fetchBoothConfigurationRequest, fetchCompanyInfoRequest })(MiddlewareLMap)