import React, { useEffect, useState } from 'react';
import { Menu, Card, Dropdown, Col } from 'antd';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router';
import { requestMenuData } from '../../appRedux/actions';
import { bindActionCreators } from 'redux';
import { ReactComponent as ProfileHomeIcon } from '../../assets/icons/corporateProfileHome.svg';
import { ReactComponent as RegistrationItemsIcon } from '../../assets/icons/registrationItems.svg';
import { ReactComponent as StaffMembersIcon } from '../../assets/icons/staffMembers.svg';
import { ReactComponent as CompanyInfoIcon } from '../../assets/icons/companyInfo.svg';
import { ReactComponent as ReviewCheckoutIcon } from '../../assets/icons/reviewCheckout.svg';
import { useMenu } from './hooks/useMenu';
import { DownOutlined } from '@ant-design/icons';

const LeftMenu = props => {
	const [selectedkeys, setSelectedkeys] = useState([]);
	const { moduleUrlId } = useParams();

	const [loading, menu] = useMenu();

	const width = props.settings.width;
	const mobileThreshold = props.common.mobileThreshold;
	const publicEmbed = props.settings.publicEmbed;

	useEffect(() => {
		let splittedPath = props.match.path.split('/');
		if (!loading) {
			menu.forEach(menu => {
				if (splittedPath.includes(menu.screentype.toLowerCase())) {
					setSelectedkeys([`${menu.button}`]);
				}
			});
		}
	}, [loading]);

	const menuChange = route => {
		if (publicEmbed) {
			props.history.push(`/main/public/company/${moduleUrlId}/${route}?public_embed=1`);
		} else {
			props.history.push(`/main/company/${moduleUrlId}/${route}`);
		}
	};

	const menuComponent = (
		<Menu
			// mode={'inline'}
			style={{ borderRight: 'none' }}
			selectedKeys={selectedkeys}
			defaultSelectedKeys={[]}
			mode={width < mobileThreshold ? 'horizontal' : 'vertical'}
		>
			{menu.map(menu => {
				return menu.display === 1 ? (
					<Menu.Item
						key={menu.menuid}
						className="gx-mb-10"
						onClick={() => menuChange(menu.screentype.toLowerCase())}
					>
						<div className="gx-media gx-align-items-center">
							<div className="gx-media-body fg-primary" style={{ color: 'black' }}>
								{menu.screentype === 'Home' && (
									<ProfileHomeIcon className="left-menu-icon fg-primary-dark-light" />
								)}
								{menu.screentype === 'SubMenu' && (
									<RegistrationItemsIcon className="left-menu-icon fg-primary-dark-light" />
								)}
								{menu.screentype === 'Staff' && (
									<StaffMembersIcon className="left-menu-icon fg-primary-dark-light" />
								)}
								{menu.screentype === 'ContactInfo' && (
									<CompanyInfoIcon className="left-menu-icon fg-primary-dark-light" />
								)}
								{menu.screentype === 'Checkout' && (
									<ReviewCheckoutIcon className="left-menu-icon fg-primary-dark-light" />
								)}
								<span
									style={{
										marginLeft: '5%',
										fontSize: '16px',
									}}
								>
									{menu.button}
								</span>
							</div>
						</div>
					</Menu.Item>
				) : (
					<div></div>
				);
			})}
		</Menu>
	);

	return (
		<>
			{!loading && (
				<div>
					<Card
						className="card"
						style={{
							padding: width > mobileThreshold ? '24px 0px 24px 0px' : '',
							borderRadius: width > mobileThreshold ? '27px' : '',
						}}
					>
						<div className="gx-chat-sidenav-main">
							<div className="gx-chat-sidenav-content">
								<Col xl={0} lg={24} md={24} sm={24} xs={24}>
									<Dropdown overlay={menuComponent} trigger={['click']}>
										<h2
											className="ant-dropdown-link fg-primary"
											style={{ width: '20rem' }}
											onClick={e => e.preventDefault()}
										>
											{selectedkeys[0]} <DownOutlined />
										</h2>
									</Dropdown>
								</Col>

								<Col xl={24} lg={0} md={0} sm={0} xs={0}>
									{menuComponent}
								</Col>
							</div>
						</div>
					</Card>
				</div>
			)}
		</>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestMenuData: requestMenuData,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
		loginInfo: state.loginInfo,
		settings: state.settings,
		common: state.common,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(LeftMenu));
