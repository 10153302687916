import React, { useMemo, useState } from 'react';
import { Radio, Modal, Badge } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { connectStateResults } from 'react-instantsearch-dom';
import _isEmpty from 'lodash/isEmpty';

import AdvanceSearch from './AdvanceSearch';
import ClearRefinements from '../Algolia/ClearRefinements';
import { searchStateToParams } from '../../Consumption/helpers';

const AdvancedSearch = ({
    searchStateValues,
    advancedRefinementCount,
    categoriesMap,
    tagsMap,
    storeFilter = false,
    handlePageSize = () => { },
    searchResults,
    customRefresh,
    quickViewOnly = false,
    handleCustomSearch = () => { },
    showTags = false
}) => {

    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

    const filteredFacets = useMemo(() => {
        return searchResults?.disjunctiveFacets?.filter(facet => !_isEmpty(facet.data)).map(facet => facet.name);
    }, [searchResults?.disjunctiveFacets]);

    const handleShowResults = () => {
        setIsModalVisible((prev) => !prev);
    }

    const clearSelectedValues = () => {
        handlePageSize(1)
        history.push(`?${searchStateToParams({})}`);
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: (advancedRefinementCount) ? 'space-between' : 'flex-end'
        }}>
            {(advancedRefinementCount) ? <Radio.Group
                size="small"
            >
                <Radio.Button>
                    <ClearRefinements
                        advanceSearch
                        clearSelectedValues={clearSelectedValues}
                    />
                </Radio.Button>
            </Radio.Group> : null}
            <div className={'filter-count-btn'}>
                <Radio.Group
                    size="small"
                >
                    <Radio.Button onClick={() => setIsModalVisible(true)}>
                        <div>
                            <div className="gx-d-flex gx-align-items-start">
                                <div>Advanced Search</div>
                                <div>
                                    <Badge
                                        count={advancedRefinementCount}
                                        className="gx-ml-1"
                                        style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <Modal
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                width={800}
                footer={null}
                forceRender
            >
                <AdvanceSearch
                    searchState={searchStateValues}
                    advancedRefinementCount={advancedRefinementCount}
                    storeFilter={storeFilter}
                    categoriesMap={categoriesMap}
                    tagsMap={tagsMap}
                    totalHitsCountInit={searchResults?.nbHits}
                    facetsValue={filteredFacets}
                    customRefresh={customRefresh}
                    quickViewOnly={quickViewOnly}
                    handleShowResults={handleShowResults}
                    handleCustomSearch={handleCustomSearch}
                    showTags={showTags}
                />
            </Modal>
        </div>
    );
}

export default connectStateResults(AdvancedSearch);
