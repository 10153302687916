// Customizer/ Settings const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

export const PUBLIC_EMBED = 'PUBLIC_EMBED';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const FIND_ACCOUNT = 'FIND_ACCOUNT';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const SIGNIN_USER_EMAIL = 'SIGNIN_USER_EMAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const EXHIBITOR_AUTH_SIGNEDUP = 'EXHIBITOR_AUTH_SIGNEDUP';

export const REQUEST_SIGNIN_TOKEN = 'REQUEST_SIGNIN_TOKEN';
export const RECEIVE_SIGNIN_TOKEN = 'RECEIVE_SIGNIN_TOKEN';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_USER_SUCCESS = 'SET_AUTH_USER_SUCCESS';

export const REQUEST_AUTHENTICATE_USER = 'REQUEST_AUTHENTICATE_USER';
export const RECEIVE_AUTHENTICATE_USER = 'RECEIVE_AUTHENTICATE_USER';

export const REQUEST_LOGOUT_USER = 'REQUEST_LOGOUT_USER';
export const RECEIVE_LOGOUT_USER = 'RECEIVE_LOGOUT_USER';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const RECEIVE_RESET_PASSWORD = 'RECEIVE_RESET_PASSWORD';

export const REQUEST_SEND_EMAIL = 'REQUEST_SEND_EMAIL';
export const RECEIVE_SEND_EMAIL = 'RECEIVE_SEND_EMAIL';

export const REQUEST_SEND_PASSCODE = 'REQUEST_SEND_PASSCODE';
export const RECEIVE_SEND_PASSCODE = 'RECEIVE_SEND_PASSCODE';

export const REQUEST_VALIDATE_EMAIL = 'REQUEST_VALIDATE_EMAIL';
export const RECEIVE_VALIDATE_EMAIL = 'RECEIVE_VALIDATE_EMAIL';

export const REQUEST_CLEAR_VALIDATE_EMAIL = 'REQUEST_CLEAR_VALIDATE_EMAIL';
export const RECEIVE_CLEAR_VALIDATE_EMAIL = 'RECEIVE_CLEAR_VALIDATE_EMAIL';

export const REQUEST_ERASE_AUTH_DATA = 'REQUEST_ERASE_AUTH_DATA';
export const RECEIVE_ERASE_AUTH_DATA = 'RECEIVE_ERASE_AUTH_DATA';

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const RECEIVE_FORGOT_PASSWORD = 'RECEIVE_FORGOT_PASSWORD';

export const REQUEST_CHECKOUT_CREATE_PROFILE = 'REQUEST_CHECKOUT_CREATE_PROFILE'; //creating profile on checkout when coming from public embed
export const RECEIVE_CHECKOUT_CREATE_PROFILE = 'RECEIVE_CHECKOUT_CREATE_PROFILE';

export const REQUEST_ADD_AUTH_DATA = 'REQUEST_ADD_AUTH_DATA';
export const RECEIVE_ADD_AUTH_DATA = 'RECEIVE_ADD_AUTH_DATA';

export const REQUEST_MAGIC_LOGIN = 'REQUEST_MAGIC_LOGIN';
export const RECEIVE_MAGIC_LOGIN = 'RECEIVE_MAGIC_LOGIN';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

//sessions
export const GET_ALL_SESSION = 'GET_ALL_SESSIONS';
export const GET_ALL_SESSION_SUCCESS = 'GET_ALL_SESSION_SUCCESS';

//exhibTable
export const REQUEST_EXHIB_DATA = 'REQUEST_EXHIB_DATA';
export const RECIEVE_EXHIB_DATA = 'RECIEVE_EXHIB_DATA';
export const REQUEST_EXHIB_FILTERS = 'REQUEST_EXHIB_FILTERS';
export const RECIEVE_EXHIB_FILTERS = 'RECIEVE_EXHIB_FILTERS';

//socialfeed
export const REQUEST_ALL_POSTS = 'REQUEST_ALL_POSTS';
export const RECIEVE_ALL_POSTS = 'RECIEVE_ALL_POSTS';

//products
export const REQUEST_PRODUCT_DATA = 'REQUEST_PRODUCT_DATA';
export const RECIEVE_PRODUCT_DATA = 'RECIEVE_PRODUCT_DATA';

//login pages
export const REQUEST_INITIAL_API = 'REQUEST_INITIAL_API';
export const RECEIVE_INITIAL_API = 'RECEIVE_INITIAL_API';

export const REQUEST_PROFILE_INFO = 'REQUEST_PROFILE_INFO';
export const RECEIVE_PROFILE_INFO = 'RECEIVE_PROFILE_INFO';

export const REQUEST_REGISTER_INITIAL_VALUES = 'REQUEST_REGISTER_INITIAL_VALUES';
export const RECEIVE_REGISTER_INITIAL_VALUES = 'RECEIVE_REGISTER_INITIAL_VALUES';

export const REQUEST_EXISTING_ACCOUNTS = 'REQUEST_EXISTING_ACCOUNTS';
export const RECEIVE_EXISTING_ACCOUNTS = 'RECEIVE_EXISTING_ACCOUNTS';

export const REQUEST_RETRIEVE_ACCOUNT = 'REQUEST_RETRIEVE_ACCOUNT';
export const RECEIVE_RETRIEVE_ACCOUNT = 'RECEIVE_RETRIEVE_ACCOUNT';

export const REQUEST_LOGINFORM_DATA = 'REQUEST_LOGINFORM_DATA';
export const RECEIVE_LOGINFORM_DATA = 'RECEIVE_LOGINFORM_DATA';

export const REQUEST_PROFILE_DATA = 'REQUEST_PROFILE_DATA';
export const RECEIVE_PROFILE_DATA = 'RECEIVE_PROFILE_DATA';

export const REQUEST_ERASE_PROFILE_DATA = 'REQUEST_ERASE_PROFILE_DATA';
export const RECEIVE_ERASE_PROFILE_DATA = 'RECEIVE_ERASE_PROFILE_DATA';

export const REQUEST_CHECK_ACCOUNT = 'REQUEST_CHECK_ACCOUNT';
export const RECEIVE_CHECK_ACCOUNT = 'RECEIVE_CHECK_ACCOUNT';

export const FETCH_PROFILE_HOME_LINKS = 'FETCH_PROFILE_HOME_LINKS';
export const PROFILE_HOME_LINKS_FETCHED = 'PROFILE_HOME_LINKS_FETCHED';

export const FETCH_NEWS_ITEMS = 'FETCH_NEWS_ITEMS';
export const NEWS_ITEMS_FETCHED = 'NEWS_ITEMS_FETCHED';

export const FETCH_EMAIL_MESSAGES_COUNT = 'FETCH_EMAIL_MESSAGES_COUNT';
export const EMAIL_MESSAGES_COUNT_FETCHED = 'EMAIL_MESSAGES_COUNT_FETCHED';

export const FETCH_EMAIL_MESSAGES = 'FETCH_EMAIL_MESSAGES';
export const EMAIL_MESSAGES_FETCHED = 'EMAIL_MESSAGES_FETCHED';

export const MARK_EMAIL_READ = 'MARK_EMAIL_READ';
export const EMAIL_MARKED_READ = 'EMAIL_MARKED_READ';

export const REQUEST_REGISTER_PROFILE_FIELDS = 'REQUEST_REGISTER_PROFILE_FIELDS';
export const RECEIVE_REGISTER_PROFILE_FIELDS = 'RECEIVE_REGISTER_PROFILE_FIELDS';

export const REQUEST_PROFILE_INITIAL_VALUES = 'REQUEST_PROFILE_INITIAL_VALUES';
export const RECEIVE_PROFILE_INITIAL_VALUES = 'RECEIVE_PROFILE_INITIAL_VALUES';

export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE';
export const RECEIVE_UPDATE_PROFILE = 'RECEIVE_UPDATE_PROFILE';

export const REQUEST_CREATE_PROFILE = 'REQUEST_CREATE_PROFILE';
export const RECEIVE_CREATE_PROFILE = 'RECEIVE_CREATE_PROFILE';

export const REQUEST_DELETE_PROFILE_MSG = 'REQUEST_DELETE_PROFILE_MSG'; // deleting the create & update profile msgs
export const RECEIVE_DELETE_PROFILE_MSG = 'RECEIVE_DELETE_PROFILE_MSG';

export const REQUEST_UPLOAD_PROFILE_PIC = 'REQUEST_UPLOAD_PROFILE_PIC';
export const RECEIVE_UPLOAD_PROFILE_PIC = 'RECEIVE_UPLOAD_PROFILE_PIC';

export const REQUEST_DELETE_RESET_PASSWORD_MSG = 'REQUEST_DELETE_RESET_PASSWORD_MSG';
export const RECEIVE_DELETE_RESET_PASSWORD_MSG = 'RECEIVE_DELETE_RESET_PASSWORD_MSG';

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const EMAIL_UPDATED = 'EMAIL_UPDATED';

export const UPDATE_APPDIR = 'UPDATE_APPDIR';

export const REQUEST_CLEAR_EXISTING_ACCOUNTS = 'REQUEST_CLEAR_EXISTING_ACCOUNTS';
export const RECEIVE_CLEAR_EXISTING_ACCOUNTS = 'RECEIVE_CLEAR_EXISTING_ACCOUNTS';

//cookies
export const REQUEST_APPDIR_COOKIE = 'REQUEST_APPDIR_COOKIE';
export const RECEIVE_APPDIR_COOKIE = 'RECEIVE_APPDIR_COOKIE';

//ecommerce
export const REQUEST_PREV_SCREEN = 'REQUEST_PREV_SCREEN';
export const RECEIVE_PREV_SCREEN = 'RECEIVE_PREV_SCREEN';

export const REQUEST_SCREEN_COUNT = 'REQUEST_SCREEN_COUNT';
export const RECEIVE_SCREEN_COUNT = 'RECEIVE_SCREEN_COUNT';

export const REQUEST_MENU_OBJECTS = 'REQUEST_MENU_OBJECTS';
export const RECEIVE_MENU_OBJECTS = 'RECEIVE_MENU_OBJECTS';

export const REQUEST_ATTENDEE_ARRAY = 'REQUEST_ATTENDEE_ARRAY';
export const RECEIVE_ATTENDEE_ARRAY = 'RECEIVE_ATTENDEE_ARRAY';

export const REQUEST_CART_ITEMS = 'REQUEST_CART_ITEMS';
export const RECEIVE_CART_ITEMS = 'RECEIVE_CART_ITEMS';
export const REQUEST_LOGINFORM_COOKIE = 'REQUEST_LOGINFORM_COOKIE';
export const RECEIVE_LOGINFORM_COOKIE = 'RECEIVE_LOGINFORM_COOKIE';

export const FORM_DATA_FETCHING = 'FORM_DATA_FETCHING';
export const FORM_DATA_FETCH_SUCCESS = 'FORM_DATA_FETCH_SUCCESS';
export const FORM_DATA_FETCH_ERROR = 'FORM_DATA_FETCH_ERROR';
export const SAVE_FORM_SCREEN_DATA = 'SAVE_FORM_SCREEN_DATA';

export const FORM_VALIDATION_FETCHING = 'FORM_VALIDATION_FETCHING';
export const FORM_VALIDATION_FETCH_SUCCESS = 'FORM_VALIDATION_FETCH_SUCCESS';
export const FORM_VALIDATION_FETCH_ERROR = 'FORM_VALIDATION_FETCH_ERROR';

export const REQUEST_FREE_SELECTION_ITEMS = 'REQUEST_FREE_SELECTION_ITEMS';
export const RECEIVE_FREE_SELECTION_ITEMS = 'RECEIVE_FREE_SELECTION_ITEMS';

export const REQUEST_OPEN_SHOPPING_STYLE = 'REQUEST_OPEN_SHOPPING_STYLE';
export const RECEIVE_OPEN_SHOPPING_STYLE = 'RECEIVE_OPEN_SHOPPING_STYLE';

export const REQUEST_SELECTION_VIEW = 'REQUEST_SELECTION_VIEW'; // this toggles the list/grid view
export const RECEIVE_SELECTION_VIEW = 'RECEIVE_SELECTION_VIEW';

export const REQUEST_VALIDATION = 'REQUEST_VALIDATION';
export const RECEIVE_VALIDATION = 'RECEIVE_VALIDATION';

export const REQUEST_ATTENUUID_INFO = 'REQUEST_ATTENUUID_INFO'; // this also gets status and progress
export const RECEIVE_ATTENUUID_INFO = 'RECEIVE_ATTENUUID_INFO';

export const REQUEST_INVOICE_INFO = 'REQUEST_INVOICE_INFO';
export const RECEIVE_INVOICE_INFO = 'RECEIVE_INVOICE_INFO';

export const REQUEST_SELECTION_ARRAY = 'REQUEST_SELECTION_ARRAY';
export const RECEIVE_SELECTION_ARRAY = 'RECEIVE_SELECTION_ARRAY';
export const LOADING_SELECTION_ARRAY = 'LOADING_SELECTION_ARRAY';

export const REQUEST_CART_INFO = 'REQUEST_CART_INFO';
export const RECEIVE_CART_INFO = 'RECEIVE_CART_INFO';

export const REQUEST_ADD_REGISTRATION_CART = 'REQUEST_ADD_REGISTRATION_CART';
export const RECEIVE_ADD_REGISTRATION_CART = 'RECEIVE_ADD_REGISTRATION_CART';

export const REQUEST_ADD_SELECTION_CART = 'REQUEST_ADD_SELECTION_CART';
export const RECEIVE_ADD_SELECTION_CART = 'RECEIVE_ADD_SELECTION_CART';

export const REQUEST_DELETE_CART_ITEM = 'REQUEST_DELETE_CART_ITEM';
export const RECEIVE_DELETE_CART_ITEM = 'RECEIVE_DELETE_CART_ITEM';

export const REQUEST_PAYMENT_SETTINGS = 'REQUEST_PAYMENT_SETTINGS';
export const RECEIVE_PAYMENT_SETTINGS = 'RECEIVE_PAYMENT_SETTINGS';

export const REQUEST_ERASE_ECOMMERCE_DATA = 'REQUEST_ERASE_ECOMMERCE_DATA';
export const RECEIVE_ERASE_ECOMMERCE_DATA = 'RECEIVE_ERASE_ECOMMERCE_DATA';

export const REQUEST_RESET_ECOMMERCE_CART = 'REQUEST_RESET_ECOMMERCE_CART';
export const RECEIVE_RESET_ECOMMERCE_CART = 'RECEIVE_RESET_ECOMMERCE_CART';

export const REQUEST_ECOMMERCE_STORE_PRODUCT_TAGS = 'REQUEST_ECOMMERCE_STORE_PRODUCT_TAGS';
export const RECEIVE_ECOMMERCE_STORE_PRODUCT_TAGS = 'RECEIVE_ECOMMERCE_STORE_PRODUCT_TAGS';

export const REQUEST_ATTENDEE_SETTINGS = 'REQUEST_ATTENDEE_SETTINGS';
export const RECEIVE_ATTENDEE_SETTINGS = 'RECEIVE_ATTENDEE_SETTINGS';

export const REQUEST_ADD_ITEM_NO_LOGIN = 'REQUEST_ADD_ITEM_NO_LOGIN';
export const RECEIVE_ADD_ITEM_NO_LOGIN = 'RECEIVE_ADD_ITEM_NO_LOGIN';

export const REQUEST_ECOMMERCE_MODULARURLID = 'REQUEST_ECOMMERCE_MODULARURLID';
export const RECEIVE_ECOMMERCE_MODULARURLID = 'RECEIVE_ECOMMERCE_MODULARURLID';

export const REQUEST_DELETE_CART_ITEM_NOLOGIN = 'REQUEST_DELETE_CART_ITEM_NOLOGIN';
export const RECEIVE_DELETE_CART_ITEM_NOLOGIN = 'RECEIVE_DELETE_CART_ITEM_NOLOGIN';

export const REQUEST_ADD_ITEM_API_NO_LOGIN = 'REQUEST_ADD_ITEM_API_NO_LOGIN';
export const RECEIVE_ADD_ITEM_API_NO_LOGIN = 'RECEIVE_ADD_ITEM_API_NO_LOGIN';

export const REQUEST_ECOMMERCE_STORE_PRODUCTS = 'REQUEST_ECOMMERCE_STORE_PRODUCTS';
export const RECEIVE_ECOMMERCE_STORE_PRODUCTS = 'RECEIVE_ECOMMERCE_STORE_PRODUCTS';

export const REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES = 'REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES';
export const RECEIVE_ECOMMERCE_STORE_PRODUCT_PRICES = 'RECEIVE_ECOMMERCE_STORE_PRODUCT_PRICES';

export const REQUEST_FILTER_PRODUCTS_BY_PRICE = 'REQUEST_FILTER_PRODUCTS_BY_PRICE';

export const REQUEST_PUBLIC_TO_PRIVATE_CART = 'REQUEST_PUBLIC_TO_PRIVATE_CART';
export const RECEIVE_PUBLIC_TO_PRIVATE_CART = 'RECEIVE_PUBLIC_TO_PRIVATE_CART';

export const REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT = 'REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT';
export const RECEIVE_RESET_CUSTOM_CHECKOUT_COMPONENT = 'RECEIVE_RESET_CUSTOM_CHECKOUT_COMPONENT';

export const REQUEST_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT =
	'REQUEST_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT';
export const RECEIVE_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT =
	'RECEIVE_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT';

export const REQUEST_INCREMENT_SCREEN_NUM = 'REQUEST_INCREMENT_SCREEN_NUM';
export const REQUEST_RESET_SCREEN_NUM = 'REQUEST_RESET_SCREEN_NUM';

export const REQUEST_RESET_REGISTRATION_UPDATED = 'REQUEST_RESET_REGISTRATION_UPDATED';

export const REQUEST_SET_SCREEN_NUM = 'REQUEST_SET_SCREEN_NUM';

export const REQUEST_STORE_SHIPPING_DETAILS = 'REQUEST_STORE_SHIPPING_DETAILS';
export const RECEIVE_STORE_SHIPPING_DETAILS = 'RECEIVE_STORE_SHIPPING_DETAILS';

export const REQUEST_FLUSH_CART_ITEMS = 'REQUEST_FLUSH_CART_ITEMS';
export const RECEIVE_FLUSH_CART_ITEMS = 'RECEIVE_FLUSH_CART_ITEMS';

export const IS_SUB_FORM_LOADING ='IS_SUB_FORM_LOADING';

export const STORE_PRODUCT_DATA = 'STORE_PRODUCT_DATA';

export const REQUEST_PRODUCT_ACCESS_LIST = 'REQUEST_PRODUCT_ACCESS_LIST';
export const RECEIVE_PRODUCT_ACCESS_LIST = 'RECEIVE_PRODUCT_ACCESS_LIST';

export const REQUEST_VIDEO_SEEK_TIME = 'REQUEST_VIDEO_SEEK_TIME';
export const RECEIVE_VIDEO_SEEK_TIME = 'RECEIVE_VIDEO_SEEK_TIME';

// Corporate Membership
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const COMPANY_CREATED = 'COMPANY_CREATED';
export const FETCH_USER_COMPANY_AFFILIATION = 'FETCH_USER_COMPANY_AFFILIATION';
export const USER_COMPANY_AFFILIATION_FETCHED = 'USER_COMPANY_AFFILIATION_FETCHED';
export const FETCH_EXHIBITOR_CART_DETAILS = 'FETCH_EXHIBITOR_CART_DETAILS';
export const EXHIBITOR_CART_DETAILS_FETCHED = 'EXHIBITOR_CART_DETAILS_FETCHED';
export const FETCH_MENU = 'FETCH_MENU';
export const MENU_FETCHED = 'MENU_FETCHED';
export const FETCH_COMPANY_MEMBERS = 'FETCH_COMPANY_MEMBERS';
export const COMPANY_MEMBERS_FETCHED = 'COMPANY_MEMBERS_FETCHED';
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_MEDIA = 'FETCH_COMPANY_MEDIA';
export const COMPANY_MEDIA_FETCHED = 'COMPANY_MEDIA_FETCHED';
export const UPLOAD_TO_S3 = 'UPLOAD_TO_S3';
export const UPLOADED_TO_S3 = 'UPLOADED_TO_S3';
export const ADD_UPDATE_DELETE_MEDIA_RESOURCE = 'ADD_UPDATE_DELETE_MEDIA_RESOURCE';
export const ADDED_UPDATED_DELETED_MEDIA_RESOURCE = 'ADDED_UPDATED_DELETED_MEDIA_RESOURCE';
export const SORT_MEDIA_RESOURCE = 'SORT_MEDIA_RESOURCE';
export const SORTED_MEDIA_RESOURCE = 'SORTED_MEDIA_RESOURCE';
export const COMPANY_FETCHED = 'COMPANY_FETCHED';
export const MAKE_USER_ADMIN = 'MAKE_USER_ADMIN';
export const USER_ADMIN_MADE = 'USER_ADMIN_MADE';
export const REMOVE_USER_ADMIN = 'REMOVE_USER_ADMIN';
export const USER_ADMIN_REMOVED = 'USER_ADMIN_REMOVED';
export const REMOVE_USER_FROM_GROUP = 'REMOVE_USER_FROM_GROUP';
export const USER_REMOVED_FROM_GROUP = 'USER_REMOVED_FROM_GROUP';
export const REMOVE_USER_MEMBERSHIP = 'REMOVE_USER_MEMBERSHIP';
export const USER_MEMBERSHIP_REMOVED = 'USER_MEMBERSHIP_REMOVED';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const COMPANY_PROFILE_UPDATED = 'COMPANY_PROFILE_UPDATED';
export const ADD_USER_MEMBERSHIP = 'ADD_USER_MEMBERSHIP';
export const USER_MEMBERSHIP_ADDED = 'USER_MEMBERSHIP_ADDED';
export const FETCH_PAYMENT_SETTINGS = 'FETCH_PAYMENT_SETTINGS';
export const PAYMENT_SETTINGS_FETCHED = 'PAYMENT_SETTINGS_FETCHED';
export const UPDATE_USER_CONTACT_INFORMATION = 'UPDATE_USER_CONTACT_INFORMATION';
export const USER_CONTACT_INFORMATION_UPDATED = 'USER_CONTACT_INFORMATION_UPDATED';
export const ADD_NEW_USER_CONTACT = 'ADD_NEW_USER_CONTACT';
export const NEW_USER_CONTACT_ADDED = 'NEW_USER_CONTACT_ADDED';
export const FETCH_EXHIBITOR_CART = 'FETCH_EXHIBITOR_CART';
export const EXHIBITOR_CART_FETCHED = 'EXHIBITOR_CART_FETCHED';
export const SEND_EMAIL_TO_USERS = 'SEND_EMAIL_TO_USERS';
export const EMAIL_TO_USERS_SENT = 'EMAIL_TO_USERS_SENT';
export const ADD_REGISTRATION_ITEMS_TO_CART = 'ADD_REGISTRATION_ITEMS_TO_CART';
export const REGISTRATION_ITEMS_ADDED_TO_CART = 'REGISTRATION_ITEMS_ADDED_TO_CART';
export const DELETE_REGISTRATION_ITEM_FROM_CART = 'DELETE_REGISTRATION_ITEM_FROM_CART';
export const REGISTRATION_ITEM_DELETED_FROM_CART = 'REGISTRATION_ITEM_DELETED_FROM_CART';
export const FETCH_USER_CONTACT_INFO_FIELD_VALUES = 'FETCH_USER_CONTACT_INFO_FIELD_VALUES';
export const USER_CONTACT_INFO_FIELD_VALUES_FETCHED = 'USER_CONTACT_INFO_FIELD_VALUES_FETCHED';
export const FETCH_USER_CONTACT_INFO_FIELD_VALUES_ERROR =
	'FETCH_USER_CONTACT_INFO_FIELD_VALUES_ERROR';
export const UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO';
export const COMPANY_LOGO_UPDATED = 'COMPANY_LOGO_UPDATED';
export const RENEW_ALL = 'RENEW_ALL';
export const ALL_RENEWED = 'ALL_RENEWED';
export const ADD_USER_TO_COMPANY = 'ADD_USER_TO_COMPANY';
export const USER_ADDED_TO_COMPANY = 'USER_ADDED_TO_COMPANY';
export const REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA = 'REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA';
export const RECEIVE_ERASE_CORPORATE_MEMBERSHIP_DATA = 'RECEIVE_ERASE_CORPORATE_MEMBERSHIP_DATA';
export const REQUEST_RESET_CART = 'REQUEST_RESET_CART';
export const RECEIVE_RESET_CART = 'RECEIVE_RESET_CART';
export const FETCH_PUBLIC_CART = 'FETCH_PUBLIC_CART';
export const PUBLIC_CART_FETCHED = 'PUBLIC_CART_FETCHED';
export const FETCH_COMPANY_PROFILE_FIELD_VALUES = 'FETCH_COMPANY_PROFILE_FIELD_VALUES';
export const COMPANY_PROFILE_FIELD_VALUES_FETCHED = 'COMPANY_PROFILE_FIELD_VALUES_FETCHED';
export const DELETE_USER_COMPANY_AFFILIATION = 'DELETE_USER_COMPANY_AFFILIATION';
export const USER_COMPANY_AFFILIATION_DELETED = 'USER_COMPANY_AFFILIATION_DELETED';
export const UPDATE_FINANCIAL_ITEMS_FORM_STATE = 'UPDATE_FINANCIAL_ITEMS_FORM_STATE';
export const FINANCIAL_ITEMS_FORM_STATE_UPDATED = 'FINANCIAL_ITEMS_FORM_STATE_UPDATED';
export const FETCH_EXHIBITOR_CART_UUID = 'REQUEST_EXHIBITOR_CART_UUID';
export const EXHIBITOR_CART_UUID_FETCHED = 'EXHIBITOR_CART_UUID_FETCHED';
export const EXHIBITOR_SIGNUP = 'EXHIBITOR_SIGNUP';
export const EXHIBITOR_SIGNEDUP = 'EXHIBITOR_SIGNEDUP';
export const MAKE_CART_PUBLIC_TO_PRIVATE = 'MAKE_CART_PUBLIC_TO_PRIVATE';
export const CART_MADE_PUBLIC_TO_PRIVATE = 'CART_MADE_PUBLIC_TO_PRIVATE';
export const SET_CURRENT_FINANCIAL_ITEM = 'SET_CURRENT_FINANCIAL_ITEM';
export const SEND_MAGIC_LOGIN_LINK = 'SEND_MAGIC_LOGIN_LINK';
export const DELETE_EXHIBITOR_PAYMENT = 'DELETE_EXHIBITOR_PAYMENT';
export const EXHIBITOR_PAYMENT_DELETED = 'EXHIBITOR_PAYMENT_DELETED';
export const FETCH_ROLES = 'FETCH_ROLES';
export const ROLES_FETCHED = 'ROLES_FETCHED';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const ROLES_UPDATED = 'ROLES_UPDATED';

export const REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID = 'REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID';
export const RECEIVE_CORPORATE_MEMBERSHIP_MODULEURLID = 'RECEIVE_CORPORATE_MEMBERSHIP_MODULEURLID';

export const REQUEST_MAKE_USER_PUBLIC = 'REQUEST_MAKE_USER_PUBLIC';
export const RECEIVE_MAKE_USER_PUBLIC = 'RECEIVE_MAKE_USER_PUBLIC';

export const REQUEST_REMOVE_USER_PUBLIC = 'REQUEST_REMOVE_USER_PUBLIC';
export const RECEIVE_REMOVE_USER_PUBLIC = 'RECEIVE_REMOVE_USER_PUBLIC';

export const REQUEST_CLEAR_NEW_USER_ADDED_ERROR = 'REQUEST_CLEAR_NEW_USER_ADDED_ERROR';
export const RECEIVE_CLEAR_NEW_USER_ADDED_ERROR = 'RECEIVE_CLEAR_NEW_USER_ADDED_ERROR';

export const REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG =
	'REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG';
export const RECEIVE_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG =
	'RECEIVE_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG';

export const REQUEST_CLEAR_NEW_USER_ADDED_SUCCESS = 'REQUEST_CLEAR_NEW_USER_ADDED_SUCCESS';
export const RECEIVE_CLEAR_NEW_USER_ADDED_SUCCESS = 'RECEIVE_CLEAR_NEW_USER_ADDED_SUCCESS';

export const REQUEST_MINI_PROFILE_FIELDS = 'REQUEST_MINI_PROFILE_FIELDS';
export const RECEIVE_MINI_PROFILE_FIELDS = 'RECEIVE_MINI_PROFILE_FIELDS';

export const REQUEST_UPDATE_MINI_PROFILE_FIELDS = 'REQUEST_UPDATE_MINI_PROFILE_FIELDS';
export const RECEIVE_UPDATE_MINI_PROFILE_FIELDS = 'RECEIVE_UPDATE_MINI_PROFILE_FIELDS';

export const REQUEST_CREATE_MINI_PROFILE_FIELDS = 'REQUEST_CREATE_MINI_PROFILE_FIELDS';
export const RECEIVE_CREATE_MINI_PROFILE_FIELDS = 'RECEIVE_CREATE_MINI_PROFILE_FIELDS';

export const REQUEST_ADD_COMPANY_USER = 'REQUEST_ADD_COMPANY_USER';
export const RECEIVE_ADD_COMPANY_USER = 'RECEIVE_ADD_COMPANY_USER';

// common
export const REQUEST_SEND_PAYMENT = 'REQUEST_SEND_PAYMENT';
export const RECEIVE_SEND_PAYMENT = 'RECEIVE_SEND_PAYMENT';

export const REQUEST_SEND_PAYMENT_EXHIBITOR = 'REQUEST_SEND_PAYMENT_EXHIBITOR';
export const RECEIVE_SEND_PAYMENT_EXHIBITOR = 'RECEIVE_SEND_PAYMENT_EXHIBITOR';

export const REQUEST_RESET_INVALID_CREDIT_CARD = 'REQUEST_RESET_INVALID_CREDIT_CARD';
export const RECEIVE_RESET_INVALID_CREDIT_CARD = 'RECEIVE_RESET_INVALID_CREDIT_CARD';

export const REQUEST_ERASE_COMMON_DATA = 'REQUEST_ERASE_COMMON_DATA';
export const RECEIVE_ERASE_COMMON_DATA = 'RECEIVE_ERASE_COMMON_DATA';

export const REQUEST_RESET_PAYMENT_SUCCESS = 'REQUEST_RESET_PAYMENT_SUCCESS';
export const RECEIVE_RESET_PAYMENT_SUCCESS = 'RECEIVE_RESET_PAYMENT_SUCCESS';
export const REQUEST_PAYMENT_LOADER = 'REQUEST_PAYMENT_LOADER'
// user products

export const REQUEST_USER_PRODUCT_LIST = 'REQUEST_USER_PRODUCT_LIST';
export const RECEIVE_USER_PRODUCT_LIST = 'RECEIVE_USER_PRODUCT_LIST';

export const REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW =
	'REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW'; //for toggling the list/ grid view
export const RECEIVE_USER_PRODUCTS_LIST_SELECTION_VIEW =
	'RECEIVE_USER_PRODUCTS_LIST_SELECTION_VIEW';

export const REQUEST_PRODUCT_CATEGORY = 'REQUEST_PRODUCT_CATEGORY';
export const RECEIVE_PRODUCT_CATEGORY = 'RECEIVE_PRODUCT_CATEGORY';

export const REQUEST_ERASE_USER_PRODUCTS_DATA = 'REQUEST_ERASE_USER_PRODUCTS_DATA';
export const RECEIVE_ERASE_USER_PRODUCTS_DATA = 'RECEIVE_ERASE_USER_PRODUCTS_DATA';

export const REQUEST_TOGGLE_CALENDAR_VIEW = 'REQUEST_TOGGLE_CALENDAR_VIEW';
export const RECEIVE_TOGGLE_CALENDAR_VIEW = 'RECEIVE_TOGGLE_CALENDAR_VIEW';

export const REQUEST_USER_PRODUCT_TAGS = 'REQUEST_USER_PRODUCT_TAGS';
export const RECEIVE_USER_PRODUCT_TAGS = 'RECEIVE_USER_PRODUCT_TAGS';

/* ORDER HISTORY PAGE */

export const REQUEST_ORDER_HISTORY_DATA = 'REQUEST_ORDER_HISTORY_DATA';
export const RECEIVE_ORDER_HISTORY_DATA = 'RECEIVE_ORDER_HISTORY_DATA';

export const REQUEST_ORDER_HISTORY_VIEW_DATA = 'REQUEST_ORDER_HISTORY_VIEW_DATA';
export const RECEIVE_ORDER_HISTORY_VIEW_DATA = 'RECEIVE_ORDER_HISTORY_VIEW_DATA';

export const REQUEST_ORDER_HISTORY_EMAIL_DATA = 'REQUEST_ORDER_HISTORY_EMAIL_DATA';
export const RECEIVE_ORDER_HISTORY_EMAIL_DATA = 'RECEIVE_ORDER_HISTORY_EMAIL_DATA';

export const REQUEST_ORDER_HISTORY_DOWNLOAD_DATA = 'REQUEST_ORDER_HISTORY_DOWNLOAD_DATA';
export const RECEIVE_ORDER_HISTORY_DOWNLOAD_DATA = 'RECEIVE_ORDER_HISTORY_DOWNLOAD_DATA';

/* COMMON UPDATE REDUCER */
export const ORDER_HISTORY_UPDATE_REDUCER = 'ORDER_HISTORY_UPDATE_REDUCER';

// Consumption
export const REQUEST_CONSUMPTION_PRODUCTS = 'REQUEST_CONSUMPTION_PRODUCTS';
export const RECEIVE_CONSUMPTION_PRODUCTS = 'RECEIVE_CONSUMPTION_PRODUCTS';
export const REQUEST_CONSUMPTION_TAGS = 'REQUEST_CONSUMPTION_TAGS';
export const RECEIVE_CONSUMPTION_TAGS = 'RECEIVE_CONSUMPTION_TAGS';
export const REQUEST_CONSUMPTION_CATEGORIES = 'REQUEST_CONSUMPTION_CATEGORIES';
export const RECEIVE_CONSUMPTION_CATEGORIES = 'RECEIVE_CONSUMPTION_CATEGORIES';
export const REQUEST_CONSUMPTION_PRODUCT_INFO = 'REQUEST_CONSUMPTION_PRODUCT_INFO';
export const RECEIVE_CONSUMPTION_PRODUCT_INFO = 'RECEIVE_CONSUMPTION_PRODUCT_INFO';
export const REQUEST_CONSUMPTION_BUNDLE_INFO = 'REQUEST_CONSUMPTION_BUNDLE_INFO';
export const RECEIVE_CONSUMPTION_BUNDLE_INFO = 'RECEIVE_CONSUMPTION_BUNDLE_INFO';
export const REQUEST_CONSUMPTION_PRODUCT_ANALYSIS = 'REQUEST_CONSUMPTION_PRODUCT_ANALYSIS';
export const RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS = 'RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS';
export const REQUEST_CONSUMPTION_FILES_ANALYSIS = 'REQUEST_CONSUMPTION_FILES_ANALYSIS';
export const RECEIVE_CONSUMPTION_FILES_ANALYSIS = 'RECEIVE_CONSUMPTION_FILES_ANALYSIS';
export const STORE_CARDS_VIEW_TYPE = 'STORE_CARDS_VIEW_TYPE';
export const STORE_SORT_TYPE = 'STORE_SORT_TYPE';
export const REQUEST_STORE_SEARCH_STATE = 'REQUEST_STORE_SEARCH_STATE';
export const RECEIVE_OPEN_ACCESS_CONFIG = 'RECEIVE_OPEN_ACCESS_CONFIG';
export const REQUEST_VIDEO_START = 'REQUEST_VIDEO_START'
export const RECEIVE_VIDEO_START = 'RECEIVE_VIDEO_START'
export const REQUEST_END_VIDEO = 'REQUEST_END_VIDEO'
export const RECEIVE_END_VIDEO = 'RECEIVE_END_VIDEO'
export const UPDATED_PRODUCT_COUNT = 'UPDATED_PRODUCT_COUNT';

// FloorPlan

export const FETCH_COMPANY_INFO_REQUEST = 'FETCH_COMPANY_INFO_REQUEST';
export const FETCH_COMPANY_INFO_SUCCESS = 'FETCH_COMPANY_INFO_SUCCESS';
export const FETCH_COMPANY_INFO_FAILURE = 'FETCH_COMPANY_INFO_FAILURE';

export const FETCH_BOOTH_CONFIGURATION_REQUEST = 'FETCH_BOOTH_CONFIGURATION_REQUEST';
export const FETCH_BOOTH_CONFIGURATION_SUCCESS = 'FETCH_BOOTH_CONFIGURATION_SUCCESS';
export const FETCH_BOOTH_CONFIGURATION_FAILURE = 'FETCH_BOOTH_CONFIGURATION_FAILURE';

export const UPDATE_EXHIBITOR_HASH_MAP = 'UPDATE_EXHIBITOR_HASH_MAP';
export const HANDLE_BOOTH_LIST_LOADER = 'HANDLE_BOOTH_LIST_LOADER';

export const STORE_EMBED_MODE = 'STORE_EMBED_MODE';
